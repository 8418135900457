import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { LongPara, TableContainer, TableWrapper } from "../styles";

const Transport = () => {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("central_facilities_transport_bus_service_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <LongPara>
        {t("central_facilities_transport_body")}
      </LongPara>

      <TableContainer>
        <TableWrapper>
          <table>
            <thead>
              <tr id="headRow">
                <th>{t("central_facilities_transport_table_key_1")}</th>
                <th>{t("central_facilities_transport_table_key_2")}</th>
                <th>{t("central_facilities_transport_table_key_3")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("central_facilities_transport_table_value_1_1")}</td>
                <td>{t("central_facilities_transport_table_value_1_2")}</td>
                <td> {t("central_facilities_transport_table_value_3_1")}</td>
              </tr>
              <tr>
                <td>{t("central_facilities_transport_table_value_1_2")}</td>
                <td> {t("central_facilities_transport_table_value_1_1")}</td>
                <td> {t("central_facilities_transport_table_value_3_2")}</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

export default Transport;
