import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
const SectionWrapper = styled.section`
  width: 100%;
  padding-left: 2.5em;
  padding-right: 1.5em;
  margin: auto -10px auto;
  @media only screen and (max-width: 1440px) {
    // padding: 70px 0;
  }
  @media only screen and (max-width: 1360px) {
    // padding: 50px 0;
  }
  @media only screen and (max-width: 991px) {
    // padding: 40px 0;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  box-shadow: 1px 2px 15px gray;
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1440px) {
    margin: 60px -30px 0;
  }
  @media only screen and (max-width: 1360px) {
    margin: 60px -15px 0;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    box-shadow: none;
    border: 1px solid lightgray;
    margin-left: 5px;
    margin-right: 5px;
  }
  .quick_link_container {
    position: relative;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .thumbnail {
    width: 70px;
    height: 70px;
  }
  .quick_link_container h3 {
    padding-top: 8px;
    color: ${themeGet("colors.secondary")};
  }
  .quick_link_container p {
    text-wrap: break-word;
    font-size: 16px;
    padding: 8px 20px;
  }
  .right_arrow {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: ${themeGet("colors.secondary")};
  }
  @media (max-width: 991px) {
    .quick_link_container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
`;

export default SectionWrapper;
