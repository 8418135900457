import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import { SectionHeader, SliderWrapper } from "./ResearchSection.style";
import { getShortString } from "../../../functions/utils";
import { getRequest } from "../../../functions/request";

const WorkSection = () => {
  const [researchData, setResearchData] = useState([]);

  const getResearchData = useCallback(async () => {
    try {
      let res = await getRequest("/publications/recent");
      if (res && res.data) { // Check if res and res.data are defined
        let data = res.data;
        setResearchData(data);
        setTimeout(() => {
          console.log(researchData);
        }, 2000);
      } else {
        console.error("Response or data is undefined");
      }
    } catch (error) {
      console.error("Error fetching research data:", error);
    }
  }, []);
  

  useEffect(getResearchData, [getResearchData]);
  const { t } = useTranslation();
  var settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section id="publication">
      <Container width="90%">
        <SectionHeader>
          <Heading content={t("publication")} />
        </SectionHeader>
        <SliderWrapper>
          <Slider {...settings}>
            {researchData.result ? (
              researchData.result.map((item, index) => (
                <div key={index}>
                  <div
                    className="researchCard"
                    style={
                      index % 2 === 0
                        ? { backgroundColor: "#f5f8fd", textAlign: "justify" }
                        : { backgroundColor: "#fff", textAlign: "justify" }
                    }
                  >
                    <h3 className="title" style={{ display: "inline" }}>
                      {item.authors} ({item.pub_date})
                    </h3>
                    <p style={{ display: "inline" }}>{item.title}</p>
                    
                    <p style={{ display: "inline" }} className="desc">
                      <i>{item.journal}</i>
                      
                    </p>
                    <br />
                    <a href={item.link} target="_blank" rel="noreferrer">
                      Link
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <p>No Researches to show</p>
            )}
          </Slider>
        </SliderWrapper>
      </Container>
    </section>
  );
};

export default WorkSection;
