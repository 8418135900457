import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { getRequest } from "../../../../functions/request";

const OtherAcademicActivities = () => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const activitiesData = await getRequest(
        `faculty/get_other_activities/${id}`,
        false
      );
      setActivities(activitiesData.data.result);
      setIsLoading(false);
    };

    getData();
  }, [id]);

  return (
    <>
      {isLoading && activities.length <= 0 && <Skeleton active />}
      {activities &&
        activities.map((activity) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: activity.activities }}
              key={activity.id}
              className="profile-section"
            ></div>
          );
        })}
        {activities.length === 0 ? ("Nothing Found") : null}
    
    </>
  );
};

export default OtherAcademicActivities;
