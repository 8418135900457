import React from "react";
import Container from "../../../Components/UI/Container";

import { useTranslation } from "react-i18next";

import Heading from "../../../Components/Heading";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
function People({ fontsize }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="List of important contacts of the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,contacts,contact-details of nit jsr"
        />
      </Helmet>
      <Container width="100%">
        <br />
        {/* <Heading content={t("Deans")} /> */}
        {/* <TableWrapper>
          <table className="table">
            <thead>
              <tr id="headRow">
                <th>{t("people_title1_thead1")}</th>
                <th>{t("people_title1_thead2")}</th>
                <th>{t("people_title1_thead3")}</th>
              </tr>
            </thead>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title1_tbody1_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title1_tbody1_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                dean.ac#
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title1_tbody2_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title1_tbody2_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                assodean.ac#
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title1_tbody3_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title1_tbody3_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                assodean.ac#
              </td>
            </tr>
          </table>
        </TableWrapper> */}
        <Heading content={t("people_title2")} />
        <TableWrapper>
          <table className="table">
            <thead>
              <tr>
                <th>{t("people_title2_thead1")}</th>
                <th>{t("people_title2_thead2")}</th>
                <th>{t("people_title2_thead3")}</th>
              </tr>
            </thead>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody1_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody1_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                0657 237 4131
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody2_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody2_tr2.1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                Not available
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody4_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody4_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9417 923 801
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody5_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody5_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                7091 133 048
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody6_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title1_tbody3_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                8917590346
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody6_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody6_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                9990742787
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody6_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody6_tr2.1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                8436240573
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody6_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody6_tr2.2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody6_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody6_tr2.3")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Email">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_coordinator_examinations")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_coordinator_exam_person1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_coordinator_examinations")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_coordinator_exam_person2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9853463966
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_coordinator_time_table")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_coordinator_time_table_person1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_coordinator_time_table")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_coordinator_time_table_person2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_coordinator_Samarth")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_coordinator_Samarth_person")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9589633179
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody11_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody11_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                {t("Not Available")}
              </td>
            </tr>
            {/* <tr>
            <td style={{ fontSize: fontsize }} data-column="Post">
              Assistant Dean (Academic) : Accreditation
            </td>
            <td style={{ fontSize: fontsize }} data-column="Name">
              Dr. Ch. M. S. Rao
            </td>
            <td style={{ fontSize: fontsize }} data-column="Contact">
              7870 344 602
            </td>
          </tr> */}
            {/* <tr>
              <td style={{ fontSize: fontsize }} data-column="Post">
                {t("people_title2_tbody8_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody8_tr2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9279 011 076
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: fontsize }} colSpan="3"></td>
            </tr> */}
            {/* <tr>
              <td style={{ fontSize: fontsize }} rowSpan="3" data-column="Post">
                {t("people_title2_tbody9_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody9_tr2_1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9204 873 604
              </td>
            </tr>
            <tr>
              <td
                style={{ fontSize: fontsize }}
                data-column="Post"
                className="trshmob"
              >
                {t("people_title2_tbody9_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody9_tr2_2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9431 752 990
              </td>
            </tr>
            <tr>
              <td
                style={{ fontSize: fontsize }}
                data-column="Post"
                className="trshmob"
              >
                {t("people_title2_tbody9_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody9_tr2_3")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                7779 884 549
              </td>
            </tr> */}
            {/* <tr>
              <td style={{ fontSize: fontsize }} colSpan="3"></td>
            </tr> */}
            <tr>
              <td style={{ fontSize: fontsize }} rowSpan="4" data-column="Post">
                {t("people_title2_tbody10_tr1")}
              </td>
              {/* <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody10_tr2_1")}
              </td> */}
              {/* <td style={{ fontSize: fontsize }} data-column="Contact">
                9470 126 910
              </td> */}
            </tr>
            <tr>
              <td
                style={{ fontSize: fontsize }}
                data-column="Post"
                className="trshmob"
              >
                {t("people_title2_tbody10_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody10_tr2_2")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                {t("Not Available")}
              </td>
            </tr>
            <tr>
              <td
                style={{ fontSize: fontsize }}
                data-column="Post"
                className="trshmob"
              >
                {t("people_title2_tbody10_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody10_tr2_3")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                9162 029 698
              </td>
            </tr>
            <tr>
              <td
                style={{ fontSize: fontsize }}
                data-column="Post"
                className="trshmob"
              >
                {t("people_title2_tbody10_tr1")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Name">
                {t("people_title2_tbody10_tr2_4")}
              </td>
              <td style={{ fontSize: fontsize }} data-column="Contact">
                7781 025 637
              </td>
            </tr>
          </table>
        </TableWrapper>
        <Heading
          content={t("Academics_subtitle6")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />

        <div>
          <Table
            striped
            bordered
            hover
            responsive="md"
            style={{ margin: "50px auto" }}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Person concerned</th>
                <th>E-mail </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>Superintendent (Academic)</td>
                <td>sup.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>Dealing Assistant (fee remission & fee demand)</td>
                <td>ministerial1.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  Dealing Assistant (examination, result, issuance /
                  verification of certificates & transcript)
                </td>
                <td>ministerial2.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  Dealing Assistant (withdrawal, PhD cases, duplicate degree)
                </td>
                <td>ministerial3.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>5.</td>
                <td>Dealing Assistant (caution money, stipend)</td>
                <td>ministerial4.ac@nitjsr.ac.in</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
}

export default People;
