//Recruitments Setion
import { useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { scrollToContent } from "../../functions/scroll.js";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Footer from "../../Components/Layout/Footer";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Policies() {
  useEffect(() => {
    scrollToContent(0);
  })

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          Policies | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <div className="mt-5 col-lg-8 mx-auto">
          <Container className="mt-5">
            <h1 className="text-center">
              <strong>Hyperlinking Policy</strong>
            </h1>
            <p>At many places in this website, you shall find links to other websites/portals. These links have been placed for your convenience. WebTeam is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as an endorsement of any kind. We cannot guarantee that these links will work all the time and we have no control over availability of linked pages.</p>
            <p>Prior permission is required before hyperlinks are directed from any website/portal to this site. Permission for the same, stating the nature of the content on the pages where the link has to be given and the exact language of the Hyperlink should be obtained by sending a request to WebTeam.</p>
          </Container>
        </div>


        <ContentWrapper>
          <div className="mt-5 col-lg-8 mx-auto mb-5">
            <h1 className="text-center">
              <strong>Copyright Policy</strong>
            </h1>
            <p>
              "NIT Jamshedpur" respects the intellectual property rights and other proprietary rights of the Content Owners. This Copyright Policy is incorporated in our “Terms of service & policies”, which also apply when you use our “Site”.
            </p>
            <p>
              In case you believe you are the creator or owner of any content hosted or displayed by NIT Jamshedpur, and have Intellectual Property (IP) rights over such content, you are requested to immediately contact WebTeam. Please note it is not the intent of NIT Jamshedpur to infringe on any copyright or IP right.
            </p>
            <p>
              To enable us to take the required action, the copyright owner or legal representative of the copyright owner should provide material documented proof of their rights and also specify exactly which content they are referring to, as well as the location on NIT Jamshedpur “Site” where they believe it is displayed.
            </p>
            <p>
              The copyright owner or legal representative of the copyright owner is also requested to provide a statement, stating the authorization, address, telephone number, and email address for correspondence. We would like to state that any communication without authorization, will not be acted on.
              Unless notified, we are not responsible for any infringement, and it is the sole responsibility of the copyright owner.
            </p>
          </div>
        </ContentWrapper>



        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Policies;
