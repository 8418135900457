let storage = {
	has(key) {
		var name = key + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return true;
			}
		}
		return false;
	},
	set(key, value) {
		var d = new Date();
		d.setTime(d.getTime() + 3600000); //1hrs
		var expires = 'expires=' + d.toUTCString();
		document.cookie =
			key + '=' + JSON.stringify(value) + ';' + expires + ';path=/';
	},
	get(key) {
		var name = key + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return JSON.parse(c.substring(name.length, c.length));
			}
		}
		return '';
	},
	clear() {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf('=');
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	},
	remove(key) {
		document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
	},
	isSupported() {
		let test = '_test';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	},
};

export default storage;
