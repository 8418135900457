import React from "react";
import getData from "../Common/getData";
import { Card, Image } from "antd";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/default.jpg";

function Deans() {
  const [ response] = getData("/people/deans?type=dean");
  return (
    <div className="row mx-auto my-5">
      {/* <List
              section="Deans"
              people={response}
              error={error}
              showProfile={1}
            /> */}
      {response
        ? response.map((value) => {
            console.log(value);
            return (
              <Card
                hoverable
                className="mx-auto my-3"
                style={{ width: 240 }}
                cover={
                  <Image
                    src={value.image}
                    fallback={avatar}
                    />
                }
              >
                <Link
                  to={"/people/profile/" + value.id}
                  className="text-primary"
                >
                  <center>
                    <b>{value.name} </b>
                  </center>
                </Link>
                <p className="text-center">
                  {value.committee}
                  <br />
                  <a href={"mailto:" + value.email} target="_blank" rel="noreferrer">
                    {value.email}
                  </a>
                </p>
              </Card>
            );
          })
        : null}
    </div>
  );
}

export default Deans;
