import styled from 'styled-components';

export const NoticeWrapper = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 32px;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
  max-height: 350px;
  overflow-y: auto;
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 8px;
  }
`;

export const NoticePointWrapper = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const NoticeTitleWrapper = styled.div`
  text-align: justify;
  @media (max-width: 767px) {
    text-align: left;
  }
`