import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../assets/css/Custom/DepartmentStyle";
import avatar from "../../../assets/images/default.jpg";
import { DeptData } from "../../../assets/data/DepartmentData";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";

function HOD({ Dept }) {
  const [, setHodData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const HODHandler = async () => {
      try {
        let res = await getRequest(`hod?branch=${Dept}`);
        setHodData(res.data);
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    HODHandler();
  }, [Dept]);

  return (
    <div>
      <BoxWrapper>
        <div className="head-title">
          <p>{t("Head_Of_Department")}</p>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {DeptData.map((item, i) => (
              <>
                {item.dept === Dept ? (
                  <div key={`hod_${i}`} className="mycard">
                    <div className="thumbnail text-center">
                      <img
                        src={item.hod.image === "" ? avatar : item.hod.image}
                        alt="hod"
                        id="hodimg"
                        style={{
                          width: "180px",
                          height: "180px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <hr />
                    <div className="caption content NameArea">
                      <h5
                        className="text-center"
                        style={{ fontWeight: "600", color: "#274187" }}
                      >
                        {t(`hod_name_${item.dept}`)}
                      </h5>
                      <p className="text-center">{t(`hod_des_${item.dept}`)}</p>
                    </div>
                    <div className="caption content contact">
                      <p className="text-center" id="con-head">
                        {t("Contact")}
                      </p>
                      <hr />
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          {/* <div className="row">
                            <div className="col left-contact">
                              <b>{t("Phone")}(HOD) </b>
                            </div>
                            <div className="col right-text">
                              {item.contact.PhoneHod !== "" ? (
                                item.contact.PhoneHod
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col left-contact">
                              <b>
                                {t("Phone")}(HOD {t("Office")}){" "}
                              </b>
                            </div>
                            <div className="col right-text">
                              {item.contact.PhoneHodOffice !== "" ? (
                                item.contact.PhoneHodOffice
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left-contact">
                              <b>{t("Email")} </b>
                            </div>
                            <div className="col right-text">
                              {item.contact.email !== "" ? (
                                item.contact.email
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left-contact">
                              <b>{t("Fax")} </b>
                            </div>
                            <div className="col right-text">
                              {item.contact.fax !== "" ? (
                                item.contact.fax
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left-contact">
                              <b>{t("Address")} </b>
                            </div>
                            <div className="col right-text">{t("hod_add")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default HOD;
