import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import { ParagraphWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import Icon from "react-icons-kit";
import { Helmet } from "react-helmet";

function AboutUs({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="NIT Jamshedpur adopts the MHRD, Government of India guidelines for admission to all academic programmes of the Institute."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,admissions,selections,entrance"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content="General Information"
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <img src="https://imgtr.ee/images/2023/06/07/pe14Y.png" class="img-fluid" alt="souvenir"></img>
        <Heading
          content={t("admissions_subtitle")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <ParagraphWrapper>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            {t("subtitle_para")}
          </p>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            <Icon icon={chevronCircleRight} size={18} />{" "}
            <b className="course_type">{t("course1")}</b>
            <p className="pl-3">
              {t("course1_para")}&nbsp;
              <span className="linktext">
                <Link to="/Academic/B.Tech_Admission" className="linktext">
                  {t("admission_link1")}
                </Link>
              </span>
            </p>
          </p>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            <Icon icon={chevronCircleRight} size={18} /> <b>{t("course2")}</b>
            <p className="pl-3">
              {t("course2_para")}&nbsp;
              <span className="linktext">
                <a href="/Academic/M.Tech_Admission" className="linktext">
                  {t("admission_link2")}
                </a>
              </span>
            </p>
          </p>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            <Icon icon={chevronCircleRight} size={18} /> <b>{t("course3")}</b>
            <p className="pl-3">
              {t("course3_para")}&nbsp;
              <span className="linktext">
                <a href="/Academic/M.Sc._Admission" className="linktext">
                  {t("admission_link3")}
                </a>
              </span>
            </p>
          </p>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            <Icon icon={chevronCircleRight} size={18} /> <b>{t("course4")}</b>
            <p className="pl-3">
              {t("course4_para")}
              <span className="linktext">
                <a href="https://www.nimcet.in/" className="linktext">
                  {t("admission_link4")}
                </a>
              </span>
            </p>
          </p>
          <p style={{ fontSize: fontsize }} className="smallparatext">
            <Icon icon={chevronCircleRight} size={18} /> <b>{t("course5")}</b>
            <p className="pl-3">
              {t("course5_para")}&nbsp;
              <span className="linktext">
                <Link to="/" className="linktext">
                  {t("admission_link5")}
                </Link>
              </span>
            </p>
          </p>
        </ParagraphWrapper>
      </Container>
    </>
  );
}

// HumanityBlock style props
AboutUs.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AboutUs default style
AboutUs.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default AboutUs;
