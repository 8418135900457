import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Stack, Pagination } from "@mui/material";
import { getRequest } from "../../../../functions/request";
import { Tag, Card, Skeleton, Tabs } from "antd";
import { Modal } from "react-bootstrap";
import { Button } from "antd";

export default function TabItem(props) {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [articleContent, setArticleContent] = useState(null);

  const publications = props.publications;
  const pubType = props.pubType;
  const data = publications.filter((pub) => pub?.type === pubType.value);

  const eventsPerPage = 7;
  const pageCount = Math.ceil(data.length / eventsPerPage);
  let currPageEvents = data.slice(0, eventsPerPage);

  const [page, setPage] = useState(1);

  const StartEventNo = (page - 1) * eventsPerPage;
  const endEventNo = page * eventsPerPage;
  currPageEvents = data.slice(StartEventNo, endEventNo);
  const paginate = (e, value) => {
    setPage(value);
  };

  const FetchArticleContent = async (currentArticle) => {
    if (currentArticle) {
      setArticleContent(null);
      var Article = await getRequest(
        `/publications/view?id=${currentArticle.id}`
      );
      setArticleContent(Article.data.result);
    }
  };
  return (
    <>
      {publications.filter((pub) => pub?.type === pubType.value).length === 0
        ? `No ${pubType.text} found`
        : currPageEvents.map((value) => (
            <div className="mt-2 border-bottom border-left border-left-primary pl-2 pt-2"
            style={{cursor: "pointer", width: "100%"}} onClick={() =>{
              FetchArticleContent(value);
              handleShow();
            }}>
              <p style={{textAlign:"justify"}}>
                <b>{`${value.title}, `}</b>

                {`${value.journal}  ${
                  value.volume ? ` ${value.volume}` : ""
                } (${value.pub_date})  ${
                  value.page_no ? `,pp. ${value.page_no}` : ""
                }, ${value.authors.split(",").map((item) => item)}`}
              </p>
            </div>
          ))}
      {data.length > eventsPerPage && (
        <Stack mt={5} alignItems="center">
          <Pagination
            count={pageCount}
            page={page}
            color="primary"
            defaultPage={1}
            onChange={paginate}
            size="large"
          />
        </Stack>
      )}

<Modal
        show={show}
        onHide={handleClose}
        size="lg"
        style={{ zIndex: "99999" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {articleContent ? articleContent.type : "------------"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {articleContent ? (
            <>
              <b>Title:</b> {articleContent.title}
              <br />
              <b>Date:</b> {articleContent.pub_date}
              <br />
              <b>Journal:</b> {articleContent.journal}
              <br />
              <b>{articleContent.volume ? "Volume" : ""}</b>{" "}
              {articleContent.volume ? articleContent.volume : ""}
              <br></br>
              <b>{articleContent.page_no ? "Page No" : ""}</b>{" "}
              {articleContent.page_no ? articleContent.page_no : ""}
              <br></br>
              <b>Authors:</b>{" "}
              {articleContent
                ? articleContent.authors
                    .split(",")
                    .map((element) => <Tag color="default">{element}</Tag>)
                : null}
              <br />
              {articleContent.link ? (
                <a
                  href={articleContent.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <b>Link:</b> <i className="fal fa-external-link-alt"></i>
                </a>
              ) : null}
              <hr />
              <p
                style={{
                  backgroundColor: "white",
                  maxWidth: "100%",
                }}
                dangerouslySetInnerHTML={{
                  __html: articleContent.info,
                }}
              ></p>
            </>
          ) : (
            <>
              <Card
                style={{
                  width: "100%",
                  marginTop: 16,
                }}
              >
                <Skeleton loading={true} avatar active></Skeleton>
              </Card>
              <Card
                style={{
                  width: "100%",
                  marginTop: 16,
                }}
              >
                <Skeleton loading={true} avatar active></Skeleton>
              </Card>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
