//Recruitments Setion
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";
import Heading from "../../Components/Heading";
import { MdArrowDropDown } from "react-icons/md";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
import { getRequest } from "../../functions/request";
import { formatDateString } from "../../functions/utils";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/material";

function Recruitments() {
  const [notices, setNotices] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [groupedNotices, setGroupedNotices] = useState({});
  const [year, setYear] = useState('');

  const [fontsize] = useState(17);
  
  const { t } = useTranslation();

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await getRequest(`/notices?rec=1`);
        const data = response.data.data.map((item, idx) => {
          return {
            id: idx,
            text: item.title,
            link: item.path,
            date: item.idate,
            highlight: item.highlight,
            counts: item.count,
          };
        });
        setNotices(data);

        
        const groupedByYear = {};
        data.forEach((notice) => {
          
          const year = new Date(parseInt(notice.date, 10)).getFullYear();
          if (!groupedByYear[year]) {
            groupedByYear[year] = [];
          }
          groupedByYear[year].push(notice);
        });
        setGroupedNotices(groupedByYear);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    scrollToContent(0);
    fetchData();
    
  }, []);


  const handleYearChange = (event) => {
    setCurrPage(1)
    const selectedYear = event.target.value;
    setYear(selectedYear);
    setNotices(groupedNotices[selectedYear] || []);
  };

  const totalPerPage = 35;


  return (
    <ThemeProvider theme={mainTheme}>
      {/* <Header title={'Recruitments | NIT Jamshedpur'} /> */}
      {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <TopSection fontsize={fontsize} setFontSize={setFontSize} />
          </Sticky> */}
      <Helmet>
        <title>
          {" "}
          {t("Recruitments")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta
          name="description"
          content="Recruitment Notices, NIT Jamshedpur"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,recruitments nit jsr,recruitments,recruitment notices,notices"
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Banner heading={t("Recruitment_Notices")} />
          <Container className="mt-3">
          
          <Heading />
          <Box sx={{display:'flex',justifyContent: 'right'}}>
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel >Select Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  value={year}
                  label="Year"
                  onChange={handleYearChange}
                >

                  {Object.keys(groupedNotices).map((year)=> (
                    <MenuItem value={year} key={year}>{year}</MenuItem>
                  ))}
              
                </Select>
              </FormControl>
            </Box>
          </Box>  
          <br/>
            {notices ? (
              <>
                {/* <TableWrapper> */}
                <table className="table table-striped table-bordered">
                  <thead style={{ background: "#274086", color: "white" }}>
                    <tr id="headRow">
                      <th>{t("Sl.No.")}</th>
                      <th>{t("Issue_Date")}</th>
                      <th>{t("Title")}</th>
                      {/* <th>{t("Deadline")}</th> */}
                      <th>{t("Download")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {notices.slice(
                    (currPage - 1) * totalPerPage,
                    Math.min(currPage * totalPerPage, notices.length)
                  ).map((notice, key) => {

                    if (
                      notice.text ===
                      "02/2022 Notification- Advertisement and Information Brochure for Recruitment of Assistant Professor"
                    ) {
                      return (
                        <tr key={notice.id}>
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="Sl.No."
                          >
                            {notice.id+1}
                          </td>
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="Issue Date"
                          >
                            {formatDateString(notice.date)}
                          </td>
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="Title"
                          >
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<MdArrowDropDown />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                {notice.text}
                              </AccordionSummary>
                              <AccordionDetails>
                                <List>
                                  <ListItem>
                                    <Link
                                      to={{ pathname: `${notice.link}` }}
                                      target="_blank"
                                    >
                                      Advertisement for Assistant Professor
                                      2022.pdf
                                    </Link>
                                  </ListItem>
                                  <ListItem>
                                    <Link
                                      to={{ pathname: `https://nitjsr.ac.in/backend/uploads/recents/Annexure%20and%20Gazette.pdf` }}
                                      target="_blank"
                                    >
                                      Annexture and Gazette.pdf
                                    </Link>
                                  </ListItem>
                                  <ListItem>
                                    <Link
                                      to={{ pathname: `https://nitjsr.ac.in/backend/uploads/recents/Information Brochure for Assistant Professor 2022.pdf` }}
                                      target="_blank"
                                    >
                                      Information Brochure for Assistant
                                      Professor 2022.pdf
                                    </Link>
                                  </ListItem>
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          </td>
                          {/* <td
                        style={{ fontSize: fontsize }}
                        data-column="Deadline"
                      ></td> */}
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="Download"
                          >
                            {/* <Link
                              to={{ pathname: `${notice.path}` }}
                              target="_blank"
                            >
                              <FiDownload size="25px" />
                            </Link> */}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={notice.id}>
                        <td style={{ fontSize: fontsize }} data-column="Sl.No.">
                          {notice.id+1}
                        </td>
                        <td
                          style={{ fontSize: fontsize }}
                          data-column="Issue Date"
                        >
                          {formatDateString(notice.date)}
                        </td>
                        <td style={{ fontSize: fontsize }} data-column="Title">
                          {notice.text}
                        </td>
                        {/* <td
                            style={{ fontSize: fontsize }}
                            data-column="Deadline"
                          ></td> */}
                        <td
                          style={{ fontSize: fontsize }}
                          data-column="Download"
                        >
                          <Link
                            to={{ pathname: `${notice.link}` }}
                            target="_blank"
                          >
                            <FiDownload size="25px" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                 </tbody>
                </table>
                {/* </TableWrapper> */}
                <div
                style={{ textAlign: "center", color: "#274187", margin: "1rem 0" }}
              >
                {currPage !== 1 ? (
                  <IoIosArrowDropleftCircle
                    size="1.5em"
                    onClick={() => setCurrPage((prev) => prev - 1)}
                    style={{ cursor: "pointer", margin: "0 1rem" }}
                  />
                ) : null}
                {currPage}
                {currPage !== Math.ceil(notices.length / totalPerPage) ? (
                  <IoIosArrowDroprightCircle
                    size="1.5em"
                    onClick={() => setCurrPage((prev) => prev + 1)}
                    style={{ cursor: "pointer", margin: "0 1rem" }}
                  />
                ) : null}
              </div>
       
              </>
            ) : (
              <h3 style={{ marginTop: "5rem" }}>
                <center>
                  <Spinner animation="border" />
                </center>
              </h3>
            )}
          </Container>
        </ContentWrapper>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Recruitments;
