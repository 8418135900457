// Tender section
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Footer from "../../Components/Layout/Footer";
import ActiveTenders from "./Sections/Active_Tenders";
import AcrhiveTenders from "./Sections/Acrhive_Tenders";
import AllTenders from "./Sections/All_Tenders";
import Contact from "./Sections/Contactus.js";
import UsefulLinks from "./Sections/Useful_links";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
function Tender() {
  const { page } = useParams();
  const [ActiveComp, setActiveComp] = useState("Active Tenders");
  const fontsize = 16;
  const { t } = useTranslation();

  useEffect(() => {
    setActiveComp(page);
  }, [page]);

  function handleClick(comp) {
    setActiveComp(comp);
    scrollToContent();
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
          राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp.split(" ").join("_")}`)} />
          <Container width="78%">
            <Row>
              <Col lg={4} sm={12}>
                <Sidebar TopLink="Tenders" handleClick={handleClick} />
              </Col>
              <Col lg={8} sm={12}>
                {ActiveComp === "Active_Tenders" && (
                  <ActiveTenders fontsize={fontsize} />
                )}
                {ActiveComp === "Archived_Tenders" && (
                  <AcrhiveTenders fontsize={fontsize} />
                )}
                {ActiveComp === "All_Tenders" && (
                  <AllTenders fontsize={fontsize} />
                )}
                {ActiveComp === "Contact_Us" && <Contact fontsize={fontsize} />}
                {ActiveComp === "Useful_Links" && (
                  <UsefulLinks fontsize={fontsize} />
                )}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Tender;
