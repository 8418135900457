import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themed } from '../base';

const ImageWrapper = styled('img')(
  {
    width: "fit-content",
    height: "fit-content"
  },
  themed('Image')
);

const Image = ({ src, alt, ...props }) => (
  <ImageWrapper src={src} alt={alt} {...props} />
);

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  m: 0,
  alt: "Image",
};
