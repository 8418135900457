import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { ParagraphWrapper } from "../../../assets/css/Custom/StudentStyle";
import FacultyPlacementCordinator from "../../../assets/images/Faculty Coordinator_image.png";
import PlacementCordUG from "../../../assets/images/Placement Coordinator Image.png";
// import PlacementTab from "./placementTab";
import { Helmet } from "react-helmet";
// import {
//   Grid,
//   Table,
//   TableContainer,
//   TableHead,
//   Paper,
//   TableRow,
//   TableBody,
//   TableCell,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormControl,
//   Button,
// } from "@mui/material";
// import { Table } from "react-bootstrap";
// import { Branches, fetchPlacementData, YEAR } from "../logic";
// import { Spinner } from "react-bootstrap";
import Placement_poster_2024 from "./img/Placement_poster_2024.jpg";
import Faculty_coordinators from "./img/Faculty_coordinators.png";
import PG_coordinators1 from "./img/PG_coordinators1.png";
import PG_coordinators2 from "./img/PG_coordinators2.png";
import UG_coordinators from "./img/UG_coordinators.png";

const Placements = ({ fontsize }) => {
  const { t } = useTranslation();
  // const [data, setData] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  // const [year, setYear] = React.useState(2021);
  // const [branch, setBranch] = React.useState("ALL");
  // React.useEffect(() => {
  //   fetchPlacementData(2021, "ALL", setData, setLoading);
  // }, []);

  // console.log(data);

  return (
    <Container width="1250px">
      <br />

      <Helmet>
        <meta
          name="description"
          content="The Training & Placement Cell, NIT Jamshedpur facilitates the process of placement of students passing out from the Institute besides collaborating with leading organizations and institutes in setting up of internship and training program of students."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,placements,tap,training and placement"
        />
      </Helmet>
      <Heading
        content="Placements"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <Heading content="TAP @ NIT JSR" />
      <ParagraphWrapper>
        <p
          style={{ fontSize: fontsize, textAlign: "justify" }}
          className="paragraph"
        >
          {t("placements_tap1")}
        </p>
        <p
          style={{ fontSize: fontsize, textAlign: "justify" }}
          className="paragraph"
        >
          {t("placements_tap2")}
        </p>
      </ParagraphWrapper>
      <Heading
        content="Statistics"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      {/* <Heading content="Statistics" /> */}
      <img src={Placement_poster_2024} class="img-fluid" alt="souvenir"></img>
      {/* <TableWrapper>
                {batchStats.map((item,index)=>{
                    return(
                        <table key={item.id} className="table">
                            <thead>
                                <th>Batch</th>
                                <th>{item.batch}</th>
                            </thead>
                            <tr>
                                <td>Number of companies</td>
                                <td>{item.no_of_company}</td>
                            </tr>
                            <tr>
                                <td>Placement percentage</td>
                                <td>{item.percentage}%</td>
                            </tr>
                            <tr>
                                <td>Total registered student</td>
                                <td>{item.registered}</td>
                            </tr>
                            <tr>
                                <td>Total placed</td>
                                <td>{item.placed}</td>
                            </tr>
                            <tr>
                                <td>Highest CTC</td>
                                <td>{item.highest_ctc}</td>
                            </tr>
                            <tr>
                                <td>Average CTC</td>
                                <td>{item.average_ctc}</td>
                            </tr>
                            <tr>
                                <td>Top Recruiters</td>
                                <td>{item.top_company}</td>
                            </tr>
                        </table>
                    )
                })}
            </TableWrapper> */}
      {/* <PlacementTab index={0} /> */}
      {/* <GraphWrapper>
        <BarChart
          data={percentageDataset}
          title={`Placements percentage`}
          fontSize={fontsize}
        />
        <PieChart
          data={sectorWiseDataset}
          title={`Sector Wise companies visited`}
          fontSize={fontsize}
        />
      </GraphWrapper> */}
      {/* <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="wrap"
            justifyContent="space-between"
          >
            <Grid xs={3} md={2} item>
              <FormControl fullWidth>
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  labelId="year-label"
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                  variant="standard"
                  fullWidth
                >
                  {YEAR.map((yr) => (
                    <MenuItem key={yr} value={yr}>
                      {yr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={5} md={6} item>
              <FormControl fullWidth>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  onChange={(e) => setBranch(e.target.value)}
                  value={branch}
                  variant="standard"
                  fullWidth
                >
                  {Branches["UG"].map((yr) => (
                    <MenuItem key={yr.key} value={yr.key}>
                      {yr.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                sx={{ marginTop: "8px" }}
                onClick={(e) =>
                  fetchPlacementData(year, branch, setData, setLoading)
                }
                variant="outlined"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <h3 style={{ marginTop: "5rem" }}>
              <center>
                <Spinner animation="border" />
              </center>
            </h3>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.No.</TableCell>
                      <TableCell align="center">Company</TableCell>
                      <TableCell align="center">Selects</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((ts, idx) => (
                      <TableRow key={ts.id}>
                        <TableCell scope="row">{idx + 1}</TableCell>
                        <TableCell align="center">{ts?.company}</TableCell>
                        <TableCell align="center">{ts?.final}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid> */}
      {/* <Heading content=" PLACEMENT BROCHURE" /> */}
      <Heading
        content="Important Links"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <div className="container mt-3 text-secondary">
        <strong>PLACEMENT BROCHURE 2024-25 :</strong>{" "}
        <a
          href="https://nitjsr.ac.in/backend/uploads/placements/Placement_Brochure_2024-25.pdf"
          rel="noreferrer"
          target={"_blank"}
        >
          {"Link"}
        </a>
      </div>

      <div className="container mt-3 text-secondary">
        <strong>JOB ANNOUNCEMENT FORM :</strong>{" "}
        <a
          href="https://nitjsr.ac.in/backend/uploads/placements/JAF.docx"
          rel="noreferrer"
          target={"_blank"}
        >
          {"Link"}
        </a>
      </div>

      <div className="container mt-3 text-secondary">
        <strong>INTERNSHIP ANNOUNCEMENT FORM :</strong>{" "}
        <a
          href="https://nitjsr.ac.in/backend/uploads/placements/IAF-1.docx"
          rel="noreferrer"
          target={"_blank"}
        >
          {"Link"}
        </a>
      </div>

      {/* Placement Coordinators for the Session 2023-2024 Faculty.*/}
      <Heading
        content="Faculty Coordinators for the Session 2024-2025"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <img
        // src="https://nitjsr.ac.in/backend/uploads/uploads_2023/Placement/nitjsr-PLACEMENT-BROCHURE.png"
        src={Faculty_coordinators}
        class="img-fluid"
        alt="souvenir"
      ></img>
      {/* Placement Coordinators for the Session 2023-2024 for U.G. Branches.*/}
      <Heading
        content="Placement Coordinators for the Session 2024-2025 for UG"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <img
        // src="https://nitjsr.ac.in/backend/uploads/uploads_2023/Placement/ug.jpg"
        src={UG_coordinators}
        class="img-fluid"
        alt="souvenir"
      ></img>
      {/* <div>
      
      <Table
        striped
        bordered
        hover
        responsive="md"
        style={{ margin: "50px auto" }}
      >
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Name</th>
            <th>Institute E-mail </th>
            <th>Mobile No.</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.</td>
            <td>ANIKET KUMAR SINGH</td>
            <td>2020ugce091@nitjsr.ac.in</td>
            <td>9661600722</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>HARSH RANJAN</td>
            <td>2020ugce096@nitjsr.ac.in</td>
            <td>7050050555</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>BISHAL KUMAR SHIL</td>
            <td>2020ugce064@nitjsr.ac.in</td>
            <td>8340264814</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>SUVRODEEP HALDER</td>
            <td>2020ugcs040@nitjsr.ac.in</td>
            <td>9836546431</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>VAIBHAV GANGIL</td>
            <td>2020ugcs066@nitjsr.ac.in</td>
            <td>7869319765</td>
          </tr>
          <tr>
            <td>6.</td>
            <td>VANSHIKA RAJ</td>
            <td>2020ugec036@nitjsr.ac.in</td>
            <td>8709546704</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>TANVI RAO</td>
            <td>2020ugec046@nitjsr.ac.in</td>
            <td>6202483630</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>APURV SINHA</td>
            <td>2020ugee033@nitjsr.ac.in</td>
            <td>8102366058</td>
          </tr>
          <tr>
            <td>9.</td>
            <td>JASMINE KAUR</td>
            <td>2020ugee006@nitjsr.ac.in</td>
            <td>6204397297</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>NILESH KUMAR</td>
            <td>2020ugme081@nitjsr.ac.in</td>
            <td>8603276521</td>
          </tr>
          <tr>
            <td>11.</td>
            <td>ABHIJEET PARASAR</td>
            <td>2020ugme017@nitjsr.ac.in</td>
            <td>9470501792</td>
          </tr>
          <tr>
            <td>12.</td>
            <td>HARSH KUMAR</td>
            <td>2020ugmm032@nitjsr.ac.in</td>
            <td>8235404372</td>
          </tr>
          <tr>
            <td>13.</td>
            <td>NITIN KUMAR</td>
            <td>2020ugmm062@nitjsr.ac.in</td>
            <td>7340006884</td>
          </tr>
          <tr>
            <td>14.</td>
            <td>SRIRAM KUMAR</td>
            <td>2020ugpi057@nitjsr.ac.in</td>
            <td>9262307126</td>
          </tr>
          <tr>
            <td>15.</td>
            <td>SHRISTI CHAUHAN</td>
            <td>2020ugpi060@nitjsr.ac.in</td>
            <td>9079236587</td>
          </tr>
        </tbody>
      </Table>
    
  </div> */}

      {/* Placement Coordinators for the Session 2023-2024 for P.G. Branches.*/}
      <Heading
        content="Placement Coordinators for the Session 2024-2025 for PG"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <img
        src={PG_coordinators1}
        class="img-fluid"
        alt="souvenir"
        style={{
          marginBottom: "20px",
          marginTop: "13px",
        }}
      ></img>
      <img
        src={PG_coordinators2}
        class="img-fluid"
        alt="souvenir"
        style={{
          marginBottom: "20px",
          marginTop: "13px",
        }}
      ></img>
      {/* <div>
      
      <Table
        striped
        bordered
        hover
        responsive="md"
        style={{ margin: "50px auto" }}
      >
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Name</th>
            <th>Institute E-mail </th>
            <th>Mobile No.</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.</td>
            <td>ASHAVARI CHAUDHURY</td>
            <td>2022pgcscs09@nitjsr.ac.in</td>
            <td>9938239712</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>ANKIT CHAURASIA</td>
            <td>2022pgcscs17@nitjsr.ac.in</td>
            <td>9599827804</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>AKASH KR. DEY</td>
            <td>2022pgcsis01@nitjsr.ac.in</td>
            <td>9362765746</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>SHASHI KANT</td>
            <td>2021pgcaca050@nitjsr.ac.in</td>
            <td>7044132891</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>MRITYUNJAY KUMAR</td>
            <td>2021pgcaca005@nitjsr.ac.in</td>
            <td>8949996269</td>
          </tr>
          <tr>
            <td>6.</td>
            <td>SADHVI</td>
            <td>2021pgcaca064@nitjsr.ac.in</td>
            <td>7289033513</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>SIRAJ SABIR JAMADAR</td>
            <td>2022pgcese01@nitjsr.ac.in</td>
            <td>8097786290</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>N LALITH SHUBHANKAR</td>
            <td>2022pgecem17@nitjsr.ac.in</td>
            <td>7348854907</td>
          </tr>
          <tr>
            <td>9.</td>
            <td>DURBADAL CHAKRABORTY</td>
            <td>2022pgecem04@nitjsr.ac.in</td>
            <td>7980883735</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>ABHISHEK KUMAR SINGH</td>
            <td>2022pgeepe01@nitjsr.ac.in</td>
            <td>7979751978</td>
          </tr>
          <tr>
            <td>11.</td>
            <td>NISHU KUMARI</td>
            <td>2022pgeeps03@nitjsr.ac.in</td>
            <td>9608353636</td>
          </tr>
          <tr>
            <td>12.</td>
            <td>AUROBINDA SWAIN</td>
            <td>2022pgmeci03@nitjsr.ac.in</td>
            <td>7008623842</td>
          </tr>
          <tr>
            <td>13.</td>
            <td>TANMOY DUTTA</td>
            <td>2022pgmeci03@nitjsr.ac.in</td>
            <td>9874605083</td>
          </tr>
          <tr>
            <td>14.</td>
            <td>ASHISH RANJAN</td>
            <td>2022pgmhmh22@nitjsr.ac.in</td>
            <td>6203212163</td>
          </tr>
          <tr>
            <td>15.</td>
            <td>KESHAV UPADHYAY</td>
            <td>2022pgphph19@nitjsr.ac.in</td>
            <td>7061340671</td>
          </tr>
          <tr>
            <td>16.</td>
            <td>PRATIKSHA ROY</td>
            <td>2022pgchch18@nitsr.ac.in</td>
            <td>8013311722</td>
          </tr>
        </tbody>
      </Table>
    
  </div> */}

      {/* Placement Coordinators for the Session 2023-2024 for PhD*/}
      {/* <Heading
        content="Placement Coordinators for the Session 2024-2025 for PhD"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <img
        src="https://nitjsr.ac.in/backend/uploads/uploads_2023/Placement/phd.jpg"
        class="img-fluid"
        alt="souvenir"
      ></img> */}

      {/* Contact */}
      {/* <Heading
        content="Contact"
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

<ParagraphWrapper>
        <p >
        Prof. Anil Kumar Choudhary <br/>
        Prof. I/c, Training & Placement <br/>
        National Institute of Technology, Jamshedpur-831014<br/>
        Jharkhand, India<br/>
        Mobile: (91) 9431161850/7488958352<br/>
        Email:pi.tap@nitjsr.ac.in
        </p>
      </ParagraphWrapper> */}
    </Container>
  );
};

export default Placements;
