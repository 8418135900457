import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { LongPara, TableContainer, TableWrapper } from '../styles';
import { getRequest } from '../../../functions/request';
import { Helmet } from 'react-helmet';

const SafetyAndSecurity = () => {
	const { t } = useTranslation();
	const [securityControlData, setSecurityControlData] = useState([]);

	let securityControlHandler = async () => {
		try {
			const res = await getRequest(`/central_facilities?facility=security_control_unit`);
			console.log(res.data)
			setSecurityControlData(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		securityControlHandler();
	}, []);

	return (
       <>
		<Helmet>
			<meta name="description" content="Security control unit of nitjsr"/>
			<meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,security control unit,security officier"/>
			</Helmet>
		<Container width="1250px">
			<br />
			<LongPara>
				{t("central_facilities_safety_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_contacts_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>

			<TableContainer>
				<TableWrapper>
					<table>
						<thead>
							<tr id="headRow">
								<th>{t("central_facilities_table_key_1")}</th>
								<th>{t("central_facilities_table_key_2")}</th>
								<th>{t("central_facilities_table_key_3")}</th>
								<th>{t("central_facilities_table_key_4")}</th>
							</tr>
						</thead>
						<tbody>
							{securityControlData.map((person) => (
								<tr>
									<td>{person.designation}</td>
									<td>
										{person.preName} {person.fname} {person.lname}
									</td>
									<td>{person.phoneNumber}</td>
									<td>{person.email}</td>
								</tr>
							))}
						</tbody>
					</table>
				</TableWrapper>
			</TableContainer>
		</Container>
		</>
	);
};

export default SafetyAndSecurity;
