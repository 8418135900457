// Cells section
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from "../../assets/css/Main/main.style";
import { ResetCSS } from "../../assets/css/style";
import Navbar from "../../Components/Layout/PagesNavbar";
//import TopSection from '../../containers/Main/TopSection';
import DrawerSection from "../../Components/Layout/DrawerSection";
import Footer from "../../Components/Layout/Footer";
import Banner from "../../Components/Layout/Banner/Banner";
//import Header from '../../Components/Header';
import { Row, Col } from "react-bootstrap";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Container from "../../Components/UI/Container/index";
import { DrawerProvider } from "../../contexts/DrawerContext.js";
//import { charityTheme } from 'src/theme/charity';
import { mainTheme } from "../../theme/main";
import Grievance from "./Sections/Grievance";
import Hindi from "./Sections/Hindi";
import IPR from "./Sections/IPR";
import Publication from "./Sections/Publication";
import PublicRelation from "./Sections/PublicRelation";
import Reservation from "./Sections/Reservation";
import ScSt from "./Sections/ScSt";
import ICC_Women from "./Sections/ICC_Women";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { scrollToContent } from "../../functions/scroll.js";

function Cell() {
  const { page } = useParams();
  const [ActiveComp, setActiveComp] = useState("SC-ST Cell");
  const { t } = useTranslation();

  const fontsize = 16;
  useEffect(() => {
    const pg = page.replaceAll("_", " ");
    setActiveComp(pg);
  }, [page]);
  function handleClick(comp) {
    const pg = comp.replaceAll("_", " ");
    setActiveComp(pg);
    scrollToContent();
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
          राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
        <meta
          name="description"
          content="A SC/ST Cell is being constituted in the institute to resolve the grievances of the SC/ST students and employees with immediate effect and for till further order.Register complaints related to employees and students of the institute and the redressal"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,goals, map,reach, cc,guest,objectives,technology,Director,,stafflist,facility,rti, right to informations,output Indicators,Indicators..."
        />
      </Helmet>
      {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
      <TopSection fontsize={fontsize} setFontSize={setFontSize} />
        </Sticky> */}
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp.split(" ").join("_")}`)} />
          <Container width="80%">
            <Row>
              <Col lg={4} sm={12}>
                <Sidebar TopLink="Cells" handleClick={handleClick} />
              </Col>
              <Col lg={8} sm={12}>
                {ActiveComp === "SC-ST Cell" ? (
                  <ScSt fontsize={fontsize} />
                ) : null}
                {ActiveComp === "Internal Complaints Committee(ICC)" ? (
                  <ICC_Women fontsize={fontsize} />
                ) : null}
                {ActiveComp === "IPR Cell" ? <IPR fontsize={fontsize} /> : null}
                {ActiveComp === "Grievance Cell" ? (
                  <Grievance fontsize={fontsize} />
                ) : null}
                {ActiveComp === "Reservation Cell" ? (
                  <Reservation fontsize={fontsize} />
                ) : null}
                {ActiveComp === "Publication Cell" ? (
                  <Publication fontsize={fontsize} />
                ) : null}
                {ActiveComp === "Public Relation Cell" ? (
                  <PublicRelation fontsize={fontsize} />
                ) : null}
                {ActiveComp === "Hindi Cell" ? (
                  <Hindi fontsize={fontsize} />
                ) : null}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

// HumanityBlock style props
Cell.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// Academic default style
Cell.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Cell;
