import React, { Fragment, useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { mainTheme } from '../../theme/main';
import { ResetCSS } from '../../assets/css/style';
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from '../../assets/css/Main/main.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../contexts/DrawerContext';
import Navbar from '../../Components/Layout/PagesNavbar'
import DrawerSection from '../../Components/Layout/DrawerSection';
import Banner from '../../Components/Layout/Banner/Banner'
import Footer from '../../Components/Layout/Footer'
import { useParams } from 'react-router-dom'
import HOD from './Sections/HOD';
import DeptDetails from './Sections/DeptDetails';
import NoticeSection from './Sections/NoticeSection';
import Faculty from './Sections/Faculty';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

function Index() {
  const { branch } = useParams()
  const [Department, setDepartment] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    if (branch === 'cs') setDepartment('Computer Science and Engineering');
    else if (branch === 'ece')
      setDepartment('Electronics and Communication Engineering');
    else if (branch === 'eee') setDepartment('Electrical Engineering');
    else if (branch === 'mech') setDepartment('Mechanical Engineering');
    else if (branch === 'civil') setDepartment('Civil Engineering');
    else if (branch === 'meta')
      setDepartment('Metallurgical and Materials Engineering');
    else if (branch === 'prod')
      setDepartment('Production and Industrial Engineering');
    else if (branch === 'maths') setDepartment('Mathematics');
    else if (branch === 'phys') setDepartment('Physics');
    else if (branch === 'chem') setDepartment('Chemistry');
    else if (branch === 'mca') setDepartment('Computer Applications');
    else if (branch === 'humanities')
      setDepartment('Humanities, Social Sciences and Management');
  }, [branch]);
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`Departments`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <Fragment>
        {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <TopSection  setFontSize={setFontSize} />
            </Sticky> */}
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <ContentWrapper>
            <Banner heading={t(Department.split(' ').join('_'))} />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <HOD Dept={branch} />
                </div>
                <div className="col-md-6 col-sm-12">
                  <DeptDetails Dept={branch} />
                  <br />
                  <Faculty Dept={branch} />
                  <br />
                  {/* <Research Dept={branch} /> */}
                </div>
                <div className="col-md-3 col-sm-12">
                  <NoticeSection Dept={branch} />
                </div>
              </div>
            </div>

          </ContentWrapper>
          <Footer />
        </CharityWrapper>
        {/* End of Main wrapper section */}
      </Fragment>
    </ThemeProvider>

  )
}

export default Index
