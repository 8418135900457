import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { Icon } from "react-icons-kit";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
import { BoysHostel, GirlsHostel } from "../../../assets/data/Hostels";
import { Link } from "react-router-dom";
import { LongPara, HostelContainer, HostelBtn } from "../../Facilities/styles";
const Hostels = () => {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("central_facilities_hostels_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <LongPara>{t("central_facilities_hostels_body")}</LongPara>
      <Heading
        content={t("central_facilities_hostels_head_boys")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <HostelContainer>
        {BoysHostel.map((m) => (
          <div style={{ padding: "10px" }} key={m.id}>
            <Link to={`Hostel_Details?${m.path.split("?")[1]}`}>
              <HostelBtn className="hostel-btn">{m.text}</HostelBtn>
            </Link>
          </div>
        ))}
      </HostelContainer>
      <Heading
        content={t("central_facilities_hostels_head_girls")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <HostelContainer>
        {GirlsHostel.map((m) => (
          <div style={{ padding: "10px" }} key={m.id}>
            <Link to={`Hostel_Details?${m.path.split("?")[1]}`}>
              <HostelBtn>{m.text}</HostelBtn>
            </Link>
          </div>
        ))}
      </HostelContainer>
      <Heading
        content={t("central_facilities_hostels_rules_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <a
        target="_blank"
        href="http://nitjsr.ac.in/sw/hostel/pdf/Rules%20and%20regulations%20for%20all%20hostels%20of%20the%20Institute.pdf"
        rel="noopener noreferrer"
      >
        <p className="pdf-link">
          <Icon icon={cloudDownload} size={18} />
          &nbsp;&nbsp;{t("central_facilities_hostels_rules_body")}
        </p>
      </a>
    </Container>
  );
};

export default Hostels;
