import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container/index";
import { Table } from 'react-bootstrap';
import { getRequest } from '../../../functions/request';
import { Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet";

function HOD() {
    const { t } = useTranslation();
    const [hod, setHod] = useState([]);
    useEffect(() => {
        (async () => {
            getRequest(`/hod/all`)
                .then((res) => {
                    return res.data.data.map((item, idx) => {
                        return {
                            id: idx,
                            name: item.name,
                            faculty_id: item.faculty_id,
                            branch: item.branch,
                            email: item.email,
                        };
                    });
                })
                .then((data) => setHod(data));
        })();
    }, []);
    return (
        <div>
            <Helmet>
                <title> {t("Head_of_Departments")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर</title>
                <meta name="description" content="H.O.D.s for different branches" />
                <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,h.o.d,head of departments,professor incharge,subject head" />
            </Helmet>
            <Container>
                {hod ? (
                    <Table striped bordered hover responsive="md" style={{ margin: "50px auto" }}>
                        <thead>
                            <tr>
                                <th>{t("S.No.")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Branch")}</th>
                                <th>{t("Email (replace # with @nitjsr.ac.in)")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hod.map((items, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{t(items.name.split('Prof ').join('Prof. ').split('Dr ').join('Dr. '))}</td>
                                        <td>{t(items.branch.split(' ').join('_'))}</td>
                                        <td>{t(items.email)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <h3 style={{ marginTop: '5rem' }}>
                        <center>
                            <Spinner animation="border" />
                        </center>
                    </h3>
                )}
            </Container>
        </div>
    )
}

export default HOD
