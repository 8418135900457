import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Image } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import SectionWrapper, {
  SectionHeader,
  SliderWrapper,
} from "./EventSection.style";
import invalid_image from "../../../assets/images/invalid_image.png";
import { getRequest } from "../../../functions/request";
import { getFileURL, getShortString } from "../../../functions/utils";
import { Link } from "react-router-dom";

import { Box, Button } from "@mui/material";

import EventCard from "../../../Components/EventCard";

const WorkSection = ({ fontsize }) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(3, data.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  const fetching = async () => {
    try {
      let res = await getRequest(`/events/upcoming`);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetching();
  }, []);

  return (
    <SectionWrapper id="work">
      <Container width={data.length >= 3 ? "90%" : "60%"}>
        <SectionHeader>
          <Heading content={t("home_research_head")} />
        </SectionHeader>
        <SliderWrapper>
          <Slider {...settings}>
            {data.map((item, key) => (
              <EventCard
                key={`event_card_${key}`}
                item={item}
                fontsize={fontsize}
              />
            ))}
          </Slider>
        </SliderWrapper>
        {data.length === 0 && (
          <div>
            <div className="singleCard text-center">
              <p style={{ fontSize: fontsize }} className="desc">
                {t("home_event_section_no_event")}
              </p>
            </div>
          </div>
        )}
        <Link to="/archiveEvents" className="learn__more-btn">
          {" "}
          <span className="hyphen" />
          <span className="btn_text">{t("Archive Events")}</span>
        </Link>
        <Link to="/events" className="learn__more-btn">
          {" "}
          <span className="hyphen" />
          <span className="btn_text">{t("Events")}</span>
        </Link>
      </Container>
    </SectionWrapper>
  );
};

export default WorkSection;
