import React from "react";
import { useTranslation } from "react-i18next";
import { BoxWrapper } from "../../../assets/css/Custom/DepartmentStyle";
import { DeptData } from "../../../assets/data/DepartmentData";
import Heading from "../../../Components/Heading";

function DeptDetails({ Dept }) {
  const { t } = useTranslation();
  // function to return only string without html tags
  const puretext = (text) => {
    return { __html: text };
  };
  // const [DepartmentDetails, setDepartmentDetails] = useState({});
  return (
    <div>
      <BoxWrapper>
        <div className="row">
          <div className="col-sm-12">
            <div className="deptdetails">
              <Heading
                content={t(`About`)}
                style={{
                  fontSize: "1.5em",
                  marginBottom: "15px",
                  fontWeight: "500",
                  background: "#274187",
                  color: "#fff",
                  padding: "2% 2% 2% 3%",
                }}
              />

              {DeptData.map((items) => (
                <>
                  {items.dept === Dept ? (
                    <>
                      <p
                        className="detail-text"
                        dangerouslySetInnerHTML={puretext(t(`${items.dept}_title1_para`))}
                      />
                    </>
                  ) : null}
                </>
              ))}

              <br />
              {DeptData.map((items) => (
                <>
                  {items.dept === Dept ? (
                    <>
                      {items.vision !== "" ? (
                        <>
                          <Heading
                            content={t(`vission_title2`)}
                            style={{
                              fontSize: "1.5em",
                              marginBottom: "15px",
                              fontWeight: "500",
                              background: "#274187",
                              color: "#fff",
                              padding: "2% 2% 2% 3%",
                            }}
                          />
                          <p
                            className="detail-text"
                            dangerouslySetInnerHTML={puretext(t(`${items.dept}_title2_para`))}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
              <br />
              {DeptData.map((items) => (
                <>
                  {items.dept === Dept ? (
                    <>
                      {items.mission !== "" ? (
                        <>
                          {/* <h4>
                            <b>
                              <u>Mission</u>
                            </b>
                          </h4> */}

                          <Heading
                            content={t(`mission_title2`)}
                            style={{
                              fontSize: "1.5em",
                              marginBottom: "15px",
                              fontWeight: "500",
                              background: "#274187",
                              color: "#fff",
                              padding: "2% 2% 2% 3%",
                            }}
                          />
                          <p
                            className="detail-text"
                            dangerouslySetInnerHTML={puretext(t(`${items.dept}_title3_para`))}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default DeptDetails;
