import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";
import { formatDateString } from "../../../functions/utils";
import "./Searchedbox.css";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Office_order({ fontsize }) {
  const [notices, setNotices] = useState([]);
  const [searchedvalue, setsearchedvalue] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [groupedNotices, setGroupedNotices] = useState({});
  const [year, setYear] = useState('');
  
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest(`notices?office=1`);
        const data = response.data.data.map((item, idx) => {
          return {
            id: idx,
            text: item.title,
            link: item.path,
            date: item.idate,
            highlight: item.highlight,
            counts: item.count,
          };
        });
        setNotices(data);

        
        const groupedByYear = {};
        data.forEach((notice) => {
          
          const year = new Date(parseInt(notice.date, 10)).getFullYear();
          if (!groupedByYear[year]) {
            groupedByYear[year] = [];
          }
          groupedByYear[year].push(notice);
        });
        setGroupedNotices(groupedByYear);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []);


  const handleYearChange = (event) => {
    setCurrPage(1);
    const selectedYear = event.target.value;
    setYear(selectedYear);
    setNotices(groupedNotices[selectedYear] || []);
  };

  const totalPerPage = 15;
  
  const searchfun = (e) => {
    e.preventDefault();
    (async () => {
      getRequest(`/notices?office=1&searchedvalue=${searchedvalue}`)
        .then((res) => {
          return res.data.data.map((item, idx) => {
            return {
              id: idx,
              text: item.title,
              link: item.path,
              date: item.idate,
              highlight: item.highlight,
              counts: item.count,
              // vupto: new Date(dt.getDate()+item.highlight*( 3600 * 1000 * 24)),	//TODO: MAKE USE OF NEW UPTO DATE
            };
          });
        })
        .then((data) => setNotices(data));
      setCurrPage(1);
    })();
  };
  return (
    <Container width="1250px">
      <br />
      <Heading />
      <Box sx={{display:'flex',justifyContent: 'space-between'}}>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <InputLabel >Select Year</InputLabel>
            <Select
              labelId="year"
              id="year"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >

              {Object.keys(groupedNotices).map((year)=> (
                <MenuItem value={year} key={year}>{year}</MenuItem>
              ))}
          
            </Select>
          </FormControl>
        </Box>
        <form className="form-submit-button-notice" onSubmit={searchfun}>
          <input
            type="text"
            onChange={(e) => setsearchedvalue(e.target.value)}
          />
          <button
            type="submit"
            className="searched-submit-button-notice"
            disabled={!searchedvalue.length}
          >
            {" "}
            Search
          </button>
        </form>
      </Box>
      {notices.length !== 0 ? (
        <>
         
          <TableWrapper>
            {notices
              .slice(
                (currPage - 1) * totalPerPage,
                Math.min(currPage * totalPerPage, notices.length)
              )
              .map((item, id) => (
                <div
                  key={`list_key${id}`}
                  className="row border-bottom pb-2 mb-2"
                >
                  <div>{item.id + 1}</div>
                  <div className="col">
                    {item.counts <= 1 ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b className="text-secondary">{item.text}</b>
                      </a>
                    ) : (
                      <Link
                        to={`/notices/Announcements/multiple?title=${item.text}`}
                      >
                        <b className="text-secondary">{item.text}</b>
                      </Link>
                    )}
                    <div className="row">
                      <div className="col-md-6">
                        <span
                          className="badge badge-pill "
                          style={{ background: "#274086", color: "white" }}
                        >
                          {formatDateString(item.date)}
                        </span>
                      </div>
                      <div className="col-md-6 text-right">
                        {item.status === 1 ? t("Closed") : t("Active")}{" "}
                        {item.counts <= 1 ? (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiDownload size="25px" />
                          </a>
                        ) : (
                          <Link
                            to={`/notices/Announcements/multiple?title=${item.text}`}
                          >
                            <FiExternalLink size="25px" />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </TableWrapper>

          <div
            style={{ textAlign: "center", color: "#274187", margin: "1rem 0" }}
          >
            {currPage !== 1 ? (
              <IoIosArrowDropleftCircle
                size="1.5em"
                onClick={() => setCurrPage((prev) => prev - 1)}
                style={{ cursor: "pointer", margin: "0 1rem" }}
              />
            ) : null}
            {currPage}
            {currPage !== Math.ceil(notices.length / totalPerPage) ? (
              <IoIosArrowDroprightCircle
                size="1.5em"
                onClick={() => setCurrPage((prev) => prev + 1)}
                style={{ cursor: "pointer", margin: "0 1rem" }}
              />
            ) : null}
          </div>
        </>
      ) : searchedvalue ? (
        <h3 style={{ marginTop: "5rem" }}>
          <center>Not Found</center>
        </h3>
      ) : (
        <h3 style={{ marginTop: "5rem" }}>
          <center>
            <Spinner animation="border" />
          </center>
        </h3>
      )}
    </Container>
  );
}

export default Office_order;
