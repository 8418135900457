import shubham from "../images/Webteam/shubham.jpeg";
import suvrodeep from "../images/Webteam/suvrodeep.jpeg";
import shashank from "../images/Webteam/shashank.jpeg";
import priya from "../images/Webteam/priya.jpg";
import aditi from "../images/Webteam/aditi.jpg";
import manish from "../images/Webteam/Manish.jpg";
import akhilesh from "../images/Webteam/AKHILESH.jpg";
import ayush from "../images/Webteam/AYUSH.jpg";
import subham2 from "../images/Webteam/SHUBHAM.jpg";
import mohit from "../images/Webteam/Mohit.jpg";
import rajiv from "../images/Webteam/rajiv.jpg";
import nand from "../images/Webteam/nand.jpg";
import harshraj from "../images/Webteam/harshraj.jpg";
import shivansh from "../images/Webteam/shivansh.jpg";
import harsh from "../images/Webteam/Harsh.jpg";
import arvindh from "../images/Webteam/arvindh.jpg";
import gautam from "../images/Webteam/Gautam.jpg";
import mayur from "../images/Webteam/mayur.jpg";
// import shashank from "../images/Webteam/shashank.jpeg";

export const WebTeamData = [
  {
    priority: 1,
    name: "Shubham Tiwary",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "ME 2K20",
    img: shubham,
  },
  {
    priority: 1,
    name: "Suvrodeep Halder",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K20",
    img: suvrodeep,
  },

  {
    priority: 1,
    name: "Shashank Karan",
    post: "Full Stack Developer",
    mobile: "7004307974",
    email: "shashankkaran41@gmail.com",
    batch: "CSE 2K20",
    img: shashank,
  },
  {
    priority: 1,
    name: "Deva Priya",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K20",
    img: priya,
  },
  {
    priority: 1,
    name: "Aditi Prasad",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "EE 2K20",
    img: aditi,
  },
  {
    priority: 1,
    name: "Akhilesh Kumar Mishra",
    post: "Full Stack Developer",
    mobile: "",
    email: "contactakhileshmishra@gmail.com",
    batch: "CSE 2K20",
    img: akhilesh,
  },
  {
    priority: 1,
    name: "Srirajiv Ranjan",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K20",
    img: rajiv,
  },
  {
    priority: 1,
    name: "Harsh Raj",
    post: "Full Stack Developer",
    mobile: "8210175872",
    email: "iharshraj1123@gmail.com",
    batch: "CE 2K20",
    img: harshraj,
  },
  {
    priority: 1,
    name: "Ayush Ambasta",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "ECE 2K20",
    img: ayush,
  },
  {
    priority: 1,
    name: "Manish Vidyarthi",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "EE 2K20",
    img: manish,
  },
  {
    priority: 1,
    name: "Nand Raj",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "EE 2K20",
    img: nand,
  },
  {
    priority: 2,
    name: "Mohit Kumar Agrawal",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: mohit,
  },
  {
    priority: 2,
    name: "Shubham Kumar Singh",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "PIE 2K21",
    img: subham2,
  },
  {
    priority: 2,
    name: "Harsh Raj 21",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: harsh,
  },
  {
    priority: 2,
    name: "Adatra Gautam Kiran",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: gautam,
  },
  {
    priority: 2,
    name: "Shivansh Kumar Dubey",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: shivansh,
  },
  {
    priority: 2,
    name: "Arvindh Iyer",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: arvindh,
  },
  {
    priority: 2,
    name: "Mayur Kumar",
    post: "Full Stack Developer",
    mobile: "",
    email: "",
    batch: "CSE 2K21",
    img: mayur,
  },
];

export const PreviousWebTeam = [
  {
    name: "Pushpakant Behera",
    Batch: "CSE (2019)",
    email: "",
  },
  {
    name: "Aman Kumar Pandey",
    Batch: "CSE (2019)",
    email: "",
  },
  {
    name: "Pragya Singh",
    Batch: "CSE (2019)",
    email: "",
  },
  {
    name: "Bhavya Shukla",
    Batch: "CSE (2019)",
    email: "",
  },
  {
    name: "Ankit Raj",
    Batch: "CSE (2019)",
    email: "",
  },
  {
    name: "Abhishek Kumar",
    Batch: "PIE (2018)",
    email: "",
  },
  {
    name: "Abhinav Mani",
    Batch: "CSE (2018)",
    email: "",
  },
  {
    name: "Amit Raj",
    Batch: "CSE (2018)",
    email: "",
  },
  {
    name: "Adarsh Gautam",
    Batch: "CSE (2018)",
    email: "",
  },
  {
    name: "Sunny Kumar Singh",
    Batch: "ECE (2018)",
    email: "",
  },
  {
    name: "Ritik Raj",
    Batch: "CSE (2018)",
    email: "",
  },
  {
    name: "Ayush Dubey",
    Batch: "2017",
    email: "ayushdubey957@gmail.com",
  },
  {
    name: "Gurmeet Singh",
    Batch: "2017",
    email: " gsinghs1998@gmail.com",
  },
  {
    name: "Niraj Oswal",
    Batch: "2017",
    email: "niraj5517@gmail.com",
  },
  {
    name: "Vivek Sharma",
    Batch: "2017",
    email: "vivek.dakshana17@gmail.com",
  },
  {
    name: "Piyush Rai",
    Batch: "2017",
    email: "-",
  },
  {
    name: "Abhinav Kinshuk",
    Batch: "2017",
    email: "-",
  },
  {
    name: "Diwakar Singh",
    Batch: "2017(IIIT ranchi)",
    email: "-",
  },
  {
    name: "P. Soumya",
    Batch: "2017(MCA)",
    email: "-",
  },
  {
    name: "Tunesh Kant",
    Batch: "2016",
    email: "-",
  },
  {
    name: "Suryansh Raj Dubey",
    Batch: "2016",
    email: "suryanshraj97@gmail.com",
  },
  {
    name: "Mohit Kumar Jha",
    Batch: "2016",
    email: "mohitkumarjha56@gmail.com",
  },
  {
    name: "Chandan Kumar Sharma",
    Batch: "2016",
    email: "chandancks732@gmail.com",
  },
  {
    name: "Sourabh Kumar",
    Batch: "2016",
    email: "sourabh.nit067@gmail.com",
  },
  {
    name: "Shivam Kumar Gupta",
    Batch: "2016",
    email: "shivamgupta161998@gmail.com",
  },
  {
    name: "Ankit Kumar Gupta",
    Batch: "2016",
    email: "ankitkumargupta365@gmail.com",
  },
  {
    name: "Omkar Mishra",
    email: "omkarmishra66@gmail.com",
    Batch: "2015",
  },
  {
    name: "Rahul Nag",
    Batch: "2015",
    email: "rahulnitjsrece@gmail.com",
  },
  {
    name: "Anurag Kumar",
    Batch: "2015",
    email: "anuragrajpoot028@gmail.com",
  },
  {
    name: "Rishabh Agrawal",
    Batch: "2015",
    email: "rishabhagrawal041@gmail.com",
  },
  {
    name: "Rohit Kumar",
    Batch: "2015",
    email: "rohit.nit35@gmail.com",
  },
  {
    name: "Vinit Kumar",
    Batch: "2015",
    email: "vinitkumar1610@gmail.com",
  },
  {
    name: "Aamir Hussain",
    Batch: "2015",
    email: "aamir007.handsome@gmail.com",
  },
  {
    name: "Akshay Poddar",
    Batch: "2014",
    email: "apnaakshay@gmail.com",
  },
  {
    name: "Shikhar Kunal",
    Batch: "2014",
    email: "shikharkunal99@gmail.com",
  },
  {
    name: "Abhishek Keshri",
    Batch: "2014",
    email: "akeshari87@gmail.com",
  },
  {
    name: "Kornala Arun",
    Batch: "2014",
    email: "kornalaarun@gmail.com",
  },
  {
    name: "Devesh Jagwani",
    Batch: "2014",
    email: "deveshjagwani8@gmail.com",
  },
  {
    name: "Avinash Kumar",
    Batch: "2014",
    email: "ak007avisonu@gmail.com",
  },
  {
    name: "Sanjay Suman",
    Batch: "2014",
    email: "sanjaysuman59@hotmail.com",
  },
  {
    name: "Akash Kumar",
    Batch: "2014",
    email: "akashrakshit401@gmail.com",
  },
  {
    name: "Aayushi",
    Batch: "2014",
    email: "aayushi9555@gmail.com",
  },
  {
    name: "Sandhya Singh",
    Batch: "2014",
    email: "sandhyasinghnitjsr123@gmail.com",
  },
  {
    name: "Shreya Bhattacharya",
    Batch: "2014",
    email: "shreya.23sep95@gmail.com",
  },
  {
    name: "Goutam Kumar",
    Batch: "2015",
    email: "gautamsingh722@gmail.com",
  },
  {
    name: "Sidhant Rajan",
    Batch: "2014",
    email: "sidhant.r60@gmail.com",
  },
  {
    name: "Ujjayanta Bhaumik",
    Batch: "2014",
    email: "ujjayanta_bhaumik@rediffmail.com",
  },
  {
    name: "Shubham Vishwakarma",
    Batch: "2013",
    email: "-",
  },
  {
    name: "Aman Kumar Singh",
    Batch: "2013",
    email: "aman.13ugcs043@nitjsr.ac.in",
  },
  {
    name: "Sanket Kumar Singh",
    Batch: "2013",
    email: "sanket.13ugcs003@nitjsr.ac.in",
  },
  {
    name: "Shubhendu Shishir",
    Batch: "2013",
    email: "aiden.jsr@gmail.com",
  },
  {
    name: "Mukesh Kumar",
    Batch: "2013",
    email: "mukesh.13ugcs048@nitjsr.ac.in",
  },
  {
    name: "Suraj Potnuru",
    Batch: "2013",
    email: "surajpotnuru7@gmail.com",
  },
  {
    name: "Punj Kumar",
    Batch: "2013",
    email: "punjkumar3@gmail.com",
  },
  {
    name: "Abhishek Kumar",
    Batch: "2012",
    email: "abhishekheaven7@gmail.com",
  },
  {
    name: "Riya Prasad      (Student, CSE)",
    Batch: "2011",
    email: "-",
  },
  {
    name: "Rohit Mishra     (Student, CSE)",
    Batch: "2011",
    email: "-",
  },
  {
    name: "Ashutosh Kumar    (Student, CSE)",
    Batch: "2011",
    email: "-",
  },
  {
    name: "Sandipon Mukherjee     (Student, CSE)",
    Batch: "2011",
    email: "sandipan_says@yahoo.in",
  },
  {
    name: "Mayank Kumar     (Student, CSE)",
    Batch: "2011",
    email: "mayank.cse.nitjsr@gmail.com",
  },
  {
    name: "Apurwa Rani     (Student, CSE)",
    Batch: "2011",
    email: "raniapurwa@gmail.com",
  },
  {
    name: "Rishikesh Maurya     (Student, CSE)",
    Batch: "2011",
    email: "rishi.nit.iim@gmail.com",
  },
  {
    name: "Ankur Sahuwala     (Student, CSE)",
    Batch: "2009",
    email: "ankur_sahuwala@yahoo.com",
  },
  {
    name: "D. Hariharan     (Student, CSE)",
    Batch: "2009",
    email: "mani.hariharan@gmail.com",
  },
  {
    name: "Ajit Kumar     (Student, CSE)",
    Batch: "2009",
    email: "ajitk1990@gmail.com",
  },
  {
    name: "Nishant Kumar     (Student, ECE)",
    Batch: "2008",
    email: "nishantkumar35@gmail.com",
  },
  {
    name: "Md. Shahnawaz Alam     (Student, CSE)",
    Batch: "2008",
    email: "shan92822@gmail.com",
  },
];
