import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import avatar from '../../../assets/images/default.jpg';
import { SectionWrapper } from "../../../assets/css/Custom/DepartmentStyle";
import { Icon } from "react-icons-kit";
import { handORight } from "react-icons-kit/fa/handORight";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";

function NoticeSection({ Dept }) {
  const [notices, setNotice] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNotice = async () => {
      try {
        let res = await getRequest(`/department_notices?branch=${Dept}`);
        setNotice(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchNotice();
  }, [Dept]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    vertical: true,
  };

  const Notices = notices.map((item, idx) => {
    return {
      id: idx,
      text: item.title,
      link: item.notice_path,
    };
  });

  // console.log('Notices', Notices.length);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <SectionWrapper>
            <h5 className="Notice-head">{t("Notices and Announcements")}</h5>
            <br />
            <div className="notice-box">
              {Notices.length <= 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "300px",
                    boxShadow: "1px 2px 10px lightgray",
                    background: "#fff",
                  }}
                >
                  {t("No Notice Available")}
                </div>
              ) : (
                <Slider {...settings}>
                  {Notices.map((item, idx) => (
                    <div className="single-notice" key={item.id}>
                      <Icon icon={handORight} />
                      &nbsp;&nbsp;
                      <Link to={{ pathname: `${item.link}` }} target="_blank">
                        {item.text}
                      </Link>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </SectionWrapper>
        </div>
      </div>
    </div>
  );
}

// function department(dept) {
//   if (dept === 'CSE') return 'cs';
//   if (dept === 'ECE') return 'ece';
//   if (dept === 'EE') return 'eee';
//   if (dept === 'ME') return 'mech';
//   if (dept === 'CE') return 'civil';
//   if (dept === 'MME') return 'meta';
//   if (dept === 'PI') return 'prod';
//   if (dept === 'MAT') return 'maths';
//   if (dept === 'PHY') return 'phys';
//   if (dept === 'CA') return 'mca';
//   if (dept === 'HSM') return 'humanities';
//   if (dept === 'CHEM') return 'chem';
// }

export default NoticeSection;
