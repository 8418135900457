export const AssistantListdata = [
  {
    id: 1,
    Dept: "Chemistry",
    data: [
      { id: 1, AGP: 6000 },
      { id: 2, AGP: 7000 },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Chemistry%20Assistant%20Professor,%20AGP-8000.pdf",
      },
    ],
  },

  {
    id: 3,
    Dept: "Computer Science and Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Computer%20Science%20and%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CSE%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Computer%20Science%20and%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CSE%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Computer%20Science%20and%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CSE%208000%20Ineligible.pdf",
      },

    ],
  },
  {
    id: 4,
    Dept: "Department of Civil Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Civil%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CIVIL%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Civil%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CIVIL%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Civil%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/CIVIL%208000%20Ineligible.pdf",
      },

    ],
  },
  {
    id: 5,
    Dept: "Department of Humanities, Social Sciences and Management",
    data: [
      { id: 1, AGP: 6000 },
      { id: 2, AGP: 7000 },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Humanities,%20Social%20Sciences%20and%20Management%20Assistant%20Professor,%20AGP-8000.pdf",
      },

    ],
  },
  {
    id: 6,
    Dept: "Department of Production and Industrial Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PIE%206000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Production%20and%20Industrial%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PIE%207000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Production%20and%20Industrial%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PIE%208000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Department%20of%20Production%20and%20Industrial%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
      },
    ],
  },
  {
    id: 7,
    Dept: "Electrical Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electrical%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/EE%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electrical%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/EE%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electrical%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/EE%208000%20Ineligible.pdf",
      },
    ],
  },
  {
    id: 8,
    Dept: "Electronics and Communication Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electronics%20and%20Communication%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ECE%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electronics%20and%20Communication%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ECE%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Electronics%20and%20Communication%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ECE%208000%20Ineligible.pdf",
      },
    ],
  },
  {
    id: 9,
    Dept: "Mathematics",
    data: [
      {
        id: 1,
        AGP: 6000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/Maths%206000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mathematics%20Assistant%20Professor,%20AGP-6000.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/Maths%207000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mathematics%20Assistant%20Professor,%20AGP-7000.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/Maths%208000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mathematics%20Assistant%20Professor,%20AGP-8000.pdf",
      },
    ],
  },
  {
    id: 10,
    Dept: "Mechanical Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mechanical%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ME%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mechanical%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ME%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Mechanical%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ME%208000%20Ineligible.pdf",
      },
    ],
  },
  {
    id: 11,
    Dept: "Metallurgical and Materials Engineering",
    data: [
      {
        id: 1,
        AGP: 6000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/MME%206000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Metallurgical%20and%20Materials%20Engineering%20Assistant%20Professor,%20AGP-6000.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://www.nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Metallurgical%20and%20Materials%20Engineering%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/MME%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/MME%208000%20Ineligible.pdf",
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Metallurgical%20and%20Materials%20Engineering%20Assistant%20Professor,%20AGP-8000.pdf",
      },
    ],
  },
  {
    id: 12,
    Dept: "Physics",
    data: [
      {
        id: 1,
        AGP: 6000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Physics%20Assistant%20Professor,%20AGP-6000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PHY%206000%20Ineligible.pdf",
      },
      {
        id: 2,
        AGP: 7000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Physics%20Assistant%20Professor,%20AGP-7000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PHY%207000%20Ineligible.pdf",
      },
      {
        id: 3,
        AGP: 8000,
        Eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/ap_eligible/Final%20Eligible%20Physics%20Assistant%20Professor,%20AGP-8000.pdf",
        Not_eligible:
          "https://nitjsr.ac.in/backend/uploads/recruitments/aplist/PHY%208000%20Ineligible.pdf",
      },
    ],
  },
];


export const NoteDowns = [
  {
    key: 1,
    text: "Test / Interview : likely to start from the last week of July / first week of August 2022. Exact dates will be notified shortly.",
  },
  {
    key: 2,
    text: "OBC-NCL, EWS certificates issued by competent authority on or after 01 April 2022 have to be submitted by the concerned applicants at the time of test / interview.",
  },
  {
    key: 3,
    text: "Applicants, already employed in Government / Govt. Undertaking / Autonomous Body, have to produce an NOC from the competent authority at the time of test / interview.",
  },
  {
    key: 4,
    text: "For further updates, applicants are requested to visit our Institute website regularly.",
  },
];