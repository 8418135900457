export const BoysHostel = [
	// {
	// 	id: 1,
	// 	text: 'Hostel C',
	// 	path: '/Facilities/Hostel_Details?type=C',
	// },
	// {
	// 	id: 2,
	// 	text: 'Hostel D',
	// 	path: '/Facilities/Hostel_Details?type=D',
	// },
	{
		id: 3,
		text: 'Hostel E',
		path: '/Facilities/Hostel_Details?type=E',
	},
	{
		id: 4,
		text: 'Hostel F',
		path: '/Facilities/Hostel_Details?type=F',
	},
	{
		id: 5,
		text: 'Hostel G',
		path: '/Facilities/Hostel_Details?type=G',
	},
	{
		id: 6,
		text: 'Hostel H',
		path: '/Facilities/Hostel_Details?type=H',
	},
	{
		id: 7,
		text: 'Hostel I',
		path: '/Facilities/Hostel_Details?type=I',
	},
	{
		id: 8,
		text: 'Hostel J',
		path: '/Facilities/Hostel_Details?type=J',
	},
	{
		id: 9,
		text: 'Hostel K',
		path: '/Facilities/Hostel_Details?type=K',
	},
];
export const GirlsHostel = [
	{
		id: 1,
		text: 'Hostel A',
		path: '/Facilities/Hostel_Details?type=A',
	},
	{
		id: 2,
		text: 'Hostel B',
		path: '/Facilities/Hostel_Details?type=B',
	},
	{
		id: 3,
		text: 'Hostel C',
		path: '/Facilities/Hostel_Details?type=C',
	},
	{
		id: 4,
		text: 'Hostel D',
		path: '/Facilities/Hostel_Details?type=D',
	},
	{
		id: 5,
		text: 'Hostel RLB',
		path: '/Facilities/Hostel_Details?type=D',
	},
	{
		id: 6,
		text: 'Hostel AHR',
		path: '/Facilities/Hostel_Details?type=D',
	},
];

export const CommonHostelData = [
	'Mess (Breakfast, Lunch, Refreshment, Dinner)',
	'Wi-Fi internet facility',
	'Guest Room',
	'Gymnasium',
	'Common room : Television with DTH facility',
	'Outdoor games facility',
	'Indoor games like carom, T.T.tables etc.',
];
export const BoysHostelData = [
	{
		id: 1,
		name: 'Hostel C',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'Warden I/C (Girls)',
				name: 'Dr. Madhu Singh',
				phone: ['9431724528'],
				email: ['wardenic.girls@nitjsr.ac.in', 'madhu.ee@nitjsr.ac.in'],
			},
			{
				id: 3,
				post: 'Warden',
				name: 'Dr. Neha Agnihotri',
				phone: ['8933873665', '0657-237-4158 (O)'],
				email: ['warden.b@nitjsr.ac.in', 'neha.phy@nitjsr.ac.in'],
			},
			{
				id: 4,
				post: 'Warden',
				name: 'Dr. Mayuri Baruah',
				phone: ['9678434603', '0657-237-4158 (O)'],
				email: ['warden2.b@nitjsr.ac.in', 'mayuri.prod@nitjsr.ac.in'],
			},
		],
	},
	{
		id: 2,
		name: 'Hostel D',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 3,
		name: 'Hostel E',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 4,
		name: 'Hostel F',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 5,
		name: 'Hostel G',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 6,
		name: 'Hostel H',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 7,
		name: 'Hostel I',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 8,
		name: 'Hostel J',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
	{
		id: 9,
		name: 'Hostel K',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'warden',
				name: '',
			},
		],
	},
];

export const GirlsHostelData = [
	{
		id: 1,
		name: 'Hostel A',
		nrooms: '',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'Warden I/C (Girls)',
				name: 'Dr. Madhu Singh',
				phone: ['9431724528'],
				email: ['wardenic.girls@nitjsr.ac.in', 'madhu.ee@nitjsr.ac.in'],
			},
			{
				id: 3,
				post: 'Warden',
				name: 'Dr. Poulami Maji',
				phone: ['8617638634', '0657-237-4157 (O)'],
				email: ['warden.a@nitjsr.ac.in', 'poulami.met@nitjsr.ac.in'],
			},
			{
				id: 4,
				post: 'Warden',
				name: 'Dr. Moumita Mondal',
				phone: ['7541013198', '0657-237-4157 (O)'],
				email: ['warden2.a@nitjsr.ac.in', 'moumita.chem@nitjsr.ac.in'],
			},
		],
	},
	{
		id: 2,
		name: 'Hostel B',
		nrooms: '180',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'Warden I/C (Girls)',
				name: 'Dr. Madhu Singh',
				phone: ['9431724528'],
				email: ['wardenic.girls@nitjsr.ac.in', 'madhu.ee@nitjsr.ac.in'],
			},
			{
				id: 3,
				post: 'Warden',
				name: 'Dr. Neha Agnihotri',
				phone: ['8933873665', '0657-237-4158 (O)'],
				email: ['warden.b@nitjsr.ac.in', 'neha.phy@nitjsr.ac.in'],
			},
			{
				id: 4,
				post: 'Warden',
				name: 'Dr. Mayuri Baruah',
				phone: ['9678434603', '0657-237-4158 (O)'],
				email: ['warden2.b@nitjsr.ac.in', 'mayuri.prod@nitjsr.ac.in'],
			},
		],
	},
	{
		id: 3,
		name: 'Hostel C',
		nrooms: '180',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'Warden I/C (Girls)',
				name: 'Dr. Madhu Singh',
				phone: ['9431724528'],
				email: ['wardenic.girls@nitjsr.ac.in', 'madhu.ee@nitjsr.ac.in'],
			},
			{
				id: 3,
				post: 'Warden',
				name: 'Dr. Akanksha Shukla',
				phone: ['9458587575', '0657-237-4227 (O)'],
				email: ['warden.c@nitjsr.ac.in', 'ashukla.hum@nitjsr.ac.in'],
			},
			{
				id: 4,
				post: 'Warden',
				name: 'Dr. Shwati Sudha',
				phone: ['9162728686', '0657-237-4227 (O)'],
				email: ['warden2.c@nitjsr.ac.in', 'ssudha.hum@nitjsr.ac.in'],
			},
		],
	},
	{
		id: 4,
		name: 'Hostel D',
		nrooms: '180',
		Wardens: [
			{
				id: 1,
				post: 'Chief Warden',
				name: 'Mr. Nigam Prakash',
				phone: ['9973771555'],
				email: ['chiefwarden@nitjsr.ac.in'],
			},
			{
				id: 2,
				post: 'Warden I/C (Girls)',
				name: 'Dr. Madhu Singh',
				phone: ['9431724528'],
				email: ['wardenic.girls@nitjsr.ac.in', 'madhu.ee@nitjsr.ac.in'],
			},
			{
				id: 3,
				post: 'Warden',
				name: 'Dr. Seeram Madhuri',
				phone: ['8309942434', '0657-237-4228 (O)'],
				email: ['warden.d@nitjsr.ac.in', 'smadhuri.ce@nitjsr.ac.in'],
			},
			{
				id: 4,
				post: 'Warden',
				name: 'Dr. Dulari Hansdah',
				phone: ['9439844294', '0657-237-4228 (O)'],
				email: ['warden2.d@nitjsr.ac.in', 'dhansdah.me@nitjsr.ac.in'],
			},
		],
	},
];
