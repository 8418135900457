//Recruitments Setion
import { useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { scrollToContent } from "../../functions/scroll.js";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Footer from "../../Components/Layout/Footer";
import { TableWrapper } from "../../assets/css/Custom/AcademicStyle";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

function PAN_GST() {
  const { t } = useTranslation();

  useEffect(() => {
    scrollToContent(0);
  }, [])
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("pan_gst_heading")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Container className="mt-5">
            <h1 className="text-center">
              <strong>{t("pan_gst_heading")}</strong>
            </h1>
            <strong>
              <TableWrapper className="table-responsive">
                <table className="table mb-5 ">
                  <tr>
                    <td>{t("pan_gst_tleft1")}</td>
                    <td>{t("pan_gst_tright1")}</td>
                  </tr>
                  <tr>
                    <td>{t("pan_gst_tleft2")}</td>
                    <td>
                      {t("pan_gst_tright2")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("pan_gst_tleft3")}</td>
                    <td>AACAN7221M</td>
                  </tr>
                  <tr>
                    <td>{t("pan_gst_tleft4")}</td>
                    <td>20AACAN7221M1ZR</td>
                  </tr>
                  <tr>
                    <td>{t("pan_gst_tleft5")}</td>
                    <td>{t("pan_gst_tright3")}</td>
                  </tr>
                  <tr>
                    <td>{t("pan_gst_tleft6")}</td>
                    <td>RCHN00191C</td>
                  </tr>
                </table>
              </TableWrapper>
            </strong>
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default PAN_GST;
