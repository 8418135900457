import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import PropTypes from "prop-types";
import Container from "../../../Components/UI/Container";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import Select from "../../../Components/Select";
import { SemesterScheduleData } from "../../../assets/data/SemesterSchedule";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

function PdfComponent({ title, link }) {
  return (
    <a href={link}>
      <p className="pdfLink">
        <Icon icon={chevronCircleRight} size={18} />
        &nbsp;&nbsp; {title}
      </p>
    </a>
  );
}

function SemesterSchedule() {
  const [SingleData, setSingleData] = useState();
  useEffect(() => {
    setSingleData(SemesterScheduleData[0]);
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Important documents related to the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,documents,notice,calender of nit jsr,schedule of nit jsr"
        />
      </Helmet>
      <Container width="99%">
        <br />
        <br />
        <Select
          placeholder={t("Select Department")}
          options={SemesterScheduleData}
          onChange={(id) => setSingleData(id)}
          // onChange={(id) => console.log(id)}
        />
        {SingleData ? (
          <>
            <Heading
              content={t(`${SingleData.label}`)}
              style={{
                fontSize: "1.5em",
                marginBottom: "15px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <SectionWrapper>
              {SingleData.content.length ? (
                SingleData.content.map((item, index) => {
                  return (
                    <PdfComponent title={t(`${item.title}`)} link={item.link} />
                  );
                })
              ) : (
                <Skeleton count={10} />
              )}
            </SectionWrapper>
          </>
        ) : (
          <Skeleton count={10} />
        )}
      </Container>
    </>
  );
}

// HumanityBlock style props
SemesterSchedule.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AboutUs default style
SemesterSchedule.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default SemesterSchedule;
