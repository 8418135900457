import React, { useState, useEffect } from "react";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";
import { Stack, Pagination } from "@mui/material";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { Helmet } from "react-helmet";
import Sticky from "react-stickynode";
import Navbar from "../../../Components/Layout/PagesNavbar";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import {
  CharityWrapper,
  GlobalStyle,
} from "../../../assets/css/Main/main.style";
import { ResetCSS } from "../../../assets/css/style";
import Banner from "../../../Components/Layout/Banner/Banner";

function ListDebarred({ fontsize }) {
  const [debarredList, setDebarredList] = useState([]);

  useEffect(() => {
    getRequest("debarred_agencies").then((res) => {
      const data = res.data;
      setDebarredList(data.data);
    });
  }, []);

  const { t } = useTranslation();

  const listPerPage = 8;
  const pageCount = Math.ceil(debarredList.length / listPerPage);
  let currPageList = debarredList.slice(0, listPerPage);

  const [page, setPage] = useState(1);

  const StartEventNo = (page - 1) * listPerPage;
  const endEventNo = page * listPerPage;
  currPageList = debarredList.slice(StartEventNo, endEventNo);
  const paginate = (e, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>

        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>

        <Banner heading={"List Debarred Agencies"} />
        <Container width="1250px">
          <TableWrapper>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th style={{ padding: "10px 10px", minWidth: "70px" }}>
                    {t("Sl. No.")}
                  </th>
                  <th>{t("Title")}</th>
                  <th>{t("View")}</th>
                </tr>
              </thead>
              <tbody>
                {currPageList.map((item, id) => (
                  <tr key={`list_key${id}`}>
                    <td
                      style={{
                        fontSize: fontsize,
                        marginLeft: "40px",
                      }}
                      data-column="Sl.No."
                    >
                      {id + 1}
                    </td>
                    <td style={{ fontSize: fontsize }} data-column="title">
                      {item.title}
                    </td>
                    <td
                      style={{
                        fontSize: fontsize,
                      }}
                      data-column="View"
                    >
                      {/* <Link href={item.link}> */}
                      <a
                        href={
                          process.env.REACT_APP_BACKEND_URL + "/" + item.upload
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="dwn-btn"
                          style={{ marginRight: "40px" }}
                        >
                          {t("View")}
                        </button>
                      </a>
                      {/* </Link> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrapper>
          {debarredList.length > listPerPage && (
            <Stack mt={5} alignItems="center">
              <Pagination
                count={pageCount}
                page={page}
                color="primary"
                defaultPage={1}
                onChange={paginate}
                size="large"
              />
            </Stack>
          )}
        </Container>
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default ListDebarred;
