import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import { Tag, Divider } from "antd";
import { getRequest } from "../../../../functions/request";

export default function ResearchAreaSection() {
    const { id } = useParams();
    const [research, setResearch] = useState([]);
    const [member, setMember] = useState(null);

    useEffect(() => {

        const getData = async () => {
            const memberData = await getRequest(`/members?id=${id}`);
            setMember(memberData.data.result);
            const researchData = await getRequest(`/research?id=${id}`);
            setResearch(researchData.data.result);
        };

        getData();
    }, [id]);


    return (
        <div>

            <p
                className="px-4"
                style={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "Poppins, sans-serif",
                }}
            >
                {research
                    ? research.map((value) => {
                        return (
                            <Tag color="#686060">
                                <b>{value.topic}</b>
                            </Tag>
                        );
                    })
                    : null}
            </p>
            {member?.length > 0 &&
                <>
                    <Divider />
                    <li>

                        <label>
                            <b className="text-muted">Member of</b>
                        </label>
                        <p
                            className="px-4"
                            style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            {  member.map((value) => {
                                return (
                                    <Tag color="#686060">
                                        <b>{value.member}</b>
                                    </Tag>
                                );
                            })}
                        </p>
                    </li>
                </>
            }
            
                { research.length === 0 ? ("Nothing Found") : null}

        </div>
    )
}
