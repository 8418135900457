import React, { Fragment, useState, useEffect } from "react";
// import { ThemeProvider } from "styled-components";
// import { mainTheme } from "../../theme/main";
// import { ResetCSS } from "../../assets/css/style";
// import {
//   GlobalStyle,
//   CharityWrapper,
//   ContentWrapper,
// } from "../../assets/css/Main/main.style";
// import Sticky from "react-stickynode";
// import Container from "../../Components/UI/Container";
// import { DrawerProvider } from "../../contexts/DrawerContext";
// import Navbar from "../../Components/Layout/PagesNavbar";
// import DrawerSection from "../../Components/Layout/DrawerSection";
// //import Banner from '../../Components/Layout/Banner/Banner';
// import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
// import Footer from "../../Components/Layout/Footer";
// import { Row, Col } from "react-bootstrap";
// import { useParams } from "react-router-dom";
// import ResearchArea from "./Sections/ResearchArea";
// import ResearchPublications from "./Sections/ResearchPublications";
// import ResearchProjects from "./Sections/ResearchProjects";
// import ResearchFacilities from "./Sections/ResearchFacilities";
// import Awards from "./Sections/Awards";
// import Entrepreneurship from "./Sections/Entrepreneurship";
// import MOU from "./Sections/MOU";
// import Documents from "./Sections/Documents";
// import ContactUs from "./Sections/ContactUs";
// import { Helmet } from "react-helmet";
// import { useTranslation } from "react-i18next";
// import { scrollToContent } from "../../functions/scroll.js";
// import BannerRnc from "../../Components/Layout/Banner/BannerRnc";
import Loader from "../../Components/Preloader/Loader.js";

function ResearchCon() {
  useEffect(() => {
    window.location.href = "https://rnc-nitjsr.in";
  });
  // const { page } = useParams();
  // const { t } = useTranslation();
  // const [ActiveComp, setActiveComp] = useState("");
  // useEffect(() => {
  //   const pg = page.replaceAll("_", " ");
  //   setActiveComp(pg);
  // }, [page]);
  // function handleClick(comp) {
  //   const pg = comp.replaceAll("_", " ");
  //   setActiveComp(pg);
  //   scrollToContent();
  // }

  return (
    <Loader />
    // <ThemeProvider theme={mainTheme}>
    //   <Helmet>
    //     <title>
    //       {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
    //       राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
    //     </title>
    //     <meta
    //       name="description"
    //       content="Infrastructure Planning and Development is the administrative unit of NIT Jamshedpur that is responsible for planning, design, construction and maintenance of new infrastructural facilities in the Institute campus including buildings, roads, walkways, overhead and underground cables, lifelines (water and gas pipelines), communication networks and power transmission systems. "
    //     />
    //     <meta
    //       name="keywords"
    //       content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,nit jsr pnd,nit jamshedpur planning and development,infrastructure development nit jamshedpur"
    //     />
    //   </Helmet>
    //   <Fragment>
    //     <ResetCSS />
    //     <GlobalStyle />
    //     <CharityWrapper>
    //       <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
    //         <Navbar />
    //       </Sticky>
    //       <DrawerProvider>
    //         <DrawerSection black="black" white="white" />
    //       </DrawerProvider>
    //       <ContentWrapper>
    //         <BannerRnc heading={t(`${ActiveComp.split(" ").join("_")}`)} />
    //         <Container width="80%">
    //           <Row>
    //             <Col lg={4} sm={12}>
    //               <Sidebar
    //                 TopLink="Research & Consultancy"
    //                 handleClick={handleClick}
    //               />
    //             </Col>
    //             <Col lg={8} sm={12}>
    //               {ActiveComp === "Research Area" ? <ResearchArea /> : null}
    //               {ActiveComp === "Research Publications" ? (
    //                 <ResearchPublications />
    //               ) : null}
    //               {ActiveComp === "Research Projects" ? (
    //                 <ResearchProjects />
    //               ) : null}
    //               {ActiveComp === "Research Facilities" ? (
    //                 <ResearchFacilities />
    //               ) : null}
    //               {ActiveComp === "Awards & Achievements" ? <Awards /> : null}
    //               {ActiveComp === "Entrepreneurship" ? (
    //                 <Entrepreneurship />
    //               ) : null}
    //               {ActiveComp === "MOU" ? <MOU /> : null}
    //               {ActiveComp === "Documents" ? <Documents /> : null}
    //               {ActiveComp === "Contact Us" ? <ContactUs /> : null}
    //             </Col>
    //           </Row>
    //         </Container>
    //       </ContentWrapper>
    //       <Footer />
    //     </CharityWrapper>
    //     {/* End of Main wrapper section */}
    //   </Fragment>
    // </ThemeProvider>
  );
}

export default ResearchCon;
