import "./media.css";
import React from "react";
import { Link } from "@mui/material";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../Home/ResearchSection/ResearchSection.style";
import Heading from "../../../Components/Heading";
import {
  NoticeWrapper,
  NoticePointWrapper
} from "./notices.style";
import "./scrollBar.css";

import { FaHandPointRight } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import pdf1 from "../../../assets/data/feeLink.pdf";
import pdf2 from "../../../assets/data/dateNotice.pdf";
import pdf3 from "../../../assets/data/13thcommitte.pdf";
import pdf4 from "../../../assets/data/ListGold.pdf";
import pdf5 from "../../../assets/data/correctedName.pdf";
import pdf6 from "../../../assets/data/convo1.pdf";
import pdf7 from "../../../assets/data/convo2.pdf";
import pdf8 from "../../../assets/data/FinalListDR.pdf";
import pdf9 from "../../../assets/data/Names23.pdf";
import pdf10 from "../../../assets/data/convocationAdvisory.pdf";

function Notices() {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <Card>
        <br />
        <SectionHeader>
          <Heading content="Recent Notices" v />
        </SectionHeader>
        <NoticeWrapper>
          <NoticePointWrapper>
            <div class="container row">
            <div>No recent notices </div>
           

              {/* <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  13th Convocation Date Confirmation
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf2}>
                  {" "}
                  PDF
                </a>
              </div>

              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  13th Convocation Registration and Fee Payment Link
                  
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf1}>
                  {" "}
                  PDF
                </a>
              </div>

              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  13th Convocation Committee{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf3}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  List of Gold and Silver Medalist in 13th Convocation{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf4}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  Corrected Names in Hindi and English List (Final Version) for
                  Concovation 2023{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf5}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  List of Degree Recipients(Print Version of name in Hindi and
                  English) dated 20/10/2023{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf6}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  Dues for 2019 UG Tuition/Hostel Fee{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf7}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  List of Degree Recipients (Final Print Version of name in
                  Hindi and English) dated 21/10/23{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf8}>
                  {" "}
                  PDF
                </a>
              </div>
              <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  NOTICE S-64-2023 Revised final print version of Names in Hindi
                  and English dated 23/10/23{" "}
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf9}>
                  {" "}
                  PDF
                </a>
              </div>


                     <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                <FaHandPointRight />
              </div>
              <div className="col-8 col-md-9 px-4">
                <NoticeTitleWrapper>
                  Advisory for the Degree Recipients
                </NoticeTitleWrapper>
              </div>
              <div className="col-2 col-md-2" style={{ textAlign: "center" }}>
                <FaFilePdf />
                <a target="__blank" href={pdf10}>
                  {" "}
                  PDF
                </a>
              </div> */}
            </div>
          </NoticePointWrapper>
        </NoticeWrapper>
      </Card>
      <br />
      <br />
    </div>
  );
}

export default Notices;
