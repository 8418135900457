import React from "react";
import "./sitemap.styles.css";
import { Link } from 'react-router-dom'

const MapArea = (props) => {
  return (
    <div>
      <li>
        <Link className="sitemap-link" to={props.link}>
        <h4>
          <span className="badge badge-pill" style={{backgroundColor: props.bgColor, color: props.color}}>{props.name}</span>
        </h4>
        </Link>
      </li>
    </div>
  );
};

export default MapArea;
