import Container from "../../../../../Components/UI/Container";
import { LongPara } from "../../../styles";

const Configurations = () => {
    return (
        <Container>
            <div><br/>Configurations</div>
        </Container>
    );
};

export default Configurations;