import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionWrapper } from "../../../../assets/css/Custom/DepartmentStyle";
import { Icon } from "react-icons-kit";
import { handORight } from "react-icons-kit/fa/handORight";
import { Link } from "react-router-dom";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  vertical: true,
};

const DeanSWOffice = () => {
  const { t } = useTranslation();
  const rps =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE113/profile/66f9f0b0-5caf-4b49-ba5f-112956f96bc4.jpg";
  const neha =
    "https://nitjsr.ac.in/backend/uploads/Faculty/PH104/profile/18f46564-8bb4-4076-a408-ab5f77b808f8.jpg";
  const sakthivel =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CH105/profile/7ce371fc-6110-43de-a29a-447ff3208185.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={rps}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-sw")}
                </h5>
                <p className="text-center"> {t("n-sw")}</p>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={neha}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Neha Agnihotri
                    </h5>
                    <p
                      className="text-center"
                      style={{ marginBottom: ".25rem" }}
                    >
                      Associate Dean
                    </p>
                    <p className="text-center">Students Welfare</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={sakthivel}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Sakthivel S
                    </h5>
                    <p
                      className="text-center"
                      style={{ marginBottom: ".25rem" }}
                    >
                      Associate Dean
                    </p>
                    <p className="text-center">Students Welfare</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean="Student Welfare" />
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanSWOffice;
