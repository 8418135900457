import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container/index";
import Heading from "../../../Components/Heading/index";
import { Table } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Icon } from "react-icons-kit";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
import icc_event from "../../../assets/images/events/icc_event.jpeg";
import { Typography } from "antd";
function ICC_Women({ fontsize }) {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <Heading
        content={"The Committee's Function:"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          // style={{ textAlign: "center", fontSize: fontsize }}
        >
          <ol
            type="a"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <li>
              <strong>1.</strong> Conduct a prompt and impartial investigation into the incident.
            </li>
            <li>
            <strong>2.</strong>  Establish clear and confidential reporting mechanisms for
              incidents of harassment.
            </li>
            <li>
            <strong>3.</strong>  Ensure the victim's safety and well-being are prioritized.
            </li>
            <li>
            <strong>4.</strong>  To take proactive steps to raise awareness of gender issues
              among NIT Jamshedpur's teachers, staff, and students.
            </li>
            <li>
            <strong>5.</strong>  Promote a culture of respect and accountability within the
              institution.
            </li>
            <li>
            <strong>6.</strong>  To fulfill any additional responsibilities as outlined by the
              act.
            </li>
          </ol>
        </p>
      </SectionWrapper>
      <br />
      <Heading
        content={"Women at work place: Working without Harassment"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          // style={{ textAlign: "center", fontSize: fontsize }}
        >
          <div style={{margin:"20px"}}>According to the Supreme Court, sexual harassment comprises the following behaviours: making advances or physical contact</div>
          <ol
            type="a"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <li>
              <strong>1.</strong> Demanding or requesting sexual favours
            </li>
            <li>
            <strong>2.</strong> Making sexually suggestive comments
            </li>
            <li>
            <strong>3.</strong>  Displaying pornography
            </li>
            <li>
            <strong>4.</strong>  Engaging in any other unwanted physical, verbal, or nonverbal behaviour of a sexual character.
            </li>
          </ol>
        </p>
      </SectionWrapper>
      <br />
      <Heading
        content={"What steps should you take if you encounter sexual harassment?"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          // style={{ textAlign: "center", fontSize: fontsize }}
        >
                    <ol
            type="a"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <li>
              <strong>1.</strong> Do not experience guilt. Make it extremely apparent to the harasser that you find his actions insulting
            </li>
            <li>
            <strong>2.</strong> Keep in mind that harassment will not end on its own if you choose to ignore it. Inform the Internal Complaints Committee of your grievances by coming forward.
            </li>
            <li>
            <strong>3.</strong>  Document every instance of sexual harassment. This record will be useful if you ever feel the need to file a formal complaint.
            </li>
            <li>
            <strong>4.</strong>  Engaging in any other unwanted physical, verbal, or nonverbal behaviour of a sexual character.
            </li>
          </ol>
        </p>
      </SectionWrapper>
      <br />
      <Heading
        content={"Who can approach ICC and file a complaint?"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          // style={{ textAlign: "center", fontSize: fontsize }}
        >
          Any woman employee (faculty and staff including contractual, casual
          and temporary) of NIT Jamshedpur, female students, and any women
          residing in NIT Jamshedpur Campus or visiting the Campus in any
          capacity may file a complaint against an incident of sexual
          harassment.
        </p>
      </SectionWrapper>
      <br />
      <Heading
        content={"How and where to file a complaint?"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          // style={{ textAlign: "center", fontSize: fontsize }}
        >
          The person concerned can personally approach/telephone/write/e-mail to
          any member of ICC followed by the submission of a formal written
          complaint as a hard copy addressed to the Presiding Officer, ICC
          within 90 days of the incident about which the complaint is filed. The
          ICC is required to complete the process of inquiry and submit its
          report within 90 days of the filing of the complaint. The formal
          complaint must contain the following:
        </p>

        <button style={{ border: "none" }}>
          <a href="https://forms.gle/G1gaQNHSV8ankrB4A">
            Register your Complaint here
          </a>
        </button>
      </SectionWrapper>
      <br />
      <Heading
        content={"The formal complaint must contain the following:"}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <ul
        style={{
          fontsize: "1.5em",
        }}
      >
        <li> - Date and time of the incident</li>
        <li> - The place where it occurred</li>
        <li> - Name and contact information of the accused</li>
        <li> - Name and contact information of witnesses</li>
        <li> - Detailed description of the incident</li>
      </ul>
      <br />
      <h6 style={{ fontWeight: 700 }}>Contact Details :</h6>
      Presiding Officer ICC
      <br />
      Email : icc.nitjsr@nitjsr.ac.in
      <br />
      <button style={{ border: "none" }}>
        <a href="https://nitjsr.ac.in/backend/uploads/recents/proforma%20ICASH.docx">
          Download file
        </a>
      </button>
      <br />
      <Heading
        content={t("womencell_top_subtitle1")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p>According to the guidelines outlined in the Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act of 201, the present composition of the Internal Complaints Committee at NIT Jamshedpur stands as follows:</p>
      <Table
        striped
        bordered
        hover
        responsive="md"
        style={{ margin: "10px auto 50px auto" }}
      >
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Position </th>
            <th>Department </th>
            <th>Contact details </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Dr. (Mrs.)Madhu Singh</td>
            <td>Presiding Officer</td>
            <td>Electrical Engineering Department</td>
            <td>Madhu.ee@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>Dr. (Mrs.) Renu Kumari</td>
            <td>Member</td>
            <td>MME</td>
            <td>renu.met@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Dr. (Mrs) Shubham Tripathi</td>
            <td>Member</td>
            <td>Production & Industrial Engineering Department</td>
            <td>shubham.prod@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>Sri Arjun Kumar Singh, Assistant SG-II</td>
            <td>Member</td>
            <td></td>
            <td>arjun.esta@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>Sri Sanjay Chatterjee, Assistant SG-I</td>
            <td>Member</td>
            <td></td>
            <td>schatterjee.adm@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>6.</td>
            <td>Ms. Kushboo (2021RSEC012)</td>
            <td>Member</td>
            <td>Electronics Engineering</td>
            <td>2021rsec012@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>Srishti Singh (2O22PGCESEO2)</td>
            <td>Member</td>
            <td>Civil Engineering</td>
            <td>2022pgcese02@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>Ms. Astha Gupta (2021UGME027)</td>
            <td>Member</td>
            <td>Mechanical Engineering</td>
            <td>2021ugme027@nitjsr.ac.in</td>
          </tr>
          <tr>
            <td>9.</td>
            <td>
              Mrs. Parimita (Samajik Netritwa Sanghthan shreni Pramukh sampark
              Vivhag/ Founder Director of Women in Social Welfare and Services)
            </td>
            <td>NGO, Member</td>
            <td></td>
            <td>parimitaparitosh@ymail.com</td>
          </tr>
        </tbody>
      </Table>
      {/* <TableWrapper>
        <table className="table">
          <thead>
            <tr id="headRow">
              <th>{t("womencell_top_subtitle2_th1")}</th>
              <th>{t("womencell_top_subtitle2_th2")}</th>
            </tr>
          </thead>
          <tr>
            <td style={{ fontSize: fontsize }} data-column="Name">
              {t("womencell_top_subtitle2_tr1")}
            </td>
            <td style={{ fontSize: fontsize }} data-column="Contact">
              7209 805 149
            </td>
          </tr>
        </table>
      </TableWrapper> */}
      <Heading
        content={t("cells_top_subtitle")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <a href="http://www.nitjsr.ac.in/women/Office%20Memorandum%20-%20F.No.11013-3-2009-Esst%20dated%2021.07.2009%20issued%20by%20the%20Department%20of%20Personal%20&%20Training%20%20Government%20of%20India.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("womencell_Downloads_link1")}
          </p>
        </a>
        <a href="https://wcd.nic.in/sites/default/files/Sexual-Harassment-at-Workplace-Act.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("womencell_Downloads_link2")}
          </p>
        </a>
        <a href="https://wcd.nic.in/act/handbook-sexual-harassment-women-workplace">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("womencell_Downloads_link3")}
          </p>
        </a>
        <a href="http://www.nitjsr.ac.in/women/Constitution%20of%20Internal%20Complaints%20Committee%20(ICC)%20.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("womencell_Downloads_link4")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Women_Cell/women%20cell.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;Internal Complaints Committee(ICC)
          </p>
        </a>
      </SectionWrapper>
      <Heading
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
        content={t("icc_event_heading")}
      />
      <Card className="mt-5 mx-3">
        <Card.Img
          variant="center"
          src={icc_event}
          style={{ height: 620, display: "block", margin: "40px auto 0" }}
        />
      </Card>
    </Container>
  );
}

export default ICC_Women;
