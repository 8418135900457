import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { ParagraphWrapper } from '../../../assets/css/Custom/AcademicStyle';
import { useTranslation } from 'react-i18next';
import { Table } from "react-bootstrap";

function Contactus({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <br />
        <Heading content={t("Contacts")} />
        <ParagraphWrapper>
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>{t("Mr. N K Jha")}</b>
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>{t("Assistant Registrar(Store & Purchase Division)")}</b>
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            {t("Email-ID")} : ar.sp@nitjsr.ac.in
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            {t("Mobile No.")}: 7903650585
          </p>
        </ParagraphWrapper>
        <Heading
          content={t("Store_&_Purchase_subtitle1")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
      
    <div>
      
        <Table
          striped
          bordered
          hover
          responsive="md"
          style={{ margin: "50px auto" }}
        >
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Designation concerned</th>
              <th>E-mail </th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>1.</td>
              <td>Superintendent (Store & Purchase)</td>
              <td>supdt.sp@nitjsr.ac.in</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>Assistant Grade -II</td>
              <td>ministerial1.sp@nitjsr.ac.in</td>
            </tr>
            <tr>
              <td>3.</td>
              <td>Dealing Assistant</td>
              <td>ministerial2.sp@nitjsr.ac.in</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>Dealing Assistant</td>
              <td>ministerial3.sp@nitjsr.ac.in</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>Dealing Assistant</td>
              <td>ministerial4.sp@nitjsr.ac.in</td>
            </tr>
            <tr>
              <td>6.</td>
              <td>Dealing Assistant</td>
              <td>ministerial5.sp@nitjsr.ac.in</td>
            </tr>
          </tbody>
        </Table>
      
    </div>
      </Container>
    </Fragment>
  );
}

// HumanityBlock style props
Contactus.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// Contactus default style
Contactus.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Contactus;
