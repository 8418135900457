import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionWrapper } from "../../../../assets/css/Custom/DepartmentStyle";
import { Icon } from "react-icons-kit";
import { handORight } from "react-icons-kit/fa/handORight";
import { Link } from "react-router-dom";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  vertical: true,
};

const DeanFWOffice = () => {
  const { t } = useTranslation();
  const ImgLink =
    "https://nitjsr.ac.in/backend/uploads/Faculty/ME114/profile/8d5b50a4-d81e-42df-b321-63d37c6f4145.png";
  const dinesh =
    "https://nitjsr.ac.in/backend/uploads/Faculty/PI106/profile/383ca2b0-6f6f-4f9d-8866-9692d67e3337.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={ImgLink}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <hr />
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-fw")}
                </h5>
                <p className="text-center">{t("n-fw")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={dinesh}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Dinesh Kumar
                    </h5>
                    <p className="text-center">Faculty Welfare</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean="Faculty Welfare" />
                {/* <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <SectionWrapper>
                        <h5 className="Notice-head">{t("Notices and Announcements")}</h5>
                        <br />
                        <div className="notice-box">
                            <Slider {...settings}>
                             
                                <div className="single-notice" >
                                  <Icon icon={handORight} />
                                  &nbsp;&nbsp;
                                  <a href="https://nitjsr.ac.in/backend/uploads/recruitments/e114856f-554b-4a73-a390-675cfab5f983-Conference%20Report_.pdf" target="_blank">
                                  List of faculty members who have attended workshop/conferences through CPDA
                                  </a>
                                </div>
                                <div className="single-notice" >
                                  <Icon icon={handORight} />
                                  &nbsp;&nbsp;
                                  <a href="https://nitjsr.ac.in/backend/uploads/recruitments/ee243a2c-8e80-4761-bbcb-a3183780ff3e-APAR%20form%20for%20Faculty%20Members%20(1).pdf" target="_blank">
                                  APAR form for faculty members
                                  </a>
                                </div>
                             
                            </Slider>
                        </div>
                      </SectionWrapper>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanFWOffice;
