import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container/index";
import Heading from "../../../Components/Heading/index";
import { SectionWrapper, TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Helmet } from "react-helmet";
import { getRequest } from '../../../functions/request';
import { formatDateString } from '../../../functions/utils';
import { Spinner } from 'react-bootstrap';

function FormerDirector({ fontsize }) {
  const {t} = useTranslation();
  const [formerdirector, setFormerDirector] = useState([]);
	useEffect(() => {
		(async () => {
			getRequest(`/former_directors`)
				.then((res) => {
					return res.data.result.map((item, idx) => {
						return {
							id: idx,
							name: item.name,
							from: item.start,
							to: item.end,
						};
					});
				})
				.then((data) => setFormerDirector(data));
		})();
	}, []);
  return (
    <>
    <Helmet>
        <title> {t("Former_Directors")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर</title>
        <meta name="description" content="Former Directors, NIT Jamshedpur" />
        <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,former directors nit jsr,directors,director list,board of governors" />
      </Helmet>
    <Container width="1250px">
      <br />
      <Heading
        content={t("Former_Directors")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
      <Container>
            {formerdirector ? (
              <>
                <TableWrapper>
                  <table className="table">
                    <thead>
                      <tr id="headRow">
                        <th>{t("Sl.No.")}</th>
                        <th>{t("Name")}</th>
                        <th>{t("From")}</th>
                        <th>{t("To")}</th>
                      </tr>
                    </thead>
                    {formerdirector.map((list, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ fontSize: fontsize }} data-column="Sl.No.">
                            {key+1}.
                          </td>
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="Name"
                          >
                            {t(list.name)}
                          </td>
                          <td style={{ fontSize: fontsize }} data-column="From">
                            {formatDateString(list.from)}
                          </td>
                          <td
                            style={{ fontSize: fontsize }}
                            data-column="To"
                          >
                            {formatDateString(list.to)}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </TableWrapper>
              </>
            ) : (
              <h3 style={{ marginTop: '5rem' }}>
                <center>
                  <Spinner animation="border" />
                </center>
              </h3>
            )}
          </Container>
      </SectionWrapper>
    </Container>
    </>
  );
}

export default FormerDirector;
