import React, { Fragment } from "react";
import { Icon } from "react-icons-kit";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function RankingandRecognition() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta
            name="keywords"
            content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,about Us,Computer center,services,manthan"
          />
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("subhead_ins_rank")}
        />
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2024</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/file/d/17a-MccsQ9fQ41jv_poy8nGJAIpOqi9tz/view?usp=sharing"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("Submitted Report")} 2024
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2023</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2023/NIRF%20INDIA%20RANKINGS%202023%20(ENGINEERING).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2023 INDIA RANK (ENGINEERING)
            </p>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2023/NIRF%20INDIA%20RANKINGS%202023%20(INNOVATION).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2023 INDIA RANK (INNOVATION)
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2023/NIRF2023%20Submitted%20data.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("Submitted Report")} 2023
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2022</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2022/NIRF-2022%20INDIA%20RANK%20(ENGINEERING).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2022 INDIA RANK (ENGINEERING)
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2022/NIRF 2022 Committee.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("committee")} 2022
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2022/NIRF-2022 Submitted Data.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("Submitted Report")} 2022
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2021</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2021/NIRF-2021%20INDIA%20RANK(ENGG.).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2021 INDIA RANK (ENGINEERING)
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2021/NIRF-2021%20Submitted%20Data.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("Submitted Report")} 2021
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2020</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2020/NIRF-2020%20INDIA%20RANK%20(ENGINEERING).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2020 INDIA RANK (ENGINEERING)
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2020/Submitted%20Institute%20Data%20for%20NIRF'2020'_NIT%20Jamshedpur.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF {t("Submitted Report")} 2020
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2019</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2019/(1)%20NIRF_2019_All%20Report-MHRD,%20National%20Institutional%20Ranking%20Framework%20(NIRF).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2019 INDIA RANK (ENGINEERING)
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>NIRF 2018</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2018/All%20Report-MHRD,%20National%20Institutional%20Ranking%20Framework%20(NIRF).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; NIRF 2018 INDIA RANK (ENGINEERING)
            </p>
          </a>
        </SectionWrapper>
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content="NBA"
        />
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>{t("Program Accrediated")}</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/recents/National%20Institute%20of%20Technology,%20Jharkhand27_10_2022_11_27_32%20(2).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("Accreditation Status 2022")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/Accreditation%20status_NITJSR_NBA%20VISIT_09%20TO%2011%20AUGUST%202019-new.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("Accreditation Status 2019")}
            </p>
          </a>
        </SectionWrapper>
        <h3 style={{ fontFamily: "Source Sans Pro,sans-serif" }}>
          <u>{t("Notice")}</u>
        </h3>
        <SectionWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/NBA%2019%20team%20Visit%20Schedule.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_1")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/course%20prep-converted.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_2")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/directors%20meeting%20with%20faculty%20students%20and%20satff%20for%20accdn.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_3")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/New%20Microsoft%20Office%20Word%20Document%20(2)-converted.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_4")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/Points%20to%20be%20observed%20during%20NBA%20visit%202019-converted.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_5")}
            </p>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/NBA%20visit_Director's%20Presentation%20on%2009-08-2019%20at%209_20%20am%20in%20Senate%20room%20(%201st%20floor%20of%20old%20library).pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_6")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/First%20year%20faculty%20meeting%20with%20the%20Chairman,%20NBA%20_%20venue%20change%20notice.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_7")}
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nitjsr.ac.in/backend/uploads/old_rankings/NBA/NOTICE/Day%201%20NBA%20VISIT%20Schedule.pdf"
          >
            <p className="pdfLink">
              <Icon icon={cloudDownload} size={18} />
              &nbsp;&nbsp; {t("notice_8")}
            </p>
          </a>
        </SectionWrapper>
      </Container>
    </Fragment>
  );
}

export default RankingandRecognition;
