import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AcademicMenuData,
  CellMenuData,
  FacilitiesData,
  InstituteMenuData,
  NoticesMenuData,
  ResearchesMenuData,
  StudentMenuData,
  TenderMenuData,
  DownloadsMenuData,
  TAPMenuData,
  RncData,
} from "../../../assets/data";
import { BoysHostel, GirlsHostel } from "../../../assets/data/Hostels";
import "./Sidebar.style.css";
import { useTranslation } from "react-i18next";

function Sidebar({ TopLink, handleClick }) {
  const [Parent, setParent] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (TopLink === "Academics") {
      setParent(AcademicMenuData);
    } else if (TopLink === "Institute") {
      setParent(InstituteMenuData);
    } else if (TopLink === "Tenders") {
      setParent(TenderMenuData);
    } else if (TopLink === "Notices") {
      setParent(NoticesMenuData);
    } else if (TopLink === "Research") {
      setParent(ResearchesMenuData);
    } else if (TopLink === "Central Facilities") {
      setParent(FacilitiesData);
    } else if (TopLink === "Research & Consultancy") {
      setParent(RncData);    
    } else if (TopLink === "Boys Hostel") {
      setParent(BoysHostel);
    } else if (TopLink === "Girls Hostel") {
      setParent(GirlsHostel);
    } else if (TopLink === "Downloads") {
      setParent(DownloadsMenuData);
    } else if (TopLink === "Cells") setParent(CellMenuData);
    else if (TopLink === "Students") setParent(StudentMenuData);
    else if (TopLink === "TAP @ NITJSR") setParent(TAPMenuData);
  }, [Parent, TopLink]);
  return (
    <div className="outerContainer sidebarToggle">
      <h2 id="parentLink" style={{ color: "white" }}>
        {t(`${TopLink.split(" ").join("_")}`)}
      </h2>
      <div className="innerContainer">
        <ul className="childLink">
          {Parent.map((item, idx) => (
            <li
              key={idx}
              onClick={() => {
                handleClick(item.text);
                history.push(item.path);
              }}
            >
              <h5>{t(`${item.text.split(" ").join(" ")}`)}</h5>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
