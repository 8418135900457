import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import { Helmet } from 'react-helmet';
import { Card, Button } from 'react-bootstrap';
import president from '../../../assets/images/People/president.jpg';
import { useTranslation } from "react-i18next";

function Visitors() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Container width="1250px">
                <Helmet>
                    <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,visitors,guest" />
                </Helmet>
                <Card className='mt-5 mx-3'>
                    <Card.Img variant="center" src={president} style={{height: 350, display: 'block', margin: '30px auto 0'}} />
                    <Card.Title style={{display: 'block', margin: '0 auto'}}>
                    <Card.Body style={{display: 'block', margin: '0 auto'}}>
                        <center>
                            <div style={{margin: 0, padding: 0}}>{t("Smt. Droupadi Murmu")}</div>
                            <div>{t("Honorable President of India")}</div>
                        </center>
                    </Card.Body>
                    <Card.Body>
                        <a href='https://www.presidentofindia.nic.in/' target='_blank' rel="noreferrer"><Button variant="primary">{t("Official Website of the Visitor")}</Button></a>
                    </Card.Body>
                    </Card.Title>
                </Card>
            </Container>
        </Fragment>
    );
}


export default Visitors;
