import styled from 'styled-components';

export const PeopleWrapper=styled.div`
width: 100%;
  padding: 0;
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 20px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 1px 0 1px;
  }

  .outer_people_wrapper{
      display:flex;
      justify-content:space-between;
      align-items:center;
      flex-wrap:wrap;
  }
  .mycard{
      padding:10px;
      height:300px;
  }
  .card_img{
      margin:10px 0;
      display:flex;
      padding:10px 100px;
      justify-content:center;
  }
  .card_img img{
      width:130px;
      height:130px;
      border-radius:50%;
  }
  .card_text{
      display:flex;
      justify-content:center;
      align-items:center;
      margin-top:20px;
      background-color:#274187;
      color:#fff;
      border-radius:5px;
      height:35px;
      font-size:18px;
  }
  @media(max-width:991px){
    .mycard{
        margin:10px 5px;
        width:100%;
        padding:20px;
        background:#fff;
        box-shadow:1px 2px 10px lightgray;
        border-radius:4px;
        height:300px;
    }
  }

  .card {
    width: 18rem;
    margin: 1rem;
    border-radius: 30px;
    border: none;
    box-shadow: 1px 1px 10px lightgray;
    text-align: center;
  }
  .profilebtn {
    background-color: #fff;
    padding: 5px 10px;
    border: 1px solid #274187;
    border-radius: 10px;
    color: #274187;
  }
  .profilebtn:hover {
    background-color: #274187;
    color: #fff;
  }
  
  .cardbtn {
    background-color: #fff;
    border: none;
  }
  
  /* .tabs-details {
    padding: 10px;
    background: #f5f8fd;
    border-left: 1px solid #274187;
    border-right: 1px solid #274187;
    border-bottom: 1px solid #274187;
  } */
  .nav-tabs .nav-link {
    /* color: #fff; */
    color: #274187;
    background-color: #ededed;
    margin: 0 3px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-top: 5px solid #274187 !important;
  }
  .tab_body {
    box-shadow: 1px 2px 10px lightgray;
    min-height: 200px;
    margin: 0 3px;
    padding: 20px;
  }
  
  .faculty_mainwrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .faculty_mainwrapper .profile_pic img {
    margin: 10px;
    width: 270px;
    height: 270px;
    border-radius: 50%;
  }
  .profile_detail {
    font-family: 'Poppins, sans-serif';
    padding: 20px 40px;
    font-size: 16px;
  }
  .profile_detail p {
    color: #000;
  }
  #desig,
  #dept {
    font-size: 20px;
  }
  .single-detail {
    font-family: 'Poppins, sans-serif';
    font-size: 16px;
  }
  
  @media only screen and (max-width: 991px) {
    .faculty_mainwrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #desig,
    #dept {
      text-align: center;
      font-size: 20px;
    }
    .profile_detail {
      padding: 20px;
      font-size: 15px;
    }
    .nav-tabs .nav-link {
      width: 100%;
      border-bottom: 2px solid lightgray;
      text-align: center;
    }
  }
  
`
