import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../Components/UI/Container';
import { LongPara } from '../styles';
import Heading from '../../../Components/Heading';
import { Helmet } from 'react-helmet';


const GuestHouse = ({ fontsize }) => {
	const { t } = useTranslation();
	return (
		<>
		<Helmet>
			<meta name="keywords" content="NIT Jamshedpur, Facilities, Guest House" />
			</Helmet>
		<Container width="1250px">
			<br />
			<LongPara>
				{t("central_facilities_guesthouse_body")}
			</LongPara>
			<LongPara>
				<Heading
					content={t("central_facilities_contacts_head")}
					style={{
						fontSize: '1.5em',
						marginBottom: '20px',
						marginTop: '13px',
						fontWeight: '500',
						background: '#274187',
						color: '#fff',
						padding: '1% 1% 1% 3%',
					}}
				/>
				<br />
				<p style={{ fontSize: fontsize }} className="paragraph">
            		<b>{t("Dr. Vineet Sahoo")}</b>
          		</p>
          		<p style={{ fontSize: fontsize }} className="paragraph">
            		<b>{t("Faculty In-Charge(Guest House)")}</b>
          		</p>
          		<p style={{ fontSize: fontsize }} className="paragraph">
            		{t("Email-ID")} : fic.guesthouse@nitjsr.ac.in
          		</p>
          		<p style={{ fontSize: fontsize }} className="paragraph">
            	{t("Mobile No.")}: +91-657-237-4156
         		 </p>
				
			</LongPara>
		</Container>
		</>
	);
};

export default GuestHouse;
