import React from "react";
import Container from "../../UI/Container";
import NavbarWrapper, { TextArea } from "./navbar.style";
import { Link } from "react-router-dom";
import Heading from "../../../Components/Heading";

import logoImage from "../../../assets/images/logo_new1.png";

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar" style={{ padding: "8px" }}>
      <Container fullWidth={true}>
        <Link to="/">
          <img
            style={{ maxHeight: "100px", marginLeft: "-15px" }}
            alt="logo"
            src={logoImage}
          />
        </Link>
        <TextArea className="d-none d-lg-block" style={{ textAlign: "center" }}>
          <Heading content="राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर"  fontSize="2.1rem" color="#274187" fontWeight="5" mt="0" mb="1rem" letterSpacing="0.5"/>
          <Heading content="National Institute of Technology Jamshedpur" as="h1" fontSize="2.1rem" color="#274187" fontWeight="5" mt="2" mb="0.5rem" letterSpacing="0.5"/>
        </TextArea>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
