
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get'

export const EventWrapper = styled.div`
  width: 100%;
  padding: 1px;
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: #274187 !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .singleCard {
    margin: 3%;
    padding: 10px;
    // border: 1px solid lightgrey;
    box-shadow: 1px 1px 7px lightgray;
    height: 470px;
    border-radius: 5px;
  }
  .achvCard {
    margin: 3%;
    padding: 10px;
    // border: 1px solid lightgrey;
    box-shadow: 1px 1px 7px lightgray;
    height: 380px;
    border-radius: 5px;
  }
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 50px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 30px 0 30px;
  }
  @media only screen and (max-width: 991px) {
    padding: 30px 0 25px;
    .singleCard {
      margin: 3%;
      padding: 8px;
      border: 1px solid lightgrey;
      max-height: 520px;
      border-radius: 5px;
    }
  }
  .thumbnailachImg {
    width: 100%;
    padding: 2%;
    height: 50px;
  }
  .thumbnailImg {
    width: 100%;
    padding: 2%;
    height: 300px;
    object-fit: fill;
  }
  .title {

    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  .desc {
    // font-family: 'Poppins', sans-serif;
    font-family: 'Poppins', sans-serif;

    padding: 3% 5%;
    font-size: 16px;
  }
  .card-btn {
    padding: 20px;
  }
  .link {
    color: ${themeGet("colors.secondary", "#D50032")};
    text-decoration: underline;

    font-family: 'Poppins', sans-serif;

  }
`;

