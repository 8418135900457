import React from 'react';
import { useTranslation } from 'react-i18next' ;
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { ParagraphWrapper} from '../../../assets/css/Custom/StudentStyle';
function FinancialAssistance() {
	const { t }= useTranslation();

	return (
		<Container width="1250px">
			<br />
			<ParagraphWrapper>
				<div className="panel-body" id="scontent">
					<div id="abt-us">
						<Heading
							content={t("wca_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
							<b>{t("wca_1")}</b>
						</p>
						<ol>
							<li>{t("wca_2")}</li>
							<li>
							{t("wca_3")}
							</li>
							<li>
							{t("wca_4")}<br />
								<ul>
									<li>{t("wca_5")}</li>
									<li>{t("wca_6")}</li>
								</ul>
							</li>
						</ol>
						<p />
						<p>
							<b>Financial Support : </b>{t("fss")}{' '}
						</p>
						
						<a rel="noreferrer" href="http://ekalyan.jharkhand.gov.in/" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<br/><br/>
                        <Heading
							content={t("fa_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						
						<p>
						{t("fa_1")}
						</p>
						<p>
							<b>{t("fa_2")} </b>50
						</p>
						<p>
							<b>{t("fa_3")} </b>
						</p>
						<ol>
							<li>
							{t("fa_4")}
							</li>
							<li>{t("fa_5")} </li>
							<li>{t("fa_6")} </li>
						</ol>
						<p />
						<p>
							<b>Financial Support : </b>{t("fa_7")}{' '}
						</p>
						<p>
							<b>How to apply: </b>
						</p>
						<ol>
							<li>{t("fa_8")} </li>
							<li>{t("fa_9")}</li>
						</ol>

						<a rel="noreferrer" href="http://www.faeaindia.org/" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<p /><br/>
                        <Heading
							content={t("sssfjk-1")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
							{t("sssfjk-2")}
						</p>
						<p>
							
						</p>
						<a rel="noreferrer" href="http://www.aicte-jk-scholarship.in/" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<br /><br/>
						<Heading
							content={t("nhfd_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						{/* <h2 className="page-header" style={{ color: '#444444', fontFamily: 'Calibri' }}>
							National Handicapped Finance and Development Corporation
						</h2> */}
						
						<ul>
							<li>
							{t("nhfd_1")}
							</li>
							<li>
							{t("nhfd_2")}.{' '}
							</li>
						</ul>
							<br/>
						<a rel="noreferrer" href="http://www.nhfdc.nic.in/" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<br />
                        <Heading
							content={t("ntpc_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						
						<p />
						<p>
							<b>Number of Scholarships : </b>35(20 SC, 10 ST and 05 PC)
						</p>{' '}
						<p>
							<b>Eligibility Criteria : </b>
						</p>
						<ul>
							<li>
							{t("ntpc_1")}
							</li>
							<li>{t("ntpc_2")}</li>
						</ul>
						<p>
							<b>Financial Support : </b>{t("ntpc_3")}{' '}
						</p>
						
						<a
							rel="noreferrer"
							href="http://www.ntpc.co.in/index.php?option=com_content&amp;view=article&amp;id=11&amp;Itemid=91&amp;lang=en "
							target="_blank"
							className="learn__more-btn"
						>
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<br /><br/>
                        <Heading
							content={t("ioes_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						
						<p>
							{t("ioes_1")}
						</p>
						
						<a rel="noreferrer" href="http://ioclscholar.sifyitest.com" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<br />
                        <Heading
							content={t("cdif_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
						{t("cdif_1")}
						</p>
						
						<a rel="noreferrer" href="http://cumminsindia.com/sustainability.html" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<br />
                        <Heading
							content={t("mcms_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
						{t("mcms_1")}
						</p>
						
						<a rel="noreferrer" href="https://scholarships.gov.in/main.do" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<br />
                        <Heading
							content={t("opje_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
						{t("opje_1")}
						</p>
						<ul>
							<li>{t("opje_2")}</li>
							<li>{t("opje_3")}</li>
							<li>{t("opje_4")}</li>
							<li>{t("opje_5")}</li>
						</ul>
						<p />
						
						<a href="http://www.opjems.com" className="learn__more-btn"><span className="hyphen"></span>
							<span className="btn_text">View</span></a>
						<p />
						<br />
                        <Heading
							content={t("pmss_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>
						{t("pmss_1")}
						</p>
						
						<a rel="noreferrer" href="https://scholarships.gov.in/main.do" target="_blank" className="learn__more-btn">
							<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<br />
                        <Heading
							content={t("ongc_0")}
							style={{
								fontSize: '1.5em',
								marginBottom: '10px',
								marginTop: '13px',
								fontWeight: '500',
								background: '#274187',
								color: '#fff',
								padding: '1% 1% 1% 3%'
							}}
						/>
						<p>{t("ongc_1")}</p>
						<p>
							<b>{t("ongc_2")} </b>40
						</p>
						<p>
							<b>Financial Support : </b>Years :<br />
							I - 12,000/-<br />
							II - 12,000/- <br />
							III - 18,000/- <br />
							IV - 18,000/-<br />
						</p>
						<p>
							<b>{t("ongc_3")} </b>
						</p>
						<ol>
							<li>
							{t("ongc_4")}{' '}
							</li>
							<li>
							{t("ongc_5")}
							</li>
						</ol>
						<p>
							<b>{t("ongc_6")} </b>
							{t("ongc_7")}
						</p>
						
						<a rel="noreferrer" href="https://scholarships.gov.in/main.do" target="_blank" className="learn__more-btn">
						<span className="hyphen"></span>
							<span className="btn_text">View</span>
						</a>
						<p />
						<p />
					</div>
				</div>
			</ParagraphWrapper>
		</Container>
	);
}

export default FinancialAssistance;
