import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";
import { Page } from "../../assets/css/Custom/RecruitementStyles";
import { MdArrowDropDown } from "react-icons/md";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
import { getRequest } from "../../functions/request";
import { formatDateString } from "../../functions/utils";
import EventCard from "../../Components/EventCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/material";
import { flex, flexWrap } from "styled-system";
import {EventWrapper} from "./ArchiveEvents.style.js"
import {Box,Stack,Typography,Pagination} from '@mui/material'

const ArchiveEvents = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const fetching = async () => {
    try {
      let res = await getRequest(`/events/archive-events`);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetching();
  }, []);

  data.reverse();

  const eventsPerPage=9
  const pageCount=Math.ceil(data.length/eventsPerPage);
  let currPageEvents=data.slice(0,eventsPerPage)

  const [page,setPage]=useState(1)

  const StartEventNo=(page-1)*eventsPerPage;
  const endEventNo=page*eventsPerPage
  currPageEvents=data.slice(StartEventNo,endEventNo)
  const paginate=(e,value)=>{
    
    setPage(value); 
    window.scrollTo(0, 0);
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("Recruitments")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta name="description" content="Archive events, NIT Jamshedpur" />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,Archive Events nit jsr,Archive Events,Archive events,notices"
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      
        
        <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
            <DrawerSection black="black" white="white" />
        </DrawerProvider>
          <ContentWrapper>
            <Banner heading={t("Archive News and Events")} />
            <Container className="mt-3">
              <div className="event-card-container" style={{ display: "flex", flexWrap: "wrap",justifyContent:"space-around" }}>
              
                {currPageEvents.map((item, key) => (
                  <div  style={{width:"350px"}}>
                      <EventWrapper>
                          <div key={`event_card_${key}`} className="event-card" >
                              <EventCard item={item} fontsize="20px" />
                          </div>
                      </EventWrapper>
                  </div>
                ))}
              </div>
            </Container>

            {
              data.length>eventsPerPage && (  <Stack mt={5} alignItems="center" >
              <Pagination count={pageCount} page={page} color="primary" defaultPage={1} onChange={paginate} size="large"/>
              </Stack>)
            }
          </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
};

export default ArchiveEvents;
