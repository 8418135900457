import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import "antd/dist/antd.css";

import "./index.css";
import "./i18n";
import App from "./App";
import Loader from "./Components/Preloader/Loader";
import reportWebVitals from "./reportWebVitals";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>,
    rootElement
  );
} else {
  render(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>,
    rootElement
  );
}


// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     var newSW = registration.installing;
//     newSW.onstatechange = function() {
//       if (newSW.state === 'waiting') {
//         newSW.postMessage('skipWaiting');
//         setTimeout(() => {
//           console.log("New content is available; please refresh.");
//           window.location.reload();
//         }, 1000);
//       }
//     };
//   },
//   onSuccess: (registration) => {
//     console.log("Service worker registered!", registration);
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
