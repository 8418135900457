//profile img
import komalAggarwalImg from "../../../assets/images/achievements/komalAggarwal.png"
import anantBhartiImg from "../../../assets/images/achievements/anantBharti.png"
import amanJhaImg from "../../../assets/images/achievements/amanJha-removebg-preview.png"
import cezainImg from "../../../assets/images/achievements/cezainn.png"
import amanKumarPandeyImg from "../../../assets/images/achievements/amanKumarPandey.png"
import harshdeepMauryaImg from "../../../assets/images/achievements/harshdeepMaurya.png"

import shrutiVermaImg from "../../../assets/images/achievements/shrutiVerma.png"
import subhaRawatImg from "../../../assets/images/achievements/subhaRawat.png"
import namitaChoudharyImg from "../../../assets/images/achievements/namitaChoudhary.png"
import pragyaSinghImg from "../../../assets/images/achievements/pragyaSingh.png"
import tariqueAhmadImg from "../../../assets/images/achievements/tariqueAhmad.png"
import ashishImg from "../../../assets/images/achievements/ashish.png"
import bhavyaShukya from "../../../assets/images/achievements/bhavyaShukya.png"

import priyaSingh from "../../../assets/images/achievements/priyaSingh.png"

// logos
// import invalid_  ~image from "../../../assets/images/invalid_image.png";
import adobeLogo from "../../../assets/images/companylogo/adobe.png";
import atlassianLogo from "../../../assets/images/companylogo/Atlassian_logo_PNG1.png";
import lowesLogo from "../../../assets/images/companylogo/Lowes_logo_PNG1.png";
import oracleLogo from "../../../assets/images/companylogo/Oracle_logo_PNG1.png";
import texasInstrumentsLogo from "../../../assets/images/companylogo/Texas_Instruments_logo_PNG1.png";
import celigiLogo from "../../../assets/images/companylogo/celigo.png";
import gameskraftLogo from "../../../assets/images/companylogo/gameskraft.png";
import tekionLogo from "../../../assets/images/companylogo/tekionlogo.png";
import goldmanSachsLogo from "../../../assets/images/companylogo/goldmanSachsLogo.png";
import cashfreeLogo from "../../../assets/images/companylogo/cashfreeLogo.png";

export const data = [
    // {
    //   title: "Aditi Soni",
    //   desc: { invalid_image },
    //   imageLink: invalid_image,
    //   fontsize: "2rem",
    //   link: "#",
    //   brochure: "#",
    // },
    {
      title: "Namita Choudhary",
      desc: atlassianLogo,
      imageLink: namitaChoudharyImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Shubha Rawat",
      desc: adobeLogo,
      imageLink: subhaRawatImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    // {
    //   title: "Ankit Raj",
    //   desc: { invalid_image },
    //   imageLink: invalid_image,
    //   fontsize: "2rem",
    //   link: "#",
    //   brochure: "#",
    // },
    {
      title: "Tarique Ahmad",
      desc: tekionLogo,
      imageLink: tariqueAhmadImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
        title: "Priya Singh",
        desc: lowesLogo,
        imageLink: priyaSingh,
        fontsize: "2rem",
        link: "#",
        brochure: "#",
      },
      {
        title: "Aman Kr Pandey",
        desc: oracleLogo,
        imageLink: amanKumarPandeyImg,
        fontsize: "2rem",
        link: "#",
        brochure: "#",
      },
    {
      title: "Harshdeep Mourya",
      desc: celigiLogo,
      imageLink: harshdeepMauryaImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Shruti Verma",
      desc: lowesLogo,
      imageLink: shrutiVermaImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Ashish",
      desc: gameskraftLogo,
      imageLink: ashishImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Pragya Singh",
      desc: tekionLogo,
      imageLink: pragyaSinghImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Anant Bharti",
      desc: goldmanSachsLogo,
      imageLink: anantBhartiImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Aman Jha",
      desc: tekionLogo,
      imageLink: amanJhaImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    
    {
      title: "Bhavya Shukla",
      desc: texasInstrumentsLogo,
      imageLink: bhavyaShukya,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
      title: "Cezain Majeed",
      desc: cashfreeLogo,
      imageLink: cezainImg,
      fontsize: "2rem",
      link: "#",
      brochure: "#",
    },
    {
        title: "Komal Agrawal",
        desc: adobeLogo,
        imageLink: komalAggarwalImg,
        fontsize: "2rem",
        link: "#",
        brochure: "#",
      },
  ];