import React, { useState, useEffect, useCallback } from "react";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import { getRequest } from "../../../functions/request";
import Container from "../../../Components/UI/Container";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Navbar from "../../../Components/Layout/PagesNavbar";
import Sticky from "react-stickynode";
import { BACKEND_URL } from "../../../assets/Constants/"

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import Heading from "../../../Components/Heading";
import {
  ImageContainer,
  ImageCard,
  Images,
  GalleryContainer,
} from "./Gallery.styled";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "../../../../node_modules/aos/dist/aos.css";
import logo from "../../../assets/images/invalid_image.png";

const GalleryCards = () => {
  const [tags, setTags] = useState("");
  const [images, setImages] = useState([]);
  let url = useLocation().search;

  const getImages = useCallback(async () => {
    if (tags !== "") {
      let res = await getRequest(`gallery/get-by-tag?tags=${tags}`);
      let data = await res.data;
      setImages(data);
    }
  }, [tags]);

  useEffect(() => {
    const queryParams = new URLSearchParams(url);
    for (const p of queryParams) {
      if (p[1]) {
        setTags(p[1]);
      }
    }
    getImages();
  }, [tags, getImages, url]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      delay: 500,
    });
    AOS.refresh();
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <SimpleReactLightbox>
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <GalleryContainer>
            <ContentWrapper className="mh-100%">
              <Heading
                as="h2"
                content="Gallery"
                style={{ textAlign: "center" }}
              />
              <Container>
                <SRLWrapper>
                  <ImageContainer widthSize="22rem" data-aos="zoom-in">
                    {images.map((image) => {
                      return (
                        <ImageCard key={image.id}>
                          <Images
                            src={`${BACKEND_URL}/${image.path}`}
                            alt="An image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = logo;
                            }}
                          />
                        </ImageCard>
                      );
                    })}
                  </ImageContainer>
                </SRLWrapper>
              </Container>
            </ContentWrapper>
          </GalleryContainer>
        </CharityWrapper>
      </SimpleReactLightbox>
    </ThemeProvider>
  );
};

export default GalleryCards;
