//Recruitments Setion
import { useEffect, useState } from "react";
import Sticky from "react-stickynode";
// import Heading from "../../Components/Heading";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
//import Link from "../../Components/CustomLink/CustomLink";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Container from "../../../Components/UI/Container";
import Footer from "../../../Components/Layout/Footer";
//import { TableWrapper } from "../../assets/css/Custom/AcademicStyle";

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { scrollToContent } from "../../../functions/scroll.js";
//import { fontFamily } from "@mui/system";
import image1 from "../../../assets/images/aicte-banner.jpg";
import { getRequest } from "../../../functions/request.js";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle.js";
import { Pagination, Stack } from "@mui/material";

function Iks() {
  const [iksList, setIkslist] = useState([]);
  const fontsize = 14;

  const listPerPage = 8;
  const pageCount = Math.ceil(iksList.length / listPerPage);
  let currPageList = iksList.slice(0, listPerPage);

  const [page, setPage] = useState(1);

  const StartEventNo = (page - 1) * listPerPage;
  const endEventNo = page * listPerPage;
  currPageList = iksList.slice(StartEventNo, endEventNo);
  const paginate = (e, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRequest("iks");
        if (res.status === 200) {
          const data = await res.data;
          setIkslist(data.data);
        } else {
          console.log("Response or data is undefined:", res);
        }
        scrollToContent(0);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchData();
  }, []);

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("Iks_head")} | {t("NIT Jamshedpur")} | à¤°à¤¾à¤·à¥ à¤Ÿà¥ à¤°à¥€à¤¯
          à¤ªà¥ à¤°à¥Œà¤¦à¥ à¤¯à¥‹à¤—à¤¿à¤•à¥€ à¤¸à¤‚à¤¸à¥ à¤¥à¤¾à¤¨
          à¤œà¤®à¤¶à¥‡à¤¦à¤ªà¥ à¤°
        </title>
        <meta
          name="description"
          content="Right to Information: Filing of Application RTI Application Form: A person seeking information may file an application on form either in writing or by e-mail giving particulars of the information being sought...."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,goals, map,reach, cc,guest,objectives,technology,Director,,stafflist,facility,rti, right to informations,output Indicators,Indicators..."
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Container
            className="mt-5 mb-5 py-5"
            style={{ textAlign: "justify", fontFamily: "Arial" }}
          >
            <div class="curruption-banner">
              <img src={image1} alt="Indian Knowledge Systems" />
            </div>
            <h1 className="text-center">
              <strong>{t("Iks_head")}</strong>
            </h1>
            <br />
            <h2
              style={{ textAlign: "center", fontFamily: "Arial", color: "red" }}
            >
              Namaste and Swagatam!
            </h2>
            <p
              style={{
                textAlign: "justify",
                fontFamily: "Arial",
                fontSize: "1.5em",
              }}
              className="paragraph"
            >
              राष्ट्रीय प्रौद्यिगिकी संस्थान जमशेदपुर में नई राष्ट्रीय शिक्षा
              नीति के उद्देश्यो की पूर्ति हेतु भारतीय ज्ञान परंपरा केंद्र की
              स्थापना दिनांक ३० जून २०२३ को हुआ। भारतीय ज्ञान परंपरा केंद्र का
              मुख्य उद्देश्य प्राचीन काल से चली आ रही ज्ञान परंपरा, मानव जीवन
              में प्रयोग होने वाले परंपरागत पद्धति को नवीन विज्ञान एवं
              प्रौद्यिगिकी के साथ समन्वय स्थापित कर उसे वर्तमान समय मे तकनीक का
              विकास है। इस केंद्र की स्थापना का कार्यक्षेत्र मुख्यतः परंपरागत
              विज्ञान एवं प्रौद्यिगिकी का विकास, वैदिक गणित वर्ष खगोलिय विज्ञान
              आधारित नये तकनीक का विकास एवं शास्त्रीय संगीत एवं प्राचीन भारतीय
              कला का डिजिटलीकरण एवं संवर्धन है। इस केंद्र के अंतर्गत आयुर्वेद,
              योग एवं प्राकृतिक चिकित्सा विज्ञान से संबंधित पुरातन परम्पराओ पर
              आधारित नए तकनीक के प्रयोग को सर्वसुलभ बनाना है। इस केंद्र के
              प्रमुख उद्देश्य में गो आधारित कृषि एवं उसके सतत् विकास के
              कार्यक्षेत्र के साथ इससे किसानों को जागरूक करते हुए आम आदमी से
              जोड़ना भी है। यह केंद्र आदिवासीयों के उन्नत एवं पारंपरिक कला,
              संस्कृति के संरक्षण के साथ-साथ दुनिया के अन्य हिस्सो में प्रचार
              एवं आत्मसाथ करने वाले तकनीक का विकास भी है। परंपरागत धातु र्कम,
              एवं धातु विज्ञान के प्राचीन पद्धति पर शोध के साथ- साथ पूजा पद्धति
              विशेषकर मंत्रों के उच्चारण से उत्त्पन होने वाली ध्वनि के प्रभाव
              एवं यज्ञ से संबंधित विज्ञान पर शोध है। इस केंद्र के विकसित होने से
              नई पीढीयाँ आधुनिक विज्ञान के साथ-साथ भारतीय ज्ञान परंपरा मे
              पदार्थ, मन और चेतना की उत्पत्ति और विकास, प्राचीन एवं आधुनिक
              प्रौद्योगिक उसके दार्शनिक आधार से रु ब रु सबक होगें। इसपर होने
              वाले शोध हमारे पुरानी व्यवस्था एवं परंपराओं को वैज्ञानिक आधार
              प्रदान करेगा. यह केंद्र तकनीकी संस्थानों को अपने पूर्वजों के
              कृतित्व पर शोध के साथ-साथ आने वाली पीढ़ी में राष्ट्र जीवन के प्रति
              श्रेष्ठ पुरुषार्थ की प्रेरणा एवं भारत को परम वैभव की पुन:
              प्रतिष्ठापित करने में सहयोग करेगा।
            </p>

            <a
              href="https://www.mygov.in/campaigns/iks/"
              target="_blank"
              rel="noopener noreferrer"
            >
              know more..
            </a>
            <TableWrapper>
              <table className="table">
                <thead>
                  <tr id="headRow">
                    <th style={{ padding: "10px 10px", minWidth: "70px" }}>
                      {t("Sl. No.")}
                    </th>
                    <th>{t("Title")}</th>
                    <th>{t("View")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currPageList.map((item, id) => (
                    <tr key={`list_key${id}`}>
                      <td
                        style={{
                          fontSize: fontsize,
                          marginLeft: "40px",
                        }}
                        data-column="Sl.No."
                      >
                        {id + 1}
                      </td>
                      <td style={{ fontSize: fontsize }} data-column="title">
                        {item.title}
                      </td>
                      <td
                        style={{
                          fontSize: fontsize,
                        }}
                        data-column="View"
                      >
                        <a
                          href={
                            process.env.REACT_APP_BACKEND_URL + "/" + item.path
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            className="dwn-btn"
                            style={{ marginRight: "40px" }}
                          >
                            {t("View")}
                          </button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrapper>
            {iksList.length > listPerPage && (
              <Stack mt={5} alignItems="center">
                <Pagination
                  count={pageCount}
                  page={page}
                  color="primary"
                  defaultPage={1}
                  onChange={paginate}
                  size="large"
                />
              </Stack>
            )}
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Iks;
