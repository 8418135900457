import React from "react";

export default function AreaOfInterest() {
    return (
        <div className="panel-body body1">
            Message From the Director
            <p>Greetings from the Director! <br /> </p>
            <p style={{textAlign: "justify"}}>
            It is a privilege and honour for me to have led the NIT Jamshedpur as a Director of the Institution. 
            NIT Jamshedpur has a rich legacy of more than sixty dedicated years of service to the nation and its pride of Jharkhand. 
            NIT Jamshedpur takes extreme pride in the fact that it has been occupying a unique position in imparting technological education to Indian youth. 
            Since its inception, the institute has had a vision to provide quality technical education and to facilitate scientific and technological research, coupled with a mission to develop human potential to its zenith with excellence in teaching and high quality research.
            In consonance with its vision, the institute has been contributing substantially to our country's technological power. NIT Jamshedpur emphasizes the need to promote entrepreneurship by introducing entrepreneurial spirit and start up as a part of the curriculum. 
            It is fast emerging as a global knowledge centre for quality research and teaching through cross disciplinary, innovative and dynamic approaches. It is indeed a pleasure in mentioning that institute is leading active research collaborations and have established vital links with many repreted institution industry, business and government agencies. 
            The Institute is aiming to develop 21th century skills and competencies supported by different learning frameworks and Industry supported by a one semester internship program for undergraduate and postgraduate students.  As a result year after year, with its exemplary placement record, NIT Jamshedpur has reinforced its brand image as a leading technical institution.
            I on the behalf of all stakeholders, would like to call everyone to work on converged ideas to achieve the most deserved position at national and international levels. Institutes will have significant contributions in different flagship programs of our country like make in India, Start up India, Standup India . </p>

            {/* <p></p>
            <p style={{textAlign: "justify"}}>
                In consonance with its vision, the institute has been contributing substantially to our country's
                technological power.
                It is fast emerging as a global knowledge centre for quality research and teaching through cross
                disciplinary,
                innovative and dynamic approaches. The curriculum is regularly updated with greater emphasis
                on hands on experience and also enabling students to keep abreast with the latest in scientific and
                technological spheres.
                It is indeed a pleasure in mentioning that we are leading active research collaborations
                and have established vital links with industry, business and government agencies. Sixty seven highly
                qualified faculty members have joined the institute recently and the institute is looking forward to hiring
                more in the near future.<br />

            </p>
            <p style={{textAlign: "justify"}}>
                Year after year, with its exemplary placement record, NIT Jamshedpur has reinforced its
                brand image as a leading technical institution. The students of the institute have been selected by most of
                the prominent Indian and multinational companies and industrial and research organizations. Exposure
                provided at this institute inculcates in them not only technological acumen, but also an overall cognizance
                of the world. Our distinguished Alumni, several of them holding high offices in a variety of spectrum, both
                in India and abroad, have been a perennial source of sustenance to us. We march on as we look forward to
                continue our
                endeavour by chiselling worthy and humane engineers for a better tomorrow.
            </p> */}
            With best regards

        </div>
    )
}
