import React from 'react';
import { useTranslation } from 'react-i18next' ;
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { ParagraphWrapper } from '../../../assets/css/Custom/StudentStyle';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
function StudentLife({ fontsize }) {

  const { t }= useTranslation();
  return (
    <Container width="1250px">
      <Helmet>
        <meta name="description" content="Though the college is residential in nature, it gives its staff and students the facility of a bus service. The bus runs thrice a day from the college bus stand to Gopal Maidan at Bistupur and vice-versa. It has three other stoppages at NIT Main gate, Road No. Four of Adityapur colony, All India Radio (Adityapur) respectively."/>
        <meta name="author" content="Webteam,NIT Jamshedpur"/>
        <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,bus,student,amenities,Computer,Centre,Hostels,ragging,student life,campus life,life of student,student's culture"/>
      </Helmet>
      <br />
      <Heading
        content={t("sl_1")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <ParagraphWrapper>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-21")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-22")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-23")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-6")}
        </p>
      </ParagraphWrapper>
      <Heading
        content={t("sl-3")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <ParagraphWrapper>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-4")}
        </p>
      </ParagraphWrapper>
      <Heading
        content={t("sl-5")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <ul>
        <li style={{ margin: '10px' }}>
          <b>Commercial: </b>NIT-Store-Bank-PO-ATM(multiple)
        </li>
        <li style={{ margin: '10px' }}>
          <b>Social / Entertainment: </b> Games & Sports:Gymkhana-Tennis
          Courts-Basketball Court; Vishwakarma Sabha Griha-Clubs-Eateries
        </li>
        <li style={{ margin: '10px' }}>
          <b>Infrastructure: </b>24 hr Power-Water-Network-Well maintained Roads
        </li>
      </ul>
      <Link href="https://en.wikipedia.org/wiki/National_Institute_of_Technology,_Jamshedpur">
          <p style={{ fontSize: fontsize }} className="linktext">
            <b>Visit our wiki page for more</b>
          </p>
      </Link>
      <Heading
        content={t("sl-7")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <ParagraphWrapper>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-8")}
        </p>
      </ParagraphWrapper>
    </Container>
  );
}

export default StudentLife;
