import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";

import { Helmet } from "react-helmet";

function HealthAndWelfare() {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />

      <Helmet>
        <meta
          name="description"
          content="NIT Jamshedpur The Cradle of technical excellence "
        />
        <meta
          name="keywords"
          content="nit jamshedpur,health and welfare,health facilities,health,health care,helth care facilites"
        />
      </Helmet>
      <Heading
        content={t("HaW_0")}
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">
        <b>{t("HaW_1")}</b>
      </p>
      <Heading
        content={t("HaW_2")}
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_3")}</p>
      <p>{t("HaW_4")}</p>
      <ul className="ul-list">
        <li>{t("HaW_5")}</li>
        <li>{t("HaW_5.1")}</li>
        <li>{t("HaW_6")}</li>
        <li>{t("HaW_7")}</li>
        <li>{t("HaW_8")}</li>
      </ul>
      <Heading
        content={t("HaW_9")}
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_10")}</p>
      <ul className="ul-list">
        <li>{t("HaW_17")}</li>
        <li>{t("HaW_19")}</li>
        <li>{t("HaW_20")}</li>
        <li>{t("HaW_21")}</li>
      </ul>
      <p className="long-para">{t("HaW_11")}</p>
      <Heading
        content="Pathological Facility"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">
        <b>Aarogyam Pathology Laboratory</b>
        {t("HaW_12")}
      </p>
      <Heading
        content="Medical Facilities to Students"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_13")}</p>
      <Heading
        content="Referral Cases"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_14")}</p>
      <Heading
        content="Visiting Specialised Doctors"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_15")}</p>
      <Heading
        content="Ambulance Facility"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">{t("HaW_16")}</p>
      <p className="long-para">
        <b>Contact:</b>
      </p>
      <p className="long-para">
        <b>Phone:</b> +91-657-237-4226
      </p>
    </Container>
  );
}

export default HealthAndWelfare;
