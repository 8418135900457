import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
// import { useTranslation } from 'react-i18next';
import {
  ParagraphWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import { Helmet } from 'react-helmet';

function BtechAdmission() {
  // const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="The admission to the under graduate programmes is made on the performance in the class 12/equivalent qualifying examination and in the JEE (Main). The Joint Seat Allocation Authority (JoSAA) / Central Seat Allocation Board (CSAB) allocates the seats for Under Graduate Programmes through Common Counseling process.For general guidelines of B.Tech Admissions and documents required" />
          <meta name="keywords" content="admission, btech,nit jamshedpur,nit jsr,nit,jamshedpur,goals,student wellness center, map,reach, cc,guest,vision, mission,objectives,technology,Director,schorlarships,stafflist,facility,rti, right to informations,output Indicators,Indicators..." />
        </Helmet>
        <br />
        
        <Heading
          content="B.Tech"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paargraph">
          The admission to the under graduate programmes is made on the performance in the class 12/equivalent qualifying examination and in the JEE (Main). The Joint Seat Allocation Authority (JoSAA) / Central Seat Allocation Board (CSAB) allocates the seats for Under Graduate Programmes through Common Counseling process.
          </p>      
        </ParagraphWrapper>

        <Heading
          content="B.TECH PROGRAMMES AT NIT JSR"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <div>
        <ol>
          <li>● Civil Engineering (CE) - [Intake-99]</li>
          <li>● Computer Science and Engineering (CSE) - [Intake-116]</li>
          <li>● Engineering & Computational Mechanics (ECM) - [Intake-30]</li>
          <li>● Electronics and Communication Engineering (ECE) - [Intake-116]</li>
          <li>● Electrical Engineering (EE) - [Intake-114]</li>
          <li>● Mechanical Engineering (ME) - [Intake-115]</li>
          <li>● Metallurgical and Materials Engineering (MME) - [Intake-99]</li>
          <li>● Production and Industrial Engineering (PIE) - [Intake-62]</li>
        </ol>
        </div>
      </Container>
    </Fragment>
  );
}

export default BtechAdmission;
