import "./media.css";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../functions/request";
import { Link } from "@mui/material";
import { Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../Home/ResearchSection/ResearchSection.style";
import Heading from "../../../Components/Heading";
import {
  NoticeWrapper,
  NoticePointWrapper,
  NoticeTitleWrapper,
} from "./notices.style";
import { FaHandPointRight } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import { List } from "antd";
import "./scrollBar.css";

function Notices() {
  const { t } = useTranslation();
  const [notices, setNotices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getNotices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/alumni_publication/`
      );
      const dataRe = await response.json();
      return dataRe.data.reverse();
    } catch (error) {
      console.error("Error fetching notices:", error);
      return [];
    }
  };

  useEffect(() => {
    getNotices()
      .then((data) => {
        console.log("getNotices Called...");
        setNotices(data);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error while fetching notices:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="wrapper">
      <Card>
        <br />
        <SectionHeader>
          <Heading content={t("Recent_Activity")} v />
        </SectionHeader>
        <NoticeWrapper>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10rem",
              }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            notices.map((item, index) => (
              <NoticePointWrapper>
                <div class="container row">
                  <div class="col-2 col-md-1" style={{ textAlign: "center" }}>
                    <FaHandPointRight />
                  </div>
                  <div className="col-8 col-md-9 px-4">
                    <NoticeTitleWrapper>{item.title}</NoticeTitleWrapper>
                  </div>
                  <div
                    className="col-2 col-md-2"
                    style={{ textAlign: "center" }}
                  >
                    <FaFilePdf />
                    <Link
                      href={process.env.REACT_APP_BACKEND_URL + "/" + item.link}
                    >
                      {" "}
                      PDF
                    </Link>
                  </div>
                </div>
              </NoticePointWrapper>
            ))
          )}
          {notices.length === 0 && (
            <div>
              <div className="singleCard text-center">
                <p style={{ fontSize: 24 }} className="desc">
                  No recent notices !
                </p>
              </div>
            </div>
          )}
        </NoticeWrapper>
      </Card>
      <br />
      <br />
    </div>
  );
}

export default Notices;
