import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import invalid_image from "../../assets/images/invalid_image.png";
import { getFileURL, getShortString } from "../../functions/utils";

import { Box } from "@mui/material";

export default function EventCard({ item, fontsize }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        transition: "all 0.3s ease-in-out",
        ":hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <div
        className="singleCard text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          // gap: "1rem",
        }}
      >
        <Image
          width="100%"
          marginWidth="0"
          border="0"
          src={item.image_link}
          alt="thumb"
          preview={false}
          className="thumbnailImg"
          style={{ minWidth: "100%" }}
          fallback={invalid_image}
        />

        <div style={{ flex: 1, overflow: "auto" }}>
          <h5
            style={{
              fontSize: "1.2em",
              fontWeight: "700",
              letterSpacing: "-0.5px",
            }}
          >
            {item.title}
          </h5>
          <p style={{ fontSize: fontsize }}>{getShortString(item.desc)}</p>
        </div>

        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href={item.link ? getFileURL(item.link) : item.brochure}
            className="learn__more-btn"
          >
            <span className="hyphen"></span>
            <span className="btn_text">{t("read_more_button")}</span>
          </a>
        </div>
      </div>
    </Box>
  );
}
