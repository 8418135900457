import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { useTranslation } from "react-i18next";

import Container from "../../../Components/UI/Container";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import { getRequest } from "../../../functions/request";
function FeeStructure({ fontsize }) {
  const { t } = useTranslation();
  const [feeStructureData, setFeeStructureData] = useState([]);
  const getFeeStructureData = () => {
    getRequest("/files/feeStructure.json", false)
      .then((res) => {
        setFeeStructureData([...Object.values(res.data)]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFeeStructureData();
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The fee structure of the institute..."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,fees,fees of nit jsr,fees of nit jamshedpur"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("Fee Structure")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper>
          {feeStructureData.length < 1 ? (
            <p style={{ fontSize: fontsize }}>&nbsp; &nbsp; No Data</p>
          ) : (
            feeStructureData.map((data) => {
              return (
                <a href={data?.link}>
                  <p style={{ fontSize: fontsize }} className="pdfLink">
                    <Icon icon={chevronCircleRight} size={18} />
                    &nbsp;&nbsp; {t(data?.title)}
                  </p>
                </a>
              );
            })
          )}
        </SectionWrapper>
      </Container>
    </>
  );
}

// HumanityBlock style props
FeeStructure.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AboutUs default style
FeeStructure.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default FeeStructure;
