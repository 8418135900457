import React, { Fragment, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { scrollToContent } from "../../functions/scroll.js";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import AcademicHome from "./Sections/AcademicHome";
import Admission from "./Sections/Admissions";
import People from "./Sections/People";
import Departments from "./Sections/Departments";
import Curriculum from "./Sections/Curriculum";
import StudentStatistics from "./Sections/StudentStatistics";
import FeeStructure from "./Sections/FeeStructure";
import SemesterSchedule from "./Sections/SemesterSchedule";
import Ordinance from "./Sections/Ordinance";
import AcademicDocumnents from "./Sections/AcademicDocuments";
import Footer from "../../Components/Layout/Footer";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BannerImage from "../../assets/images/others/newbuilding.jpg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import MtechAdmission from "./Sections/MtechAdmission";
import MscAdmission from "./Sections/MscAdmission";
import BtechAdmission from "./Sections/BtechAdmission";
function AcademicIndex() {
  const { page } = useParams();
  const { t } = useTranslation();
  const [ActiveComp, setActiveComp] = useState(page);
  useEffect(() => {
    setActiveComp(page);
  }, [page]);

  function handleClick(comp) {
    setActiveComp(comp);
    scrollToContent();
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
          राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <Fragment>
        {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <TopSection  setFontSize={setFontSize} />
            </Sticky> */}
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <ContentWrapper width="80%">
            <Banner
              heading={t(`${ActiveComp.split(" ").join("_")}`)}
              bg_img={BannerImage}
            />
            <Container width="80%">
              <Row>
                <Col lg={4} sm={12}>
                  <Sidebar TopLink="Academics" handleClick={handleClick} />
                </Col>
                <Col lg={8} sm={12}>
                  {ActiveComp === "Academics" ? <AcademicHome /> : null}
                  {ActiveComp === "Admissions" ? <Admission /> : null}
                  {ActiveComp === "People" ? <People /> : null}
                  {ActiveComp === "Departments" ? <Departments /> : null}
                  {ActiveComp === "Curriculum" ? <Curriculum /> : null}
                  {ActiveComp === "Student_Statistics" ? (
                    <StudentStatistics />
                  ) : null}
                  {ActiveComp === "Fee_Structure" ? <FeeStructure /> : null}
                  {ActiveComp === "Semester_Schedule" ? (
                    <SemesterSchedule />
                  ) : null}
                  {ActiveComp === "Ordinance" ? <Ordinance /> : null}
                  {ActiveComp === "Academic_Documents" ? (
                    <AcademicDocumnents />
                  ) : null}
                  {ActiveComp === "B.Tech_Admission" ? (
                    <BtechAdmission />
                  ) : null}
                  {ActiveComp === "M.Tech_Admission" ? (
                    <MtechAdmission />
                  ) : null}
                  {ActiveComp === "M.Sc._Admission" ? <MscAdmission /> : null}
                </Col>
              </Row>
            </Container>
          </ContentWrapper>
          <Footer />
        </CharityWrapper>
        {/* End of Main wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
}

export default AcademicIndex;
