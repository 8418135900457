import React, { useState} from 'react';
import Sticky from "react-stickynode";
//import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container/index";

import { charityTheme } from "../../../theme/charity";
import { ThemeProvider } from "styled-components";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Footer from "../../../Components/Layout/Footer";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { internalLinks } from "../../../assets/data";
import { Table } from 'react-bootstrap';
import Banner from "../../../Components/Layout/Banner/Banner";
import Link from "../../../Components/CustomLink/CustomLink";

export default function InternalLinks() {
  const [ActiveComp] = useState('Internal Links');
  const {t} = useTranslation();
  return (
    <ThemeProvider theme={charityTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर 
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
        <Banner heading={t(`${ActiveComp}`)} />
          <Container>
                <Table striped bordered hover responsive="md" style={{margin: "50px auto"}}>
                  <thead>
                    <tr>
                      <th>{t(`internal_link_table_head_1`)}</th>
                      <th>{t(`internal_link_table_head_2`)}</th>
                      <th>{t(`internal_link_table_head_3`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {internalLinks &&
                    internalLinks.sort((a,b) => a.siteName.localeCompare(b.siteName)).map((items, index) => {
                    return (
                    <tr>
                      <td>{index+1}</td>
                      <td>{items.siteName}</td>
                      <td><Link to={{ pathname: items.link}} target='_blank'>{items.link}</Link></td>
                    </tr>
                      )
                    })}
                    </tbody>
                </Table>
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}
