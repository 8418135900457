import styled from 'styled-components';

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
`;

export const TableTitle = styled.h1`
	padding: 1rem 0;
`;

export const TableWrapper = styled.div`
	width: 100%;
	text-align: center;

	margin-bottom: 3%;
	@media only screen and (max-width: 1440px) {
		padding: 20px 0 40px;
	}
	@media only screen and (max-width: 1360px) {
		padding: 20px 0 20px;
	}
	@media only screen and (max-width: 991px) {
		padding: 10px 0 10px;
	}

	.primary-btn {
		background: #fff;
		border: 2px solid #274187;
		border-radius: 10px;
		padding: 5px;
		color: #274187;
	}
	.primary-btn:hover {
		color: #fff;
		background-color: #274187;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		margin: 20px auto;
	}

	th {
		background: #274187;
		color: white;
		font-weight: bold;
	}

	td,
	th {
		padding: 1.5%;
		border: 1px solid #ccc;
		text-align: center;
		font-size: 15px;
		// text-transform: capitalize;
	}

	td {
		word-break: break-word;
	}

	.trshmob {
		display: none;
	}
	.dwn-btn {
		background: #fff;
		color: #274187;
		cursor: pointer;
		padding: 8px;
		outline: none;
		border: 1px solid #274187;
		border-radius: 8px;
	}
	.dwn-btn:hover {
		background-color: #274187;
		color: #fff;
	}
	.stat-btn {
		background: #fff;
		color: #274187;
		cursor: pointer;
		padding: 8px;
		outline: none;
		border: 1px solid #274187;
		border-radius: 8px;
	}
`;

export const LongPara = styled.p`
	padding: 0.5rem 0;
	font-weight: 500;
	font-size:16px;
	font-family: 'Poppins', sans-serif;
`;

export const HostelContainer = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
`;

export const HostelBtn = styled.button`
	padding: 8px;
	background-color: #fff;
	border: 1px solid #274187;
	border-radius: 5px;
	color: #274187;
	:hover {
		cursor: pointer;
		background-color: #274187;
		color: #fff;
	}
`;
