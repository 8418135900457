import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import Container from "../../../Components/UI/Container";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import "../../../assets/css/Departments.css";
import { Helmet } from "react-helmet";

let branches = [
  {
    link: "/departments/civil",
    text: "img_civil",
  },
  {
    link: "/departments/chem",
    text: "img_chem",
  },
  // {
  //   link: '/departments/mca',
  //   text: 'img_computer'
  // },
  {
    link: "/departments/cs",
    text: "img_cse",
  },
  {
    link: "/departments/ece",
    text: "img_ece",
  },
  {
    link: "/departments/eee",
    text: "img_ee",
  },
  {
    link: "/departments/humanities",
    text: "img_humanities",
  },
  {
    link: "/departments/maths",
    text: "img_maths",
  },
  {
    link: "/departments/mech",
    text: "img_me",
  },
  {
    link: "/departments/meta",
    text: "img_mme",
  },
  {
    link: "/departments/phys",
    text: "img_phy",
  },
  {
    link: "/departments/prod",
    text: "img_pi",
  },
];
function Departments({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="List of the different departments of the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("departments_title")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper className="pl-3">
          {branches.map((branch, index) => {
            return (
              <div key={index} className="single_branch_div">
                <Link to={branch.link}>
                  <span className="branch_name">{t(branch.text)}</span>
                </Link>
              </div>
            );
          })}
        </SectionWrapper>
      </Container>
    </>
  );
}

// HumanityBlock style props
Departments.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// Departments default style
Departments.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Departments;
