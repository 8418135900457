import "./sitemap.styles.css";
import SitemapLinks from "../../../assets/data/sitemap-links.json";
import Sticky from "react-stickynode";
//import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container/index";

import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Footer from "../../../Components/Layout/Footer";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import { Helmet } from 'react-helmet';
import MapArea from "./MapArea";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function SiteMap() {
  const {t} = useTranslation();
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`SiteMap`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Container>
            <div className="row">
              <div className="col-md-4 site-map-sec">
                <h1 className="sitemap-heading">{t("Site Map")}</h1>
                <form>
                  <input type="text" name="search" placeholder={t("Search...")} />
                </form>
                <ul className="list-inline main-line">
                  {SitemapLinks.map((SitemapLink) => (
                    <div className="section mx-auto px-2 rounded">
                      <li>
                        {SitemapLink.section === "Home" ? <Link to="/">
                          <h3>
                            <span className="badge badge-pill" style={{ backgroundColor: "#1e2761", color: "white" }}>
                              {t(`${SitemapLink.section.split(' ').join('_')}`)}
                            </span>
                          </h3>
                        </Link> :
                          <h3>
                            <span className="badge badge-pill" style={{ backgroundColor: "#1e2761", color: "white" }}>
                            {t(`${SitemapLink.section.split(' ').join('_')}`)}
                            </span>
                          </h3>}
                      </li>
                      {SitemapLink.sublinks && (
                        <ul>
                          {SitemapLink.sublinks.map((SitemapSubLink) => {
                            return (
                              <div>
                                <MapArea
                                  name={t(`${SitemapSubLink.name.split(' ').join('_')}`)}
                                  link={SitemapSubLink.link}
                                  bgColor="#7a2048"
                                  color="white"
                                />
                                {SitemapSubLink.sublinks && (
                                  <ul>
                                    {SitemapSubLink.sublinks.map(
                                      (SitemapSubSubLink) => {
                                        return (
                                          <MapArea
                                            name={t(`${SitemapSubSubLink.name.split(' ').join('_')}`)}
                                            link={SitemapSubSubLink.link}
                                            bgColor="#b0dfe5"
                                            color="black"
                                          />
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </div>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}
