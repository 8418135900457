import React from "react";
import { useTranslation } from 'react-i18next' ;
import styled from "styled-components";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { ParagraphWrapper } from "../../../assets/css/Custom/StudentStyle";
import img1 from "./img/noragging.jpg";
import img2 from "./img/img1.jpg";
import img3 from "./img/img2.jpg";
import img4 from "./img/img3.jpg";
import nescafe from "./img/nescafe.jpeg";
import { Helmet } from "react-helmet";

const styles = {
  Heading: {
    fontSize: "1.5em",
    marginBottom: "20px",
    marginTop: "13px",
    fontWeight: "500",
    background: "#274187",
    color: "#fff",
    padding: "1% 1% 1% 3%",
  },
  Image: {
    display: "flex",
    justifyContent: "center",
  },
};

const Wrapper = styled.p`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    width: 18rem;
    margin: 0.3rem;
  }

  @media (max-width: 1120px) {
    img {
      margin-top: 2rem;
      width: 15rem;
    }
  }

  @media (max-width: 995px) {
    img {
      width: 28rem;
    }
  }

  @media (max-width: 600px) {
    img {
      margin-top: 1rem;
      width: 28rem;
      display: block;
      width: 90%;
    }
  }
`;

const LifeNITJSR = (fontsize) => {

  const { t }= useTranslation();
  return (
    <Container width="1250px">
      <Helmet>
        <meta name="description" content="Campus Life at NIT-Jamshedpur" />
        <meta name="keywords" content="Campus life,Nit-Jamshedpur,student life" />
      </Helmet>
      <br />
      <Heading content= {t("laN-1")} style={styles.Heading} />
      <Wrapper>
        <img src={img2} alt="thumnail" className="rounded" />
        <img src={img3} alt="thumnail" className="rounded" />
        <img src={img4} alt="thumnail" className="rounded" />
        <img src={nescafe} alt="thumnail" className="rounded" />
      </Wrapper>
      <ParagraphWrapper>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-21")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-22")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-23")}
        </p>
        <p style={{ fontSize: fontsize }} className="paragraph">
        {t("sl-6")}
        </p>
      </ParagraphWrapper>
      
      <ParagraphWrapper>
        <Heading content="About Us" style={styles.Heading} />
        <p>
        {t("laN-2")}
        </p>
        <p>
        {t("laN-3")}
        </p>
        <p>
        {t("laN-4")}
        </p>
        <p>
        {t("laN-4")}
        </p>
        <br />
        <Heading content= {t("sl-3")} style={styles.Heading} />
        <p style={{ ...styles.Image, justifyContent: "center" }}>
          <img src={img1} alt="no raggin img"></img>
        </p>
        <p>
        {t("sl-4")}
        </p>

        <br />
        <Heading
          content={t("sl-5")}
          style={styles.Heading}
        />
        <p>
          <ul className="ul-list">
            <li>
              {/* <b>NIT-Store-Bank-PO-ATM(multiple) */}
            </li>
            <li>
              <b>Social / Entertainment :</b>Games & Sports:Gymkhana-Tennis
              Courts-Basketball Court; Vishwakarma Sabha Griha-Clubs-Eateries
            </li>
            <li>
              <b>Infrastructure :</b>24 hr Power-Water-Network-Well maintained
              Roads
            </li>
             <li><b>
              Amenities Available in the Campus</b>
            </li>
             <li>
              Nescafe
            </li>
             <li>
              Juice Corner
            </li>
            <li>
              Desi food court
            </li>
            <li>
              Continental stall
            </li>
            <li>
              Night canteen for every hostel
            </li>
            <li>
              Photocopy and Stationary stall
            </li>
            <li>
              NIT Store
            </li>
            <li>
              Sudha Milk parlour
            </li>
            
            <li>
              Barber shop
            </li>
            <li>
              SBI and ICICI Bank ATM
            </li>
          </ul>
          Visit our wiki page for{" "}
          <a href="https://en.wikipedia.org/wiki/National_Institute_of_Technology,_Jamshedpur">
            more
          </a>
        </p>
        <br />
        <Heading
          content= {t("sl-7")}
          style={styles.Heading}
        />
        <p>
        {t("laN-7")}
        </p>
        
      </ParagraphWrapper>
    </Container>
  );
};

export default LifeNITJSR;
