import React, { useState, useEffect } from "react";
import AOS from "aos";
import Container from "../../../Components/UI/Container";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Navbar from "../../../Components/Layout/PagesNavbar";
import { getRequest } from "../../../functions/request";
import Sticky from "react-stickynode";
import { BACKEND_URL } from "../../../assets/Constants/"

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import Heading from "../../../Components/Heading";
import {
  ImageContainer,
  ImageCard,
  Images,
  GalleryContainer,
} from "./Gallery.styled";
import GalleryTags from "./GalleryTags";
import SimpleReactLightbox from "simple-react-lightbox";
import logo from "../../../assets/images/invalid_image.png";
import Footer from "../../../Components/Layout/Footer";
import "../../../../node_modules/aos/dist/aos.css";

const GalleryIndex = () => {
  const [thumbNails, setThumbNails] = useState([]);

  const getImages = async () => {
    let res = await getRequest(`gallery/get-by-tag?tags=all`);
    let temp = res.data.filter((data, index, self) => {
      return index === self.findIndex((t) => t.tags === data.tags);
    });
    setThumbNails(temp);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
      delay: 500,
    });
    AOS.refresh();
    getImages();
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <SimpleReactLightbox>
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <GalleryContainer>
            <ContentWrapper className="mh-100%">
              <Heading
                as="h2"
                content="Gallery"
                style={{ textAlign: "center" }}
              />
              <Container>
                <ImageContainer widthSize="22rem">
                  {thumbNails.map((image) => {
                    return (
                      <ImageCard key={image.id} data-aos="zoom-in">
                        <Images
                        style={{minWidth:"100%"}}
                          src={`${BACKEND_URL}/${image.path}`}
                          alt="An image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = logo;
                          }}
                        />
                        <GalleryTags tags={image.tags} />
                      </ImageCard>
                    );
                  })}
                </ImageContainer>
              </Container>
            </ContentWrapper>
          </GalleryContainer>
        </CharityWrapper>
          <Footer/>
      </SimpleReactLightbox>
    </ThemeProvider>
  );
};

export default GalleryIndex;
