import styled,{css} from 'styled-components';

export const Page = styled.span`
    margin:2px;
    color:#274187;
    padding-top:2px;
    padding-bottom:2px;
    padding-right:6px;
    padding-left:6px;
    border-style:solid;
    border-color:#274187;
    border-width:0px;
    border-radius:3px;
    ${props => props.active && 
        css`
        border-width:1px;
        `
    }
    ${props => props.display===false &&
        css`
        display:none;
        `
    }

:hover{
    background-color:#e0e0e0;
    cursor:pointer;
}
`;