import React from 'react';
import './BannerStyle.css';
import PropTypes from 'prop-types'
import defaultBannerImg from '../../../assets/images/others/newbuilding.jpg'
function Banner({ heading,bg_img }) {
  return (
    <>
    <div className="header-banner desktop_banner" 
    style={{
      background:`url(${bg_img})`,
      backgroundPosition:'center',
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      height: '400px',
      position: 'relative'
      }}>
      <p id="header-text">{heading.split('_').join(' ')}</p>
    </div>
    <div className="header-banner mobile_banner" 
    style={{
      background:`url(${bg_img})`,
      backgroundPosition:'center',
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      height: '200px',
      position: 'relative'
      }}>
      <p id="header-text">{heading.split('_').join(' ')}</p>
    </div>
    </>
  );
}

Banner.propTypes={
heading:PropTypes.string,
bg_img:PropTypes.string
}
Banner.defaultProps={
  bg_img:defaultBannerImg
}
export default Banner;
