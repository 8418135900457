import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import "./Searchedbox.css";
import { FiDownload } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import { getRequest } from "../../../functions/request";
import { formatDateString } from "../../../functions/utils";
// import { height } from "styled-system";

function MultipleNotices({ fontsize }) {
  const [orders, setOrders] = useState([]);
  const url = useLocation().search;

  // ?office=1
  useEffect(() => {
    (async () => {
      // const urlParam = new URLSearchParams(url);
      let title = url.split("=")[1];
      console.log(decodeURI(title));
      await getRequest(
        `/notices/get-multiple-notices?title=${decodeURI(title)}`
      )
        .then((res, err) => {
          if (res.data.results) {
            return res.data.results.map((item, idx) => {
              return {
                id: idx,
                text: item.title,
                link: item.path,
                date: item.idate,
                highlight: item.highlight,
                counts: item.count,
                // vupto: new Date(dt.getDate()+item.highlight*( 3600 * 1000 * 24)),	//TODO: MAKE USE OF NEW UPTO DATE
              };
            });
          } else {
            console.log(err);
          }
        })
        .then((data) => setOrders(data));
    })();
  }, [url]);

  return (
    <Container width="1250px">
      <br />
      <Heading />
      {orders.length !== 0 ? (
        <>
          <TableWrapper style={{ minHeight: "60vh !important" }}>
            {orders.map((item, id) => (
              <div
                key={`list_key${id}`}
                className="row border-bottom pb-2 mb-2"
              >
                <div>{item.id + 1}</div>
                <div className="col">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <b className="text-secondary">{item.text}</b>
                  </a>
                  <div className="row">
                    <div className="col-md-6">
                      {" "}
                      <span
                        className="badge badge-pill "
                        style={{ background: "#274086", color: "white" }}
                      >
                        {formatDateString(item.date)}
                      </span>
                    </div>
                    <div className="col-md-6 text-right">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiDownload size="25px" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TableWrapper>
        </>
      ) : (
        <h3 style={{ marginTop: "5rem" }}>
          <center>
            <Spinner animation="border" />
          </center>
        </h3>
      )}
    </Container>
  );
}

export default MultipleNotices;
