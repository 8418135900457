import React,{ useState, useEffect  }from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getRequest } from "../../../functions/request";
import { removeDoubleSlash } from '../../../functions/utils';
import img1 from '../../../assets/images/banner/1.jpg';
import img2 from '../../../assets/images/banner/2.jpg';
import img3 from '../../../assets/images/banner/3.jpg';
import img4 from '../../../assets/images/banner/4.jpg';
import img5 from '../../../assets/images/banner/5.jpg';

function Carousel() {

  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const getBanners = async () => {
      try {
        const res = await getRequest(`/banner?limit=10`);
        setBanners(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getBanners();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  //Default banner
  if(banners.length === 0 || !banners)
    return (
    <div>
      <Slider {...settings}>
          <div>
            <img src={img1} alt="slider-img" className="small-carousel-img" />
          </div>
          <div>
            <img src={img2} alt="slider-img" className="small-carousel-img" />
          </div>
          <div>
            <img src={img3} alt="slider-img" className="small-carousel-img" />
          </div>
          <div>
            <img src={img4} alt="slider-img" className="small-carousel-img" />
          </div>
          <div>
            <img src={img5} alt="slider-img" className="small-carousel-img" />
          </div>
      </Slider>
    </div>
  );
  //Banner from api
  return (
    <div>
      <Slider {...settings}>
        {banners?.map((b) => (
          <div>
            <img
              src={removeDoubleSlash(`https://nitjsr.ac.in/backend/${b.image}`)}
              alt="slider-img"
              className="small-carousel-img"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}


export default Carousel;
