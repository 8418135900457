import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import {
	CharityWrapper,
	ContentWrapper,
	GlobalStyle,
} from '../../assets/css/Main/main.style';
import img1 from '../../assets/images/Webteam/kksir.jpg';

import { ResetCSS } from '../../assets/css/style';
import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer';
import Navbar from '../../Components/Layout/PagesNavbar';
import Container from '../../Components/UI/Container';
import { DrawerProvider } from '../../contexts/DrawerContext';
import { charityTheme } from '../../theme/charity';
import DrawerSection from '../../Components/Layout/DrawerSection';
import {
	IoIosArrowDropleftCircle,
	IoIosArrowDroprightCircle,
} from 'react-icons/io';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import {
	ProfileWrapper,
	TableContainer,
	TableTitle,
	TableWrapper,
} from './styles';

import { WebTeamData, PreviousWebTeam } from '../../assets/data/ClubData';
import { scrollToContent } from '../../functions/scroll.js';

function WebTeam() {
	const totalPerPage = 10;
	const [currPage, setCurrPage] = useState(1);
	const { t } = useTranslation();

	useEffect(() => {
		scrollToContent()
	}, []);

	return (
		<ThemeProvider theme={charityTheme}>
			<ResetCSS />
			<GlobalStyle />
			<Helmet>
				<title>
					{t("Web_Team")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
				</title>
				<meta name="description" content="Web team NIT Jamshedpur, official web team of nit jamshedpur responsible for creating and maintaing  website of nit jamshedpur"/>
			</Helmet>
			<CharityWrapper>
				<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
					<Navbar />
				</Sticky>
				<DrawerProvider>
					<DrawerSection />
				</DrawerProvider>
				{/* Main Content Starts from here */}
				<ContentWrapper>
					<Banner heading={t('Web_Team')} />
					<Container width="90%">
						<br />
						<br />
						<ProfileWrapper>
							<img
								src={img1}
								style={{
									width: '150px',
									height: '175px',
									borderRadius: '50%',
									marginBottom: '1rem',
								}}
								alt={t("Dr. Koushlendra Kumar Singh")}
							/>
							<b>{t("Dr. Koushlendra Kumar Singh")}</b>
							<h6>Prof.-In-Charge, Website</h6>
							<a href={`mailto:webmaster@nitjsr.ac.in`}>
								<i className="fad fa-envelope"></i> webmaster@nitjsr.ac.in
							</a>
						</ProfileWrapper>
						<hr className='my-3'/>
						<div className='row col-lg-10 mx-auto'>
							{WebTeamData.map((person) => (
								<div className="col-lg-3 mx-auto mt-4">
								<ProfileWrapper>
									<div
									style={{
										border: '2px solid #00008B',
										background: 'lightgreen',
										borderRadius: '50%',
										width: '152px',
										height: '152px',									}}
									>
									<img
										src={person.img}
										style={{
											width: '150px',
											height: '150px',
											marginBottom: '1rem',
											borderRadius: '50%',
										}}
										alt={t(`${person.name.split(' ').join('_')}`)}
									/>
									</div>
									<b>{t(`${person.name.split(' ').join('_')}`)}</b>
									{/* <div>{person.post}</div> */}
									{person.batch ? <div>{person.batch}</div> : null}
									{/* <a href={`mailto:${person.email}`}>
                    <i className="fad fa-envelope"></i>
                  </a> */}
									<br />
								</ProfileWrapper>
								</div>
							))}
						</div>
						<TableContainer>
							<TableTitle>{t("previous")}</TableTitle>
							<TableWrapper>
								<table>
									<thead>
										<tr id="headRow">
											<th>{t("Name")}</th>
											<th>{t("Batch")}</th>
											{/* <th>{t("E-mail")}</th> */}
										</tr>
									</thead>
									<tbody>
										{PreviousWebTeam.slice(
											(currPage - 1) * totalPerPage,
											Math.min(currPage * totalPerPage, PreviousWebTeam.length)
										).map((person) => (
											<tr>
												<td>{t(person.name)}</td>
												<td>{person.Batch}</td>
												{/* <td>{person.email}</td> */}
											</tr>
										))}
									</tbody>
								</table>
							</TableWrapper>
						</TableContainer>
						<div
							style={{
								textAlign: 'center',
								color: '#274187',
								margin: '1rem 0',
							}}
						>
							{currPage !== 1 ? (
								<IoIosArrowDropleftCircle
									size="1.5em"
									onClick={() => setCurrPage((prev) => prev - 1)}
									style={{ cursor: 'pointer', margin: '0 1rem' }}
								/>
							) : null}
							{currPage}
							{currPage !== Math.ceil(PreviousWebTeam.length / totalPerPage) ? (
								<IoIosArrowDroprightCircle
									size="1.5em"
									onClick={() => setCurrPage((prev) => prev + 1)}
									style={{ cursor: 'pointer', margin: '0 1rem' }}
								/>
							) : null}
						</div>
					</Container>
				</ContentWrapper>
				{/* Footer Area */}
				<Footer />
			</CharityWrapper>
		</ThemeProvider>
	);
}

export default WebTeam;
