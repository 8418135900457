import React from 'react';
import {useTranslation} from 'react-i18next';
import director from '../../../assets/images/others/director.jpg';
import Heading from '../../../Components/Heading';
import {Link} from 'react-router-dom';
function DirectorSection() {
    const {t}=useTranslation();
    return (
        <div className="director_container">
              <Heading
                content={t("directors_corner_head")}
                className="heading"
              />
           <div className="director_content_container">
               <p style={{textAlign: 'justify'}}> 
                
                <img src={director} alt="director" />
               <span>{t("directors_corner_speech")}
           </span>
               </p>
              {/* <p>Prof. Karunesh Kumar Shukla</p> */}
           </div>
           <div className="director_content">
              
          
           <Link to="/Administration/Director" className="learn__more-btn">
                  <span className="hyphen" />
                  <span className="btn_text">{t("read_more_button")} </span>
              </Link>
           </div>
        </div>
    )
}

export default DirectorSection
