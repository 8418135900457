import React, { useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import {
	CharityWrapper,
	ContentWrapper,
	GlobalStyle,
} from '../../../assets/css/Main/main.style';
import { ResetCSS } from '../../../assets/css/style';
import DrawerSection from '../../../Components/Layout/DrawerSection';
import Banner from '../../../Components/Layout/Banner/Banner';
import Footer from '../../../Components/Layout/Footer';
import Sidebar from '../../../Components/Layout/Sidebar/Sidebar';
import Navbar from '../../../Components/Layout/PagesNavbar';
import Container from '../../../Components/UI/Container';
import { DrawerProvider } from '../../../contexts/DrawerContext';
import { charityTheme } from '../../../theme/charity';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { scrollToContent } from '../../../functions/scroll.js';
import Students from './Sections/students';
import Employees from './Sections/employees';

function Downloads() {

	const { page } = useParams();
	const [ActiveComp, setActiveComp] = useState('Students');
	const fontsize = 16;
	const { t } = useTranslation();
	useEffect(() => {
		const pg = page.replaceAll('-', ' ');
		setActiveComp(pg);
	}, [page]);
	function handleClick(comp) {
		const pg = comp.replaceAll('-', ' ');
		setActiveComp(pg);
		scrollToContent()
	}

	return (
		<ThemeProvider theme={charityTheme}>
			<Helmet>
				<title>
					{t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
				</title>
			</Helmet>
			{/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
      <TopSection fontsize={fontsize} setFontSize={setFontSize} />
        </Sticky> */}
			<ResetCSS />
			<GlobalStyle />
			<CharityWrapper>
				<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
					<Navbar />
				</Sticky>
				<DrawerProvider>
					<DrawerSection />
				</DrawerProvider>
				{/* Main Content Starts from here */}
				<ContentWrapper>
					<Banner heading={t(`${ActiveComp}`)} />
					<Container width="90%">
						<Row>
							<Col md={4} sm={12}>
								<Sidebar TopLink="Downloads" handleClick={handleClick} />
							</Col>
							<Col md={8} sm={12}>
								{ActiveComp === 'Students' && (
									<Students fontsize={fontsize} />
								)}
								{ActiveComp === 'Employees' && (
									<Employees fontsize={fontsize} />
								)}
							</Col>
						</Row>
					</Container>
				</ContentWrapper>
				{/* Footer Area */}
				<Footer />
			</CharityWrapper>
		</ThemeProvider>
	);
}

export default Downloads;
