import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";
import { FiExternalLink } from "react-icons/fi";
import { Page } from "../../assets/css/Custom/RecruitementStyles";
import { MdArrowDropDown } from "react-icons/md";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
import { getRequest } from "../../functions/request";
import { formatDateString } from "../../functions/utils";
import { getFileURL, getShortString } from "../../functions/utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/material";
import { flex, flexWrap } from "styled-system";
import { EventWrapper } from "./EventStyles.style.js";
import { Box, Stack, Typography, Pagination } from "@mui/material";

const ArchiveEvents = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const fetching = async () => {
    try {
      let res = await getRequest(`/events/archive-events`);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetching();
    console.log("data  = ", data);
    console.log("Page no : ", page);
  }, []);

  data.reverse();

  const eventsPerPage = 9;
  const pageCount = Math.ceil(data.length / eventsPerPage);
  let currPageEvents = data.slice(0, eventsPerPage);

  const [page, setPage] = useState(1);

  const StartEventNo = (page - 1) * eventsPerPage;
  const endEventNo = page * eventsPerPage;
  currPageEvents = data.slice(StartEventNo, endEventNo);
  const paginate = (e, value) => {
    setPage(value);

    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("Recruitments")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta name="description" content="Archive events, NIT Jamshedpur" />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,Archive Events nit jsr,Archive Events,Archive events,notices"
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />

      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection black="black" white="white" />
        </DrawerProvider>
        <ContentWrapper>
          <Banner heading={t("Events")} />
          <Container className="mt-3">
            <div
              className="event-card-container"
              style={{
                display: "flex",
                flexWrap: "wrap",

                justifyContent: "space-around",
              }}
            >
              {currPageEvents.map((item, id) => (
                <div
                  key={`list_key${id + page * 10}`}
                  className="row border-bottom pb-2 mb-2"
                  style={{ width: "100%" }}
                >
                  <div>{id + 1 + 10 * (page - 1)}</div>
                  <div className="col">
                    {/* href={item.link ? getFileURL(item.link) : item.brochure} */}

                    {item.counts <= 1 ? (
                      <a
                        href={item.brochure}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b className="text-secondary">{item.title}</b>
                      </a>
                    ) : (
                      <a
                        href={item.link ? getFileURL(item.link) : item.brochure}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b className="text-secondary">{item.title}</b>
                      </a>
                    )}
                    <div className="row">
                      <div className="col-md-6">
                        <span
                          className="badge badge-pill "
                          style={{ background: "#274086", color: "white" }}
                        >
                          {formatDateString(item.start_date)}
                        </span>
                        <span> to </span>
                        <span
                          className="badge badge-pill "
                          style={{ background: "#274086", color: "white" }}
                        >
                          {formatDateString(item.end_date)}
                        </span>
                      </div>
                      <div className="col-md-6 text-right">
                        {item.counts <= 1 ? (
                          <a
                            href={
                              item.link ? getFileURL(item.link) : item.brochure
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiDownload size="25px" />
                          </a>
                        ) : (
                          <a
                            href={
                              item.link ? getFileURL(item.link) : item.brochure
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiDownload size="25px" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>

          {data.length > eventsPerPage && (
            <Stack mt={5} alignItems="center">
              <Pagination
                count={pageCount}
                page={page}
                color="primary"
                defaultPage={1}
                onChange={paginate}
                size="large"
              />
            </Stack>
          )}
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
};

export default ArchiveEvents;

{
  /* <TableWrapper style={{ minHeight: "60vh !important" }}>
{notices
  .slice(
    (currPage - 1) * totalPerPage,
    Math.min(currPage * totalPerPage, notices.length)
  )
  .map((item, id) => (
    <div
      key={`list_key${id}`}
      className="row border-bottom pb-2 mb-2"
    >
      <div>{item.id + 1}</div>
      <div className="col">
        {item.counts <= 1 ? (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <b className="text-secondary">{item.text}</b>
          </a>
        ) : (
          <Link
            to={`/notices/Announcements/multiple?title=${item.text}`}
          >
            <b className="text-secondary">{item.text}</b>
          </Link>
        )}
        <div className="row">
          <div className="col-md-6">
            {" "}
            <span
              className="badge badge-pill "
              style={{ background: "#274086", color: "white" }}
            >
              {formatDateString(item.date)}
            </span>
          </div>
          <div className="col-md-6 text-right">
            {item.counts <= 1 ? (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiDownload size="25px" />
              </a>
            ) : (
              <Link
                to={`/notices/Announcements/multiple?title=${item.text}`}
              >
                <FiExternalLink size="25px" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  ))}
</TableWrapper> */
}
