import AcademicIndex from "./Pages/Academics/AcademicIndex";
import People from "./Pages/People/index";
import CellIndex from "./Pages/Cell/Cellindex";
import Homeindex from "./Pages/Home/Homeindex";
import Institute from "./Pages/Institute";
import Notices from "./Pages/Notices";
import RecruitmentIndex from "./Pages/Recruitments/index";
import StudentIndex from "./Pages/Students/index";
import Profile from "./Pages/People/Profile";
import DepartmentIndex from "./Pages/Departments";
import ErrorPage from "./Pages/Error/ErrorPage";
import Facilities from "./Pages/Facilities/index";
import ResearchAndDevelpoment from "./Pages/ResearchCon";
// import ResearchAndDevelpoment from "./ResearchAndDevelpoment";
import DeptFaculty from "./Pages/Departments/Sections/DepartmentWiseFaculty";
import WebTeam from "./Pages/WebTeam";
import PAN_GST from "./Pages/More/PAN_GST";
import RTI from "./Pages/More/RTI";
import LegalCases from "./Pages/More/LegalCases";
import Sitemap from "./Pages/More/Sitemap/sitemap";
import Downloads from "./Pages/More/Downloads/index";
import TenderIndex from "./Pages/Tender";
import Convocation from "./Pages/Convocation/index";
import GalleryIndex from "./Pages/Home/Gallery/GalleryIndex";
import GalleryCards from "./Pages/Home/Gallery/GalleryCards";
import Director from "./Pages/Administration/Sections/Director";
import DeputyDirector from "./Pages/Administration/Sections/DeputyDirector";
import InternalLinks from "./Pages/Home/InternalLinks/index";
import ResearchData from "./Pages/Home/ResearchSection/ResearchData";
import Policies from "./Pages/More/Policies";
import Administration from "./Pages/Administration/index";
import MultipleNotices from "./Pages/Notices/Sections/MultipleNotices";
import MediaCoverage from "./Pages/MediaCoverage/index";
// import APList from './pages/APList/index';
import DeanOffice from "./Pages/Administration/Sections/deanOffice";
// import Tap from "./Pages/TAP";
import ApList from "./Pages/APList/page";
import AssistantList from "./Pages/AssistantList";
import StudentCouncil from "./Pages/Students/Sections/StudentCouncil";
import Iks from "./Pages/Cell/Sections/Iks";
import { Achievers } from "./Pages/Achievers/achievers";
import ArchiveEvents from "./Pages/ArchiveEvents";
import Alumni from "./Pages/Alumni";
import ResearchCon from "./Pages/ResearchCon";
import Events from "./Pages/Events/index";
import Admission from "./Pages/Admission/index";
import BisMou from "./Pages/BisMoU";
import ListDebarred from "./Pages/Tender/Sections/ListDebarred";

export const Routes = [
  {
    id: 1,
    component: Homeindex,
    path: "/",
    exact: true,
  },
  {
    id: 2,
    component: AcademicIndex,
    path: "/academic/:page",
    exact: true,
  },
  {
    id: 3,
    component: Institute,
    path: "/institute/:page",
    exact: true,
  },
  {
    id: 4,
    component: StudentIndex,
    path: "/Students/:page",
    exact: true,
  },
  {
    id: 5,
    component: RecruitmentIndex,
    path: "/Recruitments",
    exact: true,
  },
  
  {

    id: 6,
    component: CellIndex,
    path: "/cell/:page",
    exact: true,
  },
  {
    id: 7,
    component: Notices,
    path: "/notices/:page",
    exact: true,
  },
  {
    id: 8,
    component: BisMou,
    path: "/bis-mou",
    exact: true,
  },
  {
    id: 32,
    component: MultipleNotices,
    path: "/notices/:page/multiple",
    exact: true,
  },
  {
    id: 15,
    component: Director,
    path: "/Administration/director",
    exact: true,
  },
  {
    id: 29,
    component: DeputyDirector,
    path: "/Administration/deputy_director",
    exact: true,
  },
  {
    id: 8,
    component: People,
    path: "/people/:page",
    exact: true,
  },
  {
    id: 9,
    component: Profile,
    path: "/people/profile/:id",
    exact: true,
  },
  {
    id: 10,
    component: Facilities,
    path: "/facilities/:page",
    exact: true,
  },
  {
    id: 11,
    component: DepartmentIndex,
    path: "/departments/:branch",
    exact: true,
  },
  {
    id: 12,
    component: ResearchAndDevelpoment,
    path: "/rnd",
    exact: true,
  },
  {
    id: 13,
    component: DeptFaculty,
    path: "/departments/faculty/:branch",
    exact: true,
  },
  {
    id: 14,
    component: WebTeam,
    path: "/Clubs/Webteam",
    exact: true,
  },
  {
    id: 16,
    component: PAN_GST,
    path: "/PAN&GST",
    exact: true,
  },

  {
    id: 17,
    component: RTI,
    path: "/RTI",
    exact: true,
  },
  {
    id: 19,
    component: LegalCases,
    path: "/Legal-cases",
    exact: true,
  },
  {
    id: 18,
    component: Iks,
    path: "/Iks",
    exact: true,
  },
  {
    id: 26,
    component: Policies,
    path: "/policy",
    exact: true,
  },
  {
    id: 34,
    component: Sitemap,
    path: "/Sitemap",
    exact: true,
  },
  {
    id: 20,
    component: TenderIndex,
    path: "/tender/:page",
    exact: true,
  },
  {
    id: 21,
    component: Convocation,
    path: "/convocation",
    exact: true,
  },
  {
    id: 22,
    component: GalleryIndex,
    path: "/galleryIndex",
    exact: true,
  },
  {
    id: 23,
    component: GalleryCards,
    path: "/gallery",
    exact: true,
  },
  {
    id: 24,
    component: Downloads,
    path: "/downloads/:page",
    exact: true,
  },
  {
    id: 25,
    component: InternalLinks,
    path: "/internalLinks",
    exact: true,
  },
  {
    id: 26,
    component: ResearchData,
    path: "/researchData/:researchId",
    exact: true,
  },
  {
    id: 27,
    component: Administration,
    path: "/administration/:page",
    exact: true,
  },

  {
    id: 35,
    component: DeanOffice,
    path: "/Administration/DeanOffice/:page",
    // /Administration/DeanOffice/:page
  },
  {
    id: 28,
    component: MediaCoverage,
    path: "/MediaCoverage",
    exact: true,
  },
  {
    id: 33,
    component: ApList,
    path: "/aplist2019",
    exact: true,
  },
  {
    id: 29,
    component: ApList,
    path: "/List_of_provisional_Eligible_candidate",
    exact: true,
  },
  {
    id: 30,
    component: AssistantList,
    path: "/List_of_provisional_Eligible_candidate_Assistant",
    exact: true,
  },
  {
    id: 31,
    component: StudentCouncil,
    path: "Students/Student-Council",
    exact: true,
  },
  {
    id: 32,
    component: ResearchCon,
    path: "/ResearchCon",
    exact: true,
  },

  //
  //   id: 33,
  //   component: Rnc,
  //   path: "/rnd/rnc",
  //   exact: true,
  // },
  // {
  //   id: 34,
  //   component: Publ,
  //   path: "/rnd/publ",
  //   exact: true,
  // },
  // {
  //   id: 34,
  //   component: Awards,
  //   path: "/rnd/awards",
  //   exact: true,
  // },
  // {
  //   id: 35,
  //   component: Lec,
  //   path: "/rnd/lec",
  //   exact: true,
  // },
  // {
  //   id: 36,
  //   component: Mou,
  //   path: "/rnd/mou",
  //   exact: true,
  // },
  // {
  //   id: 37,
  //   component: Forms,
  //   path: "/rnd/form",
  //   exact: true,
  // },

  // // {
  // //   id: 38,
  // //   component: Areas,
  // //   path: "/rnd/areas",
  // //   exact: true,
  // // },
  // {
  //   id: 39,
  //   component: Projects,
  //   path: "/rnd/pnc",
  //   exact: true,
  // },
  {
    id: 40,
    component: Achievers,
    path: "/achievers",
    exact: true,
  },
  {
    id: 41,
    component: ArchiveEvents,
    path: "/archiveEvents",
    exact: true,
  },
  {
    id: 42,
    component: Alumni,
    path: "/Alumni",
    exact: true,
  },
  {
    id: 43,
    component: Events,
    path: "/events",
    exact: true,
  },
  {
    id: 44,
    component: Admission,
    path: "/admission",
    exact: true,
  },
  {
    id: 45,
    component: ListDebarred,
    path: "/DebarredAgencies",
    exact: true,
  },
  {
    id: 34,
    component: ErrorPage,
    path: "*",
    exact: false,
  },
];
