import React, { Fragment, useState, useEffect } from "react";

import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../../theme/main";
import { ResetCSS } from "../../../../assets/css/style";
import {
  GlobalSyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import { DrawerProvider } from "../../../../contexts/DrawerContext";
import Navbar from "../../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../../Components/Layout/DrawerSection";
import Banner from "../../../../Components/Layout/Banner/Banner";
import FacultyWelfare from "./DeanFWOffice.js";
import Footer from "../../../../Components/Layout/Footer";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../../../functions/scroll.js";

// page imports
import DeanAcadOffice from "./DeanAcadOffice.js";
import DeanFWOffice from "./DeanFWOffice.js";
import DeanIROffice from "./DeanIROffice.js";
import DeanPNDOffice from "./DeanPNDOffice.js";
import DeanRCOffice from "./DeanRCOffice.js";
import DeanSWOffice from "./DeanSWOffice.js";

function DeanOffice() {
  const { page } = useParams();
  const { t } = useTranslation();
  const [ActiveComp, setActiveComp] = useState("Visitor");
  const BannerHeads = {
    Academic: "d-academic",
    IR: "d-industry",
    PND: "d-pnd",
    FW: "d-fw",
    SW: "d-sw",
    RNC: "d-rnc",
  };
  useEffect(() => {
    const pg = page.replaceAll("_", " ");
    setActiveComp(pg);
    scrollToContent();
  }, [page]);
  console.log("Active Comp = ", ActiveComp);
  console.log("BannerHead pf Active Comp is : ", BannerHeads["Academic"]);
  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <Helmet>
          <title>
            {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
            राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
          </title>
        </Helmet>
        <Fragment>
          <ResetCSS />
          <CharityWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <Navbar />
            </Sticky>
            <DrawerProvider>
              <DrawerSection black="black" white="white" />
            </DrawerProvider>
            <ContentWrapper>
              <Banner
                heading={t(`Office`) + " of " + t(`${BannerHeads[ActiveComp]}`)}
              />
              <Container>
                {ActiveComp === "Academic" ? <DeanAcadOffice /> : null}
                {ActiveComp === "IR" ? <DeanIROffice /> : null}
                {ActiveComp === "PND" ? <DeanPNDOffice /> : null}
                {ActiveComp === "FW" ? <DeanFWOffice /> : null}
                {ActiveComp === "RNC" ? <DeanRCOffice /> : null}
                {ActiveComp === "SW" ? <DeanSWOffice /> : null}
              </Container>
            </ContentWrapper>
            <Footer />
          </CharityWrapper>
        </Fragment>
      </ThemeProvider>
    </>
  );
}

export default DeanOffice;
