import React from "react";

export default function PublicationSection() {

    return (
        <div className="panel-body body1">
            Publications
            {/* <h5 style={{ "text-decoration": "none!important" }}> <b>1. Book Chapters </b><br /></h5>
            <ol>
                <li style={{ listStyleType: 'disc' }}>"Buckling and Post-buckling of Composite Plates Under Thermal Loadings", <b>K.K.Shukla</b> and
                    Pandey R., Encyclopedia of Thermal Stresses, Editor- R. Hetnarski, DOI 10.1007/978-94-007-2739-7, ©
                    Springer Science+Business Media Dordrecht , 505-516, (2014).</li>

                <li style={{ listStyleType: 'disc' }}> "Crushing analysis of tapered circular corrugated tubes subjected to impact loading", Rawat,S.,
                    Upadhyay , A.K., and <b>K.K.Shukla</b>, Recent Advances in Structural Engineering, Volume 2 ,
                    Lecture Notes in Civil Engineering 12, 197-205, eBook ISBN: 978-981-13-0365-4; Hardcover ISBN:
                    978981-13-0364-7, Editors: A Rama Mohan Rao, K. Ramanjaneyulu, Publisher: Springer Singapore, DOI:
                    10.1007/978-981-13-0365-4_17, (2019).
                </li>
            </ol>

            <h5 style={{ "text-decoration": "none!important" }}><b>2. Text Book </b><br /></h5>
            <ol>
                <li style={{ listStyleType: 'disc' }}>"An Introduction to Strength of Materials" <b>K.K.Shukla</b>, Anuj Jain,&amp; Ramesh Pandey ( Narosa
                    Publications , 2014, ISBN: 978-81-8487-101-2 ) </li>
            </ol>

            <h5 style={{ "text-decoration": "none!important" }}> <b>3. Book/Proceedings edited</b></h5>
            <ol>
                <li style={{ listStyleType: 'disc' }}>"Proceeding of the International Conference on Multifunctional Materials, Structures and
                    Applications", P.Chakrabarti, Sanjeev Khanna, <b>K.K.Shukla</b>, Naresh Kumar, A. Bhar (McGraw Hill
                    Education (India) Pvt. Ltd. New Delhi, 2014, ISBN: 978-93-392-2019-8)
                </li>
            </ol>

            <h5 style={{ "text-decoration": "none!important" }}> <b>4. List of Publications (Journals) </b><br /></h5>
            <ol>
                <li style={{ listStyleType: 'disc' }}> "Dynamic Analysis of Functionally Graded Curved Sandwich Panels", Tarun, Rajendra Bahadur,
                    Upadhyay, A.K. , and <b>K.K.Shukla</b> , J. of Structural Engineering (CSIR-SERC), 45 (1), (2018),
                    52-60. </li>
                <li style={{ listStyleType: 'disc' }}>"Static Analysis of thin walled composite and sandwich box beam", Sharma, T., Murari, V., and
                    <b>K.K.Shukla</b>,
                    J. Structural Engineering, 45(1) (2018), 86-98.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Analysis of thin walled composite box beam with and without piezoelectric actuators using ABAQUS",
                    Khurana A., Sharma T., and <b>K.K.Shukla</b>, J. Structural Engineering, 45(1) (2018), 99-109.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Dynamic Behaviour of Curved Panels of Rectangular Planar Form: An Analytical Approach", Rajendra
                    Bahadur, Upadhyay A.K., and <b>K.K.Shukla</b>, Int. J. Structural Stability and Dynamics, 18 (6)
                    (2018), 1850084-21.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Effect of Orthotropy Ratio of the Shear Web on the Aero-Elasticity and Torque Generation of a
                    Hybrid Wind
                    Turbine Blade". Choudhury S., Sharma T., <b>K.K.Shukla</b>, Renewable Energy, 113, 1378-1387 (2017).
                    DOI:
                    10.1016/j.renene.2017.07.016.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Static Analysis of Singly and Doubly Curved Panels on Rectangular Plan-form", Rajendra Bahadur,
                    Upadhyay A.K., and <b>K.K.Shukla</b>, Steel and Composite Structures, Techno Press, 24 (6), 659-670
                    (2017).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Stress Analysis for an Infinite Plate with Circular Holes", Kumar Soni, Upadhyay, A.K., and
                    <b>K.K.Shukla</b>,
                    Material Today Proceedings, Vol. 4, Issue 2, 2323-2332,2017, Elsevier Publication.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Study of Effect of Interface on the Elastic Modulus of CNT Nanocomposites", Guru, K., Sharma, T.,
                    <b>K.K.Shukla</b>, and Mishra, S.B., Journal of Nanomechanics and Micromechanics, ASCE,
                    6(3),04016004-1-10(2016).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Multiobjective optimization of functionally corrugated tubes for improved crashworthiness under
                    axial
                    impact", Rawat, S., Narayanan, A., Upadhyay, A.K., and <b>K.K.Shukla</b>, Procedia Engineering,
                    2016.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Effect of temperature and functionalization on the interfacial properties of CNT reinforced
                    nanocomposites",
                    Guru, K., Mishra, S.B., and <b>K.K.Shukla</b>, Applied Surface Science, 349, 59-65 (2015)
                </li>
                <li style={{ listStyleType: 'disc' }}> "Buckling of Laminated Composite and Sandwich Plates Using Radial Basis Function Collocations",
                    Sandeep
                    Singh, Jeeoot, Singh, <b>K.K.Shukla</b>,International Journal of Structural Stability and
                    Dynamics,15(1), 2015.
                    DOI: 10.1142/S0219455415400027
                </li>
                <li style={{ listStyleType: 'disc' }}>"Economic and Environmental Benefits of Roof Insulation in Composite Climate of India", Singh, H.K.,
                    PrakashR., and <b>K.K.Shukla</b>, Climate Change ,The International Quarterly journal ISSN 2394-8558
                    EISSN
                    2394-8566, 1(4), 397-403 (2015).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Experimental Investigations of RC Beams Strengthened with 4-Layered Symmetric Cross-Ply (SCP) GFRP
                    Laminates", Rushad, S.T., Duggal, S.K., and <b>K.K.Shukla</b>, IJRET: International Journal of
                    Research in
                    Engineering and Technology, Vol. 04 Special Issue: 13, 431-434 (2015).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Nonlinear free vibration of laminated composite and sandwich plates using multiquadric
                    collocations", Solanki
                    M.K.,Mishra S.K., <b>K.K.Shukla</b>, Singh J, Material Today Proceedings, Volume 2, Page 3059-3055,
                    2015,Elsevier Publication
                </li>
                <li style={{ listStyleType: 'disc' }}> "Post-buckling analysis of skew plates subjected to combined in-plane loadings" Upadhyay, A.K. and
                    <b>K.K.Shukla</b>, ActaMech, 225 (10), 2959-2968, (2014)
                </li>
                <li style={{ listStyleType: 'disc' }}>"Meshless Analysis of Laminated Composite and Sandwich Plates Subjected to Various Types of Loads",
                    Singh, Jeeoot, Singh, S., and <b>K.K.Shukla</b>, Journal for Computational Methods in Engineering
                    &amp; Mechanics,
                    15(2), 158-171, (2014)
                </li>
                <li style={{ listStyleType: 'disc' }}>"Life Cycle Energy of Low Rise Residential Buildings in Indian Context" Ramesh, T., Prakash, R., and
                    <b>K.K.Shukla</b>,Open Journal of Energy Efficiency (OJEE),3, 108-118,(2014).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Post buckling behavior of Composite and Sandwich Skew Plates" Upadhyay, A.K. and
                    <b>K.K.Shukla</b>,
                    International Journal of Non-Linear Mechanics, 55, 120-127 (2013).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Nonlinear static and dynamic analysis of skew sandwich plates", Upadhyay, A.K. and
                    <b>K.K.Shukla</b>,
                    Composite Structures, 105, 141-148 (2013)
                </li>
                <li style={{ listStyleType: 'disc' }}> "Geometrically Nonlinear Static and Dynamic Analysis of Functionally Graded Skew Plates" Upadhyay,
                    A.K.
                    and <b>K.K.Shukla</b>, Communications in Nonlinear Sciences and Numerical Simulation,
                    18(8),2252-2279 (2013)
                </li>
                <li style={{ listStyleType: 'disc' }}> "Flexural response of Doubly Curved Laminated Composite Shells" by Sharma, A.,Upadhyaya, A.K.,
                    <b>K.K.Shukla</b>, J. SCIENCE CHINA Physics, Mechanics &amp; Astronomy, 56 (4): 812-817 (2013).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Non-linear Flexural and Dynamic Response of CNT Reinforced Laminated CompositePlates", Bhardwaj,
                    G.,
                    Upadhyay, A.K., Pandey, R., and <b>K.K.Shukla</b>, Composites Part B, 45, 89-100 (2013).
                </li>
                <li style={{ listStyleType: 'disc' }}>"Buckling of laminated composite plates subjected to mechanical and thermal loads using meshless
                    collocations", Singh, Sandeep, Singh Jeeoot, and <b>K.K.Shukla</b>, Journal of Mechanical Science
                    and
                    Technology, 27(2), 327-336 (2013).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Life cycle energy analysis of a multifamily residential house: a case study in Indian context"
                    Ramesh, T.,
                    Prakash, R., and <b>K.K.Shukla</b>,Open Journal of Energy Efficiency (OJEE), 2, 34(2013).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Study of Composite Effect of Concrete Base in Rigid Pavement for Vilage Roads in Alluvial Region",
                    Srivastava R.K, <b>K.K.Shukla</b>, and Duggal S.K ,J. Indian Road Congress, 74, 1-13, 2013
                </li>
                <li style={{ listStyleType: 'disc' }}> "Nonlinear flexural analysis of functionally graded plates under different loadings usingRBF based
                    meshless
                    method", Singh, Jeeoot and <b>K.K.Shukla</b>, Engineering Analysis with Boundary Elements, Vol. 36,
                    1819-1827
                    (2012).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Large Deformation Flexural Behavior of Laminated Composite Skew Plates: An Analytical Approach"
                    Upadhyay, A.K. and <b>K.K.Shukla</b>,Composite Structures, Vol. 94, 3722-3735 (2012)
                </li>
                <li style={{ listStyleType: 'disc' }}> "Life cycle approach in evaluating energy performance of residential buildings in Indian Context",
                    Ramesh, T.,
                    Prakash, R., and Shukla, K.K., Energy and Buildings, 54, 259-265 (2012).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Nonlinear Dynamic Response of Elastically Supported Laminated Composite Plates", Pandey, R.,
                    Upadhyay,
                    A.K., <b>K.K.Shukla</b>, and Jain A., Int. J. Mechanics of Advanced Materials and Structures, Vol.
                    19 (6), 397-420,
                    2012.
                </li>
                <li style={{ listStyleType: 'disc' }}>"Nonlinear flexural analysis of laminated composite plates using RBF based meshless method", Singh,
                    Jeeoot
                    and <b>K.K.Shukla</b>, Composite Structures, Vol. 94, 1714-1720 (2012).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Life Cycle Energy Analysis of Residential Buildings with Different Envelopes and Climates in
                    Indian
                    Context" , Ramesh, T., Prakash, R., and <b>K.K.Shukla</b>, J. Applied Energy,Vol. 89(1), 193-202
                    (2012).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Reinforced Cement Concrete Pavement for Village Roads in Alluvial Region: A Sustainable Option",
                    Srivastava R.K, Duggal S.K., and <b>K.K.Shukla</b>, Highway Research Journal, Indian Road Congress,
                    5(2), 19-
                    26, 2012.
                </li>
                <li style={{ listStyleType: 'disc' }}> "Warehousing Structures-An optimal approach", Jain A.K.and <b>K.K.Shukla</b>, Indian Concrete
                    Journal (2012).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Nonlinear dynamic response of laminated composite plates subjected to pulse loading" Upadhyay,
                    A.K.,
                    Pandey, R., and <b>K.K.Shukla</b>, Communications in Nonlinear Sc. Numerical Simulation, Vol. 16,
                    4530-4544
                    (2011).
                </li>
                <li style={{ listStyleType: 'disc' }}> State of Art: Functional Electrical Stimulation (FES) by Bhatia D., Bansal G., Tewari R.P. and
                    <b>K.K.Shukla</b>,
                    Int. J. Biomedical Engineering &amp; Technology , Vol. 5, No.1, 77-79. (2011).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Flexural Analysis of laminated composite plates using thin plate spline radial basis function",
                    Singh J., Singh
                    S., and <b>K.K.Shukla</b>, J. Modeling and Simulation in Design and Engineering, Vol. 2, No. 1,
                    79-84 (2011).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Life cycle energy analysis of buildings: An overview", T.Ramesh, Ravi Prakash, <b>K.K.Shukla</b>,
                    Energy and
                    Buildings, 42(10), 1592-1600, (2010).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear Flexural Response of Laminated Composite Plates under Hygro-Thermo-Mechanical Loading by
                    Upadhyay, A.K, Pandey, R., and <b>K.K.Shukla</b>, Communications in Nonlinear Sc. Numerical
                    Simulation, 15(9),
                    2634-2650, (2010).
                </li>
                <li style={{ listStyleType: 'disc' }}>Hygrothermoelasticpostbuckling response of laminated composite plates by Pandey R., Upadhyay A.K.
                    and
                    <b>K.K.Shukla</b>, J. Aerospace Engg., ASCE , 23(1), 1-13, (2010).
                </li>
                <li style={{ listStyleType: 'disc' }}>Study of the role of muscles under different loading conditions using EMG analysis of lower
                    extremities,
                    Bhatia, D., Tewari, R.P., Ayub, S., <b>K.K.Shukla</b>, and Ansari, M.A., Advances in Applied Science
                    Research,
                    vol. 1(3), 118-128, (2010)
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear free vibration analysis of composite plates with material uncertainties: A Monte Carlo
                    simulation
                    approach by Singh B.N., Bist A.K., Pandit M.K., <b>K.K.Shukla</b>, J. Sound &amp; Vibration,
                    324(1-2) 2009 (2009).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermoelastic stability analysis of laminated composite plates: An analytical approach by Pandey
                    R., <b>K.K.Shukla</b> and Jain A., Communications in Nonlinear Sc. Numerical Simulation, 14,
                    1679-1699 (2009).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Determination of activity of significant muscle groups for lower limb exercise", Bhatia, D.,
                    Bansal, G.,
                    Tewari, R.P., and <b>K.K.Shukla</b> Indian Journal of Biomechanics: Special Issue, NCBM, IIT Roorkee
                    (2009).
                </li>
                <li style={{ listStyleType: 'disc' }}> Second order statistics of natural frequencies of smart laminated composite plates with random
                    material
                    properties by Singh B.N., Umrao A. and <b>K.K.Shukla</b>, Smart Structures and Systems: An Int.
                    Journal, 4(1)
                    (2008).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear flexural analysis of laminated composite plates by Pandey R., Shukla K.K. and Jain A.,
                    Int. J.
                    Applied Mechanics &amp; Engineering, 13(3), 707-733 (2008).
                </li>
                <li style={{ listStyleType: 'disc' }}> Postbuckling response of functionally graded rectangular plates subjected to thermo-mechanical
                    loading
                    by <b>K.K.Shukla</b>, Ravi Kumar K.V., Pandey R. and Nath Y., Int. J. Structural Stability and
                    Dynamics, 7(3), 519-
                    541 (2007).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Thermoelastic Buckling Characteristics of Angle-Ply Laminated Elliptical Cylindrical Shells",
                    Patel, B.P.,
                    <b>K.K.Shukla</b>, and Nath, Y., Composite Structures, 77(1), 120-124 (2007)
                </li>
                <li style={{ listStyleType: 'disc' }}> Postbuckling analysis of functionally graded rectangular plates by Wu T.L., <b>K.K.Shukla</b>and
                    Huang J.H.,
                    Composite Structures, 81(1), 1-10 (2007).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear thermo-elastic buckling characteristics of cross-ply laminated joined conical-cylindrical
                    shells by
                    Patel B.P., Nath Y. and <b>K.K.Shukla</b>, Int. J. Solids and Structures, 43, 4810-4829 (2006).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear thermoelastic stability characteristics of cross-ply laminated oval cylindrical/conical
                    shells by Patel
                    B.P., <b>K.K.Shukla</b>. and Nath Y., Finite Elements in Analysis and Design, 42, 1061-1070 (2006).
                </li>
                <li style={{ listStyleType: 'disc' }}> Free vibrations of laminated composite conical panels with random material properties byTripathi
                    V., Singh
                    B.N. and <b>K.K.Shukla</b>, Composite Structures, 81(1), 96-104 (2007).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear stability and dynamics of laminated composite plates and shells by Singh S., Sharma A.,
                    Patel B.P.,
                    <b>K.K.Shukla</b> and Nath Y., Vibration Problems: Springer, 415-427 (2007).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear static and dynamic analysis of functionally graded plates by Wu T.L., <b>K.K.Shukla</b>
                    and Huang J.H.,
                    Int.J. Applied Mechanics &amp; Engineering, 11(3), 679-698 (2006).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermal postbuckling analysis of laminated cross-ply truncated circular conical shells by Patel
                    B.P., <b>K.K.Shukla</b> and Nath Y., Composite Structures, 71(1), 101-114 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermal postbuckling characteristics of laminated conical shells with temperature dependent
                    properties, by
                    Patel B.P., <b>K.K.Shukla</b> and Nath Y., AIAA Journal, 43(6), 1380-1388 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermo-elastic stability behaviour of laminated cross-ply elliptical shells by Patel B.P.,
                    <b>K.K.Shukla</b> and Nath
                    Y., Structural Engineering and Mechanics, 19(6), 749-755 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Postbuckling of cross-ply laminated rectangular plates containing short random fibresby Huang J.H.
                    and <b>K.K.Shukla</b>, Composite Structures, 68(3), 255-265 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Buckling of laminated composite rectangular plates byShukla K.K., Nath Y., Kreuzer E. and Sateesh
                    K.V., J.
                    Aerospace Engg., ASCE, 18(4), 215-223 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Buckling and transient behaviour of layered composite plates under thermomechanical loading by
                    <b>K.K.Shukla</b>,
                    Nath Y. and Kreuzer E., ZAMM, 85(3), 163-175 (2005).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermal postbuckling of laminated composite plates with temperature dependent properties by
                    <b>K.K.Shukla</b>,
                    Huang J.H., Nath Y., J. Engineering Mechanics, ASCE, 130(7), 818-825 (2004).
                </li>
                <li style={{ listStyleType: 'disc' }}> Thermal buckling of laminated cross-ply oval cylindrical shells by Patel B.P., <b>K.K.Shukla</b>,
                    Nath Y.,
                    Composite Structures, 65(2), 217-229 (2004).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear dynamic analysis of composite laminated plates containing spatially oriented short
                    fibresby <b>K.K.Shukla</b>, Chen J.M. and Huang J.H., Int. Journal of Solids and Structures, 41(2),
                    365-384 (2004).
                </li>
                <li style={{ listStyleType: 'disc' }}> Buckling of laminated composite rectangular plates under transient thermal loading by
                    <b>K.K.Shukla</b> and Nath
                    Y., Journal of Applied Mechanics, ASME, 69(5), 684-692 (2002).
                </li>
                <li style={{ listStyleType: 'disc' }}>Thermomechanical postbuckling of cross-ply laminated rectangular plates by <b>K.K.Shukla</b> and
                    Nath Y., J.
                    Engineering Mechanics, ASCE, 128(1), 93-101 (2002).
                </li>
                <li style={{ listStyleType: 'disc' }}>Analytical solution for buckling and postbuckling of angle-ply laminated composite plates under
                    thermomechanical laodingby <b>K.K.Shukla</b>and Nath Y., International Journal of Nonlinear
                    Mechanics, 36(7),
                    1097-1108 (2001).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear transient analysis of moderately thick laminated composite plates byNath Y. and
                    <b>K.K.Shukla</b>,
                    Journal of Sound and Vibration, 247(3), 509-526 (2001).
                </li>
                <li style={{ listStyleType: 'disc' }}> Postbuckling of angle-ply laminated plates under thermal loading byNath Y. and <b>K.K.Shukla</b>,
                    Communications in Nonlinear Sciences and Numerical Simulation, 6(1), 1-16 (2001).
                </li>
                <li style={{ listStyleType: 'disc' }}> Postbuckling of cross-ply laminated rectangular plates under in-plane thermal loading by Nath Y.
                    and <b>K.K.Shukla</b>, Int. J. Nonlinear Science and Engineering, 1, 1-16 (2001).
                </li>
                <li style={{ listStyleType: 'disc' }}> Nonlinear analysis of moderately thick laminated rectangular plates by <b>K.K.Shukla</b> and Nath
                    Y., J.
                    Engineering Mechanics, ASCE, 126(8), 831-838 (2000).
                </li>
                <li style={{ listStyleType: 'disc' }}> "Parametric study on the vibration of cylindrical shell on an elastic foundation", Paliwal D.N.,
                    Pandey R.K. and
                    <b>K.K.Shukla</b>, J. of Structural Engineering, 26(2), 149-153 (1999).

                </li>
            </ol> */}



        </div>

    )
}
