import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "react-icons-kit";
import { handORight } from "react-icons-kit/fa/handORight";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import Carousel from "./Carousel";
import { Row, Skeleton, Column } from "antd";
import BlockWrapper, { ContentWrapper, ImageWrapper } from "./Notice.style";
import { getRequest } from "../../../functions/request";
import Tags from "../../../Components/Tags";

const NoticeBlock = ({ fontsize }) => {
  const { t } = useTranslation();
  const [notices, setNotice] = useState([]);

  useEffect(() => {
    const noticeHandler = async () => {
      try {
        const res = await getRequest(`/notices/landing?limit=20`);
        setNotice(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    noticeHandler();
  }, []);

  var settings = {
    dots: true,               
    speed: 3000,               
    slidesToShow: 2.5,           
    infinite: false,          
    slidesToScroll: 2,         
    autoplay: true,           
    autoplaySpeed: 5000,       
    arrows: false,             
    vertical: true,            
};
  const Notices = notices.map((item, idx) => {
    return {
      id: idx,
      text: item.title,
      link: item.path,
      notification_for:item.notification_for
    };
  });
  return (
    <BlockWrapper id="ourCommunity">
      <Container width="90%">
        <div className="row">

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-3">
            <ImageWrapper>
              <Carousel />
            </ImageWrapper>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3 mt-md-0">
            <ContentWrapper>
              <Heading
                content={t("home_notice")}
                style={{
                  fontSize: "1.5em",
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "500",
                  background: "#274187",
                  color: "#fff",
                  padding: "0 0 0 3%",
                }}
              />
              {!Notices.length && (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              )}
              <Slider {...settings} style={{ backgroundColor: "#fff" }}>
                {Notices.map((item, id) => (
                  <div key={`list_key${id}`}>
                    <div className="row" >
                      <div className="col-1">
                        <Icon icon={handORight} />
                        &nbsp;&nbsp;
                      </div>
                      <div className="col">
                        <p
                          style={{
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: fontsize,
                            marginTop:"20px"
                          }}
                        >
                          <a
                            href={item.link}
                            style={{ textDecoration: "none" }}
                            target="blank"
                          >
                            {item.text}
                            <Tags tags={item.notification_for}/>
                            
                          </a>
                        </p>
                        
                      </div>

                    </div>
                  </div>
                ))}
              </Slider>
            </ContentWrapper>
            <div className="mt-4">
              <Link to="/Notices/Announcements" className="learn__more-btn">
                {" "}
                <span className="hyphen" />
                <span className="btn_text">{t("show_more_button")}</span>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </BlockWrapper>
  );
};

export default NoticeBlock;
