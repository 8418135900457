import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Toolbar, Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Configurations from "./Sections/Configurations";
import CCHome from "./Sections/Home";
import ContactUs from "./Sections/ContactUs";
import Downloads from "./Sections/Downloads";
import People from "./Sections/People";
import Services from "./Sections/Services";
import { mainTheme } from "../../../../theme/main";
import { ThemeProvider } from "styled-components";

const ComputerCenter = () => {
  const { t } = useTranslation();
  const [activeComp, setActiveComp] = useState("Home");

  const sections = {
    Home: CCHome,
    People: People,
    Services: Services,
    Configurations: Configurations,
    Downloads: Downloads,
    ContactUs: ContactUs,
  };

  const ActiveSection = sections[activeComp];

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>{t("Computer Center")}</title>
      </Helmet>

      {/* Blue Navbar */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#274187",
          maxHeight: 50,
          // padding: '5px',
          marginTop: "15px",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        <Container
          sx={{
            width: "100%",
            marginTop: "-5px",
            marginBottom: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Toolbar>
            <Button color="inherit" onClick={() => setActiveComp("Home")}>
              Home
            </Button>
            &nbsp;
            <Button color="inherit" onClick={() => setActiveComp("People")}>
              People
            </Button>
            &nbsp;
           
            {/* <Button
              color="inherit"
              onClick={() => setActiveComp("Configurations")}
            >
              Configurations
            </Button>
            &nbsp; */}
            {/* <Button color="inherit" onClick={() => setActiveComp("Downloads")}>
              Downloads
            </Button>
            &nbsp; */}
            <Button color="inherit" onClick={() => setActiveComp("ContactUs")}>
              Contact
            </Button>
            <Button color="inherit" onClick={() => setActiveComp("Services")}>
              Services
            </Button>
            &nbsp;
          </Toolbar>
        </Container>
      </AppBar>

      <Container>
        <ActiveSection />
      </Container>
    </ThemeProvider>
  );
};

export default ComputerCenter;
