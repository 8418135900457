import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Helmet from 'react-helmet';
import Container from "../../../Components/UI/Container";
import Text from "../../../Components/Text";
import Heading from "../../../Components/Heading";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { getRequest } from "../../../functions/request";

function StudentStatistics({ fontsize }) {
  const [studentStatsPG, setStudentStatsPG] = useState([]);
  const [studentStatsUG, setStudentStatsUG] = useState([]);

  useEffect(() => {
    (async () => {
      getRequest("/files/student_statistics.json", false).then((res) => {
        if (res.data.length > 0) {
          setStudentStatsPG(res.data[0].Post_Graduate);
          setStudentStatsUG(res.data[0].Under_Graduate);
        }
      });
    })();
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Statistical Representation of students intake in the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,students,admission,seats in nit jsr,seats"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("student_statistics_title")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <Text content={t("student_statistics_para_pg")} />
        <TableWrapper>
          <table className="table textCenter">
            <thead>
              <tr>
                <th>{t("statistics_thead_tr1")}</th>
                <th>{t("statistics_thead1_tr2")}</th>
                <th>{t("statistics_thead1_tr3")}</th>
                <th>{t("statistics_thead1_tr4")}</th>
              </tr>
            </thead>
            {studentStatsPG.map((item) => (
              <tr>
                <td style={{ fontSize: fontsize }} data-column="Batch">
                  {item.batch}
                </td>
                <td style={{ fontSize: fontsize }} data-column="MCA">
                  {item.data["MCA"]}
                </td>
                <td style={{ fontSize: fontsize }} data-column="MTech">
                  {item.data["MTech"]}
                </td>
                <td style={{ fontSize: fontsize }} data-column="Ph.D">
                  {item.data["Ph.D"]}
                </td>
              </tr>
            ))}
          </table>
        </TableWrapper>
        <Text content={t("student_statistics_para_ug")} />
        <TableWrapper>
          <table className="table textCenter">
            <thead>
              <tr>
                <th>{t("statistics_thead2_tr1")}</th>
                <th>Civil</th>
                <th>CSE</th>
                <th>ECE</th>
                <th>MECH</th>
                <th>META</th>
                <th>MFE</th>
                <th>EEE</th>
              </tr>
            </thead>
            {studentStatsUG.map((item) => (
              <tr>
                <td style={{ fontSize: fontsize }} data-column="Batch">
                  {item.batch}
                </td>
                <td style={{ fontSize: fontsize }} data-column="Civil">
                  {item.data.Civil}
                </td>
                <td style={{ fontSize: fontsize }} data-column="CSE">
                  {item.data.CSE}
                </td>
                <td style={{ fontSize: fontsize }} data-column="ECE">
                  {item.data.ECE}
                </td>
                <td style={{ fontSize: fontsize }} data-column="MECH">
                  {item.data.MECH}
                </td>
                <td style={{ fontSize: fontsize }} data-column="META">
                  {item.data.META}
                </td>
                <td style={{ fontSize: fontsize }} data-column="MFE">
                  {item.data.MFE}
                </td>
                <td style={{ fontSize: fontsize }} data-column="EEE">
                  {item.data.EEE}
                </td>
              </tr>
            ))}
          </table>
        </TableWrapper>
      </Container>
    </>
  );
}

export default StudentStatistics;
