import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const itemData = [
    
    {
      img: 'https://i.ibb.co/c65L2hf/director-Gallary2.jpg',
      title: 'directorGallery2',
    },
    {
        img: 'https://i.ibb.co/NZLgbQg/director-Gallary1.jpg',
        title: 'directorGallery1',
      },
    
  ];

export default function PhotoGallery() {
  return (
    <ImageList  cols={2}  >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 4x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}


