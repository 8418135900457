import React from 'react';
import { LoaderWrapper } from '../../assets/css/Custom/LoaderStyle';
function MainLoader() {
  return (
    <LoaderWrapper>
      <div id="preloader">
        <div id="loader"></div>
      </div>
    </LoaderWrapper>
  );
}

export default MainLoader;
