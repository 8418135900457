import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container/index";
import Heading from "../../../Components/Heading/index";
import { Icon } from "react-icons-kit";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
function IPR({ fontsize }) {
  const {t} = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("cells_top_subtitle_ipr")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: "center", fontSize: fontsize }}
        >
          {t("ipr_para")}
        </p>
      </SectionWrapper>

      <Heading
        content={t("cells_top_subtitle")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <SectionWrapper>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023//IPR/Office Order No. 199-2023, IPR Cell is constituted to suggest and support the functions IPR and Patent filling.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />&nbsp;&nbsp;
            Office Order No. 199-2023, IPR Cell is constituted to suggest and support the functions IPR and Patent filling
            {/* &nbsp;&nbsp; {t("sc-st_para_li1")} */}
          </p>
        </a>
        </SectionWrapper>
      {/* <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: "center", fontSize: fontsize }}
        >
          {t("default_para")}
        </p>
      </SectionWrapper> */}

    </Container>
  );
}

export default IPR;
