import React, { Fragment, useEffect, useState } from "react";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Minutes from "../../../Components/Minutes/minutes";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";
import { getRequest } from "../../../functions/request";

function BoardofGoverners() {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [loadingMemeber, setLoadingMemeber] = useState(true);



  useEffect(() => {

    const memberHandler = async () => {
      try {
        let res = await getRequest("administration?type=bog");
        console.log(res.data)
        setMembers(res.data.data);
        setLoadingMemeber(false);
      } catch (err) {
        console.log(err);
      }
    }

    const minutesHandler = async () => {
      try {
        getRequest("minutes?type=bog").then((res) => res.data.results)
          .then((res) =>
            res.map((e) => ({
              name: e.heading,
              link: `${e.PATH}`,
            }))
          )
          .then((res) => {setMinutes(res); console.log(res)})
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      } catch (err) {
        console.log(err);
      }
    }

    memberHandler();
    minutesHandler();

  }, []);
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="The Board of Governors of the National Institute of Technology (NIT) - Jamshedpur (Jharkhand),Chairman,Ex Officio Member,Member,BOG Members,Powers of Board,Powers of The Chairperson, Board of Governors" />
          <meta
            name="keywords"
            content="nitjsr,nit,jsr,jamshedpur,dean,office,board-of-governors,technology,director,about us,services"
          ></meta>
        </Helmet>
        <br />
        {/* <Heading {...blueHeading} content="Chairman" />
        <TableWrapper>
          <table className="table">
            <tr>
              <td style={{fontSize:fontsize}} data-column="Sl.No.">Director,NIT Jamshedpur</td>
              <td style={{fontSize:fontsize}} data-column="Issue Date">Prof. Karunesh Kumar Shukla</td>
              <td style={{fontSize:fontsize}} data-column="Valid Upto">
                director@nitjsr.ac.in
                <br />
                06572374108
              </td>
            </tr>
          </table>
        </TableWrapper> */}
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("heading_board_of_members")}
        />
        {loadingMemeber && <Skeleton count={10} />}
        {!loadingMemeber && (
          <>
            {/* <TableWrapper>
            <table className="table">
              {members.map(element => (
                <tr key={element.id}>
                  <td style={{ fontSize: fontsize }}>{element.name}</td>
                  <td style={{ fontSize: fontsize }}>{element.designation}</td>
                  <td style={{ fontSize: fontsize }}>
                    {element.email}
                    <br />
                    {element.contact}
                  </td>
                </tr>
              ))}
            </table>
          </TableWrapper> */}
            <SectionWrapper>
              {
                members.length === 0 ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px', boxShadow: '1px 2px 10px lightgray', background: '#fff' }}>{t("No_Data_Available")}</div>
                  :
                  <>
                    <div className="member_container">
                      <h3>{t("head_ex_chairman")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box" style={{ maxWidth: '100%' }}>
                          <p><b>{members[0].name ? t(members[0].name) : t('Loading...')}</b></p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_ex_member")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box" style={{ maxWidth: '100%' }}>
                          <p><b>{members[15].name ? t(members[15].name) : t('Loading...')}</b></p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_MoE_GoI")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b>{members[1].name ? t(members[1].name) : t('Loading...')}</b></p>
                          <p>{t(members[1].designation)}</p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[2].name ? t(members[2].name) : t('Loading...')}</b></p>
                          <p>{t(members[2].designation)}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_NIT_Council_Nominee")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b>{members[3].name && t(members[3].name)} </b></p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[4].name && t(members[4].name)} </b></p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_state_govt_nominee")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b></b></p><br/>
                          <p>{t("member11_details")}</p>
                          <p>{t("member11_details2")}</p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[12].name ? t(members[12].name) : t('Loading...')}</b></p>
                          <p>{t("member12_details")}</p>
                          <p>{t("member12_details2")}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_member")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b>{members[13].name ? t(members[13].name) : t('Loading...')}</b></p>
                          <p>{t(members[13].designation)}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="member_container">
                      <h3>{t("head_senate_nominee")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b>{members[5].name ? t(members[5].name) : t('Loading...')}</b></p>
                          <p>{t("senate_details")}</p>
                          <p>{t("senate_details1")}</p>
                          <p>{t("senate_details2")}</p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[6].name ? t(members[6].name) : t('Loading...')}</b></p>
                          <p>{t("ass-pro")}</p>
                          <p>{t("me_dept")}</p>
                          <p>{t("senate_details2")}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* <div className="member_container">
                      <h3>{t("head_members")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                          <p><b>{members[7].name ? t(members[7].name.slice(0, 31)) : t('Loading...')}</b></p>
                          <p>{t("member1_details")}</p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[8].name ? t(members[8].name.slice(0, 20)) : t('Loading...')}</b></p>
                          <p>{t("member2_details")}</p>
                        </div>
                        <div className="member_box">
                          <p><b>{members[9].name ? t(members[9].name.slice(0, 8)) : t('Loading...')}</b></p>
                          <p>{t("member3_details")}</p>
                        </div>
                      </div>
                    </div>
                    <hr /> */}
                    <div className="member_container">
                      <h3>{t("heading_secretary")}</h3>
                      <div className="bar_container"><div id="left_bar"></div><div id="middle_bar"></div><div id="right_bar"></div></div>
                      <div className="members">
                        <div className="member_box">
                        <p><b>{members[10].name ? t(members[10].name) : t('Loading...')}</b></p>
                          <p>{t("secretary_details")}</p>
                          <p>{t("senate_details2")}</p>
                        </div>
                      </div>
                    </div>
                  </>
              }

              <hr />
            </SectionWrapper>
          </>
        )}
        <hr />
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("heading-min_of_meet")}
        />
        {loading && <Skeleton count={5} />}
        {!loading && <Minutes minutes={minutes} />}
      </Container>
    </Fragment>
  );
}

export default BoardofGoverners;
