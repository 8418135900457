import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { LongPara, TableContainer, TableWrapper } from '../styles';
import { getRequest } from '../../../functions/request';

const MedicalFacilities = () => {
	const { t } = useTranslation();
	const [dispensaryData, setDispensaryData] = useState([]);

	const dispensaryHandler = async () => {
		try {
			const res = await getRequest(`/central_facilities?facility=dispensary`);
			setDispensaryData(res.data);
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		dispensaryHandler();
	}, []);

	return (
		<Container width="1250px">
			<br />
			<LongPara>
				{t("central_facilities_hospital_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_hospital_dispensary_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>

			<LongPara>
				{t("central_facilities_hospital_dispensary_body")}
				<LongPara>{t("central_facilities_hospital_dispensary_staff_body")} </LongPara>
				<TableContainer>
					<TableWrapper>
						<table>
							<thead>
								<tr id="headRow">
									<th>{t("central_facilities_table_key_1")}</th>
									<th>{t("central_facilities_table_key_2")}</th>
									<th>{t("central_facilities_table_key_3")}</th>
									<th>{t("central_facilities_table_key_4")}</th>
								</tr>
							</thead>
							<tbody>
								{dispensaryData.map((person) => (
									<tr>
										<td>{person.designation}</td>
										<td>
											{person.preName} {person.fname} {person.lname}
										</td>
										<td>{person.phoneNumber}</td>
										<td>{person.email}</td>
									</tr>
								))}
							</tbody>
						</table>
					</TableWrapper>
				</TableContainer>
			</LongPara>
			<Heading
				content={t("central_facilities_hospital_city_hospital_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_hospital_city_hospital_body")}
				<ol>
					<li style={{ listStyleType: 'number' }}>
						<LongPara>{t("central_facilities_hospital_city_hospital_list_1")}</LongPara>
					</li>
					<li style={{ listStyleType: 'number' }}>
						<LongPara>{t("central_facilities_hospital_city_hospital_list_2")}</LongPara>
					</li>
					<li style={{ listStyleType: 'number' }}>
						<LongPara>{t("central_facilities_hospital_city_hospital_list_3")}</LongPara>
					</li>
					<li style={{ listStyleType: 'number' }}>
						<LongPara>{t("central_facilities_hospital_city_hospital_list_4")}</LongPara>
					</li>
					<li style={{ listStyleType: 'number' }}>
						<LongPara>{t("central_facilities_hospital_city_hospital_list_5")}</LongPara>
					</li>
				</ol>
				<LongPara>
					{t('central_facilities_hospital_city_hospital_foot')}{' '}
				</LongPara>
			</LongPara>

			<Heading
				content={t("central_facilities_hospital_pathological_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>

			<LongPara>
				{t("central_facilities_hospital_pathological_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_hospital_students_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>

			<LongPara>
				{t("central_facilities_hospital_students_body")}
			</LongPara>

			<Heading
				content={t("central_facilities_hospital_referral_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_hospital_referral_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_hospital_visitingdoctorrs_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_hospital_visitingdoctorrs_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_hospital_ambulance_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_hospital_ambulance_body")}
				<LongPara>Phone: +91-657-237-4226</LongPara>
			</LongPara>
		</Container>
	);
};

export default MedicalFacilities;
