import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { CharityWrapper } from "../../../assets/css/Main/main.style";
import { data } from "../../Home/CurrentAchievement/data";
import { Grid } from "@mui/material";
import CurrentAchvCard from "../../../Components/CurrentAchvCard";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { SliderWrapper } from "../../Home/ResearchSection/ResearchSection.style";

const Achievements = () => {
    const [achievers, setAchievers] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const getAchievers = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/ourachievers/get`
        );
        const dataRe = await response.json();
        console.log(dataRe);
    
        return dataRe;
      };
    useEffect(() => {
        setLoading(true);
        getAchievers().then((datas) => {
          setAchievers(datas.data[0].content);
          console.log(datas.data[0].content);
          console.log(datas);
          setLoading(false);
        });
        setLoading(false);
      }, []);
    return ( 
        <Container width='1250px'>
            <Helmet>
                <meta name="keywords" content="QUNITE, NIT Jamshedpur, the official quiz club of the institute,quiz,business quiz,sports quiz,entertainment quiz,Tata Crucibles,student achievement,nit jsr,nit jamshedpur,achievements"/>
                {/* <meta name="description" content=""/> */}
            </Helmet>
            <br/>
            <Heading
                content="Student Achievements"
                style={{
                fontSize: '1.5em',
                marginBottom: '20px',
                marginTop: '13px',
                fontWeight: '500',
                background: '#274187',
                color: '#fff',
                padding: '1% 1% 1% 3%',
                }}
            />


                <CharityWrapper>

                    {/* <Container width={data.length >= 3 ? "90%" : "60%"}> */}

                    <Container width="100%">
            
                    {/* <SliderWrapper> */}
                        <Grid container spacing={5}>
                        {data.map((item, key) => (
                            <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            >
                            <CurrentAchvCard
                                key={`event_card_${key}`}
                                item={item}
                                fontsize={6}
                            />
                            </Grid>
                        ))}
                        </Grid>
                    {/* </SliderWrapper> */}
                    {data.length === 0 && (
                        <div>
                        <div className="singleCard text-center">
                            <p style={{ fontSize: 12 }} className="desc">
                            No data found
                            </p>
                        </div>
                        </div>
                    )}
                    <div
                        className="mt-4"
                        style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        }}
                    ></div>
                    </Container>
                {/* </section> */}
                </CharityWrapper>
        </Container>
     );
}
 
export default Achievements;