import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
// import { useTranslation } from 'react-i18next';
import {
  ParagraphWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import { Helmet } from 'react-helmet';

function MscAdmission() {
  // const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="CCMN-2023 Centralized Counseling for M.Sc.-2023" />
          <meta name="keywords" content="admission, mtech,nit jamshedpur,nit jsr,nit,jamshedpur,goals,student wellness center, map,reach, cc,guest,vision, mission,objectives,technology,Director,schorlarships,stafflist,facility,rti, right to informations,output Indicators,Indicators..." />
        </Helmet>
        <br />
        <Heading
          content="Maters PROGRAMMES AT NIT JSR"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <div>
        <ol>
          <li>● Master of Science (M.Sc.) in Mathematics - [Intake-37]</li>
          <li>● Master of Science (M.Sc.) in Physics - [Intake-38]</li>
          <li>● Master of Science (M.Sc.) in Chemistry - [Intake-37]</li>
          <li>● Master of Computer Application - [Intake-115]</li>
        </ol>
        </div>
        <Heading
          content="CCMN-2023 Centralized Counseling for M.Sc.-2023"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paargraph">
            CCMN is the platform for candidates to apply for M.Sc. based on their JAM score of year 2023, in NITs, IIEST Shibpur, IIITs and some CFTIs. For details, please refer to the CCMN website. The centralized system is maintained by NIC, Govt. of India to provide a common and convenient platform for online counselling wherein the candidates can fill-in online application from their home and apply to all programmes in all the participating institutions (PIs) to which they are eligible.
          </p>
          <p>
            CCMN Website Link --- <a href="https://ccmn.admissions.nic.in/">https://ccmn.admissions.nic.in/</a>
          </p>
        </ParagraphWrapper>
        <Heading
          content="Important Links"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <div className="paragraph">
          <ul>
            <li><a href="https://admissions.nic.in/CCMT/ccmnapplicant/Root/Home.aspx?enc=yVQCIiq12npg+pcvNJRdcw0qIktNUmQPeVyFi1PZDuozeEroP50PRdzbnCfYxgPk">● Registration, Fee Payment and Choice Filling for CCMN 2023 Counselling</a></li>
            <li><a href="https://admissions.nic.in/ccmt/ccmnapplicant/report/SeatMatrixForm_CC.aspx?enc=Nm7QwHILXclJQSv2YVS+7o4ScUXAXJWv0g4Gbtuezvn961SVQRvFLiUC5AXn66h7">● Seat Matrix 2023</a></li>
            <li><a href="https://admissions.nic.in/ccmt/ccmnapplicant/report/ViewSpecialEligibility.aspx?enc=Nm7QwHILXclJQSv2YVS+7o4ScUXAXJWv0g4Gbtuezvn961SVQRvFLiUC5AXn66h7">● View Special Eligibility/ Restrictions</a></li>
            <li><a href="https://admissions.nic.in/ccmt/ccmnapplicant/report/SeatMapping.aspx?enc=Nm7QwHILXclJQSv2YVS+7o4ScUXAXJWv0g4Gbtuezvn961SVQRvFLiUC5AXn66h7">● View Mapping of [Institute, Department, Program] VS [JAM Paper & Qualifying Degree]</a></li>
            <li><a href="https://admissions.nic.in/ccmt/ccmnapplicant/report/ViewPIBalanceFeeList.aspx?boardid=138012321">● Participating Institutes Fee Details</a></li>
            <li><a href="https://ccmn.admissions.nic.in/document/view-faq/">● View FAQ</a></li>
          </ul>
        </div>

        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content="Contact"
        />
        <ParagraphWrapper>
          <div style={{ textAlign: 'justify' }} className="paragraph">
            <ul>
              <li>Dr. Satish Kumar - <span className="fa fa-phone"></span>&nbsp;+91-9417923801</li>
              <li>Dr. Surajit Kundu - <span className="fa fa-phone"></span>&nbsp;+91-9832271039</li>
            </ul>
          </div>
        </ParagraphWrapper>
      </Container>
    </Fragment>
  );
}

export default MscAdmission;
