import React from "react";

export default function ResearchAreaSection() {
  return (
    <div className="panel-body body1">
      <ol>
        <li style={{ listStyleType: "disc" }}>
          V.V. Satyamurthy and <b>R.V. Sharma</b>, Influence of Variable Density
          on Natural Convection in Gas-Filled Rectangular Enclosure, In
          Proceedings of 3rd ISHMTASME and 14th National Heat and Mass Transfer
          Conference, pp.463-468, I.I.T. Kanpur, 1997.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          V.V. Satyamurthy and <b>R.V. Sharma</b>, Effect of Non-Darcy Flow on
          Steady ThreeDimensional Natural Convection in Cubic Porous Enclosure,
          In Proceedings of 4th ISHMT-ASME and 15th National Heat and Mass
          Transfer Conference, pp.545- 550, Institute of Armament Technology
          Pune, 2000.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b> and Brajesh Tripathi, Influence of Property
          Variation on Natural Convection in a Gas Filled Rectangular Enclosure,
          In Proceedings of 5th ISHMTASME and 16th National Heat and Mass
          Transfer Conference, Science City , Kolkota, 2002.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b>, R. P. Sharma and M. N. Verma, Numerical Study on
          Three Dimensional Natural Convection in a Porous Box In Proceedings of
          International Symposium on Recent Trends in Heat and Mass Transfer,
          Indian Institute of Technology Guwahati, January 6-8, 2002.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b> and R.P. Sharma, Numerical Simulation of
          Three-Dimensional Natural Convection in a Porous Box, In Proceedings
          of 30th National Conference on Fluid Mechanics & Fluid Power, pp.
          404-410, National Institute of Technology Karnataka, Surathkal, 2003.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b>, N. Kumar and R. P. Sharma, Natural Convection in
          an Inclined Rectangular Layered Porous Cavity, In Proceedings of 7th
          ISHMT-ASME and 18th National Heat and Mass Transfer Conference,
          pp.352-357, Indian Institute of Technology Guwahati, 2006.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b> and R. P. Sharma, Non-Darcy Effects on
          Three-Dimensional Natural Convection in a Porous Box, In Proceedings
          of 13th International Heat Transfer Conference, Sydney , Australia,
          13-18 August 2006.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b> and Anil Kumar Mishra, Three-Dimensional Natural
          Convection in a Porous Box, In Proceedings of National Conference on
          Advances in Heat Transfer and Fluid Dynamics , AMU , Aligarh,
          September 16-17, 2006.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b> and Mohd. Atiq Ansari, Natural Convection in
          Cylindrical Porous Cavity Heated from Below, Indo Australian Workshop
          and Symposium on CFD Applications, I.I.T. Roorkee, April 12-14,2007.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          . <b>R.V. Sharma</b> and Arunav Gogoi, Effects of Anisotropy on
          Natural Convection in a Rectangular Cavity, In Proceedings of 8 th
          ISHMT-ASME and 19th Heat and Mass Transfer Conference, JNTU Hyderabad,
          2008.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V.Sharma</b>, A. K. Mishra and Sasmita Saha, Numerical simulation
          of three dimensional natural convection in a porous box heated from
          below, In Proceedings of 20th National and 9th International
          ISHMT-ASME Heat and Mass Transfer Conference, January 4-6, 2010,
          Mumbai, India.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          . S. Saha and <b>R.V. Sharma</b>, Natural Convection in a Gas Filled
          Vertical Cylindrical Porous Annuli with Property Variation, In
          Proceedings of National Conference on Modeling and Simulation in Heat
          Transfer and Fluid Flow, July 9-10, 2010, N.I.T. Jamshedpur
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Asghar Mahfooz Alam, M.K. Sinha and <b>R.V. Sharma</b>, Numerical
          Analysis of Natural Convection in Spherical Porous Annuli, In
          Proceedings of National Conference on Modeling and Simulation in Heat
          Transfer and Fluid Flow, July 9- 10, 2010, N.I.T. Jamshedpur.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V. Sharma</b>, Influence of Property Variation on Natural
          Convection in a Cylindrical Porous Annulus, In proceedings of 9th
          International Conference on Heat Transfer, Fluid Mechanics and
          Thermodynamics, July 16-18, 2012, Malta
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K. Sinha and <b>R.V.Sharma</b>, Numerical Simulation of
          Natural Convection in a Spherical Porous Annulus, In Proceedings of
          International Congress on Computational Mechanics and Simulation,
          December 9-12, 2012, I.I.T. Hyderabad.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Somenath Gorai, K.D.P. Singh and <b>R.V.Sharma</b>, Numerical
          Simulation of Natural Convection in an Inclined Porous Cavity with
          Internal Heat Generation, In Proceedings of 22nd National and 11th
          International ISHMT-ASME Heat and Mass Transfer Conference, December
          28-31, I.I.T. Kharagpur, 2013.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          A.K.Mishra, S.Kumar and <b>R.V.Sharma</b>, Numerical Simulation of
          ThreeDimensional Natural Convection in a Cuboid Box Containing Heat
          Generating Porous Medium, In the Proceedings of 10th International
          Conference on Heat Transfer, Fluid Mechanics and Thermodynamics, 14-16
          July 2014, Orlando, Florida, USA.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K.Sinha and <b>R.V.Sharma</b>, Numerical Simulation of
          Natural Convection in a Spherical Porous Annulus, In the Proceedings
          of 10th International Conference on Heat Transfer, Fluid Mechanics and
          Thermodynamics, 14-16 July 2014, Orlando, Florida, USA.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V.Sharma</b>, H.N.Singh, Ankita and Somnath Gorai, Numerical
          Simulation of Natural Convection Heat Transfer in Spherical Annulus,
          In Proceedings of 5th International and 41st National Conference on
          Fluid Mechanics and Fluid Power, I.I.T. Kanpur, December 12-14, 2014.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K.Sinha and <b>R.V.Sharma</b>, Effect of a diathermal
          partition wall on natural convection in a spherical porous annulus, In
          Proceedings of 6th International Symposium on Advances in
          Computational Heat Transfer, May 25- 29, 2015, Rutgers University,
          Piscataway, USA.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          A.K.Mishra, S. Kumar and <b>R.V.Sharma</b>, Non-Darcy Effects on
          Steady ThreeDimensional Natural Convection in a Rectangular Box
          Containing Heat Generating Porous Medium, In Proceedings of 6th
          International Symposium on Advances in Computational Heat Transfer,
          May 25-29, 2015, Rutgers University, Piscataway, USA.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          <b>R.V.Sharma</b> and Raviranjan Kumar Singh, Numerical Simulation of
          Pyrolysis of a Cylindrical Wooden Dowel, In Proceedings of the 23rd
          National and 1st International ISHMT-ASTFE Heat and Mass Transfer
          Conference, 17-20 December, 2015, Thiruvananthapuram, India.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Raviranjan Kumar Singh and <b>R.V.Sharma</b>, CFD Simulation of Earth
          Air Tube Heat Exchanger with Longitudinal Fins, In Proceedings of
          Global Conference on Renewable Energy, 4-6 March, 2016, NIT Patna,
          India.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Gajendra Kumar, <b>R.V.Sharma</b> and R.K.Prasad, Design and CFD
          Analysis of an Earth Air Heat Exchanger, In proceedings of National
          Conference on Nonconventional Energy for Sustainable Growth, RVS
          College of Engineering and Technology, Jamshedpur, July 29-30, 2016.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Anil Kumar Mishra, Shalendra Kumar and <b>Ram Vinoy Sharma</b>,
          Influence of Property Variation on Natural Convection in a Cubic Box
          Filled with Heat Generating Porous Medium, In Proceedings of 7th
          International Symposium on Advances in Computational Heat Transfer,
          May 28-June 1, 2017, Napoli, Italy.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Raviranjan Kumar Singh and <b>Ram Vinoy Sharma</b>, Numerical
          Simulation of Ground Temperature Variation for Time Variant Boundary
          Condition, In Proceedings of 7th International Symposium on Advances
          in Computational Heat Transfer, May 28-June 1, 2017, Napoli, Italy.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Gajendra Kumar, <b>R.V.Sharma</b> and R.K.Prasad, CFD Analysis of an
          Earth Air Heat Exchanger, In Proceedings of the 24th National and 2nd
          International ISHMT-ASTFE Heat and Mass Transfer Conference, December
          27-30, 2017, BITS Pilani, Hyderabad, India.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordia Jayesh Subhash and <b>R.V.Sharma</b>, Natural convection in
          inclined rectangular porous enclosure with a vertical diathermal
          partition wall, In proceedings of National Conference on Renewable
          Energy and Environmental Challenges, February 26-28, 20018, N.I.T.
          Jamshedpur.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordia Jayesh Subhash and <b>R.V.Sharma</b>, Natural convection in
          square porous enclosure with diathermal partition wall, In proceedings
          of 5th International Conference on Computational Methods for Thermal
          Problems, July 9-11, 20018, IISc, Bangalore.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordia Jayesh Subhash and <b>R.V.Sharma</b>, Unsteady Natural
          convection in a fluid saturated square porous enclosure with a pair of
          L-shaped diathermal partition, In proceedings of International
          Conference on Mathematical Modelling and Scientific Computing, July
          19-21, 20018, I.I.T. Indore.
        </li>
      </ol>
    </div>
  );
}
