import React from "react";
// import Logo from "../../UIElements/Logo";
import Container from "../../UI/Container";
import { NavbarWrapper, Image } from "./navbar.style";
import { Link } from "react-router-dom";

import logoImage from "../../../assets/images/nitlogo.png";
//This navbar is for all pages other than the home
const Navbar = () => {
  return (
    <NavbarWrapper
      className="nav"
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        backgroundColor: "#fff",
      }}
    >
      <Container fullWidth={true}>
        <Link to="/">
          <Image
            className="img"
            style={{ maxHeight: "90px" }}
            alt="logo"
            src={logoImage}
          />
        </Link>

        {/* <Select
          onChange={(e) => {
            i18next.changeLanguage(e.target.value);
          }}
        >
          <option selected disabled>
            {i18next.language === "hi" ? "हिंदी" : "English"}
          </option>
          <option value="hi">हिंदी</option>
          <option value="en">English</option>
        </Select> */}
        {/* <MenuWrapper>
          <AnchorLink className="smooth_scroll" href="#donate" offset={81}>
            <span id="contact-nav">Contact Us</span>
          </AnchorLink>
          <Button>
            <span className="text">Community</span>
            <Image src={heartImage} alt="Charity Landing" />
          </Button>
        </MenuWrapper> */}
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
