import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import { TableContainer, TableWrapper } from "../../Facilities/styles";
import { getRequest } from "../../../functions/request";

const HostelDetails = () => {
  const { t } = useTranslation();
  const hostelId = new URLSearchParams(useLocation().search).get("type");
  const [hostelData, setHostelData] = useState([]);
  useEffect(() => {
    const hostelHandler = async () => {
      try {
        let res = await getRequest(`/warden/hostel?id=${hostelId}`);
        setHostelData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    hostelHandler();
  }, [hostelId]);

  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("central_facilities_contacts_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <TableContainer>
        <TableWrapper>
          <table>
            <thead>
              <tr id="headRow">
                <th>{t("central_facilities_table_key_1")}</th>
                <th>{t("central_facilities_table_key_2")}</th>
                <th>{t("central_facilities_table_key_3")}</th>
                <th>{t("central_facilities_table_key_4")}</th>
              </tr>
            </thead>
            <tbody>
              {hostelData.map((person) => (
                <tr>
                  <td>
                    <Link to={`/people/profile/${person.user_id}`}>
                      {person.post}
                    </Link>{" "}
                  </td>
                  <td>
                    {person.hostel_name} ({person.hostel_id})
                  </td>
                  <td>{person.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

export default HostelDetails;
