import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import PropTypes from "prop-types";
import Container from "../../../Components/UI/Container";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";
import Skeleton from "react-loading-skeleton";
import Select from "../../../Components/Select";
import { CurriculumData } from "../../../assets/data/CurriculumData";
import {useTranslation} from "react-i18next";
import Helmet from 'react-helmet';

function PdfComponent({ title, link }) {
  return (
    <a target="_blank" rel="noreferrer" href={link}>
      <p className="pdfLink">
        <Icon icon={chevronCircleRight} size={18} />
        &nbsp;&nbsp; {title}
      </p>
    </a>
  );
}

function Curriculum({ row, col, colOne, colTwo, fontsize }) {
  const [SingleData, setSingleData] = useState();
  useEffect(() => {
    setSingleData(CurriculumData[0]);
  }, []);
  const {t} = useTranslation();
  return (
    <>
   
    <Container width="99%">
      <br />
      <Select
        placeholder={t("Select Department")}
        options={CurriculumData}
        onChange={(id) => setSingleData(id)}
      />
      {SingleData ? (
        <>
        <Helmet>
        <meta
          name="description"
          content="Important notices regarding the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,notice,important notice,updates,alerts"
        />
        </Helmet>
          <Heading
            content={t(`${SingleData.label}`)}
            style={{
              fontSize: "1.5em",
              marginBottom: "15px",
              marginTop: "13px",
              fontWeight: "500",
              background: "#274187",
              color: "#fff",
              padding: "1% 1% 1% 3%",
            }}
          />
          <SectionWrapper>
            {SingleData.content.length ? (
              SingleData.content.map((item, index) => {
                return <PdfComponent title={t(`${item.title}`)} link={item.link} />;
              })
            ) : (
              <Skeleton count={10} />
            )}
          </SectionWrapper>
        </>
      ) : (
        <Skeleton count={10} />
      )}
    </Container>
    </>
  );
}

// HumanityBlock style props
Curriculum.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AboutUs default style
Curriculum.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Curriculum;
