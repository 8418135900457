import React from "react";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import Modal from "react-bootstrap/Modal";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { Image } from "antd";
import { Component } from "react";
import invalid_image from "../../../assets/images/invalid_image.png";
import { Helmet } from "react-helmet";
import { getRequest } from "../../../functions/request";

class StudentActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      projectName: "",
      show: false,
      activeItem: "", //new added
      data: [],
    };


    this.handleShow = (item) => {
      console.log(item);
      this.setState({ activeItem: item }, () => this.setState({ show: true }));
    };

    this.handleClose = () => {
      this.setState({ show: false });
    };
  }

  componentDidMount() {
    getRequest(`files/studentActivity.json`, false)
      .then((res) => this.setState({ data: res.data }))
      .catch((error) => console.log(error));
  };


  render() {
    return (
      <Container className="p-5 " width="1250px" mx-auto>
        <Helmet>
          <meta name="description" content="Information of different clubs in college and various student activities" />
          <meta name="author" content="Webteam,NIT Jamshedpur" />
          <meta name="keywords" content="Student's Activities ,NIT Jamshedpur, ACM Student Chapter,CDS,WEBTEAM.FACES,DRIFT,CES,DAKSH,PHOENIX,SECE,TOPGUNS" />
        </Helmet>
        <div className="row">
          {this.state.data.map((item, key) => (
            <Card
              key={item.name}
              className="p-3 mx-auto mt-2"
              onClick={() => this.handleShow(item)}
              style={{
                hover: "#ccfff5",
                width: "250px",
                cursor: "pointer",
              }}
            >
              <Image
                src={item.logo}
                alt="thumb"
                className="mx-auto"
                preview={false}
                style={{ height: "100px", width: "100px" , display: "block"}}
                fallback={invalid_image}
              />

              <Card.Body className="text-center">
                <b style={{ fontSize: "15px", alignItems: "center" }}>
                  {item.name}
                </b>
                <div className="filtre" />
              </Card.Body>
            </Card>
          ))}
        </div>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          centered
          keyboard={true}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Heading content={this.state.activeItem.name} />
          </Modal.Header>
          <Modal.Body>
            <Card style={{ border: "none" }}>
            {this.state.activeItem.poster ? (<>
              <Card.Img
                variant="top"
                className="mx-auto"
                src={this.state.activeItem.poster}
                style={{ maxWidth: "50%", height: "200px" }}
              />
                </>):null}

              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.activeItem.description,
                }}
              ></div>
            </Card>
            
            {this.state.activeItem.events ? (
              <>
                <Heading
                  content={"Our Events"}
                  style={{
                    marginBottom: "20px",
                    marginTop: "13px",
                    background: "#274187",
                    color: "#fff",
                    padding: "1% 1% 1% 1%",
                    textAlign: "center"
                  }}
                />
                <div className="px-5">
                  {this.state.activeItem.events.map((event, id) => (
                    <>
                      ◙ {event}
                      <br />
                    </>
                  ))}
                </div>
              </>
            ) : null}
            
            <br />
            {this.state.activeItem.achievements ? (
              <>
                <Heading
                  content={"Achievements"}
                  style={{
                    marginBottom: "20px",
                    marginTop: "13px",
                    background: "#274187",
                    color: "#fff",
                    padding: "1% 1% 1% 1%",
                    textAlign: "center"
                  }}
                />
                <div className="px-5">
                  {this.state.activeItem.achievements.map((achievement, id) => (
                    <>
                      ◙ {achievement}
                      <br />
                    </>
                  ))}
                </div>
              </>
            ) : null}
            <br />

            <Card.Text>
              <Heading
                content={"Contacts"}
                style={{
                  marginBottom: "20px",
                  marginTop: "13px",
                  background: "#274187",
                  color: "#fff",
                  padding: "1% 1% 1% 1%",
                }}
              />
              <div className="px-5">
                {this.state.activeItem.url ? (<>
                  <b>Club Website:</b>{" "}
                  <a rel="noreferrer" href={this.state.activeItem.url} target="_blank">
                    {this.state.activeItem.url}
                  </a>
                  <br />
                </>):null}
                {this.state.activeItem.email ? (<>
                  <b>Club Email:</b> {this.state.activeItem.email}
                <br />
                </>):null}
                {this.state.activeItem.captain ? (<>
                  <b>Captain:</b> {this.state.activeItem.captain}
                  <br />
                </>):null}
                {this.state.activeItem.mobile ? (<>
                  <b>Captain Phone:</b> {this.state.activeItem.mobile}
                  <br />
                </>):null}
                {this.state.activeItem.capt_email ? (<>
                  <b>Captain Email:</b> {this.state.activeItem.capt_email}
                  <br />
                </>):null}
                {this.state.activeItem.faculty_advisor ? (<>
                  <b>Faculty Advisor:</b> {this.state.activeItem.faculty_advisor}
                  <br />
                </>):null}
                {this.state.activeItem.faculty_advisor_email ? (<>
                  <b>Faculty Advisor Email</b>: {this.state.activeItem.faculty_advisor_email}
                <br />
                </>):null}
                {this.state.activeItem.facebook ? (<>
                  <b>Facebook:</b>{" "}
                  <a rel="noreferrer" href={this.state.activeItem.facebook} target="_blank">
                    {this.state.activeItem.facebook}
                  </a>
                  <br />
                </> ): null}
                {this.state.activeItem.linkedin ? (<>
                  <b>Linkedin:</b>{" "}
                  <a rel="noreferrer" href={this.state.activeItem.linkedin} target="_blank">
                    {this.state.activeItem.linkedin}
                  </a>
                  <br />
                </>): null}
                {this.state.activeItem.instagram ? (<>
                  <b>Instagram:</b>{" "}
                  <a rel="noreferrer" href={this.state.activeItem.instagram} target="_blank">
                    {this.state.activeItem.instagram}
                  </a>
                <br />
                </>): null}
                {this.state.activeItem.twitter ? (<>
                  <b>Twitter:</b>{" "}
                  <a rel="noreferrer" href={this.state.activeItem.twitter} target="_blank">
                    {this.state.activeItem.twitter}
                  </a>
                  <br />
                </>): null}

              </div>
            </Card.Text>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.handleClose()}>Close</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default StudentActivities;
