// People section
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import avatar from "../../../assets/images/default.jpg";
import { getFileURL } from "../../../functions/utils";
import { Image } from "antd";
import { BRANCH } from "../../../assets/Constants";
import useStyles from "./styles";
function List({ people, error, showProfile }) {
  const [hover, setHover] = useState(null);
  const classes = useStyles();
  if (people) {
    console.log("people ", people);
  }

  return (
    <div>
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          {people &&
            people.length > 0 &&
            people.map((person, index) => {
              if (person) {
                return (
                  <Link
                    key={index}
                    to={
                      showProfile
                        ? `/people/profile/${
                            person.faculty_id ? person.faculty_id : person.id
                          }`
                        : "#"
                    }
                  >
                    <Card
                      key={index}
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(null)}
                      style={{
                        borderRadius: "5%",
                        cursor: showProfile ? "pointer" : null,
                        pointerEvents: showProfile ? null : "none",
                        backgroundColor: hover === index ? "#E1EEF2" : "",
                        width: 250,
                        height: "max-content",
                        margin: "1rem",
                        padding: "1rem",
                      }}
                    >
                      <center>
                        <Image
                          src={getFileURL(person.profile)}
                          fallback={avatar}
                          preview={false}
                          style={{
                            maxHeight: "200px",
                            width: "200px",
                            height: "200px",
                          }}
                        />
                      </center>
                      <Card.Body>
                        <Card.Text>
                          <b style={{ color: "#274187", fontWeight: "500" }}>
                            {person.fname}&nbsp;{person.lname}
                            {person.Name}
                            {person.name}
                            {person.post}
                          </b>
                          <br />
                          {/* {person.designation} */}
                          <span className={classes.designationText}>
                            {person.designation}
                          </span>
                          {person.hostel_id}
                          <br />
                          {BRANCH[person.department] || person.department}
                          {person.hostel_name}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                );
              } else return null;
            })}
          <h4 style={{ marginTop: "3rem", marginBottom: "12rem" }}>{error}</h4>
        </Row>
      </Container>
    </div>
  );
}

// HumanityBlock style props
List.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// List default style
List.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default List;
