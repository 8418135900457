import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Minutes from "../../../Components/Minutes/minutes";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { getRequest } from "../../../functions/request";
function BuildingsandWorksCommittee() {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [memebers, setMemebers] = useState([]);       //TODO: Make Use of it

  const [loadingMemeber, setLoadingMemeber] = useState(true);
  useEffect(() => {
    getRequest(`administration?type=bwc`)
      .then((res) => res.data.data)
      .then((res) => {
        setMemebers(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingMemeber(false));

    getRequest(`Minutes?type=bwc`)
      .then((res) => res.data.results)
      .then((res) =>
        res.map((e) => ({
          name: e.heading,
          link: `${e.PATH}`,
        }))
      )
      .then((res) => setMinutes(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="Building &amp; Works Committee" />
          <meta
            name="keywords"
            content="Building &amp; Works Committee,Chairman,Secretary,Members"
          ></meta>
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("building_and_work_comittee_members")}
        />
        {loadingMemeber && <Skeleton count={10} />}
        {!loadingMemeber && (
          <TableWrapper>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th>{t("people_title1_thead1")}</th>
                  <th>{t("people_title1_thead2")}</th>
                  <th>{t("people_title1_thead3")}</th>
                </tr>
              </thead>
              <tr>
                <td data-column="Name">{t("b_mem_1_name")}</td>
                <td data-column="Post">{t("b_mem_1_post")}</td>
                <td data-column="Email">{t("b_mem_1_contact")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("b_mem_2_name")}</td>
                <td data-column="Post">{t("b_mem_2_post")}</td>
                <td data-column="Email">{t("b_mem_2_contact")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("b_mem_2.1_name")}</td>
                <td data-column="Post">{t("b_mem_2.1_post")}</td>
                <td data-column="Email">{t("b_mem_2.1_contact")}</td>
              </tr>
              {/* <tr>
                <td data-column="Name">{t("mem_3_name")}</td>
                <td data-column="Post">{t("mem_3_post")}</td>
                <td data-column="Email"></td>
              </tr> */}
              <tr>
                <td data-column="Name">{t("b_mem_4_name")}</td>
                <td data-column="Post">{t("b_mem_4_post")}</td>
                <td data-column="Email"></td>
              </tr>
              <tr>
                <td data-column="Name">{t("b_mem_5_name")}</td>
                <td data-column="Post">{t("b_mem_5_post")}</td>
                <td data-column="Email"></td>
              </tr>
              <tr>
                <td data-column="Name">{t("b_mem_7_name")}</td>
                <td data-column="Post">{t("b_mem_5_post")}</td>
                <td data-column="Email"></td>
              </tr>
              <tr>
                <td data-column="Name">{t("b_mem_6_name")}</td>
                <td data-column="Post">{t("b_mem_6_post")}</td>
                <td data-column="Email"></td>
              </tr>

              <tr>
                <td data-column="Name">{t("mem_6_name")}</td>
                <td data-column="Post">{t("mem_6_post")}</td>
                <td data-column="Email">{t("mem_6_contact")}</td>
              </tr>
            </table>
          </TableWrapper>
        )}
        <hr />
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("head_minof_work_comittee")}
        />
        {loading && <Skeleton count={5} />}
        {!loading && <Minutes minutes={minutes} />}
      </Container>
    </Fragment>
  );
}

export default BuildingsandWorksCommittee;
