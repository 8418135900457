import React from 'react';
import { useTranslation } from "react-i18next";
import Container from '../../../Components/UI/Container/index';
import Heading from '../../../Components/Heading/index';
import { SectionWrapper } from '../../../assets/css/Custom/AcademicStyle';
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
function Reservation({ fontsize }) {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("cells_top_subtitle_res")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <TableWrapper>
        <table className="table">
          <thead>
            <tr id="headRow">
              <th>{t("Reservation_table_name")}</th>
              <th>{t("Reservation_table_department")}</th>
              <th>{t("Reservation_table_post")}</th>
            </tr>
          </thead>
          {/* <tr>
            <td data-column="Name">{t("Prof M.K.Paswan")}</td>
            <td data-column="Department">{t("Mechanical Engineering")}</td>
            <td data-column="Post">{t("Convener")}</td>
          </tr> */}
          <tr>
            <td data-column="Name">{t("Dr.D.A.Khan")}</td>
            <td data-column="Department">{t("Computer Applications")}</td>
            <td data-column="Post">{t("Member")}</td>
          </tr>
          {/* <tr>
            <td data-column="Name">{t("Prof H.N.Singh")}</td>
            <td data-column="Department"> {t("Mechanical Engineering")} </td>
            <td data-column="Post">{t("Member")}</td>
          </tr> */}
          <tr>
            <td data-column="Name">{t("Dr.Balram Ambade")}</td>
            <td data-column="Department">{t("Chemistry")}</td>
            <td data-column="Post">{t("Member")}</td>
          </tr>
          <tr>
            <td data-column="Name">{t("Mr.B.N.S.Munda")}</td>
            <td data-column="Department">{t("Electronics and Communications Engineering")}</td>
            <td data-column="Post">{t("Member")}</td>
          </tr>


        </table>
      </TableWrapper>

      <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: 'center', fontSize: fontsize }}
        >
          {/* {t("default_para")} */}
        </p>
      </SectionWrapper>
    </Container>
  );
}

export default Reservation;
