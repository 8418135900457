import "./media.css";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../functions/request";
import { Link } from "@mui/material";
import { Box } from "@mui/system";
import { Card, Grid, Typography } from "@mui/material";
import { CustomAlumniGrid } from "../../../assets/css/Custom/CustomAlumniGrid";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../Home/ResearchSection/ResearchSection.style";
import img1 from "../../../assets/images/alumni/Meta_Pion.jpg";
import img2 from "../../../assets/images/alumni/Farewell_DR.RPV.jpg";
import img3 from "../../../assets/images/alumni/chandrayan.jpg";
import img4 from "../../../assets/images/alumni/chandrayan1.png";
import img5 from "../../../assets/images/alumni/chandrayan2.png";
import Heading from "../../../Components/Heading";

var settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
};

const object = [
  {
    content:
      "Meet the trailblazing pioneers: The inaugural class of RIT JSR Metallurgical Engineering graduates from 1960-64, shaping the future of metallurgy.",
    img: img1,
    title:
      "THE PIONEER FIRST BATCH OF RIT JSR METALLURGICAL ENGINEERING GRADUATES-1960-64",
    date: "24 August 2023",
    link: "#",
  },
  {
    content:
      "Bidding a fond farewell to Dr. R.P. Verma on November 25, 1964, a cherished legacy in our hearts.",
    img: img2,
    title: "FAREWELL TO DR R P VERMA-NOV 25-1964",
    date: "24 August 2023",
    link: "#",
  },
  {
    content:
      "Kudos to Mr. Abhishek Kumar, NIT Jamshedpur Mechanical Engineering '97 graduate (Roll No: 07/93). He's now Head of Mechanisms of Design and Development at ISRO's U R Rao Satellite Centre. His achievements bring honor to his alma mater.",
    img: img3,
    title:
      "Institute Congratulates  Mr. Abhishek Kumar for his remarkable  contribution in CHANDRAYAAN-3",
    date: "24 August 2023",
    link: "#",
  },
  {
    content:
      "Congratulation to Mr. Chinmay Kr Rajhans, NIT Jamshedpur Production and Industrial Engineering 2009 graduate (Roll No: 56). His remarkable works were pivotal in CHANDRAYAAN-3's mission to the moon",
    img: img4,
    title:
      "Institute Congratulates  Mr. Chinmay Kr Rajhans for his remarkable  contribution in CHANDRAYAAN-3",
    date: "24 August 2023",
    link: "#",
  },
  {
    content:
      "Kudos to Mr. Ashish Kr Sharma, NIT Jamshedpur Computer Science Engineering 2015 graduate (Roll No: 513). Beginning with his B.Tech from NIT Jamshedpur , his journey to becoming a Scientist in ISRO has been truly commendable.",
    img: img5,
    title:
      "Institute Congratulates  Mr. Ashish Kr Sharma for his remarkable  contribution in CHANDRAYAAN-3",
    date: "24 August 2023",
    link: "#",
  },
];
function RecentNews() {
  const { t } = useTranslation();
  const [notices, setNotices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getNews = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/alumni_news/`
      );
      const dataRe = await response.json();
      console.log(dataRe);
      return dataRe.data;
    } catch (error) {
      console.error("Error fetching notices:", error);
      return [];
    }
  };

  useEffect(() => {
    getNews()
      .then((data) => {
        console.log("getNews Called...");
        setNotices(data);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error while fetching notices:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  // const { t } = useTranslation();
  //   const [news, setNews] = useState([]);

  //   useEffect(() => {
  //     const newsHandler = async () => {
  //       try {
  //         const res = await getRequest(`/notices/landing?limit=10`);
  //         setNews(res.data.data);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };
  //     newsHandler();
  //   }, []);

  //   const Notices = news.map((item, idx) => {
  //     return {
  //       id: idx,
  //       title: item.title,
  //       content: item.content,
  //       img: item.img,
  //       date: item.date,
  //       link: item.link,
  //     };
  //   });
  //  When we will get the destination api to hit for the news them instead of sample object[] array we will use the Notices[] array

  return (
    <div className="wrapper">
      <SectionHeader>
        <Heading content={t("Recent_News")} />
      </SectionHeader>

      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10rem",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        notices.map((item) => (
          <Card
            sx={{
              borderRadius: "12",
              boxShadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;",
            }}
          >
            <CustomAlumniGrid
              container
              sx={{
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                padding={1}
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${item.img}`}
                  alt=""
                  srcset=""
                  height={"60%"}
                  width={"60%"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "space-evenly",
                  overflowY: "auto",
                }}
                padding={1}
                marginTop={0}
              >
                <Grid padding={1} top={0}>
                  <Typography
                    variant="h5"
                    style={{ textDecoration: "underline" }}
                  >
                    {item.title}
                  </Typography>
                </Grid>
                <div
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    display: "block",
                    // flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    // overflowY: "auto",
                  }}
                >
                  <div style={{ display: "block" }}>
                    {" "}
                    <div variant="body1">{item.content}</div>
                  </div>
                  <Box
                    xs={12}
                    sm={8}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      overflowY: "auto",
                    }}
                    padding={2}
                  >
                    {/* <Grid style={{ alignSelf: "left" }}>
                    Publish Date: {item.date}
                  </Grid> */}
                    {/* <Grid>
                    <Link
                      href="https://geeksforgeeks.org"
                      target="_blank"
                      underline="always"
                    >
                      Source:
                    </Link>
                  </Grid> */}
                  </Box>
                </div>
              </Grid>
            </CustomAlumniGrid>
          </Card>
        )))}
    </div>
  );
}

export default RecentNews;
