import React from "react";
import "./maintenance.css";
export default function Maintenance() {
  return (
    <article className="text-light">
      <div className="text-center ">
        <i class="fas fa-exclamation-triangle fa-3x"></i>
      </div>
      <h1 className="text-center">We&rsquo;ll be back soon!</h1>
      <div className="text-center">
        <p>
          Sorry for the inconvenience. We&rsquo;re performing some maintenance
          at the moment. we&rsquo;ll be back up shortly!
        </p>
        <p className="author">&mdash; WebTeam, NIT Jamshedpur</p>
      </div>
    </article>
  );
}
