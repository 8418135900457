import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import { useTranslation } from "react-i18next";
import Heading from '../../../Components/Heading/index';
import {
  ParagraphWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function AboutUs() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="National Institute of Technology Jamshedpur, earlier known as Regional Institute of Technology was established on 15th August 1960 as a joint venture of Government of India ......."/>
          <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,about Us,Computer center,services,manthan"/>
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("heading_about_us")}
        />
        <ParagraphWrapper>
          <p  style={{textAlign: 'justify'}} className="paragraph" >
           
           {t("about_us_para")}
           
           </p>
        </ParagraphWrapper>
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("head_location")}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paragraph">
           {t("location_para")}
           {t("to_view")} <Link to={"/Institute/How_to_Reach"}>{t("Click Here")}</Link> 
           </p>
        </ParagraphWrapper>
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("head_history")}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paragraph">
              {t("history_para")}
            </p>
        </ParagraphWrapper>
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("Address")}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paragraph">
          <ul>
                <li>NATIONAL INSTITUTE OF TECHNOLOGY, JAMSHEDPUR</li>
                <li>NIT Campus, P.O .RIT Jamshedpur 831014, Jharkhand, India</li>
                <li><span class="fa fa-phone"></span>&nbsp;+91-657-2374108</li>
                <li>Fax No. : +91-657-2382246, 2408811</li>
                <li><span class="fa fa-envelope"></span><a href="mailto: director@nitjsr.ac.in">&nbsp;director@nitjsr.ac.in</a></li>
           </ul>
            </p>
        </ParagraphWrapper>
      </Container>
    </Fragment>
  );
}


export default AboutUs;
