import React from "react";
import { Icon } from "react-icons-kit";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Heading from "../../../Components/Heading";

function Ordinance({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,fees,fees of nit jsr,fees of nit jamshedpur,ordinance"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("ordinance_title")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper>
          <a
            href="https://nitjsr.ac.in/backend/uploads/recents/ordinance/UG%20REGULATION.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: fontsize }}
            className="pdfLink"
          >
            <Icon icon={chevronCircleRight} size={18} />
            &nbsp;&nbsp; {t("ordinance_title_link1")}
          </a>
        </SectionWrapper>
        <SectionWrapper>
          <a
            href="https://nitjsr.ac.in/backend/uploads/recents/ordinance/PG_Ordinance_2019.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: fontsize }}
            className="pdfLink"
          >
            <Icon icon={chevronCircleRight} size={18} />
            &nbsp;&nbsp; {t("ordinance_title_link2")}
          </a>
        </SectionWrapper>
        <SectionWrapper>
          <a
            href="https://nitjsr.ac.in/backend/uploads/recents/ordinance/RESEARCH_PROGRAMME_ORDINANCE.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: fontsize }}
            className="pdfLink"
          >
            <Icon icon={chevronCircleRight} size={18} />
            &nbsp;&nbsp; {t("ordinance_title_link3")}
          </a>
        </SectionWrapper>
      </Container>
    </>
  );
}

// HumanityBlock style props
Ordinance.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AboutUs default style
Ordinance.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Ordinance;
