import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../../../Components/UI/Container";
import Heading from "../../../../../Components/Heading";
import { Helmet } from "react-helmet";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Box,
} from "@mui/material";

const People = () => {

  const { t } = useTranslation();

  const exampleData = [
    {
      id: 1,
      image:
        "https://nitjsr.ac.in/backend/uploads/Faculty/CS103/profile/272e8df2-a22e-4f5f-875b-5d9149d5f935.jpeg",
      preName: "Dr.",
      fname: "Koushlendra",
      lname: "Kumar Singh",
      designation: "Professor In-Charge",
      post: "Professor",
      department: "Computer Science & Engineering (CSE)",
      email: "koushlendra.cse@nitjsr.ac.in",
      phNo: "+91-9102197734",
      profileLink: "https://nitjsr.ac.in/people/profile/CS103",
    },
  ];


  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="One of the attractions of this campus is the OVAL structure - the Computer Centre, which accommodates central computing facilities. There are mainly three types of services viz. computational services, laboratory services and network services. The Centre's services are extensively used by the students, faculty and staff members of the Institute."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,meeting,labs,laboratories,computers,stafflist,facility,conferences,software,hardware,Computer center,services,manthan,mis,server room,networking"
        />
      </Helmet>
      <Container width="1250px">

        <Heading
          content={t("People")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />

        <Grid
          container
          sx={{
            marginBottom: "40px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {exampleData.map((person) => (
            <Grid item xs={12} md={10} key={person.id}>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-around"}
                  >
                    <Box
                      component="img"
                      src={person.image}
                      alt={`${person.fname} ${person.lname}`}
                      sx={{
                        borderRadius: "50%",
                        width: { xs: "100px", md: "150px" },
                        height: { xs: "100px", md: "150px" },
                        marginRight:"12px",
                        marginBottom: { xs: 2, md: 0 },
                        objectFit: "fill",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontFamily: "Poppins" }}
                      >
                        {person.preName} {person.fname} {person.lname}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Poppins" }}
                      >
                        Head of Computer Center
                      </Typography>
                    </Box>
                  </Box>
                  <hr />
                  <Typography variant="h6" sx={{ fontFamily: "Poppins" }}>
                    Details
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    {person.post}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    {person.department}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    <Box
                      component={"span"}
                      color={"black"}
                      fontWeight="bold"
                      display="inline"
                    >
                      Email:{" "}
                    </Box>
                    {person.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    <Box
                      component={"span"}
                      color={"black"}
                      fontWeight="bold"
                      display="inline"
                    >
                      Ph-No:{" "}
                    </Box>
                    {person.phNo}
                  </Typography>
                  <Link href={person.profileLink}>Profile Link</Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
          
      </Container>
    </>
  );
};

export default People;
