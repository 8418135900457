import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";

const DeanRCOffice = () => {
  const { t } = useTranslation();
  const ImgLink =
    "https://nitjsr.ac.in/backend/uploads/Faculty/ME108/profile/19cbb633-ebd5-4fc9-ad52-688350940df1.jpg";
  const ashok =
    "https://nitjsr.ac.in/backend/uploads/Faculty/ME102/profile/98776a5a-800d-4b43-93af-552adb319e38.jpg";
  const kanika =
    "https://nitjsr.ac.in/backend/uploads/Faculty/PI108/profile/723a49be-eff3-4875-b479-e071a2f2b54b.jpg";
  const omHari =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EE111/profile/5da109b3-93b2-4ec1-a7cf-1db6c9a36b93.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={ImgLink}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <hr />
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-rnc")}
                </h5>
                <p className="text-center"> {t("n-rnc")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={ashok}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Ashok Kumar Mandal
                    </h5>
                    <p className="text-center">Research and Consultancy</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={kanika}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Kanika Prasad
                    </h5>
                    <p className="text-center">Research and Consultancy</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={omHari}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Omhari Gupta
                    </h5>
                    <p className="text-center">Research and Consultancy</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean="Research Consultancy" />
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanRCOffice;

