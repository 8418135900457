import React from "react";
import { Link } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { Grid, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ResetCSS } from "../../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import { Breadcrumb } from "antd";
import avatar from "../../../assets/images/default.jpg";

import Sticky from "react-stickynode";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Footer from "../../../Components/Layout/Footer";
import { Container } from "react-bootstrap";
import { Tabs, Image } from "antd";
import Heading from "../../../Components/Heading";
import { PeopleWrapper } from "../../../assets/css/Custom/PeopleStyle";
import PublicationSection from "./../Sections/deputyDirectorProfile/PublicationSection";
import ConferenceProceedings from "./../Sections/deputyDirectorProfile/ConferenceProceedings";
import OtherInfoSection from "./../Sections/deputyDirectorProfile/OtherInfoSection";
import TeachingInterest from "./deputyDirectorProfile/TeachingInterest";
// import MessageFromDirector from "./../Sections/deputyDirectorProfile/MessageFromDeputyDirector";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import deputyDirectorImg from "./deputyDirectorProfile/deputy_director.jpg";

const { TabPane } = Tabs;

function Profile() {
  const { t } = useTranslation();

  const useStyles = makeStyles({
    officeCard: {
      padding: "8px 12px",
    },
    officeName: {
      fontSize: "18px",
      color: "black",
    },
  });
  const classes = useStyles();
  // useEffect(() => {
  //   const getData = async () => {
  //     const personData = await getRequest("/files/director.json", false);
  //     let data = personData.data[0][id];
  //     if (data) {
  //       setPerson(data);
  //     } else {
  //       setNotFound(true);
  //     }
  //     setPersonLoaded(true);
  //   };
  //   scrollToContent(0);
  //   getData();
  // }, [id]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`Director`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,deputy director"
        />
        <meta name="description" content="Director,N.I.T. Jsr" />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeclassName="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <div>
            <div
              style={{
                fontSize: "2.0em",
                marginBottom: "15px",
                fontWeight: "550",
                background: "rgb(24 68 110)",
                padding: "1.5% 1.5% 1.5% 3%",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              <Heading
                className="text-light"
                content={"Prof Ram Vinoy Sharma"}
              />
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/" className="text-muted">
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="text-muted" to="/people/People_Home">
                    People
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="text-muted" to="/people/Faculty">
                    Faculty
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="text-light">
                  Prof. Ram Vinoy Sharma
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Container style={{ padding: "1%" }}>
              <PeopleWrapper>
                <div className="row">
                  <div className="col-lg-4 widget-block">
                    <div className="widget widget-user-about">
                      <div className="text-center">
                        <>
                          <Image
                            src={deputyDirectorImg}
                            fallback={avatar}
                            style={{
                              borderRadius: "50%",
                              maxHeight: "200px",
                              width: "200px",
                              height: "200px",
                            }}
                          />
                          <center>
                            <b>Prof. Ram Vinoy Sharma</b>
                            <br />
                            Deputy Director
                            <br />
                            {/* {person.department} */}
                          </center>
                          <br />
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <ul className="user-info">
                      <li>
                        <div
                          className="about_wrapper"
                          style={{ padding: "20px" }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: "16px",
                              fontWeight: "400",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <div
                              style={{
                                maxWidth: "100%",
                                textAlign: "justify",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  "<p> Prof Ram Vinoy Sharma is currently the Deputy Director of N.I.T Jamshedpur. He earned his B.E. degree from Indian Institute of Technology Delhi in 1985 and his M.E. from Indian Institute of Technology Delhi in 1987. He completed his PhD from Indian Institute of Technology, Kharagpur in 1999. He is the professor of Department of Mechanical Engineering, N.I.T. Jamshedpur from 1st Jan. 2008.\n\n\t\t\t\t\t\t\t\t\t\t</p>",
                              }}
                            ></div>
                          </p>
                        </div>
                      </li>
                    </ul>

                    <Tabs
                      defaultActiveKey="1"
                      style={{ minHeight: "350px", marginTop: "40px" }}
                    >
                      {/* <TabPane
                        className="table-responsive px-2"
                        tab={" Message from the Deputy Director"}
                        key="main_1"
                      >
                        <MessageFromDirector />
                      </TabPane> */}

                      <TabPane
                        className="table-responsive px-2"
                        tab={"Office of Deputy Director"}
                        key="main_4"
                      >
                        <Grid container spacing={2} direction="row" wrap="wrap">
                          <Grid xs={12} sm={6} md={6} item>
                            <Card className={classes.officeCard}>
                              <p className={classes.officeName}>
                                Mr. Dharmendra Mardi
                              </p>
                            </Card>
                          </Grid>
                        </Grid>

                        <TeachingInterest />
                      </TabPane>
                      <TabPane
                        className="table-responsive px-2"
                        tab={"Publications"}
                        key="main_3"
                      >
                        <PublicationSection />
                      </TabPane>

                      <TabPane
                        className="table-responsive px-2"
                        tab={"Conferences"}
                        key="main_2"
                      >
                        <ConferenceProceedings />
                      </TabPane>

                      <TabPane
                        className="table-responsive px-2"
                        tab={"Contact"}
                        key="main_5"
                      >
                        <OtherInfoSection />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </PeopleWrapper>
            </Container>
          </div>
        </ContentWrapper>

        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Profile;
