import React, { useState } from "react";
import { Button } from "react-bootstrap";
import getData from "../Common/getData";
import List from "../Components/List";
import Select from "../../../Components/Select";
import { PeopleWrapper } from "../../../assets/css/Custom/PeopleStyle";
import "../../../assets/css/faculty_profile.css";

import { useTranslation } from "react-i18next";
function Faculty() {
  const [department, setDepartment] = useState("all");
  const [alphabet, setAlphabet] = useState("");
  const [btnNum, setBtnNum] = useState(0);
  const { t } = useTranslation();
  const [response, error] = getData(
    `/people/faculty?dept=${department}&name=${alphabet}`,
    [department, alphabet]
  );

  const prepareAlphabets = () => {
    let result = [];
    result.push(
      <Button
        type="button"
        style={{
          backgroundColor: btnNum === 0 ? "red" : "#274187",
          margin: "0.2rem",
          marginTop: "0.8rem",
        }}
        key={0}
        onClick={() => {
          setBtnNum(0);
          setAlphabet("");
        }}
        value={"All"}
        className="mx-auto"
      >
        {t("All")}
      </Button>
    );
    for (let i = 65; i < 91; i++) {
      result.push(
        <Button
          type="button"
          className="mx-auto"
          style={{
            backgroundColor: btnNum === i ? "red" : "#274187",
            margin: "0.2rem",
            marginTop: "0.8rem",
          }}
          key={i}
          onClick={() => {
            setBtnNum(i);
            setAlphabet(String.fromCharCode(i));
          }}
          value={String.fromCharCode(i)}
        >
          {String.fromCharCode(i)}
        </Button>
      );
    }
    return result;
  };
  const options = [
    { label: t("all-dept"), value: "all" },
    { label: t("cse"), value: "cs" },
    { label: t("ee"), value: "eee" },
    { label: t("ece"), value: "ece" },
    { label: t("ce"), value: "civil" },
    { label: t("me"), value: "mech" },
    { label: t("mm"), value: "meta" },
    { label: t("pe"), value: "prod" },
    { label: t("phy"), value: "phys" },
    { label: t("math"), value: "maths" },
    { label: t("human"), value: "humanities" },
    { label: t("chem"), value: "chem" },
  ];

  return (
    <div className="container">
      <PeopleWrapper>
        <center>
          <div style={{ width: "20rem" }}>
            <label
              style={{
                marginTop: "1rem",
                backgroundColor: "#274187",
                color: "white",
                width: "100%",
                fontSize: 20,
              }}
            >
              {t("sel-dept-hod")}
            </label>
            <Select
              placeholder={t("placeholder-hod")}
              options={options}
              onChange={({ value }) => setDepartment(value)}
            />
          </div>
        </center>
        <div className="row">{prepareAlphabets()}</div>
        <List
          section="Faculty"
          people={response}
          error={error}
          showProfile={1}
        />
      </PeopleWrapper>
    </div>
  );
}

export default Faculty;
