export const SemesterScheduleData=[
    {
        id:1,
        label:'Master Time table for B.Tech Program.',
        value:'Master Time table for B.Tech Program.',
        content:[
          
            {
                title:'First Semester',
                link:'/'
            },
            {
                title:'Third Semester',
                link:'/'
            },
        ]
    },
    {
        id:2,
        label:'Time table for MCA',
        value:'Time table for MCA',
        content:[
           
            {
                title:'First Semester',
                link:'/'
            },
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
        ]
    },
    {
        id:3,
        label:' Time table for B.Tech(H) Civil Engineering',
        value:' Time table for B.Tech(H) Civil Engineering',
        content:[
            
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:4,
        label:' Time table for B.Tech(H) Computer Science and Engineering',
        value:' Time table for B.Tech(H) Computer Science and Engineering',
        content:[
           
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:5,
        label:' Time table for B.Tech(H) Electrical Engineering',
        value:' Time table for B.Tech(H) Electrical Engineering',
        content:[
          
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:6,
        label:' Time table for B.Tech(H) Electronics and Electrical Engineering',
        value:' Time table for B.Tech(H) Electronics and Electrical Engineering',
        content:[
           
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:7,
        label:' Time table for B.Tech(H) Mechanical Engineering',
        value:' Time table for B.Tech(H) Mechanical Engineering',
        content:[
           
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:8,
        label:' Time table for B.Tech(H) Metallurgical and Materials Engineering',
        value:' Time table for B.Tech(H) Metallurgical and Materials Engineering',
        content:[
           
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:8,
        label:' Time table for B.Tech(H) Production and Industrial Engineering',
        value:' Time table for B.Tech(H) Production and Industrial Engineering',
        content:[
           
            {
                title:'Third Semester',
                link:'/'
            },
            {
                title:'Fifth Semester',
                link:'/'
            },
            {
                title:'Seventh Semester',
                link:'/'
            },
        ]
    },
    {
        id:8,
        label:' Time table for M.Tech',
        value:' Time table for M.Tech',
        content:[
           
            {
                title:'Computer Science and Engineering for Semester 1',
                link:'/'
            },
            {
                title:'Communications System for Semester 1',
                link:'/'
            },
            {
                title:'Embedded System for Semester 1',
                link:'/'
            },
            {
                title:'ISSE for Semester 1',
                link:'/'
            },
            {
                title:'Manufacturing Systems Engineering for Semester 1',
                link:'/'
            },
            {
                title:'Power Electronics and Drives for Semester 1',
                link:'/'
            },
            {
                title:'Power Systems Engineering for Semester 1',
                link:'/'
            },
            {
                title:'Computer Integrated Design & Manufacturing (CIDM) for Semester 1',
                link:'/'
            },
            {
                title:'Energy Systems Engineering for Semester 1',
                link:'/'
            },
            {
                title:'Thermal Engineering for Semester 1',
                link:'/'
            },
            {
                title:'Surface Science and Engineering for Semester 1',
                link:'/'
            },
            {
                title:'DMME MTech Time Table Materials Technology',
                link:'/'
            },
            {
                title:'Time Table M Tech (ISSE)',
                link:'/'
            },
            {
                title:'AUTUMN SEM PG TIME TABLE 2020-21',
                link:'/'
            },
        ]
    },
    {
        id:9,
        label:' Time table for M.Sc(Physics)',
        value:' Time table for M.Sc(Physics)',
        content:[
           
            {
                title:'Semester 1',
                link:'/'
            },
            {
                title:'Semester 3',
                link:'/'
            },
        ]
    },
    {
        id:10,
        label:' Time table for M.Sc(Chemistry)',
        value:' Time table for M.Sc(Chemistry)',
        content:[
           
            {
                title:'Semester 1',
                link:'/'
            },
            {
                title:'Semester 3',
                link:'/'
            },
        ]
    },
    {
        id:11,
        label:' Time table for M.Sc(Maths)',
        value:' Time table for M.Sc(Maths)',
        content:[
           
            {
                title:'Semester 1',
                link:'/'
            },
            {
                title:'Semester 3',
                link:'/'
            },
        ]
    },
]