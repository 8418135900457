import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
function HowToReach() {
  const { t } = useTranslation();

  const HowToReachContent = <Container width="1250px">
    <Helmet>
      <meta name="description" content="How to reach here: Jamshedpur is the first well-planned industrial city of India, founded by the late Jamshedji Nusserwanji Tata and ranks 28th among the 35 million-plus cities and is also the 31st urban agglomeration in India according to the census 2001. Located in the East Singhbhum district of Jharkhand on the Chota Nagpur plateau, it is the district headquarters and is surrounded by the beautiful Dalma Hills. The rivers Subarnarekha and Kharkai border the North and West of the city, respectively. The institute is located in Adityapur town of the district which is at the border of Saraikela-Kharswan district and East Singhbhum district. Adityapur is a highly industrialized town having hundreds of small and medium scale industries..." />
      <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,goals, map,reach, cc,guest,objectives,technology,Director,,stafflist,facility,output Indicators,Indicators..."></meta>
    </Helmet>
    <br />
    <Heading
      content={t("how-intro")}
      style={{
        fontSize: "1.5em",
        marginBottom: "20px",
        marginTop: "13px",
        fontWeight: "500",
        background: "#274187",
        color: "#fff",
        padding: "1% 1% 1% 3%",
      }}
    />
    <p style={{ textAlign: 'justify' }}>
      {t("intro-body")}
    </p>
    <br />
    <Heading
      content={t("air")}
      style={{
        fontSize: "1.5em",
        marginBottom: "20px",
        marginTop: "13px",
        fontWeight: "500",
        background: "#274187",
        color: "#fff",
        padding: "1% 1% 1% 3%",
      }}
    />
    <p style={{ textAlign: 'justify' }}>
      <strong>{t("bmairp")}</strong> {t("air-content-1")}
    </p>
    <p style={{ textAlign: 'justify' }}>
      <strong>
        {t("air-content-2")}
      </strong>
      {t("air-content-3")}
    </p>
    <br />
    <Heading
      content={t("rail")}
      style={{
        fontSize: "1.5em",
        marginBottom: "20px",
        marginTop: "13px",
        fontWeight: "500",
        background: "#274187",
        color: "#fff",
        padding: "1% 1% 1% 3%",
      }}
    />
    <p style={{ textAlign: 'justify' }}>
      {t("rail-content-1")}
      <br />
      {t("rail-content-2")}
      <br />
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li>
        {t("rail-highway-1")}
      </li>
      <li>
        {t("rail-highway-2")}
      </li>
    </ul>
    <p></p>
    <br />
    <Heading
      content={t("direction-head")}
      style={{
        fontSize: "1.5em",
        marginBottom: "20px",
        marginTop: "13px",
        fontWeight: "500",
        background: "#274187",
        color: "#fff",
        padding: "1% 1% 1% 3%",
      }}
    />
    <p style={{ textAlign: 'justify' }}>
      {t("dir-para1")}{" "}
      <strong>
        {t("dir-parabold")}
      </strong>{" "}
      {t("dir-para2")}
    </p>
    <p style={{ textAlign: 'justify' }}>
      {t("dir-para3")}
    </p>
  </Container>
  return (
    <Fragment>
      {HowToReachContent}
      <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          className="gmap_iframe"
          width="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=nit%20jamshedpur&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
        <a href="https://gachanymph.com/">Gacha Nymph Download</a>
      </div>
      <style>
        {`
          .mapouter {
            position: relative;
            text-align: right;
            width: 100%;
            height: 400px;
          }
          .gmap_canvas {
            overflow: hidden;
            background: none!important;
            width: 100%;
            height: 400px;
          }
          .gmap_iframe {
            height: 400px!important;
          }
        `}
      </style>
    </div>
    </Fragment>
  );
}


export default HowToReach;
