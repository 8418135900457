import React from "react";

export default function PublicationSection() {
  return (
    <div className="panel-body body1">
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K. Sinha and <b>R.V. Sharma</b>, Natural Convection in a
          Spherical Porous Annulus: The Brinkman Extended Darcy Flow Model,
          Transport in Porous Media, Volume 100, Issue 2, pp.321-335, 2013.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K. Sinha and <b>R.V. Sharma</b>, Influence of Property
          Variation on Natural Convection in a Gas Saturated Spherical Porous
          Annulus, Transport in Porous Media , Volume 104, pp.521-535, 2014.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Sangita, M.K. Sinha and <b>R.V. Sharma</b>, Numerical Studies of
          Natural Convection in a Spherical Porous Annulus, Journal of Porous
          Media, Volume 19, Issue 3, pp.277-286, 2016.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          A.K. Mishra, S. Kumar and <b>Ram Vinoy Sharma</b>, Non-Darcy Effects
          on ThreeDimensional Natural Convection in a Rectangular Box Containing
          a Heat Generating Porous Medium, Journal of Porous Media, Volume 19,
          Issue 12, pp. 1033-1043, 2016.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Raviranjan Kumar Singh and <b>R.V.Sharma</b>, Mathematical
          Investigation of Soil Temperature Variation for Geothermal
          Applications, IJE TRANSACTIONS A; Basics, Volume 30, Number 10,
          pp.1609-1614, 2017. DOI: 10.5829/ije.2017.30.10a.23.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Raviranjan Kumar Singh and <b>Ram Vinoy Sharma</b>, Numerical Analysis
          for Ground Temperature Variation, Geothermal Energy, Volume 22, Number
          5, pp.1-10, 2018. DOI:10.1186/s40517-017-0082-z.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordiya Jayesh Subhash and <b>Ram Vinoy Sharma</b>, Conjugate Natural
          Convection in a Fluid Saturated Porous Enclosure with a Pair of Solid
          Vertical
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordiya Jayesh Subhash and <b>Ram Vinoy Sharma</b>, Numerical study
          on the effects of multiple internal diathermal obstructions on natural
          convection in a fluid-saturated porous enclosure, Archive of
          Mechanical Engineering, 65(4),553-578.2018.
          DOI:10.24425/ame.2018.125442.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordiya Jayesh Subhash and <b>Ram Vinoy Sharma</b>, Study of Natural
          convection in a fluid saturated porous thermal insulations with
          multiple inclined diathermal partitions, International Journal of Heat
          and Technology, 37(1), 123-130, 2019. DOI:10.18280/ijht.370115.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordia, J.S. and Sharma, <b>Ram Vinoy Sharma</b>, Numerical study on
          effect of corrugated diathermal partition on natural convection in a
          square porous cavity, Journal of Mechanical Science and Technology,
          33(5):2481-2491, 2019. DOI: 10.1007/s12206-019-0445-4.
        </li>
      </ol>
      <ol>
        <li style={{ listStyleType: "disc" }}>
          Chordia, J.S. and Sharma, <b>Ram Vinoy Sharma</b>, Natural convection
          in fluid-saturated porous enclosure with a pair of vertical diathermal
          partition, International Journal of Thermal Sciences, 144:42-49, 2019.
          DOI: 10.1016/j.ijthermalsci.2019.05.020.
        </li>
      </ol>
    </div>
  );
}
