import React, { useState, useEffect } from "react";

import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { charityTheme } from "../../theme/charity";
import Container from "../../Components/UI/Container/index";
import News from "./news";
import { Helmet } from "react-helmet";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
  // from "../../../assets/css/Main/main.style";
} from "../../assets/css/Main/main.style";
//import Heading from "../../../Components/Heading";
import { useTranslation } from "react-i18next";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
// "../../../Components/Layout/DrawerSection";
import { ResetCSS } from "../../assets/css/style";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";

import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import { Row, Col } from "react-bootstrap";
// import AboutUs from "../../Pages/Institute/Sections/AboutUs";
// import VisionAndMission from "../../Pages/Institute/Sections/VisionAnsMission";
// import ActsAndStatues from "../../Pages/Institute/Sections/ActsAndStatues";
// import FinanceCommittee from "../../Pages/Institute/Sections/FinanceCommittee";
// import BuildingsandWorksCommittee from "../../Pages/Institute/Sections/BuildingsandWorksCommittee";
// import RankingandRecognition from "../../Pages/Institute/Sections/RankingandRecognition";
// import BoardofGoverners from "../../Pages/Institute/Sections/BoardofGoverners";
// import HowToReach from "../../Pages/Institute/Sections/HowToReach";
// import FormerDirector from "../../Pages/Institute/Sections/FormerDirector";
// import OrganizationalChart from "../../Pages/Institute/Sections/OrgnizationalChart";
// import Senate from "../../Pages/Institute/Sections/Senate";
import { scrollToContent } from "../../functions/scroll.js";

import Notices from "./news/notices";

function MediaCoverage() {
  const { t } = useTranslation();
  // const [ActiveComp] = useState("Media Coverage");

  const [ActiveComp, setActiveComp] = useState("Media Corner");
  // useEffect(() => {
  //   setActiveComp(page);
  // }, [page]);

  function handleClick(comp) {
    setActiveComp(comp);
    scrollToContent();
  }

  return (
    <ThemeProvider theme={charityTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp}`)} />
          <Container
            width="80%"
            style={{
              display: "flex",
            }}
          >
            <Row>
              <Col lg={4} sm={12}>
                <Sidebar TopLink="Institute" handleClick={handleClick} />
                {/* 
                {ActiveComp === "About_NITJSR" && <AboutUs />}
                {ActiveComp === "Vision_and_Mission" && <VisionAndMission />}
                {ActiveComp === "Acts_and_Statues" && <ActsAndStatues />}
                {ActiveComp === "Finance_Committee" && <FinanceCommittee />}
                {ActiveComp === "Buildings_and_Works_Committee" && (
                  <BuildingsandWorksCommittee />
                )}
                {ActiveComp === "Senate" && <Senate />}
                {ActiveComp === "Ranking_and_Recognition" && (
                  <RankingandRecognition />
                )}
                {ActiveComp === "Board_of_Governers" && <BoardofGoverners />}
                {ActiveComp === "Former_Directors" && <FormerDirector />}
                {ActiveComp === "How_to_Reach" && <HowToReach />}
                {ActiveComp === "Organizational_Chart" && (
                  <OrganizationalChart />
                )} */}
              </Col>
              <Col lg={8} sm={12}>
                <Notices />
                <News />
              </Col>
            </Row>
          </Container>
        </ContentWrapper>

        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default MediaCoverage;
