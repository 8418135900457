import { React, useEffect, useState } from "react";
import { getRequest } from "../../../functions/request";

const HighlightedNotice = () => {
  const [data, setData] = useState("");
  const fetching = async () => {
    try {
      let res = await getRequest(`/highlightednotices`);
      setData(res.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetching();
  }, []);
  return (
    <>
      {data.length > 0 &&
        data.map((value) => {
          return (
            <center key={value}>
              <strong>
                <div dangerouslySetInnerHTML={{ __html: value }}></div>
              </strong>
            </center>
          );
        })}
    </>
  );
};

export default HighlightedNotice;
