import React, { Fragment, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
import Sticky from "react-stickynode";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Wardens from "./Sections/Wardens";
import Hostels from "./Sections/Hostels";
import HostelDetails from "./Sections/HostelDetails";
import AntiRagging from "./Sections/AntiRagging";
import FinancialAssistance from "./Sections/FinancialAssistance";
import StudentLife from "./Sections/StudentLife";
import StudentActivities from "./Sections/StudentActivities";
import Placements from "./Sections/Placements";
import HealthAndWelfare from "./Sections/HealthAndWelfare";
import Admissions from "../Academics/Sections/Admissions";
import Achievements from "./Sections/studentAchievement";
import StudentCounselling from "./Sections/StudentCounselling";
import LifeNITJSR from "./Sections/Life@NITJSR";
import Footer from "../../Components/Layout/Footer";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
import StudentCouncil from "./Sections/StudentCouncil";

function StudentIndex() {
  const { page } = useParams();
  const [ActiveComp, setActiveComp] = useState("Placements");
  const fontsize = 16;
  const { t } = useTranslation();
  useEffect(() => {
    const pg = page.replaceAll("-", " ");
    setActiveComp(pg);
  }, [page]);
  function handleClick(comp) {
    const pg = comp.replaceAll("-", " ");
    setActiveComp(pg);
    scrollToContent();
  }
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
          राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      <Fragment>
        {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <TopSection  setFontSize={setFontSize} />
            </Sticky> */}
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <ContentWrapper>
            <Banner heading={t(`${ActiveComp.split(" ").join("_")}`)} />
            <Container width="80%">
              <Row>
                <Col lg={4} sm={12}>
                  <Sidebar TopLink="Students" handleClick={handleClick} />
                </Col>
                <Col lg={8} sm={12}>
                  {ActiveComp === "Anti Ragging" ? (
                    <AntiRagging fontsize={fontsize} />
                  ) : null}
                  {/* {ActiveComp === "Student Life" ? (
                    <StudentLife fontsize={fontsize} />
                  ) : null} */}
                  {ActiveComp === "Hostel Management" ? (
                    <Hostels fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Hostel_Details" ? (
                    <HostelDetails fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Wardens" ? <Wardens /> : null}
                  {ActiveComp === "Placements" ? (
                    <Placements fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Financial Assistance" ? (
                    <FinancialAssistance fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Admissions" ? (
                    <Admissions fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Health and Welfare" ? (
                    <HealthAndWelfare fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Student Activities" ? (
                    <StudentActivities fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Student Achievements" ? (
                    <Achievements fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Student Counselling" ? (
                    <StudentCounselling />
                  ) : null}
                  {ActiveComp === "Life @NIT JSR" ? (
                    <LifeNITJSR fontsize={fontsize} />
                  ) : null}
                  {ActiveComp === "Student Council" ? (
                    <StudentCouncil fontsize={fontsize} />
                  ) : null}
                </Col>
              </Row>
            </Container>
          </ContentWrapper>
          <Footer />
        </CharityWrapper>
        {/* End of Main wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
}

export default StudentIndex;
