import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const NavbarWrapper = styled.nav`
  .container {
    padding: 0 18% 0 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 1220px) and (max-width: 1440px) {
      max-width: 1170px !important;
    }
    @media only screen and (max-width: 1440px) {
      padding: 0 30px;
    }
  }

  .logo {
    max-width: 150px;
    width: 100%;

    max-height: 75px !important;
  
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding-right: 20px;
  }
  @media only screen and (max-width: 1360px) {
    padding-right: calc(75px + 20px);
  }
  @media only screen and (max-width: 1260px) {
    padding-right: calc(75px + 30px);
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }

  a {
    font-size: 16px;
    font-weight: 600;
    margin-right: 46px;
    color: ${themeGet("colors.white", "#ffffff")};
    transition: all 0.25s ease;
    @media only screen and (max-width: 1440px) {
      margin-right: 40px;
    }
    @media only screen and (max-width: 1360px) {
      margin-right: 30px;
    }

    &:hover {
      color: ${themeGet("colors.primary", "#FCF22B")};
    }
  }
`;

export const Button = styled.button`
  border: 0;
  padding: 0;
  min-width: 150px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${themeGet("colors.heading", "#060F1E")};
  background-color: ${themeGet("colors.primary", "#FCF22B")};
  transition: all 0.3s ease;
  @media only screen and (max-width: 1440px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 14px;
  }

  &:hover {
    background-color: ${themeGet("colors.primaryHover", "#ECF22F")};
  }

  img {
    margin-left: 11px;
    @media only screen and (max-width: 1440px) {
      margin-left: 10px;
    }
  }

  &:hover,
  &:focus {
    content: 0;
    box-shadow: none;
  }
`;
export const Select = styled.select`
  color: #fff;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  border: "1px solid #eee";
  background-color: #274187;
  transition: all 0.3s ease;
  cursor: pointer;

  @media only screen and (max-width: 1440px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 14px;
  }
  &:hover {
    background-color: ${themeGet("colors.primaryHover", "#ECF22F")};
  }

  &:hover,
  &:focus {
    content: 0;
    box-shadow: none;
  }
`;

export const TextArea = styled.div`
  width: 80%;
  align-self: center;
  justify-content: center;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    padding: 10px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 10px 30px;
  }
  @media only screen and (max-width: 991px) {
    padding: 10px 25px;
  }
  @media only screen and (max-width: 780px) {
    align-self: flex-start;
    padding: 160px 15px 20px;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    color: rgb(39, 65, 135);
    font-size: 34px;
    text-align: center;
    line-height: 60px;
    font-weight: 750;
    margin: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 34px;
      line-height: 40px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 31px;
      // line-height: 10px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 30px;
      line-height: 50px;
    }
  }

  h3 {
    // font-family: 'Source Sans', sans-serif;
    font-family: "Poppins", sans-serif;
    color: rgb(39, 65, 135);
    font-size: 40px;
    line-height: 60px;
    font-weight: 800;
    margin-bottom: 3px;
    text-transform: Uppercase;
    @media only screen and (max-width: 1440px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 45px;
      line-height: 46px;
      margin-bottom: 5px;
    }
  }

  h4 {
    // font-family: 'Titillium Web', sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: ${themeGet("colors.text", "#294859")};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }
    @media only screen and (max-width: 991px) {
      font-size: 15px;
      line-height: 30px;
    }
  }

  p {
    font-family: "Hind Siliguri", sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 45px;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
    &.highlighted_text {
      margin: 0 0 40px;
      font-size: 18px;
      line-height: 17px;
      color: ${themeGet("colors.heading", "#060F1E")};
      @media only screen and (max-width: 1440px) {
        margin: 0 0 30px;
      }
    }
  }
`;

export const HighlightedText = styled.p`
  display: flex;
  align-items: center;
  max-width: 320px;
  width: 100%;
  min-height: 28px;
  border-radius: 80px;
  padding: 3px 28px 3px 4px;
  background-color: #eff0f0;

  strong {
    display: inline-flex;
    align-items: center;
    min-width: 51px;
    min-height: 20px;
    padding: 3px 11px;
    border-radius: 30px;
    font-size: 10px;
    text-transform: uppercase;
    color: ${themeGet("colors.white", "#ffffff")};
    background-color: ${themeGet("colors.secondary", "#D50032")};
    margin-right: 10px;
  }
`;

export default NavbarWrapper;
