import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";
const DeanIROffice = () => {
  const { t } = useTranslation();
  const ImgLink =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EE108/profile/d75d622a-0fbb-43b1-b8b8-35a5148cf430.jpg";
  const tushar =
    "https://nitjsr.ac.in/backend/uploads/Faculty/PI107/profile/ace330bd-5d25-4dbb-8564-6d3cc0eed5db.JPG";
  const akansha =
    "https://nitjsr.ac.in/backend/uploads/Faculty/HU101/profile/d225e1c6-ee05-441e-93c1-f9178eae073a.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={ImgLink}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <hr />
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-industry")}
                </h5>
                <p className="text-center">{t("n-industry")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={tushar}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Tushar Banerjee
                    </h5>
                    <p className="text-center">Industry and Alumni Relations</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={akansha}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Akanksha Shukla
                    </h5>
                    <p className="text-center">Alumni Relations</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean="Industry Relations" />
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanIROffice;

