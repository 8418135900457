import React from "react";
// import { Tag, Divider } from "antd";
// import { getRequest } from "../../../../functions/request";

export default function AreaOfInterest() {
    // const id = "CSE112";
    // const [research, setResearch] = useState([]);
    // const [member, setMember] = useState(null);

    // useEffect(() => {

    //     const getData = async () => {

    //         (async () => {
    //             getRequest('/files/director.json', false)
    //                 .then((res) => {
    //                     if (res.data.length > 0) {
    //                         setMember(res.data[0][id].member);
    //                         setResearch(res.data[0][id].research);
    //                     }
    //                 })
    //         })();
    //     };

    //     getData();
    // }, [id]);


    return (
        <div >
            Area Of Interest
            {/* <ol>
                <li style={{ listStyleType: 'disc' }}>Composite Plates and Shells </li>
                <li style={{ listStyleType: 'disc' }}>Smart Structures </li>
                <li style={{ listStyleType: 'disc' }}>Retrofitting &amp; Strengthening of RCC Structures </li>
                <li style={{ listStyleType: 'disc' }}>Computational Mechanics </li>
                <li style={{ listStyleType: 'disc' }}>Stability &amp; Dynamics of Structures </li>
                <li style={{ listStyleType: 'disc' }}> Multi-scale Composites
                </li>
            </ol> */}
        </div >
    )
}
