import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { LongPara, TableContainer, TableWrapper } from '../../Facilities/styles';
import { getRequest } from '../../../functions/request';

const StudentCounselling = () => {
	const { t } = useTranslation();
	const [studentCounsellingData, setStudentCounsellingData] = useState([]);

	useEffect(() => {
		let counsellingHandler = async () => {
			try {
				let res = await getRequest('central_facilities?facility=counselling')
				setStudentCounsellingData(res.data);
			} catch (err) {
				console.log(err);
			}
		}
		counsellingHandler();
	}, []);

	return (
		<Container width="1250px">
			<br />
			<Heading
				content={t("central_facilities_counselling_what_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_counselling_what_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_counselling_who_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_counselling_who_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_counselling_how_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_counselling_how_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_counselling_effective_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<LongPara>
				{t("central_facilities_counselling_effective_body")}
			</LongPara>
			<Heading
				content={t("central_facilities_contacts_head")}
				style={{
					fontSize: '1.5em',
					marginBottom: '20px',
					marginTop: '13px',
					fontWeight: '500',
					background: '#274187',
					color: '#fff',
					padding: '1% 1% 1% 3%',
				}}
			/>
			<TableContainer>
				<TableWrapper>
					<table>
						<thead>
							<tr id="headRow">
								<th>{t("central_facilities_table_key_1")}</th>
								<th>{t("central_facilities_table_key_2")}</th>
								<th>{t("central_facilities_table_key_3")}</th>
								<th>{t("central_facilities_table_key_4")}</th>
							</tr>
						</thead>
						<tbody>
							{studentCounsellingData.map((person) => (
								<tr>
									<td>{person.designation}</td>
									<td>
										{person.preName} {person.fname} {person.lname}
									</td>
									<td>{person.phoneNumber}</td>
									<td>{person.email}</td>
								</tr>
							))}
						</tbody>
					</table>
				</TableWrapper>
			</TableContainer>
		</Container>
	);
};

export default StudentCounselling;
