import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { Skeleton } from "antd";
import { Breadcrumb } from "antd";
import avatar from "../../assets/images/default.jpg";
import Sticky from "react-stickynode";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Footer from "../../Components/Layout/Footer";
import { Container } from "react-bootstrap";
import { Tabs, Image } from "antd";
import Heading from "../../Components/Heading";
import { PeopleWrapper } from "../../assets/css/Custom/PeopleStyle";
import { getFileURL } from "../../functions/utils";
import { getRequest } from "../../functions/request";
import TeachingSection from "./Sections/Profile/TeachingSection";
import ResponsibilitySection from "./Sections/Profile/ResponsibilitySection";
import PublicationSection from "./Sections/Profile/PublicationSection";
import ResearchAreaSection from "./Sections/Profile/ResearchAreaSection";
import OtherInfoSection from "./Sections/Profile/OtherInfoSection";
import ThesisSupervised from "./Sections/Profile/ThesisSupervised";
import OtherAcademicActivities from "./Sections/Profile/OtherAcademicActivities";
import { scrollToContent } from "../../functions/scroll";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BRANCH } from "../../assets/Constants";
const { TabPane } = Tabs;

function Profile() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [person, setPerson] = useState(null);
  const [personLoaded, setPersonLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const personData = await getRequest(`/people/faculty?id=${id}`);
      let data = personData.data[0];
      if (data) {
        setPerson(data);
      } else {
        setNotFound(true);
      }
      setPersonLoaded(true);
    };
    scrollToContent(0);
    getData();
  }, [id]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`Profile`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,visitors,guest"
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeclassName="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <div>
            <div
              style={{
                fontSize: "2.0em",
                marginBottom: "15px",
                fontWeight: "550",
                background: "rgb(24 68 110)",
                padding: "1.5% 1.5% 1.5% 3%",
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
              }}
            >
              <Heading
                className="text-light"
                content={
                  personLoaded ? (
                    notFound ? (
                      "Faculty Not Found"
                    ) : (
                      `${person?.prename || ""} ${person?.fname || ""} ${person?.lname || ""
                      }`
                    )
                  ) : (
                    <Skeleton.Input style={{ width: 400 }} active={true} />
                  )
                }
              />
              {personLoaded && (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" className="text-muted">
                      Home
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link className="text-muted" to="/people/People_Home">
                      People
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link className="text-muted" to="/people/Faculty">
                      Faculty
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="text-light">
                    {person?.prename} {person?.fname} {person?.lname}
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
            </div>

            {!notFound && (
              <Container style={{ padding: "1%" }}>
                <PeopleWrapper>
                  <div className="row">
                    <div className="col-lg-4 widget-block">
                      <div className="widget widget-user-about">
                        <div className="text-center">
                          {person ? (
                            <>
                              <Image
                                src={getFileURL(person.profile)}
                                fallback={avatar}
                                style={{
                                  borderRadius: "50%",
                                  maxHeight: "200px",
                                  width: "200px",
                                  height: "200px",
                                }}
                              />
                              <center>
                                <b>
                                  {person.prename} {person.fname} {person.lname}
                                </b>
                                <br />
                                {person.designation}
                                <br />
                                {BRANCH[person.department]||person.department}
                              </center>
                              <br />
                            </>
                          ) : (
                            <Skeleton.Image active />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <ul className="user-info">
                        <li>
                          <div
                            className="about_wrapper"
                            style={{ padding: "20px" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100%",
                                  textAlign: "justify",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: person?.bio,
                                }}
                              ></div>
                            </p>
                          </div>
                        </li>
                      </ul>
                      </div>

                      <div className="col-lg-11">
                      <Tabs
                        defaultActiveKey="1"
                        style={{ minHeight: "350px", margin: "40px 30px 0" }}
                      >
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Research Area"}
                          key="main_1"
                        >
                          <ResearchAreaSection />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Teachings"}
                          key="main_2"
                        >
                          <TeachingSection />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Research Publications"}
                          key="main_3"
                        >
                          <PublicationSection />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Responsibilities"}
                          key="main_7"
                        ><ResponsibilitySection />
                        </TabPane>

                        <TabPane
                         className="table-responsive px-2"
                          tab={"Thesis Supervised"}
                          key="main_5"
                         >
                          <ThesisSupervised />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Other Academic Achievements"}
                          key="main_6"
                        >
                          <OtherAcademicActivities />

                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Contact"}
                          key="main_4"
                        >
                          <OtherInfoSection />
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </PeopleWrapper>
              </Container>
            )}
          </div>
        </ContentWrapper>

        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Profile;
