import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Footer from "../../Components/Layout/Footer";
import { Row, Col } from "react-bootstrap";
import AboutUs from "./Sections/AboutUs";
import VisionAndMission from "./Sections/VisionAnsMission";
import ActsAndStatues from "./Sections/ActsAndStatues";
import FinanceCommittee from "./Sections/FinanceCommittee";
import BuildingsandWorksCommittee from "./Sections/BuildingsandWorksCommittee";
import RankingandRecognition from "./Sections/RankingandRecognition";
import BoardofGoverners from "./Sections/BoardofGoverners";
import HowToReach from "./Sections/HowToReach";
import FormerDirector from "./Sections/FormerDirector";
import OrganizationalChart from "./Sections/OrgnizationalChart";
import { mainTheme } from "../../theme/main";
import Senate from "./Sections/Senate";
import { useParams } from "react-router-dom";
import BannerImage from "../../assets/images/others/newbuilding.jpg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { scrollToContent } from "../../functions/scroll.js";
import ResearchAndConsultancy from "./Sections/ResearchAndConsultancy.js";

function Institute() {
  const { page } = useParams();
  const [ActiveComp, setActiveComp] = useState("About NITJSR");
  useEffect(() => {
    setActiveComp(page);
  }, [page]);

  const { t } = useTranslation();

  function handleClick(comp) {
    setActiveComp(comp);
    // router.query.type = comp;
    scrollToContent();
  }
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
          राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
      </Helmet>
      {/* <Header title={'Institute | NIT Jamshedpur'} /> */}
      {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
      <TopSection setFontSize={setFontSize} />
        </Sticky> */}
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Banner
            heading={t(`${ActiveComp.split(" ").join("_")}`)}
            bg_img={BannerImage}
          />
          <Container width="80%">
            <Row>
              <Col lg={4} sm={12}>
                <Sidebar TopLink="Institute" handleClick={handleClick} />
              </Col>
              <Col lg={8} sm={12}>
                {ActiveComp === "About_NITJSR" && <AboutUs />}
                {ActiveComp === "Vision_and_Mission" && <VisionAndMission />}
                {ActiveComp === "Acts_and_Statues" && <ActsAndStatues />}
                {ActiveComp === "Finance_Committee" && <FinanceCommittee />}
                {ActiveComp === "Buildings_and_Works_Committee" && (
                  <BuildingsandWorksCommittee />
                )}
                {ActiveComp === "Senate" && <Senate />}
                {ActiveComp === "rnc_nitjsr" && <ResearchAndConsultancy />}
                {ActiveComp === "Ranking_and_Recognition" && (
                  <RankingandRecognition />
                )}
                {ActiveComp === "Board_of_Governers" && <BoardofGoverners />}
                {ActiveComp === "Former_Directors" && <FormerDirector />}
                {ActiveComp === "How_to_Reach" && <HowToReach />}
                {ActiveComp === "Organizational_Chart" && (
                  <OrganizationalChart />
                )}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Institute;
