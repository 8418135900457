import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "../../CustomLink/CustomLink";
import Container from "../../UI/Container";
import FooterWrapper, {
  SectionWrapper,
  QuickLinks,
  RibbonLinks,
} from "./footer.style";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import logoImage from "../../../assets/images/logo_new1.png";
import { getRequest } from "../../../functions/request";
import { FaLocationArrow } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { background, backgroundColor, fontSize } from "styled-system";

const Footer = () => {
  const { t } = useTranslation();

  const [visitorCount, setVisitorCount] = useState("..");

  const updateCounter = useCallback(async () => {
    try {
      const res = await getRequest("/counters/trigger-count");
      const data = res.data;

      if (data && data.length) {
        setVisitorCount(data[0].count);
      }
      Number.prototype.format = function (n) {
        var r = new RegExp(
          "\\d(?=(\\d{3})+" + (n > 0 ? "\\." : "$") + ")",
          "g"
        );
        return this.toFixed(Math.max(0, Math.floor(n))).replace(r, "$&");
      };

      var countElements = document.querySelectorAll(".count");
      countElements.forEach(function (element) {
        var counter = 0;
        var text = parseFloat(element.textContent);

        var animationInterval = 100; // Interval for the animation in milliseconds
        var duration = 5000; // Total duration of the animation in milliseconds
        var steps = duration / animationInterval;
        var stepValue = text / steps;

        var currentStep = 0;
        var animationTimer = setInterval(function () {
          if (currentStep < steps) {
            counter += stepValue;
            element.textContent = counter.format();
            currentStep++;
          } else {
            clearInterval(animationTimer);
          }
        }, animationInterval);
      });
    } catch (e) {}
  });
  const linkHover = {};
  useEffect(async () => {
    updateCounter();
  }, []);

  return (
    <FooterWrapper>
      <Row>
        <Container width="120%">
          <SectionWrapper>
            <div className="row px-5 mx-2">
              <div className="col-lg-4 text-center">
                <Link to="/">
                  <img
                    className="mx-2"
                    style={{ height: "4em" }}
                    alt="logo"
                    src={logoImage}
                  />
                </Link>
                <p
                  className="text-light"
                  style={{ fontSize: "1.5em", fontWeight: "bolder" }}
                >
                  National Institute of Technology Jamshedpur
                </p>
                <div>
                  <FaLocationArrow style={{ margin: "0.5em" }} />
                  Adityapur, Jamshedpur, Jharkhand 831014
                </div>
                <div className="row px-lg-5">
                  <div className="col" style={{ fontSize: "1.5em" }}>
                    <Link
                      style={{ color: "#FFFFFF" }}
                      alt="Facebook Link"
                      to="https://www.facebook.com/NITJamshedpurOfficial/"
                    >
                      <span style={{ display: "none" }}>Facebook Link</span>
                      <FaFacebook />
                    </Link>
                  </div>
                  <div className="col" style={{ fontSize: "1.5em" }}>
                    <Link
                      style={{ color: "#FFFFFF" }}
                      to="https://www.instagram.com/nitjamshedpur_official/"
                    >
                      <span style={{ display: "none" }}>Instagram Link</span>
                      <FaInstagram />
                    </Link>
                  </div>
                  <div className="col" style={{ fontSize: "1.5em" }}>
                    <Link
                      style={{ color: "#FFFFFF" }}
                      to="https://twitter.com/jamshedpur_nit"
                    >
                      <span style={{ display: "none" }}>Twitter Link</span>
                      <FaTwitter />
                    </Link>
                  </div>
                  <div className="col" style={{ fontSize: "1.5em" }}>
                    <Link
                      style={{ color: "#FFFFFF" }}
                      to="https://www.youtube.com/channel/UCSKf3jRXKuA9QkPsu69tzkQ"
                    >
                      <span style={{ display: "none" }}>Youtube Link</span>
                      <FaYoutube />
                    </Link>
                  </div>
                  <div className="col" style={{ fontSize: "1.5em" }}>
                    <Link
                      style={{ color: "#FFFFFF" }}
                      to="https://www.linkedin.com/school/national-institute-of-technology-jamshedpur/mycompany/"
                    >
                      <span style={{ display: "none" }}>Linkedin Link</span>
                      <FaLinkedin />
                    </Link>
                  </div>
                </div>
              </div>
              <QuickLinks
                className="col-lg-8"
                style={{ borderLeft: "1px solid white" }}
              >
                <Row>
                  <h5
                    className="mx-3 text-light text-left"
                    style={{ fontWeight: "bolder" }}
                  >
                    Quick Links
                  </h5>
                  <h5
                    className="mx-auto text-light text-right"
                    style={{ fontSize: "0.95em" }}
                  >
                    Designed, Developed and Hosted by &nbsp;
                    <Link style={{ color: "#003A6E" }} to="/Clubs/Webteam">
                      {t("WebTeam")}
                    </Link>
                  </h5>
                </Row>
                <hr style={{ backgroundColor: "white" }} />
                <div className="row" style={{ fontSize: "0.95em" }}>
                  <div className="col-3">
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/convocation"
                    >
                      {" "}
                      > Convocation 2023{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Alumni"
                    >
                      {" "}
                      > Alumni Corner{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="https://www.nitjaa.org/"
                    >
                      {" "}
                      > NIT JSR Alumni Association(NITJAA){" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Institute/Ranking_and_Recognition"
                    >
                      {" "}
                      > Rankings{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Recruitments"
                    >
                      {" "}
                      > Recruitments{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/bis-mou"
                    >
                      {" "}
                      > BIS Corner{" "}
                    </Link>
                  </div>
                  <div className="col-3">
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Students/Placements"
                    >
                      {" "}
                      > Placements{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Students/Student-Activities"
                    >
                      {" "}
                      > Student Activities{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Students/Hostel-Management"
                    >
                      {" "}
                      > Hostels{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="https://sites.google.com/nitjsr.ac.in/centrallibrary/home"
                      target="_blank"
                    >
                      {" "}
                      > Central Library{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Students/Anti-Ragging"
                    >
                      {" "}
                      > Anti Ragging{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Students/Student-Life"
                    >
                      {" "}
                      > Student life{" "}
                    </Link>
                  </div>
                  <div className="col-3">
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/academic/Departments"
                    >
                      {" "}
                      > Departments{" "}
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/academic/Academic_Documents"
                    >
                      {" "}
                      > Academic Calendar
                    </Link>
                    <br/>
                    <a
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      href="https://nitjsr.ac.in/backend/uploads/notices/9826695c-934e-43f5-aae5-a8692c11e4fd-ACADEMIC%20CALENDAR%20FOR%20%20AUTUMN%20&%20SPRING%20SEMESTER%202024-2025%20(1).pdf"
                    >
                      {" "}
                      > List of Holidays
                    </a>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/galleryIndex"
                    >
                      {" "}
                      > Gallery
                    </Link>
                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/academic/Ordinance"
                    >
                      {" "}
                      > Ordinance
                    </Link>
                  </div>
                  <div className="col-3">
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Institute/About_NITJSR"
                    >
                      {" "}
                      > About NIT JSR
                    </Link>

                    <br />
                    <Link
                      className="mx-auto"
                      style={{ color: "#FFFFFF" }}
                      to="/Institute/How_to_Reach"
                    >
                      {" "}
                      > How to Reach
                    </Link>
                  </div>
                </div>
              </QuickLinks>
            </div>
            <hr style={{ color: "white" }} />
          </SectionWrapper>
          <div className="mb-3">
            <Row>
              <p
                style={{
                  color: "#003A6E",
                  fontSize: "1.1em",
                  margin: "auto",
                  fontWeight: "bolder",
                }}
              >
                VISITORS
              </p>
            </Row>
            <Row>
              <p
                className="count"
                style={{
                  color: "#003A4E",
                  fontSize: "1.4em",
                  margin: "auto",
                  letterSpacing: "0.5em",
                }}
              >
                {" "}
                {visitorCount}{" "}
              </p>
            </Row>
          </div>

          <Row
            style={{
              position: "relative",
              bottom: "1.5rem",
            }}
          >
            <div
              className="text-left text-dark col-lg-6"
              style={{
                fontFamily: "'Domine', serif",
                position: "relative",
                top: "0.2rem",
                left: "1rem",
              }}
            >
              &copy; {new Date().getFullYear()}{" "}
              <Link style={{ color: "#003A6E" }} to="/">
                {t("NIT Jamshedpur")}
              </Link>
            </div>
            <div
              className="text-right col-lg-6"
              style={{
                fontFamily: "'Domine', serif",
                position: "relative",
                bottom: "1.3rem",
                right: "1rem",
              }}
            >
              <Link style={{ color: "#003A6E" }} to="/policy">
                Policies
              </Link>
            </div>
          </Row>
        </Container>
      </Row>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Footer col one style
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "33.33%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
};

export default Footer;
