import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { Icon } from "react-icons-kit";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
import {
  ParagraphWrapper,
  TableWrapper,
  SectionWrapper,
} from "../../../assets/css/Custom/StudentStyle";
import { Helmet } from "react-helmet";

function AntiRagging() {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Helmet>
        <meta
          name="description"
          content="Anti-Ragging Committee in NIT Jamshedpur Campus.To save students from the evil of ragging."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,anti-ragging nit jsr,ragging nit jsr,anti-ragging,ragging,student committee,student committee nit jsr,anti-ragging,anti-ragging nit jsr,monitoring committee,monitoring committee nit jsr"
        />
      </Helmet>
      <Heading
        content={t("arr_0")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <ParagraphWrapper>
        <p className="paragraph">{t("arr_1")}</p>
      </ParagraphWrapper>
      <Heading
        content="Institute level Anti-ragging Monitoring Committee(Comprising of faculty members) for 2019"
        style={{
          fontSize: "1.2em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <TableWrapper>
        <table className="table">
          <thead>
            <tr id="haedRow">
              <th>Sr. No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tr>
            <td data-column="Sr. No">1.</td>
            <td data-column="Name">Director</td>
            <td data-column="Name"></td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">2.</td>
            <td data-column="Name">Deputy Director</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">3.</td>
            <td data-column="Name">Chief Warden</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">4.</td>
            <td data-column="Name">Dean(SW)</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">5.</td>
            <td data-column="Name">Registrar</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">6.</td>
            <td data-column="Name">Dr. Dinesh Kumar - Warden Hostel-I</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">7.</td>
            <td data-column="Name">Dr. Kanika Prasa - Wardern Hostel-D</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">8.</td>
            <td data-column="Name">Dr. Neha Agnihotri - Wardern Hostel-B</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">9.</td>
            <td data-column="Name">Dr. S. Metya-J</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">10.</td>
            <td data-column="Name">Shri. Jagdish Singh, PTI</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">11.</td>
            <td data-column="Name">Three representatives of parents of students</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">12.</td>
            <td data-column="Name">Three representatives of Fresher's students</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">13.</td>
            <td data-column="Name">Four representatives of students for 2nd, 3rd, 4th and PG</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">14.</td>
            <td data-column="Name">Representatives of District Adminstration</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">15.</td>
            <td data-column="Name">Ms. Anni Amrita (TV-18) - Representatives of Press</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
          <tr>
            <td data-column="Sr. No">16.</td>
            <td data-column="Name">Mr. Nandjee Prasad - Representative of and NGO</td>
            <td data-column="Email"></td>
            <td data-column="Contact"></td>
          </tr>
        </table>
      </TableWrapper>
      <SectionWrapper>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://nitjsr.ac.in/backend/uploads/recents/Antiragging%20duty%20chart.pdf"
        >
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; Hostel level Anti-ragging Commitee(Comprising of
            faculty Members & Wardens)
          </p>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.nitjsr.ac.in/backend/uploads/old_anti_ragging/ANTI%20RAGGING%20COMMITTEE%20final%20year%20girls-%202019.pdf"
        >
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; Student level Anti-ragging Commitee(Comprising of final
            year students) For Girls
          </p>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.nitjsr.ac.in/backend/uploads/old_anti_ragging/ANTI%20RAGGING%20COMMITTEE%20final%20year%20boys-%202019.pdf"
        >
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;Student level Anti-ragging Commitee(Comprising of final
            year students) For Boys
          </p>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.nitjsr.ac.in/backend/uploads/old_anti_ragging/Formation of anti ragging committee 22-23.pdf"
        >
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;  Formation of anti ragging committee 22-23
          </p>
        </a>
      </SectionWrapper>

      <ParagraphWrapper>
        <ul>
          <li>
            <p>{t("arr_2")}</p>
          </li>
          <li>
            <p>
              <b>{t("arr_3")}</b>
              {t("arr_4")}
            </p>
          </li>
        </ul>
      </ParagraphWrapper>
      <TableWrapper>
        <table className="table">
          <thead>
            <tr id="headRow">
              <th>Sr. No</th>
              <th>Name</th>
              <th>Contact No.</th>
            </tr>
          </thead>
          <tr>
            <td data-column="Sr. No">1</td>
            <td data-column="Name">Prof. A. K. L. Srivastava</td>
            <td data-column="Contact No.">9470 316 916</td>
          </tr>
          <tr>
            <td data-column="Sr. No">2</td>
            <td data-column="Name">Prof. Shailendra Kumar</td>
            <td data-column="Contact No.">7903 645 377</td>
          </tr>
          <tr>
            <td data-column="Sr. No">3</td>
            <td data-column="Name">Prof. Sanjay</td>
            <td data-column="Contact No.">9430 738 551</td>
          </tr>
          <tr>
            <td data-column="Sr. No">4</td>
            <td data-column="Name">Prof. M. K. Paswan</td>
            <td data-column="Contact No.">9771 300 840</td>
          </tr>
          <tr>
            <td data-column="Sr. No">5</td>
            <td data-column="Name">Prof. A. K. Choudhary</td>
            <td data-column="Contact No.">9431 161 850</td>
          </tr>
        </table>
      </TableWrapper>
      <ParagraphWrapper>
        <ul>
          <li>
            <p>
              <b>{t("arr_5")} </b>
              {t("arr_6")}
            </p>
          </li>
          <li>
            <p>
              <b>{t("arr_7")} </b>
              {t("arr_8")}
            </p>
          </li>
          <li>
            <p>
              <b>{t("arr_9")} </b>
              {t("arr_10")}
            </p>
          </li>
          <li>
            <p>
              <b>{t("arr_11")}</b>
              {t("arr_12")}
            </p>
          </li>
          <li>
            <p>
              <b>{t("arr_13")} </b>
              {t("arr_14")}
            </p>
          </li>
        </ul>
      </ParagraphWrapper>
    </Container>
  );
}

export default AntiRagging;
