import styled from 'styled-components';

export const ParagraphWrapper = styled.div`
  width: 100%;
  padding: 0;
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 20px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 1px 0 1px;
  }
  .paragraph {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }
  .smallparatext {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }
  .linktext {
    font-family: 'Poppins', sans-serif;
    color: #00a8ff;
  }
  .linktext:hover {
    color: #274187;
    cursor: pointer;
  }
  .detail-text {
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    color: #000;
    line-height: 1.5;
  }
  .list_conatiner li {
    list-style-type: disc;
  }
`;

export const BoxWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 3%;
  

  .listClass {
    list-style-type: "disc";
    padding: 10px;
    margin: 1px;
    // border: 1px solid #000;
    // border-bottom: 4px solid #274187;
    min-height: 100px;
    height: 100%;
  }
  .listClass li {
    font-size: 18px;
  }
  .linkp {
    color: #274187;
  }
  .linkp:before {
    // font-family: "Font Awesome 5 Free";
    // font-weight: 900;
    // content: "";
    // margin-right: 5px;
  }
  .linkp:hover {
    color: #000;
    cursor: pointer;
  }
  .listClass li:hover {
    color: #274187;
    cursor: pointer;
  }
  .linklist {
    padding: 15px;
  }
  .linklist li a {
    color: #000;
  }
  .linklist li a:hover {
    color: #274187;
  }
  .linklist li {
    padding: 7px;
    font-size: 18px;
    list-style: none;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;

  margin-bottom: 3%;
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 20px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 10px 0 10px;
  }

  .primary-btn {
    background: #fff;
    border: 2px solid #274187;
    border-radius: 10px;
    padding: 5px;
    color: #274187;
  }
  .primary-btn:hover {
    color: #fff;
    background-color: #274187;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
  }

  th {
    // background: #D70032;
    background: #274187;
    // background:#FCF22B;
    color: white;
    font-weight: bold;
  }

  td,
  th {
    padding: 1.5%;
    border: 1px solid #ccc;
    text-align: left;
    font-size: 15px;
  }
  .trshmob {
    display: none;
  }
  .dwn-btn {
    background: #fff;
    color: #274187;
    cursor: pointer;
    padding: 8px;
    outline: none;
    border: 1px solid #274187;
    border-radius: 8px;
  }
  .dwn-btn:hover {
    background-color: #274187;
    color: #fff;
  }
  .stat-btn {
    background: #fff;
    color: #274187;
    cursor: pointer;
    padding: 8px;
    outline: none;
    border: 1px solid #274187;
    border-radius: 8px;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    table {
      width: 100%;
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    .trshmob {
      display: block;
    }
    tr {
      border: 1px solid #ccc;
      margin-top:20px;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 42%;
      word-wrap: break-word;
      padding-bottom: 5%;
      overflow: hidden;
    }
    .textCenter td {
      text-align: center;
    }
    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);

      color: #000;
      font-weight: bold;
    }
  }
`;
export const SectionWrapper = styled.div`
  width: 100%;

  margin-bottom: 3%;
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 20px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 10px 0 10px;
  }
  .pdfLink {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  .pdfLink:hover {
    color: #274187;
  }
  .mycard {
    border: 1px solid lightgrey;
    height: 250px;
    margin-bottom: 5%;
    cursor: pointer;
    overflow: hidden;
  }
  .dept-img {
    width: 100%;
    height: 200px;
  }
  .dept-img:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
  .caption h6 {
    padding: 10px;
    color: #274187;
  }
  .fee-btn {
    border-radius: 20px;
    border: 1px solid #274187;
    color: #274187;
    padding: 10px;
    background: #fff;
  }
  .fee-btn:hover {
    background: #274187;
    color: #fff;
  }
  .members{
    display:flex;
    flex-wrap:wrap;
  }
  .members h3{
    font-size:20px;
  }
  .bar_container{
    display:flex;
    align-items:center;
  }
  #left_bar{
    height:1.8px;
    width:15px;
    background-color:#274187;
  }
  #middle_bar{
    height:5px;
    width:20px;
    background-color:#274187;
    border-radius:10px;
  }
  #right_bar{
    height:1.8px;
    width:40px;
    background-color:#274187;
  }
  .member_box{
    margin:10px;
    margin-right:30px;
    max-width:500px;

  }
  .member_box p{
    font-size:16px;
    color:#000;
    margin:5px;
  }
`;
