import React from "react";

export default function ResearchAreaSection() {
    return (
        <div className="panel-body body1">
            
            Foundry Technology <br/>
            Metal Casting Process <br/>
            Forged Powder Metallurgy Components <br/>
            Metal and Polymer Metrix Composites
            {/* Research Area */}
            {/* <h4><b>1. Research Experience</b></h4>

            <b>a.</b> Visiting Research Fellow, Mechanical and Computer Aided Engineering Department, Feng Chia
            University, Taichung, Taiwan, Nov. 2002- March 2003, June 2004.<br />
            <b>b.</b> Research Scholar, Applied Mechanics Department, I.I.T. Delhi, India, July 1997 - Sept. 2000 <br />

            <b>c. Sponsored Research Projects </b><br />

            <div className="ss">

                <b>i. Title:</b> Design Simulation, Modeling and Mechanical Properties Characterization of Carbon Nano
                Tube (CNT) Composites (completed) Funding Agency:Advanced Systems Laboratory (ASL) a Defense Research
                and Development Organization (DRDO), Ministry of Defence, Govt. of India. Fund allotment: Rs. 127
                lacs(Co-PI: Dr. S.B.Mishra, MED) <br />

                <b>ii. Title:</b> Characterization, Modelling and Analysis of Nano flake and Nano-sheet Graphite
                Nanocomposites, Funding Agency: Joint Research funding under Indo-Taiwan S&amp;T Programme Fund
                allotment: funding of 2 visits from each side. Period: March 2009-2012
            </div>
            <br />

            <b>d. Ph.D Thesis Supervised (Total-13 : Completed-11, Progress-2)</b><br />


            <b> i. Completed</b>
            <ol>

                <li style={{ listStyleType: 'disc' }}> B.P.Patel (2005), "Thermal Buckling and Postbuckling Characteristics of Composite Laminated Shells
                    "(Co-Supervisor: Prof. Y .Nath)
                </li>
                <li style={{ listStyleType: 'disc' }}>Ramesh Pandey (2008), "Some Studies on Nonlinear Analysis of Laminated Composite Rectangular Plates"
                    (Co-Supervsor: Prof. Anuj Jain)
                </li>
                <li style={{ listStyleType: 'disc' }}> Dinesh Bhatia (2011), "Some Studies on Biomechanical Aspects of Human Leg" (Co-Supervisor: Dr.
                    R.P.Tewari)
                </li>
                <li style={{ listStyleType: 'disc' }}>R.K.Srivastava (2012), "Study of Rigid Pavements for Village Roads in Alluvial Regions"
                    (CoSupervisor: Prof. S.K.Duggal).
                </li>
                <li style={{ listStyleType: 'disc' }}> Jeeoot Singh (2012), "Some Studies on Linear and Nonlinear Analysis of Rectangular Plates using RBF
                    based Meshfree Method" (Co-supervisor: Prof. T.Nath).
                </li>
                <li style={{ listStyleType: 'disc' }}> Ashutosh Upadhyay (2013) "Nonlinear Static and Dynamic Analysis of Skew Plates". ( </li>
                <li style={{ listStyleType: 'disc' }}> Syed Tabin Rushad (2013), "Some studies on the strengthening of the R.C.C. beams", (Cosupervisor:
                    Prof. S.K.Duggal)
                </li>
                <li style={{ listStyleType: 'disc' }}> T. Ramesh (2014), "Life Cycle Energy Analysis of Residential Buildings" (Co-Supervisor: Dr. Ravi
                    Prakash)
                </li>
                <li style={{ listStyleType: 'disc' }}> Ashok Jain (2014), "Some Studies on the Warehousing Structures using Prefabricated Ferrocement
                    Folded Plates".
                </li>
                <li style={{ listStyleType: 'disc' }}> Kishore Guru (2017), "Modeling and Simulation Studies of CNT reinforced Nanocomposites: A Hybrid
                    Approach" (Co-Supervisor: Dr. S.B.Mishra)
                </li>
                <li style={{ listStyleType: 'disc' }}> Hemant K Singh (2017), "Some Studies on Energy Saving through Building Insulation" (CoSupervisor:
                    Prof. Ravi Prakash)
                </li>
            </ol>
            <b>ii. Under Progress </b>
            <ol>
                <li style={{ listStyleType: 'disc' }}> Tushar Sharma, "Nonlinear Analysis of Tapered Beam", (Co-Supervisor: Dr. V.Murari).
                </li>
                <li style={{ listStyleType: 'disc' }}> Rajendra Bahadur, "Analysis of Spherical Shells on skew Planform", (Co-Supervisor: Dr. Ashutosh
                    Upadhyay)

                </li>
            </ol>






            <h4> <b>2. Industrial Projects/ Consultancy</b><br /></h4>


            <ol>
                <li style={{ listStyleType: 'disc' }}>Structural design of India gate at Sonauli, Gorakhpur (India- Nepal Border), UPPWD Gorakhpur, 1994,
                    0.40 lacs.
                </li>
                <li style={{ listStyleType: 'disc' }}> Structural Design Evaluation of the Agricultural Engineering College, Etawaha, UP, UPRNN, 199495,
                    1.60 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}>Structural Design of Curved Beams of Girls Polytechnic Amethi &amp; Sultanpur, UPPWD, 1996, 0.40
                    lacs.
                </li>
                <li style={{ listStyleType: 'disc' }}> Structural Design Evaluation of Overhead Water tank and Buildings at MNREC, Allahabad, 1995. 2.0
                    lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Structural Design of Foundation of Wind Tunnel at MNREC Allahabad.
                </li>
                <li style={{ listStyleType: 'disc' }}> Design of Minor Bridge at Pratapgarh, UPRNN, 2003, 0.60 lacs.
                </li>
                <li style={{ listStyleType: 'disc' }}> Design evaluation of Hospital Building at MLN Medical Allahabd, 2003. 0.50 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Stability Checking of retaining walls, Oriental structures Ltd. 2007, 0.25 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Design of Culverts, 2007, 0.25 lacs. ? Design of lifting bolts, 2007, 0.10 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Survey work of ADA Allahabad, 2007, 4.5 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}>Design of Extension of bridge culvert, Oriental structures, 2008, 0.25 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Design of sump well, Up Jal Nigam, 2008, 0.26 lacs.
                </li>
                <li style={{ listStyleType: 'disc' }}> Design checking of number water tanks, 2008, 0.25 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Design of 4 storied building, ADA Allahabad, 2008. 0.50 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Design of 4 storied building, ADA Allahabad, 2010. 0.50 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> A number of testing consultancies related to steel testing and others.(2004-8) 2.5 lacs.
                </li>
                <li style={{ listStyleType: 'disc' }}> Design Checking of different shops of Indian Railway sponsored by L&amp;T Ltd (2012)-2.5 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}>Evaluation of Flood Protection Scheme in Uttarakhand (2011-12)- 2.0 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Proof Checking of Design and Drawings of ROB at Rly Km 1409/8-9 on Kanpur(2012)- Rs. 0.68 lacs
                </li>
                <li style={{ listStyleType: 'disc' }}> Supervision of Civil construction works at the MNNIT Allahabad for the period 1994-95, 2003-2009
                </li>
                <li style={{ listStyleType: 'disc' }}> Third Party Inspection of Civil Works carried out by UP Roadways for the Mahakumbh 2013
                </li>
                <li style={{ listStyleType: 'disc' }}>Proof Checking of Design and Drawings of 4 ROBs (2014)- Rs. 2.50 lacs ? DST-FIST project for Applied
                    Mechanics Department, MNNIT Allahabad, Rs. 130 lacs (Prepared &amp; presented before the committee
                    on behalf of the deptt.)
                </li>
                <li style={{ listStyleType: 'disc' }}> Proof Checking of Design and Drawings of 4 ROB at ch. 94+050 (2015)- Rs. 0.50 lacs
                </li>
            </ol> */}

        </div>

    )
}
