import React, { useState, useEffect } from "react";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";
import { Stack, Pagination } from '@mui/material'

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function ActiveTender({ fontsize }) {
  const [tenders, setTender] = useState([]);
  const [yearWiseTenders, setYearWiseTenders] = useState({});
  const [year, setYear] = useState('');

  useEffect(() => {
    getRequest("tender/active").then((res) => {
      const data = res.data.sort((a, b) => new Date(b.time) - new Date(a.time)).map((item, idx) => {
        return {
          id: idx + 1,
          text: item.notification,
          link: item.link,
          idate: item.time.substring(0, 10),
          vupto: item.end_time.substring(0, 10),
          status: item.status,
        };
      });


      setTender(data);

      const groupedByYear = {};
      data.forEach((tender) => {
        const year = new Date(tender.idate).getFullYear();
        if (!groupedByYear[year]) {
          groupedByYear[year] = [];
        }
        groupedByYear[year].push(tender);
      });
      setYearWiseTenders(groupedByYear);
    });
  }, []);

  const { t } = useTranslation();

  const tendersPerPage = 8
  const pageCount = Math.ceil(tenders.length / tendersPerPage);
  let currPageTenders = tenders.slice(0, tendersPerPage)

  const [page, setPage] = useState(1)

  const StartEventNo = (page - 1) * tendersPerPage;
  const endEventNo = page * tendersPerPage
  currPageTenders = tenders.slice(StartEventNo, endEventNo)
  const paginate = (e, value) => {

    setPage(value);
    window.scrollTo(0, 0);
  }

  const handleYearChange = (event) => {
    setPage(1);
    const selectedYear = event.target.value;
    setYear(selectedYear);
    setTender(yearWiseTenders[selectedYear] || []);
  };

  return (
    <Container width="1250px">
      <br />
      <Heading />

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

        <Box sx={{ maxWidth: 150 , flexGrow:1 }}>
          <FormControl fullWidth>
            <InputLabel >Select Year</InputLabel>
            <Select
              labelId="year"
              id="year"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >
              {Object.keys(yearWiseTenders).map((year) => (
                <MenuItem value={year} key={year}>{year}</MenuItem>
              ))}

            </Select>
          </FormControl>
        </Box>
          {/* Add link to debarred agencies single page. */}
        <Link to="/DebarredAgencies">List of Debarred Agencies</Link>
      </Box>
      <TableWrapper>
        <table className="table">
          <thead>
            <tr id="headRow">
              <th style={{ padding: "10px 10px", minWidth: "70px" }}>{t("Sl. No.")}</th>
              <th>{t("Issue_Date")}</th>
              <th>{t("Valid_Upto")}</th>
              <th>{t("Notification")}</th>
              <th>{t("Status")}</th>
              <th>{t("Download")}</th>
            </tr>
          </thead>
          <tbody>
            {currPageTenders.map((item, id) => (
              <tr key={`list_key${id}`}>
                <td
                  style={{
                    fontSize: fontsize,
                    marginLeft: "40px",
                  }}
                  data-column="Sl.No."
                >
                  {item.id}
                </td>
                <td
                  style={{ fontSize: fontsize, minWidth: "120px" }}
                  data-column="Issue Date"
                >
                  {item.idate}
                </td>
                <td
                  style={{ fontSize: fontsize, minWidth: "120px" }}
                  data-column="Valid Upto"
                >
                  {item.vupto}
                </td>
                <td style={{ fontSize: fontsize }} data-column="Notification">
                  {item.text}
                </td>
                <td
                  style={{
                    fontSize: fontsize,
                    marginRight: "40px",
                  }}
                  data-column="Status"
                >
                  <button
                    className="stat-btn"
                    style={{
                      background: "#274187",
                      color: "#fff",
                      marginRight: "40px",
                    }}
                  >
                    {t("Active")}
                  </button>
                </td>
                <td
                  style={{
                    fontSize: fontsize,
                  }}
                  data-column="Download"
                >
                  {/* <Link href={item.link}> */}
                  <a href={item.link}>
                    <button className="dwn-btn" style={{ marginRight: "40px" }}>
                      {t("Download")}
                    </button>
                  </a>
                  {/* </Link> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
      {
        tenders.length > tendersPerPage && (<Stack mt={5} alignItems="center" >
          <Pagination count={pageCount} page={page} color="primary" defaultPage={1} onChange={paginate} size="large" />
        </Stack>)
      }
    </Container>
  );
}

export default ActiveTender;
