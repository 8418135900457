import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../Components/Preloader/Loader";

function ResearchAndConsultancy() {
  useEffect(() => {
    window.location.href = "https://rnc-nitjsr.in";
  }, []);

  return <Loader />;
}

export default ResearchAndConsultancy;
