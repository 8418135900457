import styled from "styled-components";

export const Images = styled.img`
  width: 20rem;
  aspect-ratio: 1.5/1;
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.widthSize}, 1fr)
  );
  gap: 0.75rem;
  padding-bottom: 0.75rem;
  @media only screen and (max-width: 768px) {
    padding: 8rem 0;
  }
`;

export const ImageCard = styled.div`
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; 
  margin-bottom: 0.8rem;
  object-fit:cover;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const GalleryContainer = styled.section`
  height: fit-content;
  overflow-y: auto;
  padding: 50px 0;
`;
