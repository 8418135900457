import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes } from "./Routes";
import ScrollToTop from "./Components/Layout/ScrollToTop/index";
import { Accessibility } from "./Components/Accessibility/main";
import LoaderHoc from "./HOC/LoaderHoc";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { useEffect, useState } from "react";
import { getRequest } from "./functions/request";
import Maintenance from "./Components/maintenance/Maintenance";

new Accessibility({
  session: {
    persistent: true,
  },
});
function App() {
  let [isLive, setIsLive] = useState(1);

  const checkIsLive = async () => {
    let { data } = await getRequest("/settings/frontendLive") || {};
    if(parseInt(data?.result?.value)===0){
      setIsLive(0);
    }
  };

  useEffect(() => {
    checkIsLive();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      {isLive ? (
        <Switch>
          {Routes.map((items) => {
            return (
              <Route
                key={items.id}
                exact={items.exact}
                path={items.path}
                component={LoaderHoc(items.component)}
              />
            );
          })}
        </Switch>
      ) : <Maintenance/>}
    </Router>
  );
}
export default App;
