import React from "react";
import { useTranslation } from 'react-i18next';
import Container from "../../../Components/UI/Container/index";
import Heading from "../../../Components/Heading/index";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";

function Grievance({ fontsize }) {
  const {t} = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("cells_top_subtitle_grievance")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: "center", fontSize: fontsize }}
        >
          {t("default_para")}
        </p>
      </SectionWrapper>
      <Heading
        content={t("cells_top_subtitle")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: "center", fontSize: fontsize }}
        >
          {t("default_para")}
        </p>
      </SectionWrapper>

    </Container>
  );
}

export default Grievance;
