import React from 'react'
import getData from '../Common/getData';
import List from '../Components/List';
function FacultyInCharge() {
    const [ response, error] = getData('');
    return (
        <div>
           <List
              section="Faculty InCharge"
              people={response}
              error={error}
              showProfile={1}
            />
        </div>
    )
}

export default FacultyInCharge
