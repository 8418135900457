import React, { Fragment } from 'react';
import { Icon } from 'react-icons-kit';
import { cloudDownload } from 'react-icons-kit/fa/cloudDownload';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { useTranslation } from "react-i18next";
import {
  SectionWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import Link from '../../../Components/CustomLink/CustomLink';
import { Helmet } from 'react-helmet';

function ActsAndStatues() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="Acts and Statues: The National Institutes of Technology Act, 2007 was enacted by the Parliament of India to declare India's National Institutes of Technology as Institutes of National Importance. The Act received the assent of the President of India on 5 June 2007 and became effective on Independence Day ,2007....."/>
          <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,about Us,Computer center,services,manthan"/>
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("heading_acts_and_statues")}
        />
        <SectionWrapper>
          <Link to="https://nitjsr.ac.in/backend/uploads/old_uploads/Gazette%20Notification%20Notifying%20the%20%20Amendments%20in%20First%20Statutes%20of%20NITs.pdf">
              <p className="pdfLink">
                <Icon icon={cloudDownload} size={18} />
                &nbsp;&nbsp; Gazette Notification Notifying the Amendments in
                First Statutes of NITs
              </p>
          </Link>
          <Link to="https://nitjsr.ac.in/backend/uploads/old_institute/NIT_ACT.pdf">
              <p className="pdfLink">
                <Icon icon={cloudDownload} size={18} />
                &nbsp;&nbsp; Gazette Notification NIT Act 2007
              </p>
          </Link>
          <Link to="https://nitjsr.ac.in/backend/uploads/old_institute/NIT_statutes.pdf">
              <p className="pdfLink">
                <Icon icon={cloudDownload} size={18} />
                &nbsp;&nbsp; Gazette Notification NIT Statutes Dated 23/04/2009
              </p>
          </Link>
          <Link to="https://nitjsr.ac.in/backend/uploads/recents/Annexure%20and%20Gazette.pdf">
              <p className="pdfLink">
                <Icon icon={cloudDownload} size={18} />
                &nbsp;&nbsp; Annexure and Gazette
              </p>
          </Link>

          <a target='_blank' rel="noreferrer" href="https://nitjsr.ac.in/backend/uploads/old_uploads/PAN%20&%20GST%20DETAILS.PDF">
              <p className="pdfLink">
                <Icon icon={cloudDownload} size={18} />
                &nbsp;&nbsp; Pan and Gst Details 
              </p>
          </a>
        </SectionWrapper>
      </Container>
    </Fragment>
  );
}


export default ActsAndStatues;
