import React from "react";
import { Button, Row, Col } from "react-bootstrap";

const minutes = ({ minutes }) => {
  return (
    <Row>
      {minutes.map((m) => (
        <Col xs={4} md={2} lg={2} style={{ padding: 10 }} key={m.name}>
          <Button variant="outline-dark" href={m.link}>
            {m.name}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

export default minutes;
