import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tag, Card, Skeleton, Tabs } from "antd";
import { Modal } from "react-bootstrap";
import { Button } from "antd";
import { getRequest } from "../../../../functions/request";
import { Page } from "../../../../assets/css/Custom/RecruitementStyles";
import TabItem from "../PublicationTypes/TabItem";

export default function PublicationSection() {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [publications, setPublications] = useState([]);
  const [publicationLoaded, setPublicationLoaded] = useState(false);
  const [articleContent, setArticleContent] = useState(null);
  const pubTypes = [
    {
      key: "ijp",
      text: "International Journal Paper",
      value: "International Journal Paper",
    },
    {
      key: "icp",
      text: "International Conference Paper",
      value: "International Conference Paper",
    },
    {
      key: "bkc",
      text: "Book Chapters",
      value: "Book Chapters",
    },
    { key: "bk", text: "Book", value: "Book" },
  ];

  const FetchArticleContent = async (currentArticle) => {
    if (currentArticle) {
      setArticleContent(null);
      var Article = await getRequest(
        `/publications/view?id=${currentArticle.id}`
      );
      setArticleContent(Article.data.result);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const publicationsData = await getRequest(`/publications?id=${id}`);
      let a = publicationsData.data.result;
      console.log(a);
      a.sort((a, b) => b.pub_date - a.pub_date);
      setPublications(a);
      setPublicationLoaded(true);
    };

    getData();
  }, [id]);

  return (
    <div className="block-box user-about">
      {publicationLoaded ? (
        <>
          {publications.length === 0 ? (
            "No Publication Found"
          ) : (
            <Tabs
              defaultActiveKey={pubTypes[0].key}
              style={{ minHeight: "350px" }}
            >
              {pubTypes.map((pubType) => (
                <Tabs.TabPane tab={pubType.text} key={pubType.key} >
                  {publications.filter((pub) => pub?.type === pubType.value)
                    .length === 0
                    ? `No ${pubType.text} found`
                    : 
                      <TabItem  publications={publications.filter((pub) => pub?.type === pubType.value)} pubType={pubType}/>
                  }
                </Tabs.TabPane>
              ))}
            </Tabs>
          )}
        </>
      ) : (
        <Skeleton active />
      )}


    </div>
  );
}