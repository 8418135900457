import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const SocialList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1;

  &.social-media {
    margin-top: 34vh;
  }

  li {
    margin: 0;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 58px;
      ${"" /* border-radius: 50%; */}
      overflow: hidden;
      line-height: 1;
      color: ${themeGet("colors.secondaryText", "#616970")};
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        background-color: ${themeGet("colors.text", "#294859")};
        z-index: -1;
        opacity: 0;
        transform: scale(0.01);
        transition: all 0.35s ease;
      }

      i {
        position: relative;
        z-index: 1;

        svg {
          width: 18px;
          height: auto;
          @media only screen and (max-width: 1360px) {
            width: 16px;
          }
          @media only screen and (max-width: 580px) {
            width: 14px;
          }
        }
      }
    }

    &.linkedin {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.linkedin", "#0077B5")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &.youtube {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.youtube", "#FF0000")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.facebook {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.facebook", "#3b5998")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.twitter {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.twitter", "#38A1F3")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.github {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.github", "#211f1f")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.instagram {
      a {
        color: ${themeGet("colors.white", "#ffffff")};
        &::before {
          background-color: ${themeGet("colors.instagram", "#f52f2f")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &:hover {
      a {
        color: ${themeGet("colors.hover", "#80aaff")};
        &::before {
          background-color: ${themeGet("colors.text", "#294859")};
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
`;
