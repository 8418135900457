import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionWrapper } from "../../../assets/css/Custom/DepartmentStyle";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../functions/request";
import { getFileURL } from "../../../functions/utils";

function Faculty({ Dept }) {
  const { branch } = useParams();
  const [FacultyData, setFacultyData] = useState([]);
  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: FacultyData.length < 3 ? FacultyData.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const FacultyHandler = async () => {
      try {
        let res = await getRequest(`/people/faculty?dept=${branch}`);
        setFacultyData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    FacultyHandler();
  }, [branch]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <SectionWrapper>
            <div
              className="faculty_heading"
              style={{
                fontSize: "1.5em",
                marginBottom: "15px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1.5% 2% 1% 3%",
              }}
            >
              <div style={{ fontSize: "1.0em", color: "#fff" }}>
                {t("Faculty")}
              </div>
              <div className="right-link">
                <Link to={`/departments/faculty/${branch}`} className="linktag">
                  <p>
                    <b>{t("Faculty_titlebutton")}</b>&nbsp;
                  </p>
                </Link>
              </div>
            </div>
            <br />
            {FacultyData.length > 0 ? (
              <Slider {...settings}>
                {FacultyData.map((item) => {
                  return (
                    <div className="single-slider" key={item.faculty_id}>
                      <Link to={`/people/profile/${item.faculty_id}`}>
                        <div className="mycard">
                          <div className="thumbnail mythumb">
                            <img
                              src={getFileURL(item.profile)}
                              alt="faculty-img"
                              className="faculty-img"
                            />
                          </div>
                          <div className="caption mycaption">
                            <p
                              className="text-center"
                              style={{ fontWeight: "600" }}
                            >
                              {(item.prename || "Prof.") +
                                " " +
                                item.fname +
                                " " +
                                item.lname}
                            </p>
                            <p
                              className="text-center"
                              style={{ color: "#000" }}
                            >
                              {!item.designation
                                ? "Designation"
                                : item.designation}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <p style={{ textAlign: "center", color: "#000" }}>
                {t("No_Data_Available")}
              </p>
            )}
          </SectionWrapper>
        </div>
      </div>
    </div>
  );
}

export default Faculty;
