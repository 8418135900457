import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";

const DeanAcadOffice = () => {
  const { t } = useTranslation();
  const ImgLink =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE109/profile/cd7f2347-4ead-4ef7-a592-7bc206fe85c7.jpg";
  const mrut =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EC112/profile/ece1a21b-e904-4ec6-9cc9-870c4e5c88f1.jpg";
  const dinesh =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CS111/profile/e3d3bd02-77d7-4609-8410-6a7d5c408351.jpg";
  const awdh =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE104/profile/41223832-4aad-4dc3-b918-b3529e12b63f.jpg";
  const madhu =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE118/profile/34bb8787-a3fb-40a1-91be-81df7a21b8f0.png";
  const vineet =
    "https://nitjsr.ac.in/backend/uploads/Faculty/ME121/profile/7c768890-533e-4c39-8cbb-4b185c6ab77e.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={ImgLink}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <hr />
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-academic")}
                </h5>
                <p className="text-center">{t("n-academic")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={mrut}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      {t("people_title1_tbody3_tr1")}
                    </h5>
                    <p className="text-center">Associate Dean</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={dinesh}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Dinesh Kumar
                    </h5>
                    <p className="text-center">Associate Dean</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={awdh}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Awdhesh K Choudhary
                    </h5>
                    <p className="text-center">Associate Dean</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={madhu}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Madhuri Seeram
                    </h5>
                    <p className="text-center">Associate Dean</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={vineet}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Vineet Sahoo
                    </h5>
                    <p className="text-center">Associate Dean</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean={"Academic"} />
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanAcadOffice;

