import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
// import { Tag, Divider } from "antd";
import { getRequest } from "../../../../functions/request";

export default function ResponsibilitySection() {
  const { id } = useParams();
  // const [research, setResearch] = useState([]);
  const [responsibility, setResponsibility] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // http://localhost:3030/api/people/responsibility?id=CS1303
    const getData = async () => {
      setIsLoading(true);
      const resData = await getRequest(`people/responsibility?id=${id}`);
      setResponsibility(resData.data);
      setIsLoading(false);
     
    };
    

    getData();
  }, [id]);
  console.log("ffff", responsibility);
  return (
    <div>
      {isLoading && <Skeleton active />}
      <p
        className="px-4"
        style={{
          color: "#000",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Poppins, sans-serif",
        }}
      ></p>
      {responsibility.length === 0 ? ("Nothing Found") : null}
      {responsibility &&
        responsibility.map((res) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: res.ds }}
              key={res.ds}
            ></div>
          );
        })}

    </div>
  );
}
