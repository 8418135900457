import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../../functions/request";
import { Pagination, Stack } from "@mui/material";
const { TabPane } = Tabs;

export default function ThesisSupervised() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [page3, setPage3] = useState(1);
  const [page1Count, setPage1count] = useState();
  const [page2Count, setPage2count] = useState();
  const [page3Count, setPage3count] = useState();

  const [p2events, setP2events] = useState([{}]);
  const [p1events, setP1events] = useState([{}]);
  const [p3events, setP3events] = useState([{}]);
  const [toggle, setToggle] = useState(0);

  const paginate1 = (e, value) => {
    setPage1(value);
    setToggle(!toggle);
  };
  const paginate2 = (e, value) => {
    setPage2(value);
    setToggle(!toggle);
  };
  const paginate3 = (e, value) => {
    setPage3(value);
    setToggle(!toggle);
  };
  const eventsPerPage = 9;
  useEffect(() => {
    const getData = async () => {
      const result = await getRequest(`/thesissupervised/phd?id=${id}`);
      let a = result.data;
      a.sort((x, y) => y.completion_year - x.completion_year);
      setData(a);
      const result2 = await getRequest(`/thesissupervised/mtech?id=${id}`);
      let b = result2.data;
      b.sort((x, y) => y.completion_year - x.completion_year);
      setData2(b);

      const result3 = await getRequest(`/thesissupervised/btech?id=${id}`);
      let c = result3.data;
      c.sort((x, y) => y.completion_year - x.completion_year);
      setData3(c);

      const p1count = Math.ceil(a.length / eventsPerPage);
      const p2count = Math.ceil(b.length / eventsPerPage);
      setPage1count(p1count);
      setPage2count(p2count);
      const p3count = Math.ceil(c.length / eventsPerPage);
      setPage3count(p3count);

      let currPageEvents1 = a.slice(
        (page1 - 1) * eventsPerPage,
        (page1 - 1) * eventsPerPage + eventsPerPage
      );
      let currPageEvents2 = b.slice(
        (page2 - 1) * eventsPerPage,
        (page2 - 1) * eventsPerPage + eventsPerPage
      );
      let currPageEvents3 = c.slice(
        (page3 - 1) * eventsPerPage,
        (page3 - 1) * eventsPerPage + eventsPerPage
      );
      setP1events(currPageEvents1);
      setP2events(currPageEvents2);
      setP3events(currPageEvents3);
    };

    getData();
  }, [id, toggle]);

  return (
    <Tabs defaultActiveKey="1" style={{ minHeight: "350px" }}>
      <TabPane tab={"Phd. Completed/Ongoing"} key="1">
        {data ? (
          <>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th>{t("S.No.")}</th>
                  <th>{t("Research Topic")}</th>
                  <th>{t("Name of Student")}</th>
                  <th>{t("Year of Completion")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ? p1events.map((value, index) => {
                      return (
                        <tr>
                          <td>{ ((page1 - 1) * eventsPerPage) + index + 1 }</td>
                          <td>{value.research_topic}</td>
                          <td>{value.name_of_student}</td>
                          <td>{value.completion_year}</td>
                          <td>
                            {value.heading === 1 ? "Completed" : "Ongoing"}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {data.length === 0 ? "No Thesis Supervised" : null}
          </>
        ) : (
          "No Thesis Supervised found"
        )}
        <Stack mt={5} alignItems="center">
          <Pagination
            count={page1Count}
            page={page1}
            color="primary"
            defaultPage={1}
            onChange={paginate1}
            size="large"
          />
        </Stack>
      </TabPane>

      <TabPane tab={"Masters"} key="2">
        {data2 ? (
          <>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th>{t("S.No.")}</th>
                  <th>{t("Research Topic")}</th>
                  <th>{t("Name of Student")}</th>
                  <th>{t("Year of Completion")}</th>
                </tr>
              </thead>
              <tbody>
                {data2
                  ? p2events.map((value, index) => {
                      return (
                        <tr>
                          <td>{ ((page2 - 1) * eventsPerPage) + index + 1 }</td>
                          <td>{value.research_topic}</td>
                          <td>{value.name_of_student}</td>
                          <td>{value.completion_year}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {data2.length === 0 ? "No Thesis Supervised" : null}
          </>
        ) : (
          "No Thesis Supervised found"
        )}
        <Stack mt={5} alignItems="center">
          <Pagination
            count={page2Count}
            page={page2}
            color="primary"
            defaultPage={1}
            onChange={paginate2}
            size="large"
          />
        </Stack>
      </TabPane>

      <TabPane tab={"Bachelors"} key="3">
        {data3 ? (
          <>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th>{t("S.No.")}</th>
                  <th>{t("Research Topic")}</th>
                  <th>{t("Name of Student")}</th>
                  <th>{t("Year of Completion")}</th>
                </tr>
              </thead>
              <tbody>
                {data3
                  ? p3events.map((value, index) => {
                      return (
                        <tr>
                          <td>{ ((page3 - 1) * eventsPerPage) + index + 1}</td>
                          <td>{value.research_topic}</td>
                          <td>{value.name_of_student}</td>
                          <td>{value.completion_year}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {data3.length === 0 ? "No Thesis Supervised" : null}
          </>
        ) : (
          "No Thesis Supervised found"
        )}
        <Stack mt={5} alignItems="center">
          <Pagination
            count={page3Count}
            page={page3}
            color="primary"
            defaultPage={1}
            onChange={paginate3}
            size="large"
          />
        </Stack>
      </TabPane>
    </Tabs>
  );
}
