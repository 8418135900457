import React, { Fragment } from "react";
import { TableWrapper } from "../../assets/css/Custom/AcademicStyle";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";
import BannerImage from "../../assets/images/others/newbuilding.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import {AssistantListdata,NoteDowns} from "./AssistantData"
import { Typography, Grid } from "@mui/material";
import Heading from "../../Components/Heading";

function AssistantList() {
  const listItems = AssistantListdata.map((each_dept) => {
    const filesData = each_dept.data.map((e) => {
      return (
        <>
          <td>
            {e.Eligible && (
              <Typography align="center">
                <Link to={{ pathname: `${e.Eligible}` }} target="_blank">
                  <FiDownload size="25px" />
                </Link>
              </Typography>
            )}
          </td>
          {/* <td>
            {e.Not_eligible && (
              <Typography align="center">
                <Link to={{ pathname: `${e.Not_eligible}` }} target="_blank">
                  <FiDownload size="25px" />
                </Link>
              </Typography>
            )}
          </td> */}
        </>
      );
    });
    return (
      <tr>
        <td>{each_dept.Dept}</td>
        {filesData}
      </tr>
    );
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        {/* <title>
        {t(`${ActiveComp.split(" ").join("_")}`)} | {t("NIT Jamshedpur")} |
        राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
      </title> */}
      </Helmet>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <ContentWrapper>
            <Banner
              heading=" Provisionally Eligible and Ineligible Candidates for the Interview to the post of Assistant Professor"
              bg_img={BannerImage}
            />
            <Container width="1450px">
              <TableWrapper>
                <table>
                  {/* <tr>
                    <th>Department</th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 6000
                      </Typography>
                    </th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 7000
                      </Typography>
                    </th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 8000
                      </Typography>
                    </th>
                  </tr> */}










                  {/* //New */}
                  <tr>
                    <th>Department</th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 6000
                      </Typography>
                    </th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 7000
                      </Typography>
                    </th>
                    <th colSpan={1}>
                      <Typography align="center" color="#ffffff">
                        AGP 8000
                      </Typography>
                    </th>
                  </tr>



                  {/* <tr>
                    <th>Department</th>
                    <th>
                      <Typography align="center" color="#ffffff">
                        ELIGIBLE
                      </Typography>
                    </th>{" "}
                    <th>
                      <Typography align="center" color="#ffffff">
                        NOT ELIGIBLE
                      </Typography>
                    </th>
                    <th>
                      <Typography align="center" color="#ffffff">
                        ELIGIBLE
                      </Typography>
                    </th>{" "}
                    <th>
                      <Typography align="center" color="#ffffff">
                        NOT ELIGIBLE
                      </Typography>
                    </th>
                    <th>
                      <Typography align="center" color="#ffffff">
                        ELIGIBLE
                      </Typography>
                    </th>{" "}
                    <th>
                      <Typography align="center" color="#ffffff">
                        NOT ELIGIBLE
                      </Typography>
                    </th>
                  </tr> */}

                  {listItems}
                </table>

                <br />
                <br />

                <Heading
                  content={"General Informations / Instructions"}
                  style={{
                    fontSize: "1.5em",
                    marginBottom: "20px",
                    marginTop: "13px",
                    fontWeight: "500",
                    background: "#274187",
                    color: "#fff",
                    padding: "1% 1% 1% 3%",
                  }}
                />

                <Grid container direction="column">
                  {NoteDowns.map((data, i) => (
                    <Grid key={data.key} item>
                      <Grid container spacing={2} direction="row">
                        <Grid item>{i + 1}.&nbsp;</Grid>
                        <Grid xs={11} item>
                          {data.text}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* <table>
                  <tr>
                    <th>Note:</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>1.</td>
                    <td>
                      For any query regarding above list please mail us at
                      recruitment@nitjsr.ac.in (cc to registrar@nitjsr.ac.in) on
                      or before 12.11.2019. The reply/decision with respect to
                      all the queries/mail/representation will be uploaded on
                      the website after 12.11.2019. However, no additional
                      documents/query will be accepted after 12.11.2019.
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>
                      All those candidates who have been declared ineligible and
                      want to send requiered certificates, are advised to send
                      softcopies of the same before 12.11.2019.
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>
                      NOC, OBC, EWS, updated certificates are required at the
                      time of interview.
                    </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td style={{ color: "red" }}>
                      All candidates are strictly advised to send email to the
                      above mentioned email id only.
                    </td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>
                      For updates candidates are requested to visit our
                      Institute website{" "}
                      <a href=" http://archive.nitjsr.ac.in">
                        {" "}
                        http://archive.nitjsr.ac.in
                      </a>{" "}
                      regularly.
                    </td>
                  </tr>
                </table> */}
              </TableWrapper>
            </Container>
          </ContentWrapper>
          <Footer />
        </CharityWrapper>
        {/* End of Main wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
}

export default AssistantList;
