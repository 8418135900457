import * as React from "react";
import { Link as LinkImport } from "react-router-dom";

function validExternalURL(url) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
}

const Link = ({ to, ...otherProps }) =>
  validExternalURL(to) ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={to} target="_blank" rel="noreferrer" {...otherProps} />
  ) : (
    <LinkImport to={to} {...otherProps} />
  );

export default Link;
