import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Image } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import {
  SectionHeader,
  SliderWrapper,
} from "../EventSection/EventSection.style";
import { Link } from "react-router-dom";

import { data } from "./data";

import CurrentAchvCard from "../../../Components/CurrentAchvCard";

const WorkSection = ({ fontsize }) => {
  const { t } = useTranslation();
  //   const [data, setData] = React.useState([]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  //   const fetching = async () => {
  //     try {
  //       let res = await getRequest(`/events/upcoming`);
  //       setData(res.data.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   React.useEffect(() => {
  //     fetching();
  //   }, []);
  //   data;

  return (
    // <section id="work" style={{ marginBottom: "1rem" }}>
    <section id="work">
      {/* <Container width={data.length >= 3 ? "90%" : "60%"}> */}
      <Container width="100%">
        <SectionHeader>
          <Heading content={t("Current_Achievements")} />
        </SectionHeader>
        <SliderWrapper>
          <Slider {...settings}>
            {data.map((item, key) => (
              <CurrentAchvCard
                key={`event_card_${key}`}
                item={item}
                fontsize={fontsize}
              />
            ))}
          </Slider>
        </SliderWrapper>
        {data.length === 0 && (
          <div>
            <div className="singleCard text-center">
              <p style={{ fontSize: fontsize }} className="desc">
                Hello
              </p>
            </div>
          </div>
        )}
        <div
          className="mt-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to="/Achievers" className="learn__more-btn">
            {" "}
            <span className="hyphen" />
            <span className="btn_text">{t("show_more_button")}</span>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default WorkSection;
