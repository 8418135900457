import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Fragment } from "react";
import { ResetCSS } from "../../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../../assets/css/Main/main.style";
import { CharityWrapper } from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import Navbar from "../../Components/Layout/HomeNavbar";
import { DrawerProvider } from "../../contexts/DrawerContext";
import DrawerSection from "../../Components/Layout/DrawerSection";
import EventCard from "../../Components/EventCard";
import { data } from "../Home/CurrentAchievement/data";
import { Box, Grid } from "@mui/material";
import CurrentAchvCard from "../../Components/CurrentAchvCard";
import Container from "../../Components/UI/Container";
import { SectionHeader } from "../Home/ResearchSection/ResearchSection.style";
import Heading from "../../Components/Heading";
import { SliderWrapper } from "../Home/ResearchSection/ResearchSection.style";

export const Achievers = () => {
  const [achievers, setAchievers] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const getAchievers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/ourachievers/get`
    );
    const dataRe = await response.json();
    console.log(dataRe);

    return dataRe;
  };
  useEffect(() => {
    setLoading(true);
    getAchievers().then((datas) => {
      setAchievers(datas.data[0].content);
      console.log(datas.data[0].content);
      console.log(datas);
      setLoading(false);
    });
    setLoading(false);
  }, []);

  const { t } = useTranslation();
  // console.log(data);
  return (
    loading && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10rem",
        }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ),
    (
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        {/* End of Main head section */}
        {/* Start Main wrapper section */}

        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>

          {/* <ContentWrapper
          style={{
            position: "absolute",
            top: "150px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          >
          {data.map((item, key) => (
            <CurrentAchvCard item={item} fontsize={6} />
            ))}
          </ContentWrapper> */}

          <section
            id="work"
            style={{ marginBottom: "1rem", position: "absolute", top: "150px" }}
          >
            {/* <Container width={data.length >= 3 ? "90%" : "60%"}> */}
            <Box margin={"70px"}>
              <div dangerouslySetInnerHTML={{ __html: achievers }} />
            </Box>

            <Container width="100%">
              <SectionHeader>
                <Heading content={t("Current_Achievements")} />
              </SectionHeader>
              <SliderWrapper>
                <Grid container spacing={5}>
                  {data.map((item, key) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CurrentAchvCard
                        key={`event_card_${key}`}
                        item={item}
                        fontsize={6}
                      />
                    </Grid>
                  ))}
                </Grid>
              </SliderWrapper>
              {data.length === 0 && (
                <div>
                  <div className="singleCard text-center">
                    <p style={{ fontSize: 12 }} className="desc">
                      No data found
                    </p>
                  </div>
                </div>
              )}
              <div
                className="mt-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            </Container>
          </section>
        </CharityWrapper>
      </Fragment>
    )
  );
};
