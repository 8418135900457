import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import { Helmet } from "react-helmet";
import { Box, Grid, Divider, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CVO_HL_Yadav from "../../../assets/images/CVO_HL_Yadav.jpg";

const CVO = ({ fontsize }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles({
    mainCard: {
      padding: 8,
      textAlign: "center",
    },
    mainImg: {
      width: "256px",
      height: "256px",
      borderRadius: "128px",
    },
    mainText: {
      textAlign: "center",
      paddingTop: "16px",
      fontSize: "22px",
      fontWeight: "700",
    },
    mainTextL: {
      fontSize: "15px",
      color: "#000",
      fontWeight: 550,
    },
    mainTextR: {
      fontSize: "15px",
      color: "grey",
      fontWeight: 500,
      paddingInline: "4px",
      "&-email": {
        fontSize: "15px",
        color: "grey",
        fontWeight: 500,
        paddingInline: "4px",
        "&:hover": {
          color: "blue",
          cursor: "pointer",
        },
      },
    },
    mainTextW: {},
    regiOff: {
      textAlign: "center",
      fontSize: "32px",
      color: "#274187",
    },
    officeCard: {
      padding: "8px 12px",
    },
    officeName: {
      fontSize: "18px",
      color: "black",
    },
  });
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="NIT Jamshedpur, NIT Jsr, CVO, Chief Vigilance Officer  "
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("cvo_contacts_head")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <br />

        <Box>
          <Grid
            container
            spacing={1}
            wrap="wrap"
            direction="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid xs={12} md={4} item>
              <Box className={classes.mainCard}>
                <img
                  className={classes.mainImg}
                  src={
                    "https://www.nitjsr.ac.in/backend/uploads/Faculty/CE121/profile/37c9170c-cdf4-4650-a2ab-ae61514ee9e6.jpg"
                  }
                  alt="CVO"
                />
                <br />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>Prof. Virendra Kumar</b>
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>CVO</b> (Chief Vigilance Officer)
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            Email-ID: cvo@nitjsr.ac.in
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            Contact No: +91-657-237-XXXX
          </p>
        </div>
      </Container>
    </>
  );
};

export default CVO;
