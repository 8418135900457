import React from "react";
// import { Link } from "react-router-dom";
import Link from "../../../Components/CustomLink/CustomLink";

import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import { Icon } from "react-icons-kit";
import { longArrowRight } from "react-icons-kit/fa/longArrowRight";
import SectionWrapper, { FeatureWrapper } from "./featureSection.style";
import { featureData } from "../../../assets/data";

const FeatureSection = ({ fontsize }) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper id="feature">
      <Container width="90%">
        <FeatureWrapper>
          {featureData
            ? featureData.features.map((items, index) => {
                return (
                  <Link
                    to={{ pathname: items.link }}
                    key={"single_feature_" + index}
                    target={items.openInNew ? "_blank" : "_self"}
                    className="quick_link_container"
                    style={
                      index % 2 === 0
                        ? { backgroundColor: "#f5f8fd" }
                        : { backgroundColor: "#fff" }
                    }
                  >
                    <img alt="img" src={items.icon} className="thumbnail" />
                    <h3>{t(`home_featureSection_head_${items.id}`)}</h3>
                    <p className="d-none d-lg-block">
                      {t(`desc_feature_${items.id}`)}
                    </p>
                    {/* <h3>{t(`${items.title}`)}</h3>
                  <p>{t(`${items.description}`)}</p> */}
                    <Icon
                      icon={longArrowRight}
                      className="right_arrow d-none d-lg-block"
                      size={40}
                    />
                  </Link>
                );
              })
            : null}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default FeatureSection;
