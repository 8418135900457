import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { LongPara, TableContainer, TableWrapper } from '../styles';
import { getRequest } from '../../../functions/request';
import { Helmet } from 'react-helmet';
import img3 from "./img/img2.jpg";
import Link from '../../../Components/CustomLink/CustomLink';
const CentralLibrary = () => {
  const { t } = useTranslation();
  const [libraryCommitteeData, setLibraryCommitteeData] = useState([]);
  const [libraryStaffData, setLibraryStaffData] = useState([]);
  const libraryLinks=[{
    id: 1,
    siteName: "Library Webpage(intranet)",
    link: " http://10.51.0.20/wordpress",
  },
  {
    id: 2,
    siteName: "Library Online Catalogue(Koha LMS)(intranet)",
    link: " http://10.51.0.10",
  },
  {
    id: 3,
    siteName: "Library E-Resource Remote Access",
    link: " https://idp.nitjsr.ac.in",
  }];
  let committeeHandler = async () => {
    try {
      const res = await getRequest(
        `/central_facilities?facility=library_committee`
      );
      setLibraryCommitteeData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  let staffHandler = async () => {
    try {
      const res = await getRequest(
        `/central_facilities?facility=library_staff`
      );
      setLibraryStaffData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    committeeHandler();
    staffHandler();
    return () => {
      setLibraryCommitteeData([]);
      setLibraryStaffData([]);
    };
  }, []);

                       
	return (
       <>
		<Helmet>
			<meta name="description" content="The Central Library came into existence as an important component of the institute in 1960. It continues to provide services to its users : Students, Teachers, Researchers and other staff members."/>
		    <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Library,Central,Reading,Book,Bank,INDEST,CONSORTIUM"/>
		    </Helmet>
		<Container width="1250px">
			<br />
			<LongPara>
				{t("central_facilities_body")}
				<br />
				<br />
				<ol>
					<li style={{ listStyleType: 'disc' }}>
						<LongPara>
							{t("central_facilities_point_1")}
						</LongPara>
					</li>
					<li style={{ listStyleType: 'disc' }}>
						<LongPara>{t("central_facilities_point_2")}</LongPara>
					</li>
					<li style={{ listStyleType: 'disc' }}>
						<LongPara>{t("central_facilities_point_3")}</LongPara>
					</li>
					<li style={{ listStyleType: 'disc' }}>
						<LongPara>{t("central_facilities_point_4")}</LongPara>
					</li>
				</ol>
			</LongPara>
      <center>
    <img src={img3} alt="thumnail" className="rounded" style={{marginLeft:"auto",marginRight:"auto"  }}/>
</center>
      <Heading
        content={t("central_facilities_space_heading")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <LongPara>
        {t("central_facilities_space_para")}
        <br />
        <br />
        <ol>
          <li style={{ listStyleType: "disc" }}>
            <b>{t("central_facilities_space_reading_head")}</b>
            <LongPara>{t("central_facilities_space_reading_body")}</LongPara>
          </li>
          <li style={{ listStyleType: "disc" }}>
            <b>{t("central_facilities_collection_point_key_1")}</b>
            <LongPara>{t("central_facilities_space_reading_body")}</LongPara>
          </li>
        </ol>
      </LongPara>

      <Heading
        content={t("central_facilities_book_bank_heading")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <LongPara>
        {t("central_facilities_book_bank_head")}
        <ol>
          <li style={{ listStyleType: "number" }}>
            <LongPara>{t("central_facilities_book_bank_para_1")}</LongPara>
          </li>
          <li style={{ listStyleType: "number" }}>
            <LongPara>{t("central_facilities_book_bank_para_2")}</LongPara>
          </li>
        </ol>
      </LongPara>

      <Heading
        content={t("central_facilities_acquistion_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <LongPara>
        {t("central_facilities_acquistion_body")}
        <ol>
          <li style={{ listStyleType: "disc" }}>
            <LongPara>
              {t("central_facilities_acquistion_body_point_1")}
            </LongPara>
          </li>
          <li style={{ listStyleType: "disc" }}>
            <LongPara>
              {t("central_facilities_acquistion_body_point_2")}
            </LongPara>
          </li>
        </ol>
      </LongPara>

      <Heading
        content={t("central_facilities_collection_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <LongPara>
        {t("central_facilities_collection_body")}
        <TableContainer>
          <TableWrapper>
            <table>
              <tbody>
                <tr>
                  <td>{t("central_facilities_collection_point_key_1")}</td>
                  <td>{t("central_facilities_collection_point_value_1")}</td>
                </tr>
                <tr>
                  <td>{t("central_facilities_collection_point_key_2")}</td>
                  <td>{t("central_facilities_collection_point_value_2")}</td>
                </tr>
                <tr>
                  <td>{t("central_facilities_collection_point_key_3")}</td>
                  <td>{t("central_facilities_collection_point_value_3")}</td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>
        </TableContainer>
      </LongPara>

      <Heading
        content={t("central_facilities_working_hours")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <LongPara>
        {t("central_facilities_working_hours_head")}
        <TableContainer>
          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th>{t("central_facilities_working_hours_table_head_1")}</th>
                  <th colSpan="2">
                    {t("central_facilities_working_hours_table_head_2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("central_facilities_working_hours_table_value_1")}</td>
                  <td>
                    {t("central_facilities_working_hours_table_value_1_1")}
                  </td>
                  <td>
                    {t("central_facilities_working_hours_table_value_1_2")}
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>{t("central_facilities_working_hours_table_value_2")}</td>
                  <td>
                    {t("central_facilities_working_hours_table_value_2_1")}
                  </td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>
        </TableContainer>
      </LongPara>

      <Heading
        content={t("central_facilities_other_facilites_head")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <LongPara>
        <LongPara>{t("central_facilities_other_facilites_para_1")}</LongPara>
        <LongPara>
          {t("central_facilities_other_facilites_para_2")}
          <ol>
            <li style={{ listStyleType: "disc" }}>
              <LongPara>
                {t("central_facilities_other_facilites_points_1")}
              </LongPara>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <LongPara>
                {t("central_facilities_other_facilites_points_2")}
              </LongPara>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <LongPara>
                {t("central_facilities_other_facilites_points_3")}
              </LongPara>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <LongPara>
                {t("central_facilities_other_facilites_points_4")}
              </LongPara>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <LongPara>
                {" "}
                {t("central_facilities_other_facilites_points_5")}
              </LongPara>
            </li>
          </ol>
        </LongPara>
        <LongPara>{t("central_facilities_other_facilites_para_3")}</LongPara>
      </LongPara>
      <Heading
        content={t("central_facilities_library_commitee")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

      <TableContainer>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>{t("central_facilities_table_key_1")}</th>
                <th>{t("central_facilities_table_key_2")}</th>
                <th>{t("central_facilities_table_key_3")}</th>
                <th>{t("central_facilities_table_key_4")}</th>
              </tr>
            </thead>
            <tbody>
              {libraryCommitteeData.map((person) => (
                <tr key={person.email}>
                  <td>{person.designation}</td>
                  <td>
                    {person.preName} {person.fname} {person.lname}
                  </td>
                  <td>{person.phoneNumber}</td>
                  <td>{person.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      </TableContainer>

      <Heading
        content={t("central_facilities_library_staff")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />


			<TableContainer>
				<TableWrapper>
					<table>
						<thead>
							<tr>
								<th>{t("central_facilities_table_key_1")}</th>
								<th>{t("central_facilities_table_key_2")}</th>
								<th>{t("central_facilities_table_key_3")}</th>
								<th>{t("central_facilities_table_key_4")}</th>
							</tr>
						</thead>
						<tbody>
							{libraryStaffData.map((person) => (
								<tr key={person.email}>
									<td>{person.designation}</td>
									<td>
										{person.preName} {person.fname} {person.lname}
									</td>
									<td>{person.phoneNumber}</td>
									<td>{person.email}</td>
								</tr>
							))}
						</tbody>
					</table>
				</TableWrapper>
			</TableContainer>

      <Heading
        content={t("Internal Links")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />

<TableContainer>
				<TableWrapper>
					<table>
						<thead>
							<tr>
              <th>{t(`internal_link_table_head_1`)}</th>
              <th>{t(`internal_link_table_head_2`)}</th>
              <th>{t(`internal_link_table_head_3`)}</th>
							</tr>
						</thead>
					  <tbody>
                  {libraryLinks &&
                    libraryLinks.sort((a,b) => a.siteName.localeCompare(b.siteName)).map((items, index) => {
                    return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{items.siteName}</td>
                      <td><Link to={{ pathname: items.link}} target='_blank'>{items.link}</Link></td>
                    </tr>
                      )
                    })}
                    </tbody>
					</table>
				</TableWrapper>
			</TableContainer>
		</Container>
		</>
	);
};

export default CentralLibrary;
