import React, { useEffect, useState } from 'react';
import { TableWrapper } from '../../../../assets/css/Custom/AcademicStyle';
import Heading from '../../../../Components/Heading';
import Container from '../../../../Components/UI/Container';
import {
	IoIosArrowDropleftCircle,
	IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { FiDownload } from 'react-icons/fi'
import { Spinner } from 'react-bootstrap';
import { getRequest } from '../../../../functions/request';
import { formatDateString } from '../../../../functions/utils';

function Employees({ fontsize }) {
	const [forms, setForms] = useState([]);
	useEffect(() => {
		(async () => {
			getRequest(`/manuals_forms/employee`)
				.then((res) => {
					return res.data.manuals.map((item, idx) => {
						return {
							id: idx,
							text: item.title,
							link: item.file_path,
							date: item.uploaded_on,
						};
					});
				})
				.then((data) => setForms(data));
		})();
	}, []);

	const totalPerPage = 15;
	const [currPage, setCurrPage] = useState(1);

	return (
		<Container width="1250px">
			<br />
			<Heading />
			{forms.length !== 0 ? (
				<>
					<TableWrapper style={{ minHeight: '60vh !important' }}>
						{forms
							.slice(
								(currPage - 1) * totalPerPage,
								Math.min(currPage * totalPerPage, forms.length)
							)
							.map((item, id) => (
								<div key={`list_key${id}`} className='row border-bottom pb-2 mb-2'>
									<div>{item.id + 1}</div>
									<div className='col'>
										<a
											href={item.link}
											target="_blank"
											rel="noopener noreferrer"><b className='text-secondary'>{item.text}</b></a>
										<div className='row'>
											<div className='col-md-6'>
												{formatDateString(item.date)}
											</div>
											<div className='col-md-6 text-right'>
												<a
													href={item.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													<FiDownload size="25px" />
												</a>
											</div>
										</div>
									</div>
								</div>
							))}
					</TableWrapper>
					<div
						style={{ textAlign: 'center', color: '#274187', margin: '1rem 0' }}
					>
						{currPage !== 1 ? (
							<IoIosArrowDropleftCircle
								size="1.5em"
								onClick={() => setCurrPage((prev) => prev - 1)}
								style={{ cursor: 'pointer', margin: '0 1rem' }}
							/>
						) : null}
						{currPage}
						{currPage !== Math.ceil(forms.length / totalPerPage) ? (
							<IoIosArrowDroprightCircle
								size="1.5em"
								onClick={() => setCurrPage((prev) => prev + 1)}
								style={{ cursor: 'pointer', margin: '0 1rem' }}
							/>
						) : null}
					</div>
				</>
			) : (
				<h3 style={{ marginTop: '5rem' }}>
					<center>
						<Spinner animation="border" />
					</center>
				</h3>
			)}
		</Container>
	);
}

export default Employees;
