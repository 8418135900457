import React from "react";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Helmet } from "react-helmet";

const columns = [
  { id : "post_name", label: "Position", minWidth: 170 },
  { id: "name", label: "Student Name", minWidth: 170 },
  { id: "phone", label: "Mobile", minWidth: 170 },
  { id: "email", label: "E-mail", minWidth: 170 },
];

function createData(post_name, name, phone, email) {
  return { post_name, name, phone,email };
}

const rows = [
  createData("President", "Jay Singh","6204469926", "president.sc@nitjsr.ac.in"),
  createData("Vice President", "Aditya Kumar","9142135351", "vp.sc@nitjsr.ac.in"),
  createData("General Secretary", "Unnati Jaiswal","9334213358", "gensec.sc@nitjsr.ac.in"),
  createData("Club Secratery", "Akanksha Priya","7488371454", "clubsec.sc@nitjsr.ac.in"),
  // createData("Technical Secretary", "Rishav Raj","9525018921", "techsec.sc@nitjsr.ac.in"),
  createData("Joint Technical Secretary","Vishal Raj","8863832703","jtechsec.sc@nitjsr.ac.in"),
  createData("Cultural Seceretary", "Ritik Kumar","8434311949", "culsec.sc@nitjsr.ac.in"),
  createData("Joint Cultural Secretary","Samar Raghuvanshi","9305738151","jculsec.sc@nitjsr.ac.in"),
  createData("Alumni Secretary", "Rishav Rai","7979049359", "alumsec.sc@nitjsr.ac.in"),
  createData("Joint Alumni Secretary","Swarnim Kumari","9775120355","jas.sc@nitjsr.ac.in"),
  createData("Sports Secretary", "Ravindra Raj"," 8434794879", "sportssec.sc@nitjsr.ac.in"),
  createData("Joint Sports Secretary","Anubhav","9034193237","jsportssec.sc@nitjsr.ac.in"),
  createData("PG Representative","Prakhar Verma","9369672976","pgrep.sc@nitjsr.ac.in"),
  createData("Joint Secretary", "Sumit Kumar Baranwal","7979775180", "js.sc@nitjsr.ac.in"),
  createData("Joint Secretary", "Haritima Sinha","7903975778", "js.sc@nitjsr.ac.in"),
];

function StudentCouncil({ fontsize }) {
  return (
    <Container width="1250px">
      <br />

      <Helmet>
        <meta
          name="description"
          content="NIT Jamshedpur The Cradle of technical excellence "
        />
        <meta name="keywords" content="nit jamshedpur,Student Council" />
      </Helmet>
      <Heading
        content={"1. STUDENTS’ COUNCIL"}
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">
        <b>
          Students’ Council will be the highest governing body of Students
          Activities Centre and will be responsible for all the Students
          Activities taking place in the Institute.
        </b>
      </p>
      <Heading
        content="1.1 Objective"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">
        {" "}
        A good administration and efficient students’ council is a part of an
        able institution. Students’ Council represents the opinions, ideas and
        proposals of the students.
      </p>
      <ol>
        <li style={{ listStyleType: "decimal" }}>
          To provide a link and improve communication between administration,
          students and parents.
        </li>
        <li style={{ listStyleType: "decimal" }}>
          To provide a forum for students to voice their opinions on relevant
          issues.
        </li>
        <li style={{ listStyleType: "decimal" }}>
          To encourage students to become self-confident, responsible and create
          a more caring atmosphere.
        </li>
        <li style={{ listStyleType: "decimal" }}>
          The Council functions to bridge the gap between students and
          administration, paving way to the healthy relationship between the
          two, thereby creating a conducive environment for their Academic and
          extracurricular excellence .
        </li>
        <li style={{ listStyleType: "decimal" }}>
          The administration is more approachable through the council, and also
          the demands and problems faced by the students are given a patient
          ear.
        </li>
        <li style={{ listStyleType: "decimal" }}>
          To maintain transparency in all types of students activities in the
          institute.
        </li>
        <li style={{ listStyleType: "decimal" }}>
          This document provides the Students’ Council with an explanation of
          its purpose, administration and responsibilities. Students’ Council
          should be notified before any decision is made.
        </li>
      </ol>
      <Heading
        content="1.2  Structure of Students’ Council"
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <p className="long-para">
        The students’ council of National Institute of Technology, Jamshedpur
        comprises of the following posts whose eligibility criteria are
        mentioned in the selection procedure as notified by the institute.
      </p>
      <ul className="ul-list">
        <li>1. President (4th year B.Tech.)</li>
        <li>2. Vice-President (3rd year B.Tech.)</li>
        <li>3. General Secretary (3rd year B.Tech.)</li>
        <li>4. Joint Secretary (2nd year B.Tech.)</li>
        <li>5. Alumni Secretary (4th /3rd year B.Tech.)</li>
        <li>6. Joint Alumni Secretary (3rd /2nd year B.Tech.)</li>
        <li>7. Technical Secretary (4th /3rd year B.Tech.)</li>
        <li>8. Joint Technical Secretary (3rd /2nd year B.Tech.)</li>
        <li>9. Cultural Secretary (4th /3rd year B.Tech.)</li>
        <li>10. Joint Cultural Secretary (3rd /2nd year B.Tech.)</li>
        <li>11. Sports Secretary (4th /3rd year B.Tech.)</li>
        <li>12. Joint Sports Secretary (3rd /2nd year B.Tech.)</li>
        <li>13. Club Secretary (4th year B.Tech.)</li>
        <li>14. PG representative (PG )</li>
        <li>15. 1st year Representative (1st year B.Tech.)</li>
      </ul>
      <div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#001",
                      fontWeight: "bold",
                      fontSize: "17px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}

export default StudentCouncil;
