import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../../assets/css/Main/main.style";
import { Skeleton } from "antd";
import { Breadcrumb } from "antd";
import avatar from "../../../assets/images/default.jpg";

import Sticky from "react-stickynode";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Footer from "../../../Components/Layout/Footer";
import { Container } from "react-bootstrap";
import { Tabs, Image } from "antd";
import Heading from "../../../Components/Heading";
import { PeopleWrapper } from "../../../assets/css/Custom/PeopleStyle";
import { getRequest } from "../../../functions/request";
import PublicationSection from "./../Sections/directorProfile/PublicationSection";
import ResearchAreaSection from "./../Sections/directorProfile/ResearchAreaSection";
import DelegationVisits from "./../Sections/directorProfile/DelegationVisits";
import OtherInfoSection from "./../Sections/directorProfile/OtherInfoSection";
import InterviewsArticles from "./../Sections/directorProfile/InterviewsArticles";
import PhotoGallery from "./../Sections/directorProfile/PhotoGallery";
import AreaOfInterest from "./../Sections/directorProfile/AreaOfInterest";
import MessageFromDirector from "./../Sections/directorProfile/MessageFromDirector";
import { scrollToContent } from "../../../functions/scroll";
import { Helmet } from "react-helmet";
import directorImg from "../../../assets/images/People/director.jpg";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

function Profile() {
  const { t } = useTranslation();
  const id = "CSE112";
  const [person, setPerson] = useState(null);
  const [personLoaded, setPersonLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const personData = await getRequest("/files/director.json", false);
      let data = personData.data[0][id];
      if (data) {
        setPerson(data);
      } else {
        setNotFound(true);
      }
      setPersonLoaded(true);
    };
    scrollToContent(0);
    getData();
  }, [id]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t(`Director`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,director"
        />
        <meta name="description" content="Director,N.I.T. Jsr" />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeclassName="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <div>
            <div
              style={{
                fontSize: "2.0em",
                marginBottom: "15px",
                fontWeight: "550",
                background: "rgb(24 68 110)",
                padding: "1.5% 1.5% 1.5% 3%",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              <Heading
                className="text-light"
                content={
                  personLoaded ? (
                    notFound ? (
                      "Faculty Not Found"
                    ) : (
                      `${person?.prename || ""} ${person?.fname || ""} ${person?.lname || ""
                      }`
                    )
                  ) : (
                    <Skeleton.Input style={{ width: 400 }} active={true} />
                  )
                }
              />
              {personLoaded && (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" className="text-muted">
                      Home
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link className="text-muted" to="/people/People_Home">
                      People
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link className="text-muted" to="/people/Faculty">
                      Faculty
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="text-light">
                    {person?.prename} {person?.fname} {person?.lname}
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
            </div>

            {!notFound && (
              <Container style={{ padding: "1%" }}>
                <PeopleWrapper>
                  <div className="row">
                    <div className="col-lg-4 widget-block">
                      <div className="widget widget-user-about">
                        <div className="text-center">
                          {person ? (
                            <>
                              <Image
                                src={directorImg}
                                fallback={avatar}
                                style={{
                                  borderRadius: "50%",
                                  maxHeight: "200px",
                                  width: "200px",
                                  height: "200px",
                                }}
                              />
                              <center>
                                <b>
                                  {person.prename} {person.fname} {person.lname}
                                </b>
                                <br />
                                {person.designation}
                                <br />
                                {/* {person.department} */}
                              </center>
                              <br />
                            </>
                          ) : (
                            <Skeleton.Image active />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <ul className="user-info">
                        <li>
                          <div
                            className="about_wrapper"
                            style={{ padding: "20px" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100%",
                                  textAlign: "justify",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: person?.bio.slice(0, 1776),
                                }}
                              ></div>
                            </p>
                            <a
                              href={'https://nitjsr.ac.in/backend/uploads/uploads_2023/about_director/Profile%20of%20Professor%20%28Dr.%29%20Goutam%20Sutradhar%2C%20Director%20NIT%20Jamshedpur.pdf'}
                              target="_blank" rel="noreferrer" className="learn__more-btn"
                            >
                              <span className="hyphen"></span>
                              <span className="btn_text">{t("know_more_button")}</span>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                      <Tabs
                        defaultActiveKey="1"
                        style={{ minHeight: "350px", marginTop: "40px" }}
                      >
                        <TabPane
                          className="table-responsive px-2"
                          tab={" Message from the Director"}
                          key="main_1"
                        >
                          <MessageFromDirector />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Delegation Visits"}
                          key="main_2"
                        >
                          <DelegationVisits />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Research Area"}
                          key="main_3"
                        >
                          <ResearchAreaSection />
                        </TabPane>

                        <TabPane
                          className="table-responsive px-2"
                          tab={"Publications"}
                          key="main_4"
                        >
                          <PublicationSection />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Interviews/Articles"}
                          key="main_5"
                        >
                          <InterviewsArticles />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Area of Interest"}
                          key="main_6"
                        >
                          <AreaOfInterest />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Photo Gallery"}
                          key="main_7"
                        >
                          <PhotoGallery />
                        </TabPane>
                        <TabPane
                          className="table-responsive px-2"
                          tab={"Contact"}
                          key="main_8"
                        >
                          <OtherInfoSection />
                        </TabPane>
                      </Tabs>
                    </div>

                  </div>
                </PeopleWrapper>
              </Container>
            )}
          </div>
        </ContentWrapper>

        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Profile;
