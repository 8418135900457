import React, { useState, useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from "../../assets/css/Main/main.style";
import { ResetCSS } from "../../assets/css/style";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Navbar from "../../Components/Layout/PagesNavbar";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { charityTheme } from "../../theme/charity";
import Announcements from "./Sections/Announcements";
import OfficeOrders from "./Sections/OfficeOrders";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { scrollToContent } from "../../functions/scroll.js";
import Students from "./Sections/students";
import ArchiveNotices from "./Sections/ArchiveNotices";

function Notices() {
  const { page } = useParams();
  const [ActiveComp, setActiveComp] = useState("Announcements");
  const fontsize = 16;
  const { t } = useTranslation();
  useEffect(() => {
    const pg = page.replaceAll("-", " ");
    setActiveComp(pg);
  }, [page]);
  function handleClick(comp) {
    const pg = comp.replaceAll("-", " ");
    setActiveComp(pg);
    scrollToContent();
  }

  return (
    <ThemeProvider theme={charityTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,competitions,events,workshop,exhibition,science,fest,technical,culfest,ojass,utkarsh,robotics,sports meet,acm,csi,pizza hackers,daksh,drift,
         lds,cds,manthan,mes,technica,topguns,faces,phocus,qunite,revanta,sece"
        />

        <meta
          name="description"
          content="Official Website: Information about programs offered, departments, activities, research, consultancy, admissions, events, and contacts."
        />
      </Helmet>
      {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
      <TopSection fontsize={fontsize} setFontSize={setFontSize} />
        </Sticky> */}
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp}`)} />
          <Container width="80%">
            <Row>
              <Col lg={4} sm={12}>
                <Sidebar TopLink="Notices" handleClick={handleClick} />
              </Col>
              <Col lg={8} sm={12}>
                {ActiveComp === "Announcements" && (
                  <Announcements fontsize={fontsize} />
                )}
                {ActiveComp === "Office_Orders" && (
                  <OfficeOrders fontsize={fontsize} />
                )}
                {ActiveComp === "Students" && <Students fontsize={fontsize} />}
                {ActiveComp === "Archive_Notices" && (
                  <ArchiveNotices fontsize={fontsize} />
                )}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Notices;
