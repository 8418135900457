import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { ParagraphWrapper } from '../../../assets/css/Custom/AcademicStyle';
import { Icon } from 'react-icons-kit';
import { externalLink } from 'react-icons-kit/fa/externalLink';
import { useTranslation } from 'react-i18next';

function Links({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <br />
        <Heading content={t("Links")} />
        <ParagraphWrapper>
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>
              <Link to={{ pathname: "https://gem.gov.in/" }} target="_blank" className="linktext" style={{ fontSize: '20px' }}>
                <Icon icon={externalLink} size={18} />
                &nbsp;&nbsp; {t("Government e-Marketplace")}
              </Link>
            </b>
          </p>
          <p style={{ fontSize: fontsize }} className="paragraph">
            <b>
              <Link to={{ pathname: "https://eprocure.gov.in/cppp/" }} target="_blank" className="linktext" style={{ fontSize: '20px' }}>
                <Icon icon={externalLink} size={18} />
                &nbsp;&nbsp; {t("Central Public Procurement Protocol")}
              </Link>
            </b>
          </p>
        </ParagraphWrapper>
      </Container>
    </Fragment>
  );
}

// HumanityBlock style props
Links.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// Links default style
Links.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default Links;
