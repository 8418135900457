import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";

import { Tabs } from "antd";
import { getFileURL } from "../../../../functions/utils";
import { getRequest } from "../../../../functions/request";
const { TabPane } = Tabs;

function TeachingSection() {
  const { id } = useParams();

  const [courseLoaded, setCourseLoaded] = useState(false);
  // const [, setAssignmentLoaded] = useState(false);
  const [courses, setCourses] = useState([]);
  // const [, setAssignment] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const coursesData = await getRequest(`/faculty_course?id=${id}`);
      setCourses(coursesData.data.result);
      setCourseLoaded(true);

      // const assignmentData = await getRequest(`/assignment?id=${id}`);
      // setAssignment(assignmentData.data.result);
      // setAssignmentLoaded(true);
    };

    getData();
  }, [id]);
  return (
    <Tabs defaultActiveKey="1" style={{ minHeight: "350px" }}>
      <TabPane tab={"Courses"} key="1">
        {courseLoaded ? (
          <>
            {courses
              ? courses.map((value, index) => {
                  return (
                    <>
                      <p className="border-bottom border-left p-2" key={index}>
                        <a
                          target="_blank"
                          className="text-secondary"
                          rel="noreferrer"
                          href={getFileURL(value.course_handout)}
                        >
                          <b className="text-primary">{value.course_name}</b> -{" "}
                          <span>{value.course_id}</span>
                          <span className="float-right">
                            Semester: {value.year}
                          </span>
                        </a>
                      </p>
                    </>
                  );
                })
              : null}
            {courses.length === 0 ? "No course found" : null}
          </>
        ) : (
          <Skeleton active />
        )}
      </TabPane>
      {/* <TabPane
        tab={"Assignments"}
        key="2"
      >
        {assignmentLoaded ? (
          <div
            style={{ width: "100%" }}
          >
            {assignment.filter((value) => value.file_type === 1).map((items, key) => {
              return (
                <div key={"notes" + key} className="border-left border-bottom pl-2 pt-2">
                  <a href={getFileURL(items.file)} className="text-dark" target="_blank" rel="noreferrer" >
                    <b className="text-primary">{items.file_title} - {items.course_id}</b><br />
                    <span>Assigned On: {items.assigned_date}</span>
                    <span className="float-right">Last date to submit: {items.last_date}</span>
                    &nbsp;{ }
                  </a>
                </div>
              );
            })
            }
            {
              assignment.filter((value) => value.file_type === 1).length === 0 ? ("No assignment found") : null
            }
          </div>
        ) : (
          <Skeleton active />
        )}
      </TabPane>
      <TabPane
        tab={"Notes"}
        key="3"
      >
        {assignmentLoaded ? (
          <div
            style={{ width: "100%" }}
          >
            {assignment.filter((value) => value.file_type === 2).map((items, key) => {
              return (
                <div key={"notes" + key} className="border-left border-bottom pl-2 pt-2">
                  <a href={getFileURL(items.file)} className="text-dark" target="_blank" rel="noreferrer" >
                    <b className="text-primary">{items.file_title} - {items.course_id}</b><br />
                    <span>Posted On: {items.assigned_date}</span>
                  </a>
                </div>
              );
            })}
            {
              assignment.filter((value) => value.file_type === 2).length === 0 ? ("No notes found") : null
            }
          </div>
        ) : (
          <Skeleton active />
        )}
      </TabPane> */}
    </Tabs>
  );
}

export default TeachingSection;
