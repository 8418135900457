import React, { useState, useEffect } from "react";
import { Card, Skeleton } from "antd";
import { getRequest } from "../../../../functions/request";

export default function OtherInfoSection() {
  const [person, setPerson] = useState(null);
  const [personLoaded, setPersonLoaded] = useState(false);
  const id = "CSE112";

  useEffect(() => {

    const getData = async () => {

      (async () => {
        getRequest('/files/director.json', false)
          .then((res) => {
            if (res.data.length > 0) {
              setPerson(res.data[0][id])
              setPersonLoaded(true);
            }
          })
      })();


    };

    getData();
  }, [id]);

  return (
    person ? (
      <div className="user-info">

        <ul className="info-list">
          <li>
            <span className="font-weight-bold">E-mail: </span>
            {person.email ? person.email : "N/A"}
          </li>
          <li>
            <span className="font-weight-bold">Landline: </span>
            {person.landline ? person.landline : "N/A"}
          </li>
          <li>
            {person.fb_id ? (
              <li className="social-share">
                <span className="font-weight-bold">Social: </span>
                <a
                  href={person.fb_id}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
              </li>
            ) : null}
          </li>
          <li>
            <span className="font-weight-bold">Office: </span>
            {person.pw_link ? person.office_add : "N/A"}
          </li>
        </ul>
        <div className="block-box user-about">
          {person ? (
            <ul className="list-group list-group-horizontal mx-auto mt-3">
              <li className="list-group-item  mt-2">
                <span>Google Scholar:</span>
                {person.scholar_link ? (
                  <a
                    href={person.scholar_link}
                    target="_blank"
                    className="text-primary"
                    rel="noreferrer"
                  >
                    View{" "}
                    <i className="fad fa-external-link-alt"></i>
                  </a>
                ) : (
                  "N/A"
                )}
              </li>
              <li className="list-group-item mt-2">
                <span>Website:</span>
                {person.pw_link ? (
                  <a
                    href={person.pw_link}
                    target="_blank"
                    className="text-primary"
                    rel="noreferrer"
                  >
                    View{" "}
                    <i className="fad fa-external-link-alt"></i>
                  </a>
                ) : (
                  "N/A"
                )}
              </li>
              <li className="list-group-item mt-2">
                <span>Publon:</span>
                {person.publon_id ? (
                  <a
                    href={person.publon_id}
                    target="_blank"
                    className="text-primary"
                    rel="noreferrer"
                  >
                    View{" "}
                    <i className="fad fa-external-link-alt"></i>
                  </a>
                ) : (
                  "N/A"
                )}
              </li>
              <li className="list-group-item mt-2">
                <span>Orcid:</span>
                {person.orcid_id ? (
                  <a
                    href={person.orcid_id}
                    target="_blank"
                    className="text-primary"
                    rel="noreferrer"
                  >
                    View{" "}
                    <i className="fad fa-external-link-alt"></i>
                  </a>
                ) : (
                  "N/A"
                )}
              </li>
            </ul>
          ) : (
            personLoaded ? (null) :
              <Skeleton active />
          )}
        </div>
      </div>
    ) : (
      !personLoaded ? (
        <Card style={{ width: 300, marginTop: 16 }}>
          <Skeleton loading={true} avatar active></Skeleton>
        </Card>
      ) : null)

  )
}
