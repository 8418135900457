import React from "react";

export default function Tags(pros) {
  const tags = pros.tags || [];
  return (
    <>
      {tags.map((single_tag, index) => (
        <span key={index + "chip_index"} className="badge ml-1 badge-pill text-capitalize" style={{background:'#274086',color:'white'}}>
          {single_tag}
        </span>
      ))}
    </>
  );
}
