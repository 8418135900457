import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get'

const BlockWrapper = styled.div`
  width: 100%;
  padding: 30px;
  @media only screen and (max-width: 1440px) {
    padding: 20px 0 10px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 20px 0 10px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }
.director_content_container img{
  width:280px;
  // height:180px;
  float:left;
  margin-right:10px;
  border-radius:5px;
}
.director_content_container p{
  font-size:17px;
  padding:10px;
  line-height:1.7

}
.learn__more-btn{
  margin-bottom:15px;
}
.heading{
  background-color:${themeGet('colors.secondary')};
  color:#fff;
  font-size:25px;
  padding: 20px 20px; 
  font-weight:500;
}
@media(max-width:991px){
  .heading{
    padding: 8px 20px; 
    font-size:22px;
  }
  .director_content_container img{
    width:100%;
    margin-bottom:9px;
  }

}
`;

export const ImageWrapper = styled.div`
  padding-right: 20px;
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  .iframe-cont {
    // max-width: 100%;
    height: 430px;
  }
  @media (max-width: 991px) {
    .iframe-cont {
      // max-width: 100%;
      height: 200px;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 16px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    color: ${themeGet('colors.secondary2', '#EE2541')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 50px;
    line-height: 70px;
    font-weight: 300;
    margin-bottom: 40px;
    @media only screen and (max-width: 1440px) {
      font-size: 40px;
      line-height: 60px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 34px;
      line-height: 50px;
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 30px;
      line-height: 46px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  p {
    // font-family: 'Hind Siliguri';
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 29px;
    color: ${themeGet('colors.text', '#294859')};
    @media only screen and (max-width: 1440px) {
      font-size: 20px;
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  .learn__more-btn {
    &::before {
      background-color: ${themeGet('colors.primary', '#FCF22B')};
    }
  }
  //Our Vision and Mission Heading Style start

  .heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #fff;
  }
  #our {
    background-color: #274187;
    padding: 8px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  #vision {
    color: #274187;
    font-weight: 600;
  }
  //Our Vision and Mission Heading Style end
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 40px;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

export default BlockWrapper;
