import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { socialLinks } from "../../../assets/data";
import { SocialList } from "./SocialMedia.style";

function SocialMedia() {
  const [position, setPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {position > 40 && (
        <SocialList className="social-media d-block d-lg-block">
          {socialLinks.map((item) => (
            <li className={item.name} key={`social__link-key${item.id}`}>
              <Link to={{ pathname: `${item.link}` }} target="_blank">
                <span style={{display: "none"}}>item.name</span>
                {item.icon}
              </Link>
            </li>
          ))}
        </SocialList>
      )}
    </>
  );
}

export default SocialMedia;
