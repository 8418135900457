import React from "react";
import { Box, Grid, Divider, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import data from "../../../assets/data/registar.json";
// import getData from '../Common/getData';
// import List from '../Components/List';
import {useTranslation} from "react-i18next";

function Registrar() {
  const {t} = useTranslation();
  const useStyles = makeStyles({
    mainCard: {
      padding: 8,
      textAlign:"center"
    },
    mainImg: {
      width: "256px",
      height: "256px",
      borderRadius: "128px",
    },
    mainText: {
      textAlign: "center",
      paddingTop: "16px",
      fontSize: "22px",
      fontWeight: "700",
    },
    mainTextL: {
      fontSize: "15px",
      color: "#000",
      fontWeight: 550,
    },
    mainTextR: {
      fontSize: "15px",
      color: "grey",
      fontWeight: 500,
      paddingInline: "4px",
      "&-email": {
        fontSize: "15px",
        color: "grey",
        fontWeight: 500,
        paddingInline: "4px",
        "&:hover": {
          color: "blue",
          cursor: "pointer",
        },
      },
    },
    mainTextW: {},
    regiOff: {
      textAlign: "center",
      fontSize: "32px",
      color: "#274187",
    },
    officeCard: {
      padding: "8px 12px",
    },
    officeName: {
      fontSize: "18px",
      color: "black",
    },
  });
  const classes = useStyles();
  const mailTo = (e) => {
    window.open(`mailto:${data.main.email}`, "_blank").focus();
  };

  return (
    <div>
      <br />
      <Box>
        <Grid container spacing={1} wrap="wrap" direction="row">
          <Grid xs={12} md={4} item>
            <Box className={classes.mainCard}>
              <img
                className={classes.mainImg}
                src={data.main.image}
                alt="registar"
              />
              <p className={classes.mainText}>{t(data.main.name)}</p>
              <p style={{ textAlign: "center" }}>{t(data.main.position)}</p>
              <Divider/>
              <span className={classes.mainTextW}>
                <span className={classes.mainTextL}>{t("Email")} :</span>
                <span className={`${classes.mainTextR}-email`} onClick={mailTo}>
                  {data.main.email}
                </span>
              </span>

              <br />
              <span>
                <span className={classes.mainTextL}>{t("Phone")} :</span>
                <span className={classes.mainTextR}>{data.main.phone}</span>
              </span>
              <br />
              <span>
                <span className={classes.mainTextR}>National Institute of Technology, Jamshedpur </span>
              <br />
                <span className={classes.mainTextR}>NIT Campus, P.O .RIT Jamshedpur 831014, Jharkhand, India </span>
              </span>
              <br />
              <span>
                <span className={classes.mainTextL}>Fax :</span>
                <span className={classes.mainTextR}>{data.main.fax}</span>
              </span>
            </Box>
          </Grid>
          <Grid xs={12} md={8} item>
            <p className={classes.regiOff}>{t("Registrar's Office")}</p>
            <Divider />
            <br />
            <Grid container spacing={2} direction="row" wrap="wrap">
              {data.office.map((e, i) => (
                <Grid key={e.key} xs={12} sm={6} md={6} item>
                  <Card className={classes.officeCard}>
                    <p className={classes.officeName}>{t(e.name)}</p>
                    <p>{t(e.position)}</p>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br />
    </div>
  );
}

export default Registrar;
