import styled from 'styled-components';

export const BoxWrapper = styled.div`
  .head-title {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 4px;
    margin-top: 5%;
    // border:2px solid #274187;
    background-color: #274187;
    text-align: center;
  }
  .head-title p {
    color: #fff;
    font-size: 18px;
  }
  .content {
    padding-left: 10px;
  }
  .NameArea {
    border-bottom: 1px solid #274187;
  }
  .deptdetails {
    margin-top: 3%;
    background: #f5f8fd;
    font-family: 'Poppins', sans-serif;
    height: auto;
    padding-top: 0;
    color: #000;
  }
  .detail-text {
    // font-size: 1.1em;
    // margin-top:4%;
    padding: 3%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }
  li {
    list-style: disc;
  }

  .mycard {
    border: 1px solid lightgrey;
    // border-radius:5px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
  }
  #hodimg {
    width: 200px;
    height: 170px;
  }
  #con-head {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
  }
  .left-contact {
    // padding-left:10px;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: hidden;
  }
  .right-text {
    // padding-left:12px;
    padding-top: 5px;
    padding-bottom: 5px;
    word-wrap: break-word;
    tex-align: left;
    overflow: hidden;
  }
`;

export const SectionWrapper = styled.div`
  padding-top: 5%;
  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: #274187 !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .single-slider {
    padding: 10px;
    border: none;
  }
  .heading {
    margin: 10px;
    border-bottom: 1px solid #274187;
  }
  .mycard {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    min-height: 300px;
    background: #f5f8fd;
  }
  .mythumb {
    display: flex;
    justify-content: center;
  }
  .faculty-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .mycaption p {
    color: #274187;
    cursor: pointer;
    font-size: 15px;
  }
  .caption {
    margin-top: 15px;
  }
  // .notice-box{
  //     height:500px;
  // }
  .Notice-head {
    background-color: #274187;
    // margin-top:5%;
    color: #fff;
    padding: 15px;
    text-align: center;
  }
  .single-notice {
    padding: 10px;
  }

  .mycard-research {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    min-height: 400px;
    background: #f5f8fd;
  }
  .research-img {
    width: 100%;
    height: 250px;
  }
  .mycard-research .thumbnail {
    display: flex;
    justify-content: center;
  }

  .faculty_heading {
    display: flex;
    justify-content: flex-start;
  }
  .right-link {
    position: absolute;
    right: 5%;
    background: #fff;
    border-radius: 20px;
    padding: 1px;
    width: 100px;
  }
  .right-link p {
    color: #274187;
    font-size: 0.6em;
    margin: 5px;
    text-align: center;
  }
`;

export const FacultyWrapper = styled.div`
  .faculty_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .faculty_card_container {
    margin: 20px 20px;
    display: flex;
    justify-content: center;
  }
  .faculty_card {
    background-color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 300px;
    box-shadow: 1px 1px 10px lightgray;
  }
  .faculty_card:hover {
    background-color: #f5f8fd;
  }
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .faculty_details {
    margin-top: 10px;
    text-align: center;
  }
  .faculty_name {
    font-size: 20px;
    color: #274187;
    tex-wrap: break-word;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }
  .faculty_desig {
    color: #000;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }
`;
