// Departments section
import React, { useState, useEffect } from "react";
import Sticky from "react-stickynode";
import { Link, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../theme/main";
import { ResetCSS } from "../../../assets/css/style";
import { DrawerProvider } from "../../../contexts/DrawerContext";
import Navbar from "../../../Components/Layout/PagesNavbar";
import DrawerSection from "../../../Components/Layout/DrawerSection";
import Container from "../../../Components/UI/Container";
import Footer from "../../../Components/Layout/Footer";
import {
  GlobalStyle,
  CharityWrapper,
} from "../../../assets/css/Main/main.style";
import { FacultyWrapper } from "../../../assets/css/Custom/DepartmentStyle";
import "../../../assets/css/bootstrap.min.css";
import Banner from "../../../Components/Layout/Banner/Banner";
import { getRequest } from "../../../functions/request";
import { getFileURL } from "../../../functions/utils";
import avatar from "../../../assets/images/default.jpg";
import { Image } from "antd";

function DeptFaculty() {
  const [DeptName, setDeptName] = useState("");
  const [Faculty, setFaculty] = useState([]);
  const { branch } = useParams();
  useEffect(() => {
    if (branch === "cs") setDeptName("Computer Science and Engineering");
    else if (branch === "ece")
      setDeptName("Electronics and Communication Engineering");
    else if (branch === "ee") setDeptName("Electrical Engineering");
    else if (branch === "civil") setDeptName("Civil Engineering");
    else if (branch === "mech") setDeptName("Mechanical Engineering");
    else if (branch === "meta")
      setDeptName("Metallurgical and Materials Engineering");
    else if (branch === "prod")
      setDeptName("Production and Industrial Engineering");
    else if (branch === "chem") setDeptName("Chemistry");
    else if (branch === "maths") setDeptName("Mathematics");
    else if (branch === "phys") setDeptName("Physics");
    else if (branch === "humanities")
      setDeptName("Humanities, Social Sciences and Management");
    else if (branch === "mca") setDeptName("Computer Applications");
  }, [branch]);

  useEffect(() => {
    const FacultyHandler = async () => {
      try {
        let res = await getRequest(`/people/faculty?dept=${branch}`);
        setFaculty(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    FacultyHandler();
  }, [branch]);

  return (
    <ThemeProvider theme={mainTheme}>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        {/* Main Content Starts from here */}
        <Banner heading={DeptName} />
        <Container width="90%">
          <FacultyWrapper>
            <div className="faculty_container">
              {Faculty.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "300px",
                  }}
                >
                  No Faculty Data Available
                </div>
              ) : (
                Faculty.map((items) => {
                  return (
                    <div className="faculty_card_container">
                      <Link to={`/people/profile/${items.faculty_id}`}>
                        <div className="faculty_card">
                          <div className="faculty_pic">
                            <Image
                              src={getFileURL(items.profile)}
                              fallback={avatar}
                              preview={false}
                              style={{
                                maxHeight: "200px",
                                width: "200px",
                                height: "200px",
                              }}
                            />
                            {/* <img src={getFileURL(items.profile)} alt={avatar} /> */}
                          </div>
                          <div className="faculty_details">
                            <p className="faculty_name">
                              {
                                // items.prename +
                                // " " +
                                items.fname + " " + items.lname
                              }
                            </p>
                            <p className="faculty_desig">{items.designation}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              )}
              {/* {} */}
            </div>
          </FacultyWrapper>
        </Container>
        {/* Footer Area */}
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default DeptFaculty;
