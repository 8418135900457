import React from "react";
// import { Tag, Divider } from "antd";
// import { getRequest } from "../../../../functions/request";

export default function AreaOfInterest() {
  // const id = "CSE112";
  // const [research, setResearch] = useState([]);
  // const [member, setMember] = useState(null);

  // useEffect(() => {

  //     const getData = async () => {

  //         (async () => {
  //             getRequest('/files/director.json', false)
  //                 .then((res) => {
  //                     if (res.data.length > 0) {
  //                         setMember(res.data[0][id].member);
  //                         setResearch(res.data[0][id].research);
  //                     }
  //                 })
  //         })();
  //     };

  //     getData();
  // }, [id]);

  return (
    <div>
      {/* <ol>
        <h5 style={{ "text-decoration": "none!important" }}>
          {" "}
          <b>Subjects taught at UG level </b>
          <br />
        </h5>
        <ol>
          <li style={{ listStyleType: "disc" }}>Energy Conversion</li>
          <li style={{ listStyleType: "disc" }}>Thermodynamics</li>
          <li style={{ listStyleType: "disc" }}>Automobile Engineering</li>
          <li style={{ listStyleType: "disc" }}>
            Measurement and Control System{" "}
          </li>
          <li style={{ listStyleType: "disc" }}>Heat Exchanger Design</li>
          <li style={{ listStyleType: "disc" }}> Heat and Mass Transfer</li>
          <li style={{ listStyleType: "disc" }}>
            Computational Fluid Dynamics{" "}
          </li>
        </ol>
        <h5 style={{ "text-decoration": "none!important" }}>
          {" "}
          <b>Subjects taught at PG level: </b>
          <br />
        </h5>
        <ol>
          <li style={{ listStyleType: "disc" }}>
            Programming in High Level Language
          </li>
          <li style={{ listStyleType: "disc" }}>Numerical Methods </li>
          <li style={{ listStyleType: "disc" }}>
            Artificial Intelligence and Robotics
          </li>
          <li style={{ listStyleType: "disc" }}>Advanced Thermodynamics </li>
          <li style={{ listStyleType: "disc" }}>
            Advanced Heat and Mass Transfer
          </li>
          <li style={{ listStyleType: "disc" }}>
            Robotics and Robot Applications
          </li>
          <li style={{ listStyleType: "disc" }}>CFD</li>
        </ol>
      </ol> */}
    </div>
  );
}
