import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Text from "../../../Components/Text";
import Heading from "../../../Components/Heading";
import Container from "../../../Components/UI/Container";
import "../../../assets/css/bootstrap.min.css";
import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
} from "./VisionAndMission.Style";

import { humanityData } from "../../../assets/data";
import DirectorSection from "./DirectorSection";

const NoticeSection = () => {
  const { lists } = humanityData;
  const { t } = useTranslation();
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="90%">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <DirectorSection />
          </div>
          <div className="col-md-6 col-sm-12">
            <ContentWrapper>
              <Heading
                content={t("vision_home_head")}
                style={{
                  fontSize: "25px",
                  marginBottom: "15px",
                  fontWeight: "600",
                  background: "#274187",
                  color: "#fff",
                  padding: "0 0 0 5%",
                }}
              />
              <Text
                content={t("vision_home")}
                style={{
                  padding: "0 0 0 5%",
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: "1.5",
                  fontSize: "17px",
                  textAlign: 'justify',
                }}
              />

              <Heading
                content={t("mission_home")}
                style={{
                  fontSize: "25px",
                  marginBottom: "1px",
                  fontWeight: "500",
                  background: "#274187",
                  color: "#fff",
                  padding: "0 0 0 5%",
                  fontWeight: 600
                }}
              />
              <List
                style={{
                  padding: "0 0 0 5%",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {lists.map((item) => (
                  <Item
                    key={`list_key${item.id}`}
                    style={{
                      paddingBottom: "1px",
                      fontFamily: "Poppins, sans-serif",
                      lineHeight: "1.5",
                      fontSize: "17px",
                    }}
                  >
                    {t(`mission_points_${item.id}`)}
                  </Item>
                ))}
              </List>
              {/* <Link to="/" className="learn__more-btn">
                  <span className="hyphen" />
                  <span className="btn_text">Learn More </span>
              </Link> */}
            </ContentWrapper>
          </div>
        </div>
      </Container>
    </BlockWrapper>
  );
};

// NoticeSection style props
NoticeSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// NoticeSection default style
NoticeSection.defaultProps = {
  // NoticeSection row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // NoticeSection col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
};

export default NoticeSection;
