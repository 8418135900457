import React, { useState, useContext } from "react";
import Scrollspy from "react-scrollspy";
import { Icon } from "react-icons-kit";
import { angleDown } from "react-icons-kit/fa/angleDown";
import { Link } from "react-router-dom";
import Drawer from "../../Drawer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Select } from "../HomeNavbar/navbar.style";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
} from "../../Accordion";

import "./drawerStyle.css";
import { DrawerContext } from "../../../contexts/DrawerContext";
import InnerWrapper from "./drawerSection.style";

import { menuItems } from "../../../assets/data";
import { Tooltip } from "antd";
import { backgroundColor } from "styled-system";

const DrawerSection = ({ black, white }) => {
  const [toggleState, setToggleState] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);

  const handleActiveClass = () => {
    setToggleState(!toggleState);
  };

  const handleDrawerToggle = () => {
    dispatch({
      type: "TOGGLE",
    });
    handleActiveClass();
  };

  const scrollItems = [];

  menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const { t } = useTranslation();
 
  return (
    <Drawer
      width="420px"
      placement="right"
      drawerHandler={
        <Tooltip placement="bottom" zIndex={10000} title="Main Menu">
          <button
            className={`drawer_btn ${
              toggleState ? "active" : ""
            }  drawer_style `}
            onClick={handleActiveClass}
            // onMouseEnter={()=>{!state.isOpen&&handleDrawerToggle()}}
            aria-label="drawer toggle button"
            style={{
              backgroundColor: "#274187",
            }}
          >
            <ul className="grid">
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
            <i className="flaticon-plus-symbol " />
          </button>
        </Tooltip>
      }
      open={state.isOpen}
      toggleHandler={handleDrawerToggle}
    >
      {/* <Accordion>
        <AccordionItem>
          <AccordionTitle>
            Institute
            <IconWrapper>
              <OpenIcon />
            </IconWrapper>
          </AccordionTitle>
          <AccordionBody>
            <p>xadcsdc</p>
            <p>xadcsdc</p>
            <p>xadcsdc</p>
          </AccordionBody>
        </AccordionItem>
      </Accordion> */}

      <InnerWrapper style={{ backgroundColor: white }}>
        <Select
          onChange={(e) => {
            i18next.changeLanguage(e.target.value);
          }}
        >
          <option selected disabled>
            {i18next.language === "hi" ? "हिंदी" : "English"}
          </option>
          <option value="hi">हिंदी</option>
          <option value="en">English</option>
        </Select>
        <Scrollspy
          className="scrollspy__menu"
          items={scrollItems}
          offset={-81}
          currentClassName="active"
          style={{ maxHeight: "100%" }}
        >
          <br />
          <Accordion>
            <>
              {menuItems.map((menu, index) => (
                <div
                  key={`menu_key${index}`}
                  style={{ marginTop: "10%", fontSize: "1.2rem" }}
                >
                  <AccordionItem>
                    <>
                      {menu.submenu ? (
                        <AccordionTitle>
                          <div style={{ color: black, fontWeight: "510" }}>
                            {t(`${menu.label.split(" ").join("_")}`)}&nbsp;
                            {/* &#9660; */}
                            <Icon
                              style={{ float: "right" }}
                              icon={angleDown}
                              size={25}
                            />
                          </div>
                        </AccordionTitle>
                      ) : (
                        <AccordionTitle>
                          {menu.path !== "/RnD" ? (
                            <Link
                              to={menu.path}
                              style={{ color: "#000", fontWeight: "510" }}
                              onClick={handleDrawerToggle}
                            >
                              {t(`${menu.label.split(" ").join("_")}`)}
                            </Link>
                          ) : (
                            <Link
                              to={menu.path}
                              target="_blank"
                              style={{ color: "#000", fontWeight: "510" }}
                            >
                              {t(`${menu.label.split(" ").join("_")}`)}
                            </Link>
                          )}
                        </AccordionTitle>
                      )}
                      {menu.submenu && (
                        <AccordionBody>
                          <>
                            {menu.submenu.map((submenu, subindex) => (
                              <div
                                key={`submenu_key${subindex}`}
                                style={{ backgroundColor: white }}
                              >
                                <Link
                                  to={submenu.path}
                                  style={{
                                    color: "#000",
                                    marginTop: "1rem",
                                    fontFamily: "'Poppins', sans-serif",
                                    borderBottom: "1px solid #EAE9E0",
                                    width: "100%",
                                  }}
                                  onClick={handleDrawerToggle}
                                >
                                  {t(`${submenu.text}`)}
                                </Link>
                              </div>
                            ))}
                          </>
                        </AccordionBody>
                      )}
                    </>
                  </AccordionItem>
                </div>
              ))}
            </>
          </Accordion>
        </Scrollspy>
      </InnerWrapper>
    </Drawer>
  );
};

export default DrawerSection;
