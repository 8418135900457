import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
import { useTranslation } from 'react-i18next';
import {
  ParagraphWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import { Helmet } from 'react-helmet';

function VisionAndMission() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="Vision and Mission: Vision: Establishing unique identity by development of high quality human and knowledge ..."/>
          <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,goals,student wellness center, map,reach, cc,guest,vision, mission,objectives,technology,Director,schorlarships,stafflist,facility,rti, right to informations,output Indicators,Indicators..."/>
        </Helmet>
        <br />
        <Heading
          content={t("heading_our_vision")}
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <ParagraphWrapper>
          <p className="paargraph">
           
           {t("our_vision_para")}
          </p>
        </ParagraphWrapper>
        <Heading
          content={t("heading_our_mission")}
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <p className="paragraph">
         
            {t("our_mission_para")}
         
        </p>
     
      </Container>
    </Fragment>
  );
}




export default VisionAndMission;
