import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import Heading from '../../../Components/Heading';
// import { useTranslation } from 'react-i18next';
import {
  ParagraphWrapper,
} from '../../../assets/css/Custom/AcademicStyle';
import { Helmet } from 'react-helmet';

function MtechAdmission() {
  // const {t} = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="CCMT-2023 Centralized Counseling for M. Tech-2023"/>
          <meta name="keywords" content="admission, mtech,nit jamshedpur,nit jsr,nit,jamshedpur,goals,student wellness center, map,reach, cc,guest,vision, mission,objectives,technology,Director,schorlarships,stafflist,facility,rti, right to informations,output Indicators,Indicators..."/>
        </Helmet>
        <br />
        <Heading
          content="M.TECH PROGRAMMES AT NIT JSR"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <div>
        <ol>
          <li>● Geotechnical Engineering-(GE) - [Intake-20]</li>
          <li>● Structural Engineering-(SU) - [Intake-20]</li>
          <li>● Water Resources Engineering-(WR) - [Intake-20]</li>
          <li>● Surface Science & Engineering-(SS) - [Intake-20]</li>
          <li>● Computer Science & Engineering-(XG) - [Intake-20]</li>
          <li>● Information Systems Security Engineering-(SH) - [Intake-20]</li>
          <li>● Communication Systems Engineering-(CS) - [Intake-20]</li>
          <li>● Embedded Systems Engineering-(EQ) - [Intake-20]</li>
          <li>● Power Electronics & Drives-(PD) - [Intake-20]</li>
          <li>● Power Systems Engineering-(PS) - [Intake-20]</li>
          <li>● Computer Integrated Design & Manufacturing - (XM) - [Intake-20]</li>
          <li>● Energy Systems Engineering-(EK) - [Intake-20]</li>
          <li>● Thermal Engineering-(TI) - [Intake-20]</li>
          <li>● Industrial Metallurgy-(IY) - [Intake-20]</li>
          <li>● Materials Technology-(UA) - [Intake-20]</li>
          <li>● Manufacturing Systems Engineering-(MN) - [Intake-20]</li>
        </ol>
        </div>
        <Heading
          content="CCMT-2023 Centralized Counseling for M. Tech-2023"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <ParagraphWrapper>
          <p style={{textAlign: 'justify'}} className="paargraph">
          CCMT is a common platform for candidates to apply for M.Tech/ M.Arch/ M.Plan programmes, based on their GATE score of years 2021, 
          2022 and 2023, in all NITs, IIEST Shibpur, IIITs and some CFTIs. For details, please refer to the CCMT website. The centralized
           system is maintained by NIC, Govt. of India to provide a common and convenient platform for online counselling wherein the 
           candidates can fill-in online application from their home and apply to all programmes in all the participating institutions (PIs)
            to which they are eligible.
          </p>
          <p>
            CCMT Website Link --- <a href="https://ccmt.admissions.nic.in/">https://ccmt.admissions.nic.in/</a>
          </p>
        </ParagraphWrapper>
        <Heading
          content="Important Links"
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
        />
        <div className="paragraph">
          <ul>
            <li><a href="https://admissions.nic.in/CCMT/applicant/Root/Home.aspx?enc=yVQCIiq12npg+pcvNJRdc9Vqahcf5cKpIFp874FzL0MhzIE+QPj2UR2kmsZOv9Al">● Registration, Fee Payment and Choice Filling for CCMT 2023 Counselling </a></li>
            <li><a href="https://admissions.nic.in/ccmt/applicant/report/SeatMatrixForm_CC.aspx?enc=Nm7QwHILXclJQSv2YVS+7rPY/av8EPo+WPkoz7QMYzBW76fMhtAxafOuQ7bDGWXT">● Seat Matrix 2023 </a></li>
            <li><a href="https://admissions.nic.in/ccmt/applicant/report/ViewSpecialEligibility.aspx?enc=Nm7QwHILXclJQSv2YVS+7rPY/av8EPo+WPkoz7QMYzBW76fMhtAxafOuQ7bDGWXT">● View Special Eligiblity/ Restrictions </a></li>
            <li><a href="https://admissions.nic.in/ccmt/applicant/report/SeatMapping.aspx?enc=Nm7QwHILXclJQSv2YVS+7rPY/av8EPo+WPkoz7QMYzBW76fMhtAxafOuQ7bDGWXT">● View Mapping of [Institute, Department, PG Program] VS [GATE Paper & Qualifying Degree] </a></li>
            <li><a href="https://admissions.nic.in/ccmt/applicant/report/ViewPIBalanceFeeList.aspx?enc=Nm7QwHILXclJQSv2YVS+7rPY/av8EPo+WPkoz7QMYzBW76fMhtAxafOuQ7bDGWXT">● Participating Institutes Fee Details </a></li>
            <li><a href="https://ccmt.admissions.nic.in/document/view-faq/">● View FAQ  </a></li>
          </ul>
        </div>

        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content="Contact"
        />
        <ParagraphWrapper>
          <div style={{textAlign: 'justify'}} className="paragraph">
          <ul>
                
                <li>Dr. Satish Kumar ---- <span className="fa fa-phone"></span>&nbsp;+91-9417923801</li>
                <li>Dr. Surajit Kundu ---- <span className="fa fa-phone"></span>&nbsp;+91-9832271039</li>
           </ul>
            </div>
        </ParagraphWrapper>
     
      </Container>
    </Fragment>
  );
}




export default MtechAdmission;
