import { styled } from "@mui/system";
import { Grid } from "@mui/material";

export const CustomAlumniGrid = styled(Grid)(({ theme }) => {
  return {
    [theme.breakpoints.up("xs")]: {
      // height: "480px",
    },
    [theme.breakpoints.up("sm")]: {
      // height: "230px",
    },
    [theme.breakpoints.up("md")]: {
      height: "240px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "240px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "270px",
    },
    borderRadius: ".5rem",

    borderColor: "blue",
    borderWidth: "2px",
  };
});
