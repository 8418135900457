import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import MainLoader from "../Components/Loader/MainLoader";

var Comp = (WrappedComponent) => {
  function LoaderHoc(props) {
    const [IsLoading, setIsLoading] = useState(true);
    const location = useLocation();
    useEffect(() => {
      // setTimeout(()=>{
      setIsLoading(false);
      // },2000)
    }, [location]);
    return (
      <>
        {IsLoading ? (
          <>
            <MainLoader />
          </>
        ) : (
          <WrappedComponent {...props} />
        )}
      </>
    );
  }
  return withRouter(LoaderHoc);
};

export default Comp;
