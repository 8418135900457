import React  from 'react';
import { useTranslation } from "react-i18next";
import Container from '../../../Components/UI/Container/index';
import Heading from '../../../Components/Heading/index';
import { SectionWrapper, TableWrapper } from '../../../assets/css/Custom/AcademicStyle';
function PublicRelation() {
  const {t} = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("cells_top_subtitle_pro")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
      <TableWrapper>
        <table className="table">
          <thead>
            <tr id="headRow">
              <th>{t("womencell_top_subtitle2_th1")}</th>
              <th>Position</th>
            </tr>
          </thead>
          <tr>
            <td >
            Mr. Sunil Kumar Bhagat
            </td>
            <td>
            Public Relation Officer
            </td>
          </tr>
        </table>
      </TableWrapper>
      {/* <SectionWrapper>
        <p className="pdflink" style={{ textAlign: 'center' }}>
          {t("default_para")}
        </p>
      </SectionWrapper> */}

      <Heading
        content={t("cells_top_subtitle")}
        style={{
          fontSize: '1.5em',
          marginBottom: '20px',
          marginTop: '13px',
          fontWeight: '500',
          background: '#274187',
          color: '#fff',
          padding: '1% 1% 1% 3%',
        }}
      />
       
      <SectionWrapper>
        <p className="pdflink" style={{ textAlign: 'center' }}>
          {t("default_para")}
        </p>
      </SectionWrapper>

    </Container>
  );
}

export default PublicRelation;
