import React, { Fragment, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from '../../theme/main';
import { ResetCSS } from '../../assets/css/style';
import {
	GlobalStyle,
	CharityWrapper,
	ContentWrapper,
} from '../../assets/css/Main/main.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../contexts/DrawerContext';
import Navbar from '../../Components/Layout/PagesNavbar';
import DrawerSection from '../../Components/Layout/DrawerSection';
import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PeopleHome from './Sections/PeopleHome';
import Deans from './Sections/Deans';
import Faculty from './Sections/Faculty';
import AssociateDeans from './Sections/AssociateDeans';
import OtherAdministration from './Sections/OtherAdministartion'
// import AssistantDeans from './Sections/AssistantDeans';
import FacultyInCharge from './Sections/FacultyInCharge';
import HOD from './Sections/HOD';
import Registrar from './Sections/Registrar';
import Staff from './Sections/Staff';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { scrollToContent } from '../../functions/scroll.js';

function AcademicIndex() {

	const { page } = useParams();
	const { t } = useTranslation();
	const [ActiveComp, setActiveComp] = useState("People Home");
	useEffect(() => {
		const pg = page.replaceAll('_', ' ');
		setActiveComp(pg);
		scrollToContent();
	}, [page]);

	return (
		<ThemeProvider theme={mainTheme}>
			<Helmet>
				<title>
					{t(`${ActiveComp.split(' ').join('_')}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
				</title>
			</Helmet>
			<Fragment>
				{/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <TopSection  setFontSize={setFontSize} />
            </Sticky> */}
				<ResetCSS />
				<GlobalStyle />
				<CharityWrapper>
					<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
						<Navbar />
					</Sticky>
					<DrawerProvider>
						<DrawerSection black="black" white="white" />
					</DrawerProvider>
					<ContentWrapper>
						<Banner heading={t(`${ActiveComp.split(' ').join('_')}`)} />
						<Container>
							{ActiveComp === 'People Home' ? <PeopleHome /> : null}
							{ActiveComp === 'Deans' ? <Deans /> : null}
							{ActiveComp === 'Associate Deans' ? <AssociateDeans /> : null}
							{/* {ActiveComp==='Assistant Deans'?<AssistantDeans/>:null} */}
							{ActiveComp === 'Faculty InCharge' ? <FacultyInCharge /> : null}
							{ActiveComp === 'Faculty' ? <Faculty /> : null}
							{ActiveComp === 'Head of Departments' ? <HOD /> : null}
							{ActiveComp === 'Registrar' ? <Registrar /> : null}
							{ActiveComp === 'Staff' ? <Staff /> : null}
							{ActiveComp === 'Other Administration' ? <OtherAdministration /> : null}
						</Container>
					</ContentWrapper>
					<Footer />
				</CharityWrapper>
				{/* End of Main wrapper section */}
			</Fragment>
		</ThemeProvider>
	);
}

export default AcademicIndex;
