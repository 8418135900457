import React from 'react'
import { Link } from 'react-router-dom';
import { peopleMenu } from '../../../assets/data';
import { PeopleWrapper } from '../../../assets/css/Custom/PeopleStyle';
import { useTranslation } from "react-i18next";
function PeopleHome() {
    const { t } = useTranslation();
    return (
        <PeopleWrapper>
            <div className="outer_people_wrapper">
                {peopleMenu.map((menu, index) =>
                    menu.text !== 'People Home' ? (
                        <div className='mycard'>
                            <Link to={menu.path} className="card_link">
                                <div className="card_img">
                                    <img src={menu.image} alt="" />
                                </div>
                                <div className="card_text">
                                    {t(`card_${menu.type}`)}
                                </div>
                            </Link>
                        </div>
                    ) : null
                )}
            </div>
        </PeopleWrapper>
    )
}

export default PeopleHome
