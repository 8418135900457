import axios from "axios";
import { BACKEND_URL } from "../assets/Constants";
import { removeDoubleSlash } from "./utils";
import { toast } from 'react-toastify';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
let getDeviceID = async () => {
    try{
        if (sessionStorage.getItem('deviceID')) {
            return sessionStorage.getItem('deviceID');
        }
        let fp = await FingerprintJS.load();
        let result = await fp.get();
        sessionStorage.setItem('deviceID', result.deviceID);
    }
    catch(err){
        return "Unknown";
    }
}

getDeviceID();

export const getRequest = async (endpoint, is_api = true) => {
    try {
        let deviceID = await getDeviceID();
        if (is_api) endpoint = "/api/" + endpoint
        if (endpoint.indexOf("http") === -1) {
            endpoint = removeDoubleSlash(BACKEND_URL + "/" + endpoint);
        }
        const response = await axios.get(endpoint, {
            headers: { 'device-id': deviceID }
        });

        return response;
    } catch (error) {
        console.log(error)
        toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: false,
        });

        // throw error;
    }
}


 