import React, { Fragment } from 'react';
import Container from '../../../Components/UI/Container';
import { useTranslation } from "react-i18next";
import Heading from '../../../Components/Heading/index';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import OrganizationalChart2 from '../../../assets/images/others/Organizational Chart2.jpg';
import { Icon } from "react-icons-kit";
import {arrowCircleDown} from 'react-icons-kit/fa/arrowCircleDown';

function OrganizationalChart() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="National Institute of Technology Jamshedpur, organizational chart"/>
          <meta name="keywords" content="nit jamshedpur,nit jsr,nit,jamshedpur,technology, organization, chart"/>
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: '1.5em',
            marginBottom: '20px',
            marginTop: '13px',
            fontWeight: '500',
            background: '#274187',
            color: '#fff',
            padding: '1% 1% 1% 3%',
          }}
          content={t("org_chart")}
        />
        <Card className='mt-5 mx-3'>
                    <Card.Img variant="center" src={OrganizationalChart2} style={{height: 620,  display: 'block', margin: '40px auto 0'}} />
        </Card>
        {/* <div>
            <img src={OrganizationalChart2} alt="Organizational Chart" style={{height: 650,  display: 'block', margin: '30px auto 0'}}/>
        </div> */}
        <br />
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Organizational Chart2.pdf">&nbsp;&nbsp;
           <b> NIT Jamshedpur Organizational Chart</b> &nbsp;
            <Icon icon={arrowCircleDown} size={22} style={{ color: 'Navy' }} /> 
        </a>

      </Container>
    </Fragment>
  );
}


export default OrganizationalChart;
