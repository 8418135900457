import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const GalleryTags = (props) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let tagArray = props.tags.split("#");
    setTags(tagArray);
  }, [props.tags]);

  return (
    <h5 style={{padding:"0 0.25rem", minHeight:"2rem",margin:"auto 0",overflow:"hidden"}}>
      {tags &&
        tags.map((tag,idx) => {
        return tag!==""?<Link key={idx} to={`/gallery?tags=${tag}`}>{`#${tag} `}</Link>:null;
        })}
    </h5>
  );
};

export default GalleryTags;
