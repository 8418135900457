import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getRequest } from "../../../functions/request";
var Req = (call_url, dependency = []) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(null);

  const callApi = async (link) => {
    setResponse(null);
    setError(<Spinner animation="border" />);
    try {
      const res = await getRequest(link);
      if (res.data.data.length > 0) {
        setResponse(res.data.data);
        setError(null);
      } else {
        setResponse(null);
        setError("No Data Found !");
      }
    } catch (err) {
      setError("Something went wrong");
    }
  };

  useEffect(() => {
    callApi(call_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);
  return [response, error];
};
export default Req;
